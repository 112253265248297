export default {
  title: {
    ar: 'اعتماد التفويض المالي',
    en: 'Record Authorization conveyance'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account Number'
  },
  accountName: {
    ar: 'اسم الحساب',
    en: 'Account name'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  accountNature: {
    ar: 'طبيعة الحساب',
    en: 'Account nature'
  },
  creditor: {
    ar: 'دائن',
    en: 'Creditor'
  },
  debit: {
    ar: 'مدين',
    en: 'Debit'
  },
  origin: {
    ar: 'أصل',
    en: 'Origin'
  },
  project: {
    ar: 'المشاريع',
    en: 'Projects'
  },
  accountClassification: {
    ar: 'تصنيف الحساب',
    en: 'Account classification'
  },
  accountingLevel: {
    ar: 'المستوى المحاسبي',
    en: 'Accounting level'
  },
  test: {
    ar: 'تجربة',
    en: 'Test'
  },  
  category: {
    ar: 'التصنيف',
    en: 'Category'
  },
  expenses: {
    ar: 'مصروفات',
    en: 'expenses'
  },
  theresnone: {
    ar: 'لايوجد',
    en: 'There is none'
  },
  creditorPurpos: {
    ar: 'الدائن لاغراض',
    en: 'creditor for purposes'
  },
  analyzing: {
    ar: 'تحليل',
    en: 'analyzing'
  },
  yes: {
    ar: 'نعم',
    en: 'Yes'
  },
  no: {
    ar: 'لا',
    en: 'No'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  ok: {
    ar: 'موافق',
    en: 'Ok'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  close: {
    ar: 'إغلاق',
    en: 'Close'
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  add: {
    ar: 'اضافة',
    en: 'Add'
  },
  manageCategory: {
    ar: 'ادارة التصنيفات',
    en: 'Manage categories'
  },
  manageSomething: {
    ar: 'ادارة شي ما',
    en: 'Manage something'
  },
  authorizationNo: {
    ar: 'رقم التفويض',
    en: 'Authorization number'
  },
  authorizedAccountNo: {
    ar: 'رقم الحساب المفوض له',
    en: 'Authorized account number'
  },
  authorizedAccount: {
    ar: 'الحساب المفوض له',
    en: 'Authorized account'
  },
  authorizationDate: {
    ar: 'تاريخ التفويض',
    en: 'Authorization Date'
  },
  authorizationStartDate: {
    ar: 'تاريخ بدأ التفويض',
    en: 'Authorization start date'
  },
  authorizationEndtDate: {
    ar: 'تاريخ انتهاء التفويض',
    en: 'Authorization end date'
  },
  authorizationStatus: {
    ar: 'حالة التفويض',
    en: 'Authorization status'
  },
  creditAccount: {
    ar: 'حساب الدائن',
    en: 'credit account'
  },
  certified: {
    ar: 'معتمد',
    en: 'Certified'
  },
  uncertified : {
    ar: 'غير معتمد',
    en: 'Uncertified '
  },
  authorizedAccount: {
    ar: 'الحساب المفوض له',
    en: 'Authorized account'
  },  
  authorizationValue: {
    ar: 'قيمة التفويض',
    en: 'Authorization value'
  },
  mainDoor: {
    ar: 'الباب الرئيسي',
    en: 'Main door'
  },
  creditorAccount: {
    ar: 'حساب الدائن',
    en: 'Creditor account'
  },
  authorizationDetailsForAccounts: {
    ar: 'تفاصيل التفويض للحسابات المقترنة',
    en: 'Authorization details for linked accounts'
  },
  linkedAccount: {
    ar: 'الحساب المقترن',
    en: 'Linked account'
  },
  authorizedValue: {
    ar: "القيمة المفوضة",
    en: 'Authorized value'
  },
  liquidatedValue: {
    ar: 'القيمة المسيلة ',
    en: 'Liquidated value'
  },
  authorization: {
    ar: 'تفويض',
    en:'Authorization'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  mainDoorNo: {
    ar: 'رقم الباب الرئيسي',
    en: 'Main door number'
  },
  totalAuthorized: {
    ar: "اجمالي التفويض",
    en: 'Total authorized'
  }
}