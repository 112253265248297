export default {
  categoryName: {
    ar: "المحاسبة",
    en: "Accounting",
  },
  title: {
    en: "Journal entry",
    ar: "جدول القيود",
  },
  entryNo: {
    ar: "رقم القيد",
    en: "Entry number",
  },
  entryDate: {
    ar: "تاريخ القيد",
    en: "Entry date",
  },
  surplus: {
    ar: "فائض في خزينة الصين",
    en: "The surplus in the treasury of China",
  },
  creditor: {
    ar: "دائن",
    en: "Creditor",
  },
  debtor: {
    ar: "مدين",
    en: "Debtor",
  },
  account: {
    ar: "الحساب",
    en: "Account",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  costCenter: {
    ar: "مركز التكلفة",
    en: "Cost center",
  },
  currencyValue: {
    ar: "قيمة العملة",
    en: "Currency value",
  },
  print: {
    ar: "طباعة",
    en: "Print",
  },
  printPdf: {
    ar: "طباعة بي دي اف",
    en: "Print pdf",
  },
  attachments: {
    ar: "المرفقات",
    en: "Attachments",
  },
  manual: {
    ar: "يدوي",
    en: "Manual",
  },
  review: {
    ar: "مراجعة",
    en: "Review",
  },
  totalDebit: {
    ar: "اجمالي مدين",
    en: "Total debit",
  },
  totalCredit: {
    ar: "اجمالي دائن",
    en: "Total credit",
  },
  difference: {
    ar: "الفرق",
    en: "The difference",
  },
  entryType: {
    ar: "نوع القيد",
    en: "Entry type",
  },
  actions: {
    ar: "العمليات",
    en: "Actions",
  },
  entryState: {
    ar: "حالة القيد",
    en: "Entry state",
  },
  date: {
    ar: "التاريخ",
    en: "Date",
  },
  currency: {
    ar: "العملة",
    en: "Currency",
  },
  entry: {
    ar: "قيد",
    en: "Entry",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا القيد ؟",
    en: "Are you sure you want to delete this entry ?",
  },
  shipmentType: {
    ar: "نوع الشحن",
    en: "Shipment type",
  },
  from: {
    ar: "من",
    en: "From",
  },
  to: {
    ar: "الى",
    en: "To",
  },
  receiptNumberDisplayed: {
    ar: "رقم الإيصال",
    en: "Receipt Number Displayed",
  },
};
