import React, { useEffect } from 'react'
import {
  CardBody,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Controller } from "react-hook-form";
import content from '../content';

const UpdateAccount = props => {
  const { 
    watch,
    culture, 
    control, 
    errors, 
    accountCategories,
    accountLevels
  } = props;

  const digits = watch('digits');

  return (
    <CardBody>
      <Row>
        <Col md="4">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="name"
            >
              {content.name[culture]}
            </label>
            <Controller
              control={control}
              name="name"
              rules={{required: `${content.name[culture]} field is required`}}
              render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                  {...field}
                  id="name"
                  type="text"
                  className={errors.name && "error"}
                  value={value || ""}
                  onChange={({ target: { value }}) => onChange(value)}
                  />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="accountSelected"
            >
              {content.accountNo[culture]}
            </label>
            <Controller
              control={control}
              name="number"
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  id="accountSelected1"
                  type="number"
                  disabled
                  value={value || ""}
                  onChange={({ target: { value }}) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="accountLevelId"
            >
              {content.accountingLevel[culture]}
            </label>
            <Controller
              control={control}
              name="accountLevelId"
              rules={{required: `${content.name[culture]} field is required`}}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  id="accountLevelId"
                  disabled
                  type="select"
                  className={errors.accountLevelId && "error"}
                  value={value || "disabled"}
                  onChange={({ target: { value }}) => onChange(value)}
                >
                  <option disabled value="disabled">-- {content.selectOption[culture]} --</option>
                  {accountLevels.map(item => (
                    <option value={item.id} key={item.id}>{item.name}</option>
                  ))}
                </Input>
              )}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="accountCategories"
            >
              {content.category[culture]}
            </label>
            <Controller
              control={control}
              name="accountCategoryId"
              rules={{required: `${content.name[culture]} field is required`}}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  id="accountCategories"
                  type="select"
                  className={errors.accountCategoryId && "error"}
                  value={value || "disabled"}
                  onChange={({ target: { value }}) => onChange(value)}
                >
                  <option disabled value="disabled">-- {content.selectOption[culture]} --</option>
                  {accountCategories.map(category => (
                    <option value={category.id} key={category.id}>{category.name}</option>
                  ))}
                </Input>
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </CardBody>
  )
}

export default UpdateAccount