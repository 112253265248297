import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";

const content = {
  done: {
    ar: "تمت العملية بنجاح",
    en: "Done",
  },
};

const useCreate = (
  apiFun,
  data,
  setData,
  setSpinner,
  sweetAlert,
  reset = () => {}
) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const createHandler = async (formData) => {
    setSpinner(true);
    const res = await apiFun.request(formData);
    if (res.status !== 200 && res.status !== 201) return setSpinner(false);
    const newData = [res.data.data, ...data];
    setData(newData);
    setSpinner(false);
    sweetAlert(content.done[culture]);
    reset();
  };


  return createHandler;
};

export default useCreate;
