import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  Link,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from 'components/common/CustomTable';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";

const AccountingLevelDetails = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [alert, setAlert] = useState(null);

  const { id } = useParams();

  const headers = [
    content.accountNumber[culture],
    content.name[culture],
    content.accountNature[culture],
    content.accountCategory[culture],
    content.accountingLevel[culture],
    content.group[culture],
    content.analytical[culture],
    content.actions[culture],
  ];

  const data2 = [
    { id: 1, accountNumber: 1, name: 'باب المرتبات', accountNature: 'مدين', accountCategory: 'اصل', accountingLevel: 'باب رئيسي', group: 'لايوجد', analytical: 'لا'},
    { id: 2, accountNumber: 1, name: 'المرتبات الرئيسية', accountNature: 'مدين', accountCategory: 'اصل', accountingLevel: 'باب رئيسي', group: 'لايوجد', analytical: 'لا'},
  ];

  const columns = [
    "number",
    "name",
    "accountNature",
    "accountClassificaiton",
    "accountingLevel",
    "group",
    "isAnalytical",
  ]

  return (
    <div>
      {alert}
      {loading ? 
        <RequestHandler
          fn={async () => {
            const result = await axios.get(`${env.activeServer()}/AccountLevels/Details/${id}`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
                'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
              }
            })

            if (result.status === 200) {

                result.data.data.accounts.forEach((account, index) => {
                  // add account level in every account 
                  result.data.data.accounts[index].accountingLevel = result.data.data.name;
                  // replace true and flase from boolean to text
                  result.data.data.accounts[index].isAnalytical = account.isAnalytical ? content.true[culture]: content.false[culture];
                })
                setData(result.data.data);
            }
          }}
          setAlert={setAlert}
          setLoading={setLoading}
          gate="#11cdef"
          bg="#fff"
        />
      :<>
        <SimpleHeader name={content.title[culture]} parentName="" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
            </CardHeader>
            <CardBody>
              <Row className='align-items-center'>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.previousLevel[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      disabled={true}
                      value={"first"}
                      onChange={e => {}}
                    >
                      <option value="first">{data.prevLevel?.name || content.none[culture]}</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.NoOfDigits[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="number"
                      disabled={true}
                      value={data.digits}
                      onChange={e => {}}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.name[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="text"
                      disabled={true}
                      value={data.name}
                      onChange={e => {}}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.accountNumber[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="number"
                      value={"0"}
                      onChange={e => {}}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.accountNature[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      value={"first"}
                      onChange={e => {}}
                    >
                      <option disabled value> -- {content.selectOption[culture]} -- </option>
                      <option value="first">{content.creditor[culture]}</option>
                      <option value="second">{content.debit[culture]}</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.accountCategory[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      value={"first"}
                      onChange={e => {}}
                    >
                      <option disabled value> -- {content.selectOption[culture]} -- </option>
                      <option value="first">{content.origin[culture]}</option>
                      <option value="second">{content.account[culture]}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button className="btn-neutral mr-2" color="success" size="md">
                  <span className="btn-inner--icon mr-1 ml-1">
                    <i className="fab fa-sistrix"></i>
                  </span>
                  <span>
                    {content.search[culture]}
                  </span>
                </Button>
                <Button className="mr-2" color="info" size="md">
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>
                    {content.addAccount[culture]}
                  </span>
                </Button>
              </Row>
              <h5 className='mt-5'>{content.linkedAccounts[culture]}</h5>
            </CardBody>
            <Table 
              headers={headers}
              data={data.accounts || []}
              columns={columns}
            > 
              <span to={`/${culture}-admin/accounting/main`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="fas fa-info-circle fa-lg hover-warning"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="details1"
                >
                  {content.details[culture]}
                </UncontrolledTooltip>
              </span>
              <span className="mr-2 mr-3 ml-3">
                <div id="delete" className="table-action-delete table-action cursor-pointer hover-danger">
                  <i className="fas fa-trash fa-lg"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="delete"
                >
                  {content.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Card>
        </Container>
      </>}
    </div>
  )
}

export default AccountingLevelDetails
