import client from "./client";

const endpoint = "/v1/configuration";

const preload = () => client.get(`${endpoint}/preload`);

const createOne = (schema) => client.post(`${endpoint}`, schema);

const getAll = (pageNo, docsPerPage) =>
  client.get(`${endpoint}?page=${pageNo}&PostsPerPage=${docsPerPage}`);


const getOne = (id) => client.get(`${endpoint}/${id}`);

const getActive = (id) => client.get(`${endpoint}/ActiveConfiguration`);

const updateOne = (id, schema) => client.put(`${endpoint}/${id}`, schema);


export default {
  preload,
  createOne,
  getAll,
  getOne,
  getActive,
  updateOne
};
