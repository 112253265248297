import { useRef, useEffect, useState } from "react";

function useFilter({
  apiFun,
  pageNo,
  docsPerPage,
  setData = () => {},
  editResponse = () => {},
  setPageNo = () => {},
}) {
  const isSearch = useRef(false);
  const searchedData = useRef({});
  // const isSearchClicked = useRef(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const handleSearch = async (data) => {
    searchedData.current = data;
    const res = await apiFun.request(data, 1, docsPerPage);
    if (res.status === 200) {
      res.data.data.forEach(editResponse);
      setData(res.data.data);
      setIsSearchClicked(true);
      isSearch.current = true;
      setPageNo(1);
    }
  };

  const paginationSearh = async () => {
    const res = await apiFun.request(searchedData.current, pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data.forEach(editResponse);
      setData(res.data.data);
      isSearch.current = true;
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSearchClicked(false);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [isSearchClicked]);

  useEffect(() => {
    let controller = new AbortController();
    if (isSearch.current && !isSearchClicked) paginationSearh();

    return () => controller?.abort();
  }, [pageNo, docsPerPage]);

  return { handleSearch, isSearch: isSearch.current };
}

export default useFilter;
