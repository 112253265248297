import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import classes from "./List.module.css";
import { UncontrolledTooltip } from "reactstrap";
import content from "../content";
import { useHistory } from "react-router";
import usePermissions from "hooks/usePermissions";

const List = (props) => {
  const {
    culture,
    account,
    parentLvl,
    getChildren,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleAccumulationBalance,
    maxAccountLevel,
    noIcons,
  } = props;

  const history = useHistory();

  const checkPermission = usePermissions("Account");

  const [loading, setLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [thisLevel] = useState(parentLvl + 1 || 1);

  const openedClass =
    isOpened || loading
      ? classes[`${culture}-opened`]
      : classes[`${culture}-closed`];

  const handleOpen = () => {
    setLoading(true);
    // if the account doesn't have a subLink attribute send the request
    if (!account.hasOwnProperty("subAccounts")) {
      getChildren(account.id, account.number);
    }

    setIsOpened((open) => !open);
  };

  const accountCondition = account.hasOwnProperty("subAccounts")
    ? account.subAccounts?.length
    : true;

  const isMaxLevel = thisLevel === maxAccountLevel;

  const icon =
    isMaxLevel || !accountCondition ? (
      <button className={classes.listBtn}>
        <i className={`fas fa-book-open ${classes.icon}`} />
      </button>
    ) : (
      <button className={classes.listBtn}>
        <i className={`fas fa-sort-down ${classes.icon} ${openedClass}`}></i>
      </button>
    );

  useEffect(() => {
    if (account.hasOwnProperty("subAccounts")) setLoading(false);
  }, [isOpened]);

  useEffect(() => {
    if (props.account.hasOwnProperty("subAccounts")) setLoading(false);
  }, [props]);

  return (
    <ul className={classes.list}>
      <li>
        <div
          className={`${classes.content} ${classes[`level-${thisLevel}`]}`}
          onClick={isMaxLevel ? () => {} : handleOpen}
        >
          {icon}
          <h5>
            {account.number} {account.name}
          </h5>
          <div
            className={classes.iconsWrapper}
            onClick={(e) => e.stopPropagation()}
          >
            {!noIcons &&
              account.accountClassification === 1 &&
              checkPermission("CreateAsync") && (
                <>
                  <span className="cmr-2">
                    <button
                      id="add"
                      onClick={handleCreate.bind(null, account, thisLevel)}
                    >
                      <i className="fas fa-plus-circle hover-success" />
                    </button>
                    <UncontrolledTooltip delay={0} target="add">
                      {content.add[culture]}
                    </UncontrolledTooltip>
                  </span>
                </>
              )}
            {checkPermission("UpdateAsync") && (
              <span className="cmr-2">
                <button
                  id="edit1"
                  onClick={handleUpdate.bind(null, account, thisLevel)}
                >
                  <i className="fas fa-pencil-alt hover-info" />
                </button>
                <UncontrolledTooltip delay={0} target="edit1">
                  {content.edit[culture]}
                </UncontrolledTooltip>
              </span>
            )}
            <span className="cmr-2">
              <button
                id="accumulationBalance"
                onClick={handleAccumulationBalance.bind(
                  null,
                  account,
                  thisLevel
                )}
              >
                <i className="fas fa-file-invoice-dollar hover-primary" />
              </button>
              <UncontrolledTooltip delay={0} target="accumulationBalance">
                {content.accumulationBalance[culture]}
              </UncontrolledTooltip>
            </span>
            <span className="cmr-2">
              <button
                id="details"
                onClick={() =>
                  history.push(
                    `/${culture}-admin/Accounting/account/${account.id}`
                  )
                }
              >
                <i className="fas fa-eye hover-warning"></i>
              </button>
              <UncontrolledTooltip delay={0} target="details">
                {content.accountDetails[culture]}
              </UncontrolledTooltip>
            </span>
            {checkPermission("DeleteAsync") && (
              <span
                className="cmr-2"
                onClick={handleDelete.bind(null, account.id)}
              >
                <button id="delete1">
                  <i className="fas fa-trash-alt hover-danger"></i>
                </button>
                <UncontrolledTooltip delay={0} target="delete1">
                  {content.delete[culture]}
                </UncontrolledTooltip>
              </span>
            )}
          </div>
        </div>
        {loading && (
          <ReactLoading
            type="spin"
            color="#eee"
            style={{
              fill: "var(--gray)",
              height: "30px",
              width: "30px",
            }}
          />
        )}
        {isOpened &&
          account.subAccounts &&
          account.subAccounts.map((item) => (
            <List
              key={item.id}
              account={item}
              culture={culture}
              getChildren={getChildren}
              handleCreate={handleCreate}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              handleAccumulationBalance={handleAccumulationBalance}
              maxAccountLevel={maxAccountLevel}
              parentLvl={thisLevel}
            />
          ))}
      </li>
    </ul>
  );
};

export default List;
