export default {
  delete: {
    ar: "حذف",
    en: "delete",
  },
  option1: {
    ar: "نعم",
    en: "Yes",
  },
  submit: {
    ar: "ارسال",
    en: "Submit",
  },
  subject: {
    ar: "الموضوع",
    en: "Subject",
  },
  description: {
    ar: "التفاصيل",
    en: "Description",
  },

  approve: {
    ar: "اعتماد",
    en: "Approve",
  },
  createAndSend: {
    ar: "إنشاء وإرسال",
    en: "Create And send",
  },

  option2: {
    ar: "لا",
    en: "No",
  },
  cancel: {
    ar: "إلغاء",
    en: "cancel",
  },
  alignt: {
    ar: "right",
    en: "left",
  },

  lead: {
    ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
    en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account",
  },

  alignt: {
    ar: "right",
    en: "left",
  },
  account: {
    ar: "الحساب",
    en: "Account",
  },
  totalCreditLYD: {
    ar: "إجمالي الدائن بالدينار",
    en: "totalCreditLYD",
  },
  totalCreditUSD: {
    ar: "إجمالي الدائن بالدولار",
    en: "totalCreditUSD",
  },
  totalDebitLYD: {
    ar: "إجمالي المدين بالدينار",
    en: "totalDebitLYD",
  },
  totalDebitUSD: {
    ar: "إجمالي المدين بالدولار",
    en: "totalDebitUSD",
  },
  accountNature: {
    ar: "طبيعة الحساب",
    en: "Account Nature",
  },
  accountClassification: {
    ar: "تصنيف الحساب",
    en: "Acc.Classification",
  },
  accountName: {
    ar: "إسم الحساب",
    en: "Account Name",
  },

  align: {
    ar: "left",
    en: "right",
  },

  add: {
    ar: "إضافة",
    en: "Add",
  },
  search: {
    ar: "بحث",
    en: "Search",
  },
  save: {
    ar: "حفظ",
    en: "Save",
  },
  saveAndConfirm: {
    ar: "حفظ واعتماد",
    en: "Save and confirm",
  },
  copy: {
    ar: "نسخ",
    en: "Copy",
  },

  print: {
    ar: "طباعة",
    en: "Print",
  },
  view: {
    ar: "عرض",
    en: "View",
  },
  edit: {
    ar: "تعديل",
    en: "Edit",
  },

  deleting: {
    ar: "حذف",
    en: "Delete",
  },

  printTip: {
    ar: "سيؤدي هذا إلى فتح صفحة طباعة بالصفوف المرئية من الملف",
    en: "This will open a print page with the visible rows of the table.",
  },

  copyTip: {
    ar: "سيؤدي هذا إلى نسخ الصفوف المرئية من الجدول",
    en: "This will copy to your clipboard the visible rows of the table.",
  },
  resetTip: {
    ar: "سيؤدي هذا إلى إعادة الخانات للوضع السابق",
    en: "This will reset all the fields above.",
  },
  saveTip: {
    ar: "سيؤدي هذا إلى حفظ هذه الصفحة بالصفوف المرئية من الملف",
    en: "This will save the page with the visible rows of the table.",
  },

  submitTip: {
    ar: "سيؤدي هذا إلى إرسال الصفوف المرئية من الجدول",
    en: "This will submit the visible rows of the table.",
  },

  alertMes1: {
    ar: "! أحسنت",
    en: "Good job!",
  },

  alertMes2: {
    ar: "! تم النسخ",
    en: "Copied to clipboard!",
  },

  show: {
    ar: "عرض",
    en: "Show",
  },

  entries: {
    ar: "إدخالات",
    en: "entries",
  },

  close: {
    ar: "إغلاق",
    en: "Close",
  },
  editForm: {
    ar: "نموذج التحليل ",
    en: "Edit form",
  },
  Reset: {
    ar: "إعادة تعيين",
    en: "Reset",
  },
  addedAlert: {
    ar: "تمت الإضافة بنجاح",
    en: "added successfully",
  },
  deleteAlert: {
    ar: "تم الحذف بنجاح",
    en: "deleted successfully",
  },
  editAlert: {
    ar: "تم التعديل بنجاح",
    en: "edited successfully",
  },
  sureDelete: {
    ar: "هل أنت متأكد؟",
    en: "Are you sure?",
  },
  yesDelete: {
    ar: "نعم, قم بالمسح",
    en: "Yes, delete it!",
  },
  delete2: {
    ar: "لا يمكنك استعادته مجددا",
    en: "You won't be able to revert this!",
  },
  Success: {
    ar: "تمت بنجاح",
    en: "Success",
  },
  create: {
    ar: "إنشاء",
    en: "Create",
  },
  reassign: {
    ar: "تخصيص",
    en: "reassign",
  },
  Return: {
    ar: "إسترداد البيانات",
    en: "Return",
  },
  yesSave: {
    ar: "نعم قم بالحفظ",
    en: "yer, Save",
  },
  sureSave: {
    ar: "إذا قمت بالحفظ لا يمكنك تعديل البيانات المدخلة",
    en: "if you save this , you can not edit or delete on this again ",
  },
  back: {
    ar: "رجوع",
    en: "Back",
  },
  docPerPage: {
    ar: "عدد البيانات في كل صفحة",
    en: "docement Per Page",
  },
  upload: {
    ar: "تحميل",
    en: "Upload",
  },
  saveAndApprove: {
    ar: "حفظ واعتماد",
    en: "Save and Approve",
  },
  showEntryDetails: {
    ar: "تفاصيل القيد",
    en: " Entry Details",
  },
  name: {
    ar: "إسم الحساب",
    en: "Account name",
  },
  code: {
    ar: "رمز الحساب",
    en: "Account No.",
  },

  arLanguage: {
    ar: "العربية",
    en: "Ar",
  },

  enLanguage: {
    ar: "الإنجليزية",
    en: "En",
  },

  new: {
    ar: "جديد",
    en: "New",
  },
  weight: {
    ar: "الوزن",
    en: "Weigth",
  },
  volume: {
    ar: "الحجم",
    en: "Volume",
  },

  folder: {
    ar: "مجلد",
    en: "Folder",
  },

  newFolder: {
    ar: "مجلد جديد",
    en: "New folder",
  },

  showDetails: {
    en: "Show details",
    ar: "مزيد من التفاصيل",
  },
  shipmentNumber: {
    ar: "رقم الشحنة",
    en: "Shipment Number",
  },
  usdAmount: {
    ar: "المقدار بالدولار",
    en: "USD amount",
  },
  swiftImage: {
    ar: "صورة سريعة",
    en: "Swift image",
  },

  fileUpload: {
    ar: "تحميل ملف",
    en: "File upload",
  },
  uploadImg: {
    ar: "تحميل صورة",
    en: "Upload img",
  },

  update: {
    en: "Update",
    ar: "تحديث",
  },
  updateState: {
    ar: "تحديث الحالة",
    en: "Update State",
  },
  updateInvoice: {
    ar: "هل انت متأكد من تحديث هذه الفاتورة ؟",
    en: "You are sure you want to Update this Invoice ?",
  },
  description: {
    ar: "التفاصيل",
    en: "Description",
  },
  client: {
    ar: "العميل",
    en: "Client",
  },
  subject: {
    ar: "الموضوع",
    en: "Subject",
  },

  actions: {
    ar: "العمليات",
    en: "Actions",
  },

  name: {
    ar: "الاسم",
    en: "Name",
  },
  date: {
    ar: "التاريخ",
    en: "Date",
  },
  fullName: {
    ar: "الاسم الكامل",
    en: "fullName",
  },
  phone: {
    ar: "رقم الهاتف",
    en: "Phone",
  },

  whatsupPhone: {
    ar: "رقم هاتف الواتس اب",
    en: "WhatsupPhone",
  },

  generate: {
    ar: "انشاء",
    en: "Generate",
  },
  rate: {
    ar: "المعدل",
    en: "Rate",
  },

  folderUpload: {
    ar: "تحميل مجلد",
    en: "Folder upload",
  },
  commodity: {
    ar: "السلعة",
    en: "Commodity",
  },
  commodityType: {
    ar: "نوع السلعة",
    en: "Commodity type",
  },
  numOfPackages: {
    ar: "عدد الحزم",
    en: "Number of Packages",
  },
  POL: {
    ar: "الختمة",
    en: "POL",
  },
  pay: {
    ar: "الدفع",
    en: "Pay",
  },

  supplierContact: {
    ar: "رقم هاتف المورد",
    en: "Supplier Contact",
  },

  price: {
    ar: "السعر",
    en: "Price",
  },

  priceType: {
    ar: "نوع السعر",
    en: "Price Type",
  },
  editPrice: {
    ar: "تعديل السعر",
    en: "Edit Price",
  },

  transaction: {
    ar: "العملية التجارية",
    en: "Transaction",
  },
  total: {
    ar: "الاجمالي",
    en: "Total",
  },

  fee: {
    ar: "العمولة",
    en: "Fee",
  },

  sellPrice: {
    ar: "سعر البيع",
    en: "Sell Price",
  },

  search: {
    ar: "بحث",
    en: "Search",
  },

  selectOption: {
    ar: "اختيار حقل",
    en: "Select Option",
  },
  confirm: {
    ar: "تأكيد",
    en: "Confirm",
  },

  align: {
    ar: "left",
    en: "right",
  },

  alignt: {
    ar: "right",
    en: "left",
  },

  close: {
    ar: "إغلاق",
    en: "Close",
  },

  cancel: {
    ar: "إلغاء",
    en: "Cancel",
  },
  canceled: {
    en: "Canceled",
    ar: "ألغيت",
  },

  create: {
    ar: "إنشاء",
    en: "Create",
  },

  folderName: {
    ar: "إسم المجلد",
    en: "Folder name",
  },

  fileName: {
    ar: "إسم الملف",
    en: "File name",
  },

  uploadTracking: {
    ar: "تتبع التحميل",
    en: "Upload tracking",
  },

  upload: {
    ar: " تحميل",
    en: "Upload",
  },

  file1: {
    ar: "ملف 1",
    en: "File 1",
  },

  file2: {
    ar: "ملف 2",
    en: "File 2",
  },

  chooseFile: {
    ar: "اختر ملف",
    en: "Choose file",
  },

  chooseFiles: {
    ar: "اختر ملف",
    en: "Choose files",
  },
  title: {
    ar: "ملفات قابلة للمشاركة",
    en: "Shareable Files",
  },

  alignt: {
    ar: "right",
    en: "left",
  },

  lead: {
    ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
    en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account",
  },

  fileName: {
    ar: "إسم الملف",
    en: "File name",
  },

  filesIveCreated: {
    ar: "ملفات قمت بإنشائها ",
    en: "Files I've Created",
  },

  datecreated: {
    ar: "تاريخ الإنشاء",
    en: "Date created",
  },

  size: {
    ar: "الحجم",
    en: "Size",
  },

  owner: {
    ar: "المنشئ",
    en: "Owner",
  },

  lastModified: {
    ar: "آخر تعديل",
    en: "Last modified",
  },

  language: {
    ar: "ar",
    en: "en",
  },

  align: {
    ar: "left",
    en: "right",
  },

  yes: {
    ar: "نعم",
    en: "Yes",
  },

  no: {
    ar: "لا",
    en: "No",
  },

  add: {
    ar: "إضافة",
    en: "Add",
  },
  search: {
    ar: "بحث",
    en: "search",
  },

  copy: {
    ar: "نسخ",
    en: "Copy",
  },

  print: {
    ar: "طباعة",
    en: "Print",
  },

  edit: {
    ar: "تعديل",
    en: "Edit",
  },

  deleting: {
    ar: "حذف",
    en: "Delete",
  },

  delete: {
    ar: "حذف",
    en: "Delete",
  },

  move: {
    ar: "نقل",
    en: "Move",
  },

  copy: {
    ar: "نسخ",
    en: "Copy",
  },

  download: {
    ar: "تنزيل",
    en: "Download",
  },

  done: {
    ar: "تمت العملية بنجاح",
    en: "Done",
  },

  success: {
    ar: "تمت بنجاح",
    en: "Success",
  },

  view: {
    ar: "",
    en: "View",
  },

  approve: {
    en: "Approve",
    ar: "اعتماد",
  },

  printTip: {
    ar: "سيؤدي هذا إلى فتح صفحة طباعة بالصفوف المرئية من الملف",
    en: "This will open a print page with the visible rows of the table.",
  },

  copyTip: {
    ar: "سيؤدي هذا إلى نسخ الصفوف المرئية من الجدول",
    en: "This will copy to your clipboard the visible rows of the table.",
  },

  resetTip: {
    ar: "سيؤدي هذا إلى إعادة الخانات للوضع السابق",
    en: "This will reset all the fields above.",
  },

  reset: {
    ar: "إعادة تعيين",
    en: "Reset",
  },

  alertMes1: {
    ar: "! أحسنت",
    en: "Good job!",
  },

  alertMes2: {
    ar: "! تم النسخ",
    en: "Copied to clipboard!",
  },

  show: {
    ar: "عرض",
    en: "Show",
  },

  entries: {
    ar: "إدخالات",
    en: "entries",
  },

  title: {
    ar: "أنواع الملفات ",
    en: " File Types",
  },

  alignt: {
    ar: "right",
    en: "left",
  },

  lead: {
    ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
    en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account",
  },

  fileName: {
    ar: "إسم الملف",
    en: "File name",
  },

  filesIveCreated: {
    ar: "ملفات قمت بإنشائها ",
    en: "Files I've Created",
  },

  datecreated: {
    ar: "تاريخ الإنشاء",
    en: "Date created",
  },

  size: {
    ar: "الحجم",
    en: "Size",
  },

  owner: {
    ar: "المنشئ",
    en: "Owner",
  },

  lastModified: {
    ar: "آخر تعديل",
    en: "Last modified",
  },

  align: {
    ar: "left",
    en: "right",
  },

  yes: {
    ar: "نعم",
    en: "Yes",
  },

  no: {
    ar: "لا",
    en: "No",
  },

  add: {
    ar: "إضافة",
    en: "Add",
  },

  search: {
    ar: "بحث",
    en: "search",
  },

  copy: {
    ar: "نسخ",
    en: "Copy",
  },

  print: {
    ar: "طباعة",
    en: "Print",
  },

  deleting: {
    ar: "حذف",
    en: "Delete",
  },

  alertMes1: {
    ar: "! أحسنت",
    en: "Good job!",
  },

  alertMes2: {
    ar: "! تم النسخ",
    en: "Copied to clipboard!",
  },

  show: {
    ar: "عرض",
    en: "Show",
  },

  country: {
    ar: "البلد",
    en: "Country",
  },

  entries: {
    ar: "إدخالات",
    en: "entries",
  },

  ownerName: {
    ar: " إسم المالك",
    en: "Owner name",
  },

  fileType: {
    ar: "نوع الملف",
    en: "File type",
  },
  permissionType: {
    ar: "نوع الصلاحيات",
    en: "Permission type",
  },
  file: {
    ar: "الملف",
    en: "File",
  },

  close: {
    ar: "إغلاق",
    en: "Close",
  },

  next: {
    ar: "التالي",
    en: "Next",
  },

  save: {
    ar: "حفظ",
    en: "Save",
  },

  language: {
    ar: "ar",
    en: "en",
  },
  has: {
    ar: "لديه",
    en: "has",
  },
  accessPermission: {
    ar: "صلاحيات الوصول",
    en: "Access permission",
  },
  editPermission: {
    ar: "صلاحيات التعديل",
    en: "Edit permission",
  },
  deletePermission: {
    ar: "صلاحيات الازالة",
    en: "Delete permission",
  },
  deleteInvoice: {
    ar: "هل انت متأكد من حذف الفاتورة ؟",
    en: "Are you sure you want to delete this invoice ?",
  },
  deleteRowPremission: {
    ar: "هل انت متأكد من رغبتك في حذف الحقل ؟",
    en: "Are you sure you want to delete this Row ?",
  },
  createPermission: {
    ar: "صلاحيات الانشاء",
    en: "Create Permission",
  },
  sharePermission: {
    ar: "صلاحيات المشاركة",
    en: "Share permission",
  },
  employees: {
    ar: "الموظفين",
    en: "Employees",
  },
  ok: {
    ar: "موافق",
    en: "Ok",
  },
  editForm: {
    ar: "نموذج التعديل",
    en: "Edit form",
  },
  code: {
    ar: "الرمز",
    en: "code",
  },
  branch: {
    ar: "الفرع",
    en: "Branch",
  },
  country: {
    ar: "البلد",
    en: "Country",
  },
  phone: {
    ar: "رقم الهاتف",
    en: "Phone",
  },
  type: {
    ar: "النوع",
    en: "Type",
  },
  selectAnOption: {
    ar: "اختر خيارا",
    en: "Select an option",
  },
  broker: {
    ar: "المورد",
    en: "Broker",
  },
  customer: {
    ar: "الزبون",
    en: "Customer",
  },
  orderNumber: {
    ar: "رقم الطلب",
    en: "Order number",
  },
  number: {
    ar: "الرقم",
    en: "Number",
  },
  calculate: {
    ar: "إحتساب",
    en: "Calculate",
  },
  followUpStatus: {
    ar: "حالة الشحنة",
    en: "Follow up status",
  },
  supplyContact: {
    ar: "رقم المورد",
    en: "Supply contact",
  },
  bankAccount: {
    ar: "حساب المصرف",
    en: "Bank Account",
  },
  isPaid: {
    ar: "هل تم الدفع",
    en: "isPaid",
  },
  user: {
    ar: "المستخدم",
    en: "User",
  },
  continue: {
    ar: "استمرار",
    en: "continue",
  },
  date: {
    ar: "التاريخ",
    en: "Date",
  },
  customerCode: {
    ar: "رمز الزبون",
    en: "Customer code",
  },
  customerName: {
    ar: "اسم الزبون",
    en: "Customer name",
  },
  contacted: {
    ar: "تم الاتصال",
    en: "Contacted",
  },
  orderId: {
    ar: "معرٌف الطلب",
    en: "Order Id",
  },
  rmbAmount: {
    ar: "المقدار بالعملةالصينية",
    en: "rmb amount",
  },
  spCode: {
    ar: "كود الsp",
    en: "spCode",
  },
  shaheenPay: {
    ar: "شاهين باي",
    en: "Shaheen Pay",
  },
  currency: {
    ar: "العملة",
    en: "Currency",
  },
  clear: {
    ar: "مسح",
    en: "Clear",
  },
  value: {
    ar: "القيمة",
    en: "Value",
  },
  entry: {
    ar: "القيد",
    en: "Entry",
  },
  entryType: {
    ar: "نوع القيد",
    en: "Entry type",
  },
  to: {
    ar: "الى",
    en: "To",
  },
  ShipmentPayment: { ar: "سداد شحن", en: "Shipping Payment" },
  InternalTransfer: { ar: "حوالة داخلية", en: "Internal Transfer" },
  MoneyTransferOnly: { ar: "حوالة فقط", en: "Money Transfer Only" },
  CustomerLoan: { ar: "صرف دين لعميل", en: "Customer Loan" },
  MoneyTransferForOrder: { ar: "حوالة لشحنة", en: "Money Transfer For Order" },
  ShippingPayment: { ar: "سداد شحن", en: "Shipping Payment" },
  Custody: { ar: "عهدة", en: "Custody" },
  Loan: { ar: "سلفة", en: "Loan" },
  Expenses: { ar: "مصروفات", en: "Expenses" },
  Salary: { ar: "مرتبات", en: "Salary" },
  BalanceTransfer: { ar: "نقل ارصدة", en: "Balance Transfer" },
  CustomerPayment: { ar: "ايداع زبون", en: "Customer Payment" },
  Manual: { ar: "قيد يدوي", en: "Manual" },
  Shipment: { ar: "شحنة", en: "Shipment" },
  CustomerLoanPayment: { ar: "سداد دين عميل", en: "Customer Loan Payment" },
  BrokerPayment: { ar: "سداد بروكر", en: "Broker Payment" },
  ResizingOrder: { ar: "إعادة تحجيم", en: "Resizing Order" },
  RepricingOrder: { ar: "إعادة تسعير", en: "Repricing Order" },
  AdjustmentRecompense: {
    ar: "تعويض تعديل طلبية",
    en: "Adjustment Recompense",
  },
  BulkExpenses: {
    ar: "مصروفات البلك",
    en: "Bulk expenses",
  },
  TransfersClaim: { ar: "إستحقاق حوالة", en: "Transfers Claim" },
  SettlementTransaction: { ar: "حركة تسوية ", en: "Settlement Transaction" },
  BrokerPayment: { ar: "مصروفات بلك", en: "Bulk Expenses" },
  Normal: {
    ar: "طبيعي",
    en: "Normal",
  },
  Bank: {
    ar: "مصرف",
    en: "Bank",
  },
  Treasury: {
    ar: "خزينة",
    en: "Treasury",
  },
  Employee: {
    ar: "موظف",
    en: "Employee",
  },
  Person: {
    ar: "شخصي",
    en: "Person",
  },
  Customer: {
    ar: "العميل",
    en: "Customer",
  },
  Expenses: {
    ar: "مصروفات",
    en: "Expenses",
  },
  Revenue: {
    ar: "ايراد",
    en: "Revenue",
  },
  Broker: {
    ar: "بروكر",
    en: "Broker",
  },
  shipmentType: {
    ar: "نوع الشحن",
    en: "Shipment type",
  },
  None: {
    ar: "غير شحنه",
    en: "None",
  },
  Air: {
    ar: "جوي",
    en: "Air",
  },
  LCL: {
    ar: "بحري مشترك",
    en: "LCL",
  },
  FCL: {
    ar: "حاوية كاملة",
    en: "FCL",
  },
  branch: {
    ar: "الفرع",
    en: "Branch",
  },
  costCenter: {
    ar: "مركز التسعير",
    en: "Cost center",
  },
};
