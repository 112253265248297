import React from "react";
import classnames from "classnames";
// reactstrap components
import { Button, ButtonGroup } from "reactstrap";
import content from "./content";
//import { getCulture } from 'utils/getCulture';
import env from "env";
import { withRouter } from "react-router-dom";
import catchAsync from "helper/catchAsync";
import axios from "axios";

class Buttons extends React.Component {
  state = {
    radios: this.props.culture === "ar" ? 1 : 0,
  };

  handleClick1(event) {
    let lang;
    let el = window.location.hash;
    const extension = el.substr(el.indexOf("-") - el.length + 1);
    let stringLink;
    lang = "en";
    stringLink = `${env.frontUrl()}/${lang}-${extension}`;
    if (window.location.href !== stringLink) {
      //  window.location.href = stringLink;
      // console.log(`/${lang}-${extension}`)
      this.props.history.replace(`/${lang}-${extension}`);
    }
  }

  handleClick2(event) {
    let lang;
    let el = window.location.hash;
    const extension = el.substr(el.indexOf("-") - el.length + 1);
    let stringLink;
    lang = "ar";
    stringLink = `${env.frontUrl()}/${lang}-${extension}`;
    if (window.location.href !== stringLink) {
      // window.location.href = stringLink;
      // console.log(`/${lang}-${extension}`)
      this.props.history.replace(`/${lang}-${extension}`);
    }
  }

  handleToggle = (culture) =>
    catchAsync(
      async () => {
        const newCutlrue = culture === "ar" ? 1 : 2;
        const token = `Bearer ${localStorage.getItem(btoa("token"))}`;
        await axios.put(
          `${env.activeServer()}/auth/authusers/Culture?culture=${newCutlrue}`,
          {},
          {
            headers: {
              Authorization: token,
            },
          }
        );
      },
      () => {},
      () => {}
    );

  componentDidMount() {
    const el = window.location.href;
    const arOrEn = el.substring(el.indexOf("-admin") - 2, el.indexOf("-admin"));
    // console.log({arOrEn});
    if (arOrEn === "ar") {
      this.setState({ radios: 1 });
      this.handleToggle("ar");
    } else {
      this.setState({ radios: 0 });
      this.handleToggle("en");
    }
  }

  render() {
    const { culture } = this.props;
    // console.log('yoooo props', this.props);
    // console.log(this.state.radios)
    return (
      <>
        <ButtonGroup className="btn-group-toggle langbtn" data-toggle="buttons">
          <Button
            className={classnames({ active: this.state.radios === 0 })}
            color="info"
            onClick={() => {
              this.handleClick1();
              this.setState({ radios: 0 });
            }}
          >
            <input
              autoComplete="off"
              name="options"
              type="radio"
              value={this.state.radios === 0}
            />
            {content.enLanguage[culture]}
          </Button>
          <Button
            className={classnames({ active: this.state.radios === 1 })}
            color="info"
            onClick={() => {
              this.handleClick2();
              this.setState({ radios: 1 });
            }}
          >
            <input
              autoComplete="off"
              name="options"
              type="radio"
              value={this.state.radios === 1}
            />
            {content.arLanguage[culture]}
          </Button>
        </ButtonGroup>
      </>
    );
  }
}

export default withRouter(Buttons);

{
  /* <Link
                           to="/en-admin/excuses-new"
                           className="btn btn-success mr-2"
                        >
                           <span>
                             EN
                           </span>
                    </Link>

                    <Link
                           to="/ar-admin/excuses-new"
                           className="btn btn-success mr-2"
                        >
                           <span>
                              AR
                           </span>
                    </Link> */
}
