import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  Link
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';

function EditBankAccount() {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const history = useHistory();
  
  return (
    <div>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.accountNumber[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="02014"
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.currentnotebook[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">101 الى 150</option>
                    <option value="second">104 الى 180</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.dimensions[culture]}
                  </label>
                  <Row>
                    <Col xs="6">
                      <Input
                        id="example3cols1Input"
                        value="110"
                        type="number"
                      />
                    </Col>
                    <Col xs="6">
                      <Input
                        id="example3cols1Input"
                        value="45"
                        type="number"
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Button className="mr-2" color="primary" size="md">
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>
                    {content.save[culture]}
                  </span>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default EditBankAccount
