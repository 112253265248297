import client from './client';

const endpoint = '/v1/AccountLevel';

const getAccountingLevel = (page, docsPerPage) => client.get(`${endpoint}?Page=${page}&PostsPerPage=${docsPerPage}`)

const createAccountingLevel = schema => client.post(`${endpoint}`, schema);

const deleteAccountingLevel = id => client.delete(`${endpoint}/${id}`);

const updateAccountingLevel = (id, schema) => client.put(`${endpoint}/${id}`, schema);

export default {
  getAccountingLevel,
  createAccountingLevel,
  deleteAccountingLevel,
  updateAccountingLevel
}