import { create } from "apisauce";

const apiClient = create({
  // baseURL: "https://gw.accounting.shipping.gw.ly/api",
  // baseURL: "https://pos.gw.ly/api"
  baseURL: "https://accounting.shipping.shahen.ly/api", // (Main)
});

apiClient.addAsyncRequestTransform(async (request) => {
  const authToken = await localStorage.getItem(btoa("token"));
  // const authToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjQxM2IxNmFjLTQ0YTItNGE1YS1iMGEwLWIyNTEwMzIyMzgyZiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJhZG1pbiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2xvY2FsaXR5IjoiZW4iLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJhZG1pbkB0ZXN0LmNvbSIsIkF2YXRhciI6IiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6ImFkbWluIiwibmJmIjoxNjc5MjE0OTEwLCJleHAiOjE2Nzk4MTk3MTAsImlzcyI6IlNlY3VyZUFwaSIsImF1ZCI6IlNlY3VyZUFwaVVzZXIifQ.okRVvo_Xy25rbrcYjwnBpSrHSH2FB5opWAAofNQojcY";
  if (!authToken) return;
  request.headers["Authorization"] = "Bearer " + authToken;
});

export default apiClient;
