import React, { useState } from 'react'
import { useRouteMatch } from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";
import Spinner from 'components/Spinner';
import catchAsync from 'helper/catchAsync';
import ReactBSAlert from "react-bootstrap-sweetalert";

const CreateChequebook = () => {

  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [branches, setBranches] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [schema, setSchema] = useState({});

  const handleChange = (key, val) => {
    setSchema({
      ...schema,
      [key]: val
    })
  }

  const getBranches = bankId => catchAsync(async () => {
    setSpinner(true);
    const result = await axios.get(`${env.activeServer()}/Banks/Details/${bankId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    if (result.status === 200) {
      setBranches(result.data.data.bankBranches);
    }
  }, () => setSpinner(false), setAlert);

  const getAccounts = branchId => catchAsync(async () => {
    setSpinner(true);
    const result = await axios.get(`${env.activeServer()}/BankBranchs/Details/${branchId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    if (result.status === 200) {
      setAccounts(result.data.data.bankAccounts)
    }

  }, () => setSpinner(false), setAlert)

  const handleCreate = e => catchAsync(async () => {
    e.preventDefault();
    setSpinner(true);
    const result = await axios.post(`${env.activeServer()}/ChequeBooks`, schema, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    if (result.status === 200) {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => setAlert('')}
          onCancel={() => setAlert('')}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
          btnSize=""
        >
          {result.statusText}
        </ReactBSAlert>
      )
    }
  }, () => setSpinner(false), setAlert)

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/Banks`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })

          if (result.status === 200) {
            setData(result.data.data);
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      />
      :<>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <form onSubmit={handleCreate}>
            <CardHeader>
              <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
            </CardHeader>
            <CardBody>
              <Row className='align-items-center'>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.bank[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      defaultValue={true}
                      onChange={e => getBranches(e.target.value)}
                    >
                      <option disabled value> -- {content.selectOption[culture]} -- </option>
                      {data.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.branch[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      defaultValue={true}
                      disabled={!branches.length}
                      onChange={e => getAccounts(e.target.value)}
                    >
                      <option disabled value> -- {content.selectOption[culture]} -- </option>
                      {branches.map(branch => <option key={branch.id} value={branch.id}>{branch.name}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.account[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      defaultValue={true}
                      disabled={!accounts.length}
                      value={schema.bankAccountId}
                      onChange={e => handleChange("bankAccountId", e.target.value)}
                    >
                      <option disabled value> -- {content.selectOption[culture]} -- </option>
                      {accounts.map(account => <option key={account.id} value={account.id}>{account.accountNumber}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.from[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="number"
                      onChange={e => handleChange("beginOfSequence", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.to[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="number"
                      onChange={e => handleChange("endOfSequence", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.size[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      defaultValue={true}
                      onChange={e => handleChange("chequeSequenceSize", e.target.value)}
                    >
                      <option disabled value> -- {content.selectOption[culture]} -- </option>
                      {env.chequeSizes.map(size => <option key={size} value={size}>{size}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.dimensions[culture]}
                    </label>
                    <Row>
                      <Col xs="6">
                        <Input
                          id="exampleFormControlSelect2"
                          type="number"
                          placeholder={content.width[culture]}
                          onChange={e => handleChange("chequeLength", e.target.value)}
                          />
                      </Col>
                      <Col xs="6">
                        <Input
                          id="exampleFormControlSelect2"
                          type="number"
                          placeholder={content.height[culture]}
                          onChange={e => handleChange("chequeHeight", e.target.value)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button className="mr-2" color="primary" size="md" type='submit'>
                    <span>
                      {content.save[culture]}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </form>
        </Card>
      </Container>
      :</>}
    </div>
  )
}

export default CreateChequebook
