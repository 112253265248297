export default {
  title: {
    ar: 'اضافة أذونات الصرف',
    en: 'Add Release Order'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account Number'
  },
  accountName: {
    ar: 'اسم الحساب',
    en: 'Account name'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  accountNature: {
    ar: 'طبيعة الحساب',
    en: 'Account nature'
  },
  creditor: {
    ar: 'دائن',
    en: 'Creditor'
  },
  debit: {
    ar: 'مدين',
    en: 'Debit'
  },
  debitAccount: {
    ar: 'الحساب المدين',
    en: 'Debit account'
  },
  value: {
    ar: 'القيمة',
    en: 'Value'
  },
  notes: {
    ar: 'الملاحظات',
    en: 'Notes'
  },
  origin: {
    ar: 'أصل',
    en: 'Origin'
  },
  project: {
    ar: 'المشاريع',
    en: 'Projects'
  },
  accountClassification: {
    ar: 'تصنيف الحساب',
    en: 'Account classification'
  },
  accountingLevel: {
    ar: 'المستوى المحاسبي',
    en: 'Accounting level'
  },
  test: {
    ar: 'تجربة',
    en: 'Test'
  },  
  category: {
    ar: 'التصنيف',
    en: 'Category'
  },
  expenses: {
    ar: 'مصروفات',
    en: 'expenses'
  },
  theresnone: {
    ar: 'لايوجد',
    en: 'There is none'
  },
  creditorPurpos: {
    ar: 'الدائن لاغراض',
    en: 'creditor for purposes'
  },
  analyzing: {
    ar: 'تحليل',
    en: 'analyzing'
  },
  yes: {
    ar: 'نعم',
    en: 'Yes'
  },
  no: {
    ar: 'لا',
    en: 'No'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  ok: {
    ar: 'موافق',
    en: 'Ok'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  close: {
    ar: 'إغلاق',
    en: 'Close'
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  add: {
    ar: 'تفويض',
    en: 'Authorization'
  },
  manageCategory: {
    ar: 'ادارة التصنيفات',
    en: 'Manage categories'
  },
  manageSomething: {
    ar: 'ادارة شي ما',
    en: 'Manage something'
  },
  authorizationNo: {
    ar: 'رقم التفويض',
    en: 'Authorization number'
  },
  authorizationDate: {
    ar: 'تاريخ التفويض',
    en: 'Authorization Date'
  },
  authorizationStartDate: {
    ar: 'تاريخ بدأ التفويض',
    en: 'Authorization start date'
  },
  authorizationEndtDate: {
    ar: 'تاريخ نهاية التفويض',
    en: 'Authorization end date'
  },
  authorizationStatus: {
    ar: 'حالة التفويض',
    en: 'Authorization status'
  },
  creditAccount: {
    ar: 'حساب الدائن',
    en: 'credit account'
  },
  certified: {
    ar: 'معتمد',
    en: 'Certified'
  },
  uncertified : {
    ar: 'غير معتمد',
    en: 'Uncertified '
  },
  serial: {
    ar: 'الرقم التسلسلي',
    en: 'Serial'
  },
  mainDoor: {
    ar: 'الباب الرئيسي',
    en: 'Main door'
  },
  date: {
    ar: 'التاريخ',
    en: 'Date'
  },
  typeofTransaction: {
    ar: 'نوع المعاملة',
    en: 'Type of transaction'
  },
  typeOfReleaseOrder: {
    ar: 'نوع اذن الصرف',
    en: 'Type of release order'
  },
  administrativeStatus: {
    ar: 'الحالة الادارية',
    en: 'Administrative Status'
  },
  documentStatus: {
    ar: 'حالة المستند',
    en: 'Document status'
  },
  generalExpenses: {
    ar: 'المصروفات العمومية',
    en: 'General expenses'
  },
  projects: {
    ar: 'مشروعات',
    en: 'Projects'
  },
  normal: {
    ar: 'عادي',
    en: 'Normal'
  },
  manual: {
    ar: 'يدوي',
    en: 'Manual'
  },  
  underRevision: {
    ar: 'تحت المراجعة',
    en: 'Under revision'
  },  
  fundBookNumber: {
    ar: 'رقم دفتر الصندوق',
    en: 'Fund book number'
  },
  source: {
    ar: 'المصدر',
    en: 'Source'
  },
  beneficiary: {
    ar: 'المستفيد',
    en: 'Beneficiary'
  },
  instrument: {
    ar: 'صك',
    en: 'Instrument'
  },
  transfer: {
    ar: 'حوالة',
    en: 'Transfer'
  },
  transactionNo: {
    ar: 'رقم المعاملة',
    en: 'Transaction number'
  },
  releaseOrderDetails: {
    ar: 'تفاصيل اذن الصرف',
    en: 'Release order details'
  },
  save: {
    ar: 'حفظ',
    en: 'Save'
  },
  releaseOrderNo: {
    ar: 'رقم اذن الصرف',
    en: 'Release order number'
  },
  releaseOrder: {
    ar: 'اذن الصرف',
    en: 'Release order'
  }
}