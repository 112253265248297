export default {
  categoryName: {
    ar: "العمليات",
    en: "Operations",
  },
  pageTitle: {
    ar: "قبض الخزينة",
    en: "Treasury receipt",
  },
  name: {
    ar: "الاسم",
    en: "Name",
  },
  actions: {
    ar: "العمليات",
    en: "actions",
  },
  selectOption: {
    ar: "اختر خيار",
    en: "Select option",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  currency: {
    ar: "العملة",
    en: "Currency",
  },
  entryDate: {
    ar: "تاريخ القيد",
    en: "Entry date",
  },
  save: {
    ar: "حفظ",
    save: "Save",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا المراجع المالي ؟",
    en: "Are you sure you want to delete this Finacial auditors ?",
  },
  continue: {
    ar: "الاستمرار",
    en: "Continue",
  },
  treasury: {
    ar: "الخزينة",
    en: "Treasury",
  },
  client: {
    ar: "الزيون",
    en: "Client",
  },
  value: {
    ar: "القيمة",
    en: "Value",
  },
  entryNo: {
    ar: "رقم القيد",
    en: "entry number",
  },
  entryDate: {
    ar: "تاريخ القيد",
    en: "Entry date",
  },
  surplus: {
    ar: "فائض في خزينة الصين",
    en: "The surplus in the treasury of China",
  },
  creditor: {
    ar: "دائن",
    en: "Creditor",
  },
  debtor: {
    ar: "مدين",
    en: "Debtor",
  },
  account: {
    ar: "الحساب",
    en: "Account",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  costCenter: {
    ar: "مركز التكلفة",
    en: "Cost center",
  },
  currencyValue: {
    ar: "قيمة العملة",
    en: "Currency value",
  },
  print: {
    ar: "طباعة",
    en: "Print",
  },
  printPdf: {
    ar: "طباعة بي دي اف",
    en: "Print pdf",
  },
  attachments: {
    ar: "المرفقات",
    en: "Attachments",
  },
  manual: {
    ar: "يدوي",
    en: "Manual",
  },
  review: {
    ar: "مراجعة",
    en: "Review",
  },
  actions: {
    ar: "العمليات",
    en: "Actions",
  },
  currency: {
    ar: "العملة",
    en: "Currency",
  },
  currencyValue: {
    ar: "قيمة العملة",
    en: "Currency value",
  },
  lyd: {
    ar: "دينار ليبي",
    en: "LYD",
  },
  addField: {
    ar: "اضافة خانة",
    en: "Add a field",
  },
  deleteField: {
    ar: "حذف خانة",
    en: "Delete field",
  },
  close: {
    ar: "إغلاق",
    en: "Close",
  },
  saveData: {
    ar: "حفظ البيانات",
    en: "Save data",
  },
  totalDebit: {
    ar: "اجمالي مدين",
    en: "Total debit",
  },
  totalCredit: {
    ar: "اجمالي دائن",
    en: "Total credit",
  },
  difference: {
    ar: "الفرق",
    en: "The difference",
  },
  categoryName: {
    ar: "تقارير مالية",
    en: "Financial reports",
  },
  title: {
    ar: "حساب التشغيل",
    en: "Operation account",
  },
  level: {
    ar: "المستوى",
    en: "Level",
  },
  constCenter: {
    ar: "مركز التكلفة",
    en: "Cost center",
  },
  fromDate: {
    ar: "من تاريخ",
    en: "From date",
  },
  toDate: {
    ar: "الى تاريخ",
    en: "To date",
  },
  creditor: {
    ar: "دائن",
    en: "Creditor",
  },
  debtor: {
    ar: "مدين",
    en: "Debtor",
  },
  detailed: {
    ar: "تفصيلي",
    en: "detailed",
  },
  accountName: {
    ar: "اسم الحساب",
    en: "Account name",
  },
  totalDebit: {
    ar: "اجمالي مدين",
    en: "Total debit",
  },
  totalCredit: {
    ar: "اجمالي دائن",
    en: "Total credit",
  },
  print: {
    ar: "طباعة",
    en: "Print",
  },
  exportTable: {
    ar: "تصدير الجدول",
    en: "Export table",
  },
  search: {
    ar: "بحث",
    en: "Search",
  },
  selectOption: {
    ar: "اختر خيار",
    en: "Select option",
  },
  totalBlanace: {
    ar: "الرصيد الاجمالي",
    en: "Total balance",
  },
  selectAccount: {
    ar: "اختر حساب",
    en: "Select an account",
  },
  accountNo: {
    ar: "رقم الحساب",
    en: "Account number",
  },
  accountClassification: {
    ar: "تصنيف الحساب",
    en: "Account classification",
  },
  createdOn: {
    ar: "تاريخ الانشاء",
    en: "Created on",
  },
  from: {
    ar: "من",
    en: "From",
  },
  to: {
    ar: "الى",
    en: "To",
  },
  category: {
    ar: "نوع الحساب",
    en: "Account category",
  },
  notes: {
    ar: "ملاحظات",
    en: "Notes",
  },
  select: {
    ar: "اختر",
    en: "Select",
  },
  deleteRow: {
    ar: "حذف صف",
    en: "Delete row",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا القيد ؟ ",
    en: "Are you sure you want ",
  },
  delete: {
    ar: "حذف",
    en: "Delete",
  },
  allFieldsRequired: {
    ar: "يجب التأكد من تعبئة حقل الحساب والدائن و المدين",
    en: "You must make sure to fill in the Account, Credit and Debit field",
  },
  done: {
    ar: "تمت العملية بنجاح",
    en: "Done",
  },
  entryType: {
    ar: "نوع القيد",
    en: "Entry type",
  },
  USD: {
    ar: "دولار أمريكي",
    en: "USD",
  },
  LYD: {
    ar: "دينار ليبي",
    en: "LYD",
  },
  Custody: {
    ar: "عهدة",
    en: "Custody",
  },
  Expenses: {
    ar: "نفقات",
    en: "Expenses",
  },
  Manual: {
    ar: "يدوي",
    en: "Manual",
  },
  CustomerPayment: {
    ar: "دفع عميل",
    en: "Customer Payment",
  },
  BrokerPayment: { ar: "سداد بروكر", en: "Broker Payment" },
  Shipment: {
    ar: "شحنة",
    en: "Shipment",
  },
  Loan: {
    ar: "سلفة",
    en: "Loan",
  },
  Expenses: {
    ar: "المصروفات",
    en: "Expenses",
  },
  Salary: {
    ar: "مرتبات",
    en: "Salary",
  },
  BalanceTransfer: {
    ar: "نقل رصيد",
    en: "Balance transfer",
  },
  Shipment: { ar: "شحنة", en: "Shipment" },
  CustomerLoanPayment: { ar: "سداد دين عميل", en: "Customer Loan Payment" },
  ResizingOrder: { ar: "إعادة تحجيم", en: "Resizing Order" },
  RepricingOrder: { ar: "إعادة تسعير", en: "Repricing Order" },
  AdjustmentRecompense: { ar: "تعويض تعديل طلبية", en: "Adjustment Recompense" },
  BulkExpenses: {
    ar: "مصروفات البلك",
    en: "Bulk expenses"
  },
  BrokerPayment: { ar: "مصروفات بلك", en: "Bulk Expenses" },
  TransfersClaim: { ar: "إستحقاق حوالة", en: "Transfers Claim" },
  SettlementTransaction: { ar: "حركة تسوية ", en: "Settlement Transaction" },

  saveAndConfirm: {
    ar: "حفظ و اعتماد",
    en: "Save and confirm",
  },
};
