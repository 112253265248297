import client from "./client";

const endpoint = "/v1/entryDetail";

const createEntryDetails = (schema) => client.post(`${endpoint}`, schema);

const deleteEntryDetails = (id) => client.delete(`${endpoint}/${id}`);

const updateEntryDetails = (id, schema) => client.put(`${endpoint}/${id}`, schema);

export default {
  createEntryDetails,
  deleteEntryDetails,
  updateEntryDetails
};
