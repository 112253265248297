import React from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";
import content from "Screen/Accounts/customerMovment/accountContent";
import env from "env";

function AccountCard({ list, culture }) {
  const setEnum = (type) => {
    switch (type) {
      case 1:
        return content.Normal[culture];
      case 2:
        return content.Treasury[culture];
      case 3:
        return content.Employee[culture];
      case 4:
        return content.Customer[culture];
      case 5:
        return content.Expenses[culture];
      case 6:
        return content.Revenue[culture];
      case 7:
        return content.Broker[culture];
    }
  };

  return (
    <Row>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.name[culture]}
          </label>

          <Input
            id="example3cols1Input"
            placeholder={content.name[culture]}
            type="text"
            style={{ color: "black" }}
            readOnly
            value={list.name ? list.name : ""}
          />
        </FormGroup>
      </Col>

      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.number[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.number[culture]}
            type="number"
            style={{ color: "black" }}
            readOnly
            value={list.number ? list.number : ""}
          />
        </FormGroup>
      </Col>

      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.accountLevelId[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.accountLevelId[culture]}
            type="text"
            style={{ color: "black" }}
            readOnly
            value={list.accountLevel ? list.accountLevel.name : ""}
          />
        </FormGroup>
      </Col>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.AccountCategory[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.AccountCategory[culture]}
            type="text"
            style={{ color: "black" }}
            readOnly
            value={list.accountCategory ? list.accountCategory.name : ""}
          />
        </FormGroup>
      </Col>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="accountClassification">
            {content.accountClassification[culture]}
          </label>
          <Input
            id="accountClassification"
            placeholder={content.accountClassification[culture]}
            type="text"
            readOnly
            style={{ color: "black" }}
            value={
              list.accountClassification
                ? setEnum(list.accountClassification)
                : ""
            }
          />
        </FormGroup>
      </Col>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="accountNature">
            {content.accountNature[culture]}
          </label>
          <Input
            id="accountNature"
            placeholder={content.accountNature[culture]}
            type="text"
            readOnly
            style={{ color: "black" }}
            value={env.accountNature[list?.accountNature - 1]?.[culture] || ""}
          />
        </FormGroup>
      </Col>
      {setEnum(list.accountClassification) === 2 ? (
        <>
          <Col md="4" lg="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols1Input"
              >
                {content.bankName[culture]}
              </label>
              <Input
                id="example3cols1Input"
                placeholder={content.bankName[culture]}
                type="text"
                readOnly
                style={{ color: "black" }}
                value={list.bankName ? list.bankName : ""}
              />
            </FormGroup>
          </Col>
          <Col md="4" lg="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols1Input"
              >
                {content.bankBranchName[culture]}
              </label>
              <Input
                id="example3cols1Input"
                placeholder={content.bankBranchName[culture]}
                type="text"
                readOnly
                style={{ color: "black" }}
                value={list.bankBranchName ? list.bankBranchName : ""}
              />
            </FormGroup>
          </Col>
          <Col md="4" lg="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols1Input"
              >
                {content.bankAccountNumber[culture]}
              </label>
              <Input
                id="example3cols1Input"
                placeholder={content.bankAccountNumber[culture]}
                type="text"
                readOnly
                style={{ color: "black" }}
                value={list.bankAccountNumber ? list.bankAccountNumber : ""}
              />
            </FormGroup>
          </Col>
        </>
      ) : (
        <></>
      )}
      {setEnum(list.accountClassification) === 6 ? (
        <>
          <Col md="4" lg="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols1Input"
              >
                {content.customerAccountId[culture]}
              </label>
              <Input
                id="example3cols1Input"
                placeholder={content.customerAccountId[culture]}
                type="text"
                style={{ color: "black" }}
                readOnly
                value={list.customerAccountId ? list.customerAccountId : ""}
              />
            </FormGroup>
          </Col>
        </>
      ) : (
        <></>
      )}

      {setEnum(list.accountClassification) === 4 ||
      setEnum(list.accountClassification) === 5 ? (
        <>
          <Col md="4" lg="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols1Input"
              >
                {content.phoneNumber[culture]}
              </label>
              <Input
                id="example3cols1Input"
                placeholder={content.phoneNumber[culture]}
                type="text"
                disabled={true}
                style={{ color: "black" }}
                value={list.phoneNumber ? list.phoneNumber : ""}
              />
            </FormGroup>
          </Col>
        </>
      ) : (
        <></>
      )}
    </Row>
  );
}

export default AccountCard;
