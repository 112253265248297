import useCulture from "hooks/useCulture";
import React from "react";
import { Controller } from "react-hook-form";
import { CardBody, Row, Col, FormGroup, Input } from "reactstrap";
import content from "../../CreateEntry/content";

const EditEntryDetails = ({ control, errors, setValue }) => {
  const culture = useCulture();

  return (
    <CardBody>
      <Row>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="debit">
              {content.debtor[culture]}
            </label>
            <Controller
              control={control}
              name="debit"
              rules={{ required: `This field is required` }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="debit"
                  type="text"
                  value={value || ""}
                  placeholder={content.debtor[culture]}
                  className={errors.debit && "error"}
                  onChange={({ target: { value } }) => {
                    onChange(+value);
                    setValue("credit", 0);
                  }}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="credit">
              {content.creditor[culture]}
            </label>
            <Controller
              control={control}
              name="credit"
              rules={{ required: `This field is required` }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="credit"
                  type="text"
                  placeholder={content.creditor[culture]}
                  value={value || ""}
                  className={errors.credit && "error"}
                  onChange={({ target: { value } }) => {
                    onChange(+value);
                    setValue("debit", 0);
                  }}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="note">
              {content.notes[culture]}
            </label>
            <Controller
              control={control}
              name="note"
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="note"
                  type="textarea"
                  placeholder={content.notes[culture]}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </CardBody>
  );
};

export default EditEntryDetails;
