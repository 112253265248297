export default {
  title: {
    ar: "الحساب",
    en: "Account",
  },
  name: {
    ar: "اسم الحساب",
    en: "Name",
  },
  number: {
    ar: "الرمز",
    en: "Number",
  },
  accountClassification: {
    ar: "نوع الحساب",
    en: "account Classification",
  },
  accountLevelId: {
    ar: "مستوى الحساب",
    en: "account Level",
  },
  AccountCategory: {
    ar: "تصنيف الحساب",
    en: "Account Category",
  },
  bankName: {
    ar: "اسم البنك",
    en: "Bank Name",
  },
  BankBranchName: {
    ar: "اسم الفرع",
    en: "Bank Branch Name",
  },
  BankAccountNumber: {
    ar: "رقم حساب البنك",
    en: "Bank Account Number",
  },
  CoustomerAccount: {
    ar: "حساب الزبون",
    en: "Coustomer Account",
  },
  PhoneNumber: {
    ar: "رقم الهاتف",
    en: "PhoneNumber",
  },
  Normal: {
    ar: "طبيعي",
    en: "Normal",
  },
  Bank: {
    ar: "مصرف",
    en: "Bank",
  },
  Treasury: {
    ar: "خزينة",
    en: "Treasury",
  },
  Employee: {
    ar: "موظف",
    en: "Employee",
  },
  Person: {
    ar: "شخصي",
    en: "Person",
  },
  Customer: {
    ar: "العميل",
    en: "Customer",
  },
  Expenses: {
    ar: "مصروفات",
    en: "Expenses",
  },
  Revenue: {
    ar: "ايراد",
    en: "Revenue",
  },
  Broker: {
    ar: "بروكر",
    en: "Broker",
  },
  followedBy: {
    ar: "يتبع الى",
    en: "follow by",
  },
  accountDetails: {
    ar: "بيانات الحساب",
    en: "Account Details",
  },

  balanceUSD: {
    ar: "صافي القيمة بالدولار",
    en: "Balance USD",
  },
  balanceLYD: {
    ar: "صافي القيمة بالدينار",
    en: "Balance LYD",
  },
  totalDebitUSD: {
    ar: "مجموع المدين بالدولار",
    en: "Total Debit USD",
  },
  totalDebitLYD: {
    ar: "مجموع المدين بالدينار",
    en: "Total Debit LYD",
  },
  totalCreditUSD: {
    ar: "مجموع الدائن بالدولار",
    en: "Total Credit USD",
  },
  totalCreditLYD: {
    ar: "مجموع الدائن بالدينار",
    en: "Total Credit LYD",
  },
  balanceDetails: {
    ar: "بيانات الرصيد",
    en: "Balance Details",
  },
  custommerDetails: {
    ar: "بيانات الزبون",
    en: "Customer Details",
  },
  UnConfirmedDetailsLYD: {
    ar: " الحركات المالية الغير معتمدة دينار",
    en: "unapproved financial movements LYD",
  },
  confirmedDetailsLYD: {
    ar: "الحركات المالية المعتمدة دينار",
    en: "Approved financial movements LYD",
  },
  UnConfirmedDetailsUSD: {
    ar: " الحركات المالية الغير معتمدة دولار",
    en: "unapproved financial movements USD",
  },
  confirmedDetailsUSD: {
    ar: "الحركات المالية المعتمدة دولار",
    en: "Approved financial movements USD",
  },
  financialMovements: {
    ar: "عرض الحركات المالية",
    en: "Show Financial Movements",
  },
  arrayIsEmpty: {
    ar: "لا يوجد حركات مالية لهذا الحساب",
    en: "There is no Financial Movements for this user ",
  },
  accountNature: {
    ar: "طبيعة الحساب",
    en: "Account nature",
  },
  totalDebitEURO: {
    ar: "مجموع المدين باليورو",
    en: "Total Debit EURO",
  },
  totalCreditEURO: {
    ar: "مجموع الدائن باليورو",
    en: "Total Credit EURO",
  },
  balanceEURO: {
    ar: "صافي القيمة باليورو",
    en: "Balance EURO",
  },
};
