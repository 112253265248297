export default {
  title: {
    ar: "تقرير حركات العميل",
    en: "customer movement Report",
  },
  year: {
    ar: "السنة المالية",
    en: "Year",
  },
  show: {
    ar: "عرض",
    en: "show",
  },
  note: {
    ar: " ملاحظات الحركة",
    en: "note's movement",
  },
  credit: {
    ar: "دائن",
    en: "credit",
  },
  debit: {
    ar: "مدين",
    en: "debit",
  },
  currency: {
    ar: "العملة",
    en: "currency",
  },
  serialNumber: {
    ar: "سند القيد",
    en: "Serial Number",
  },
  selectOption: {
    ar: "اختر خيار",
    en: "selectOption",
  },
  currentBalanceLYD: {
    ar: "الرصيد الحالي(د.ل)",
    en: "Current Balance(LYD)",
  },
  currentBalance: {
    ar: "الرصيد الحالي",
    en: "Current balance",
  },
  currentBalanceUSD: {
    ar: "الرصيد الحالي($)",
    en: "Current Balance(USD)",
  },
  afterDate: {
    ar: "الحركات بعد تاريخ:",
    en: "Movements after this date:",
  },
  entryStatement: {
    ar: "بيان القيد",
    en: "Entry Statement",
  },
  transactionState: {
    ar: "حالة الحركة",
    en: "Transaction State",
  },
  transactionDate: {
    ar: "تاريخ الحركة",
    en: "Transaction Date",
  },
  print: {
    ar: "طباعة",
    en: "print",
  },
  accountTitle: {
    ar: "تفاصيل الحساب",
    en: "Account Details",
  },
  balanceDetails: {
    ar: "مجموع الأرصدة",
    en: "Balance total",
  },
  Unconfirmed: {
    ar: "غير مؤكد",
    en: "Unconfirmed",
  },
  Confirmed: {
    ar: "مؤكد",
    en: "Confirmed",
  },
  Cancelled: {
    ar: "ملغي",
    en: "Cancelled",
  },
  transactionAfter: {
    ar: "الحركات بعد تاريخ :",
    en: "Transactions after :",
  },
  entryState: {
    ar: "حالة القيد",
    en: "Entry State",
  },
  totalDebitEURO: {
    ar: "اجمالي المدين باليورو",
    en: "Total debit euro",
  },
  totalCreditEURO: {
    ar: "اجمالي الدائن باليورو",
    en: "Total credit euro",
  },
  balanceUSD: {
    ar: "صافي القيمة بالدولار",
    en: "Balance USD",
  },
  balanceLYD: {
    ar: "صافي القيمة بالدينار",
    en: "Balance LYD",
  },
  balanceEuro: {
    ar: "صافي القيمة باليورو",
    en: "Balance euro",
  },
  debtExclusion: {
    ar: "إستثناء الديون",
    en: "Exclude Loans"
  },
};
