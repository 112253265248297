export default {
  title: {
    ar: 'تفاصيل الفرع المصرفي',
    en: 'Bank branch details'
  },
  branchName: {
    ar: 'اسم الفرع',
    en: 'Branch name'
  },
  branchCode: {
    ar: 'رمز الفرع',
    en: 'Branch code'
  },
  bankName: {
    ar: 'اسم المصرف',
    en: 'Bank name'
  },
  account: {
    ar: 'حساب',
    en: 'Account'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  currentCheckBook: {
    ar: 'دفتر الشيكات الحالي',
    en: 'Current check book'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  currentNotebook: {
    ar: 'الدفتر الحالي',
    en: 'Current notebook'
  },
  nextNotebook: {
    ar: 'الدفتر القادم',
    en: 'Next notebook'
  },
  itemName: {
    ar: 'اسم البند',
    en: 'Account name'
  },
  itemNo: {
    ar: 'رقم البند',
    en: 'Account No'
  },
  managingWrongCheques: {
    ar: 'ادارة الصكوك الخاطئة',
    en: 'Managing wrong cheques'
  },
  none: {
    ar: "لا يوجد",
    en: "None"
  },
  bankAccountNumber: {
    ar: "رقم الحساب المصرفي",
    en: "Bank account number"
  },
  create: {
    ar: "إنشاء",
    en: "Create"
  }
}