import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  Link
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from 'components/common/CustomTable';

function ManagingWrongCheques() {
  
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const headers = [
    content.date[culture],
    content.paymentMethod[culture],
    content.transactionNumber[culture],
    content.creditor[culture],
    content.debit[culture],
    content.registrationDate[culture],
    content.cancellationDate[culture],
    content.actions[culture],
  ];

  const data = [
    {id: 1, date: "2020-01-12", paymentMethod: "cheque", transactionNo: 545, creditor: 100, debit: 10025, registrationDate: "2021-11-1", cancellationDate: "2021-2-1"},
    {id: 1, date: "2020-01-12", paymentMethod: "cheque", transactionNo: 545, creditor: 100, debit: 10025, registrationDate: "2021-11-1", cancellationDate: "2021-2-1"},
  ]

  const columns = ["date", "paymentMethod", "transactionNo", "creditor", "debit", 'registrationDate', 'cancellationDate'];

  return (
    <div>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.bankName[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={"first"}
                    onChange={e => {}}
                    disabled={true}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">الجهرية</option>
                    <option value="second">الوحدة</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchName[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={"first"}
                    onChange={e => {}}
                    disabled={true}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">قرجي</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchCode[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="33"
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.bankAccountNumber[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="54444"
                    type="number "
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.linkedAccount[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="مصرف الجمهرية فرع القادسية رقم 3301"
                    type="text "
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.accountNumber[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="010205"
                    type="number "
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Link to={`/${culture}-admin/addwrongcheque/afd`}>
                  <Button className="mr-2" color="primary" size="md">
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>
                      {content.wrongCheque[culture]}
                    </span>
                  </Button>
                </Link>
              </Col>
            </Row>
          </CardBody>
          <Table 
            headers={headers}
            columns={columns}
            data={data}
          >
            <span className="mr-2 mr-3 ml-3">
              <div id="edit1" className="table-action cursor-pointer">
                <i className="fas fa-pencil-alt fa-lg hover-info"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="edit1"
              >
                {content.edit[culture]}
              </UncontrolledTooltip>
            </span>
            <span to={`/${culture}-admin/recordwrongcheque`}>
              <div id="register1" className="table-action cursor-pointer">
                <i className="far fa-file-alt fa-lg hover-success"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="register1"
              >
                {content.register[culture]}
              </UncontrolledTooltip>
            </span>
            <span to={`/${culture}-admin/cancelwrongcheque`}>
              <div id="cancel1" className="table-action cursor-pointer">
                <i className="fas fa-times-circle fa-lg hover-warning"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="cancel1"
              >
                {content.cancel[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
        </Card>
      </Container>
    </div>
  )
}

export default ManagingWrongCheques
