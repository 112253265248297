export default {
  title: {
    ar: 'تعديل مستوى محاسبي',
    en: 'Edit an accounting level'
  },  
  name: {
    ar: 'الاسم',
    en: 'Name'
  },
  previousLevel: {
    ar: 'المستوى السابق',
    en: 'Previous level'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  mainDoor: {
    ar: 'باب رئيسي',
    en: 'Main door'
  },
  account: {
    ar: 'بند',
    en: 'Account'
  },
  subEntry: {
    ar: 'قيد فرعي',
    en: 'Sub-entry'
  },
  NoOfDigits: {
    ar: 'عدد الخانات',
    en: 'Number of digits'
  },
  prevNoOfDigits: {
    ar: 'عدد الخانات للمستوى السابق',
    en: 'Number of digits for the previous level'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  accountingLevel: {
    ar: 'مستوى محاسبي',
    en: 'Accounting level'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  none: {
    ar: 'لا يوجد',
    en: 'None'
  },
  new: {
    ar: 'جديد',
    en: 'New'
  },
  levelName: {
    ar: 'اسم المستوى',
    en: 'Level name'
  }
}