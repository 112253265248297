export default {
  months: {
    ar: [
      "يناير",
      "فبراير",
      "مارس",
      "إبريل",
      "مايو",
      "يوليو",
      "يونيو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    en: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  },
  title: {
    ar: 'لوحة القيادة',
    en: 'Dashboard'
  },
  size: {
    ar: 'المساحة',
    en: 'Size'
  },
  mb: {
    ar: 'ميغابايت',
    en: 'Mb'
  },
  gb: {
    ar: 'جيجابايت',
    en: 'GB'
  },
  kb: {
    ar: 'كيلوبايت',
    en: 'Kb'
  },
  publicFiles: {
    ar: 'ملفات عامة',
    en: 'Public files'
  },
  privateFiles: {
    ar: 'ملفات خاصة',
    en: 'Private files'
  },
  shareFiles: {
    ar: 'الملفات المشتركة',
    en: 'Shared Files'
  },
  personalFiles: {
    ar: 'الملفات الشخصية',
    en: 'Personal Files'
  },
  file: {
    ar: 'ملف',
    en: "File"
  },
  public: {
    ar: 'عامة',
    en: 'Public'
  },
  private: {
    ar: 'خاصة',
    en: 'Private'
  },
  share: {
    ar: 'مشتركة',
    en: 'Shared'
  },
  personal: {
    ar: 'شخصية',
    en: 'Personal'
  },
  sinceLastMonth: {
    ar: 'منذ أخر شهر',
    en: 'Since last month'
  },
  filesSize: {
    ar: 'حجم الملفات',
    en: 'Files size'
  },
  filesCount: {
    ar: 'عدد الملفات',
    en: 'Files count'
  },
  accountsNo: {
    ar: "عدد الحسابات",
    en: "Accounts number"
  },
  mainTreasuryBalance: {
    ar: "رصيد الخزينة الرئيسية",
    en: "Main treasury balance"
  },
  chinaTreasuryBalance: {
    ar: "رصيد خزينة الصين",
    en: "China treasury balance"
  },
  shipmentValues: {
    ar: "قيم الشحنات",
    en: "Shipment values"
  },
  accounts: {
    ar: "حسابات",
    en: "Accounts"
  },
  value: {
    ar: "قيمة",
    en: "Value"
  }
}