import { create } from "apisauce";

const shipping = create({
  // baseURL: "https://gw.shahen.gw.ly/api",
  baseURL: "https://shippingv2.shahen.ly/api"
});

shipping.addAsyncRequestTransform(async (request) => {
  const authToken = await localStorage.getItem(btoa("token"));
  if (!authToken) return;
  request.headers["Authorization"] = "Bearer " + authToken;
});
export default shipping;
