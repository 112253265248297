import React from "react";

// reactstrap components
import { Button, Modal } from "reactstrap";

//common
import commonContent from "components/common/content";

import Example from "./CreditreportToprint";
function ModalToPrint({ data, culture, setToggleP, toggleP }) {
  console.log("data recieved: ", data);

  return (
    <Modal
      className="modal-dialog-centered custom-map-modal"
      isOpen={toggleP}
      // zIndex="102"
      // fullscreen="xl-down"
      size="xl"
    >
      <div className="modal-header">
        <h2 className="modal-title" id="exampleModalLabel">
          {commonContent.print[culture]}
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            setToggleP(false);
          }}
        >
          <span
            className={culture === "ar" ? "text-md-right-bb" : ""}
            aria-hidden={true}
          >
            ×
          </span>
        </button>
      </div>
      <div className="modal-body">
        <Example data={data} culture={culture} />
      </div>
      {/* <div className="modal-footer">
          <Button
            className={culture === "en" ? "text-md-right" : "text-md-left"}
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              setToggleP(false);
            }}
          >
            {commonContent.close[culture]}
          </Button>
          <Button
            className={culture === "en" ? "text-md-right" : "text-md-left"}
            color="success"
            type="button"
            onClick={() => {
              setToggleP(false);
            }}
          >
            {commonContent.save[culture]}
          </Button>
        </div> */}
    </Modal>
  );
}
export default ModalToPrint;
