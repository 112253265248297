export default {
  rolePermissions: {
    ar: "أذوانات الدور",
    en: "Role permissions"
  },
  username: {
    ar: "اسم المستخدم",
    en: "Username"
  },
  screen: {
    ar: "اسم الواجهة",
    en: "Screen"
  },
}