export default {
  title: {
    ar: 'تفاصبل دفتر الشيكات',
    en: 'Chequebook details'
  },
  bankName: {
    ar: 'اسم المصرف',
    en: 'Bank name'
  },
  branchName: {
    ar: 'اسم الفرع',
    en: 'Branch name'
  },
  branchCode: {
    ar: 'رمز الفرع',
    en: 'Branch code'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  from: {
    ar: 'من',
    en: 'From'
  },
  to: {
    ar: 'الى',
    en: 'To'
  },
  current: {
    ar: 'الحالي',
    en: 'Current'
  },
  currentSequence: {
    ar: 'التسلسل الحالي',
    en: 'Current sequence'
  },
  dimensions: {
    ar: 'الابعاد',
    en: 'Dimensions'
  },
  beginning: {
    ar: 'بداية الدفتر القادم',
    en: 'Beginning of the next notebook'
  },
  size: {
    ar: 'الحجم',
    en: 'Size'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  link: {
    ar: 'ربط',
    en: 'Link'
  },
  save: {
    ar: 'حفظ',
    en: 'Save'
  },
  none: {
    ar: "لا يوجد",
    en: "None"
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  }
}