import React from "react";
import { Controller } from "react-hook-form";
import { Col, FormGroup, Input, Row } from "reactstrap";
import commonContent from "components/common/commonContent";
import useCulture from "hooks/useCulture";

function EditRole({ control, errors }) {
  const culture = useCulture();
  return (
    <Row>
      <Col md="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="name">
            {commonContent.name[culture]}
          </label>
          <Controller
            control={control}
            name="name"
            rules={{
              required: `${commonContent.name[culture]} field is required`,
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="name"
                type="text"
                className={errors.name && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="description">
            {commonContent.description[culture]}
          </label>
          <Controller
            control={control}
            name="description"
            rules={{
              required: `description field is required`,
            }}
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Input
                {...field}
                id="description"
                type="text"
                className={errors.description && "error"}
                value={value || ""}
                onChange={({ target: { value } }) => onChange(value)}
              />
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default EditRole;
