import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";
import Spinner from 'components/Spinner';
import catchAsync from 'helper/catchAsync';
import ReactBSAlert from "react-bootstrap-sweetalert";

const EditAccountingLevel = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [schema, setSchema] = useState({});
  const [prev, setPrev] = useState(0);

  const { id } = useParams();

  const handleChange = (key, val) => {
    setSchema({
      ...schema,
      [key]: val
    })
  }
  
  const checkDisabled = () => {

    // check if the last accounting level created is the same as this one 
    if (data[data.length - 1].id === schema.id) 
      // if it's then you can edit the digits number
      return false;
    
    // else you can't edit the digits number
    return true;
  }

  const handleEdit = e => catchAsync(async () => {  
    e.preventDefault();
    setSpinner(true);
    const newSchema = {
      name: schema.name,
      digits: schema.digits
    };

    if (checkDisabled()) {
      delete newSchema.digits;
    }

    const result = await axios.put(`${env.activeServer()}/AccountLevels/${id}`, newSchema, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    if (result.status === 200) {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => setAlert('')}
          onCancel={() => setAlert('')}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
          btnSize=""
        >
          {result.statusText}
        </ReactBSAlert>
      )
    }
    
  }, () => { setSpinner(false)}, setAlert)

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/AccountLevels`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })

          if (result.status === 200) {
            const newData = result.data.data.reverse();
            setData(newData);
            const thiSchema = newData.find(item => item.id === id);
            setSchema(thiSchema);
            setPrev(newData.findIndex(item => item.id === id) - 1);
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      />
      :<>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.previousLevel[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    value={data[prev]?.id || ""}
                    disabled
                  >
                    <option value={data[prev]?.id || ""}>{data[prev]?.name || ""}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.prevNoOfDigits[culture]}
                  </label>
                  <Input
                    min="0"
                    id="exampleFormControlSelect2"
                    type="number"
                    disabled
                    value={data[prev]?.digits || ""}
                  />
                </FormGroup>
              </Col>
            </Row>
            <form onSubmit={handleEdit}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.levelName[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="text"
                      placeholder={content.levelName[culture]}
                      value={schema.name}
                      onChange={e => handleChange("name", e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.NoOfDigits[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="number"
                      min={Math.max.apply(Math, data.map(function(item) { return item.digits }))}
                      disabled={checkDisabled()}
                      value={schema.digits}
                      onChange={e => handleChange("digits", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button className="mr-2" color="primary" size="md" type="submit">
                    <span>
                      {content.edit[culture]}
                    </span>
                  </Button>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Container>
      </>}
    </div>
  )
}

export default EditAccountingLevel
