import React, { useEffect, useState } from "react";
import { CardBody, FormGroup, Input, Row, Col, Container } from "reactstrap";
import { Controller } from "react-hook-form";
import content from "../content";
import env from "env";
import TextPopup from "components/common/TextModal";
import PopupTable from "components/common/PopupTableUsed";
import commonContent from "components/common/content";
import CountryTable from "components/common/CountryTable";

const CreateAccount = (props) => {
  const {
    watch,
    culture,
    control,
    errors,
    setValue,
    sweetAlert,
    accountLevels,
    accountCategories,
    accountClassifications,
  } = props;

  const digits = watch("digits");
  const classification = watch("accountClassification");

  const [countryTable, setCountryTable] = useState(false);
  const [tableModal, setTableModal] = useState(false);

  const handleCancel = () => {
    setTableModal(false);
    setCountryTable(false);
  };

  useEffect(() => {
    if (classification !== 4) setValue("customerAccountId", "");
  }, [classification]);

  useEffect(() => {
    if (classification !== 4) return;
    const name = `${watch("client")} - ${watch("customerCode")}`;
    setValue("name", name);
  }, [watch("client")]);

  return (
    <>
      <CardBody>
        <Row>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                {content.name[culture]}
              </label>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: `${content.name[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="name"
                    type="text"
                    className={errors.name && "error"}
                    value={value || ""}
                    readOnly={classification === 4}
                    onChange={({ target: { value } }) => onChange(value)}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="accountSelected">
                {content.accountNo[culture]} ({digits})
              </label>
              <Row>
                <Col md="6">
                  <Controller
                    control={control}
                    name="number"
                    rules={{
                      required: `${content.name[culture]} field is required`,
                      pattern: {
                        value: /^[0-9]*$/,
                      },
                      minLength: digits,
                      maxLength: digits,
                    }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="accountSelected"
                        type="text"
                        value={value || ""}
                        className={errors.number && "error"}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </Col>
                <Col md="6">
                  <Controller
                    control={control}
                    name="parentNo"
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="accountSelected1"
                        type="number"
                        disabled
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="accountLevelId">
                {content.accountingLevel[culture]}
              </label>
              <Controller
                control={control}
                name="accountLevelId"
                rules={{
                  required: `${content.name[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="accountLevelId"
                    disabled
                    type="select"
                    className={errors.accountLevelId && "error"}
                    value={value || "disabled"}
                    onChange={({ target: { value } }) => onChange(value)}
                  >
                    <option disabled value="disabled">
                      -- {content.selectOption[culture]} --
                    </option>
                    {accountLevels.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="accountClassification"
              >
                {content.accountClass[culture]}
              </label>
              <Controller
                control={control}
                name="accountClassification"
                rules={{
                  required: `${content.name[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="accountClassification"
                    type="select"
                    className={errors.accountClassification && "error"}
                    value={value || "disabled"}
                    onChange={({ target: { value } }) => onChange(+value)}
                  >
                    <option disabled value="disabled">
                      -- {content.selectOption[culture]} --
                    </option>
                    {accountClassifications.map((item, index) => (
                      <option value={index + 1} key={item}>
                        {commonContent[item][culture]}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="accountCategories">
                {content.category[culture]}
              </label>
              <Controller
                control={control}
                name="accountCategoryId"
                rules={{
                  required: `${content.name[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="accountCategories"
                    type="select"
                    className={errors.accountCategoryId && "error"}
                    value={value || "disabled"}
                    onChange={({ target: { value } }) => onChange(value)}
                  >
                    <option disabled value="disabled">
                      -- {content.selectOption[culture]} --
                    </option>
                    {accountCategories.map((category) => (
                      <option value={category.id} key={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="accountNature">
                {content.accountNature[culture]}
              </label>
              <Controller
                control={control}
                name="accountNature"
                rules={{
                  required: `${content.name[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="accountNature"
                    type="select"
                    className={errors.accountNature && "error"}
                    value={value || "disabled"}
                    readOnly={watch("parentNo")}
                    onChange={({ target: { value } }) => onChange(+value)}
                  >
                    <option disabled value="disabled">
                      -- {content.selectOption[culture]} --
                    </option>
                    {env.accountNature.map((acc, index) => (
                      <option value={index + 1} key={index}>
                        {acc[culture]}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormGroup>
          </Col>
          {classification === 4 && (
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="customer">
                  {content.customer[culture]}
                </label>
                <Controller
                  control={control}
                  name="client"
                  rules={{
                    required: `${content.customer[culture]} field is required`,
                  }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="customer"
                      type="text"
                      className={errors.client && "error"}
                      value={value || ""}
                      onChange={({ target: { value } }) => onChange(value)}
                      onClick={() => setTableModal(true)}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          )}
          {classification === 2 && (
            <Col md="6" lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="country">
                  {commonContent.country[culture]}
                </label>
                <Controller
                  control={control}
                  name="country"
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="country"
                      type="text"
                      value={value || ""}
                      placeholder={`-- ${content.selectOption[culture]} --`}
                      // onChange={({ target: { value } }) => onChange(value)}
                      onClick={() => setCountryTable(true)}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
      </CardBody>
      <TextPopup
        modal={tableModal}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <PopupTable
            setCustomer={(customer) => {
              setValue("customerAccountId", customer.id);
              setValue("customerCode", customer.customerCode);
            }}
            setTableModal={setTableModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <CardBody>
          <CountryTable
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </CardBody>
      </TextPopup>
    </>
  );
};

export default CreateAccount;
