export default {
  categoryName: {
    ar: "الاعدادات",
    en: "Settings",
  },
  pageTitle: {
    ar: "المراجع المالي",
    en: "Financial auditors",
  },
  name: {
    ar: "الاسم",
    en: "Name",
  },
  actions: {
    ar: "العمليات",
    en: "actions",
  },
  save: {
    ar: "حفظ",
    save: "Save",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا المراجع المالي ؟",
    en: "Are you sure you want to delete this Finacial auditors ?",
  },
  continue: {
    ar: "الاستمرار",
    en: "Continue",
  },
  approveEntries: {
    ar: "قبول القيود",
    en: "Approve entries",
  },
  confirmModal: {
    ar: "هل انت متأكد من انك تريد قبول هذه القيود ؟",
    en: "Are you sure you want to approve these entries ?",
  },
  approve: {
    ar: "قبول",
    en: "Approve",
  },
  customerDeposit: {
    ar: "ايداع الزبائن",
    en: "Customer deposit",
  },
  currencyPurchaseTransactions: {
    ar: "حركات شراء عمله",
    en: "Currency purchase transactions",
  },
  transactionState: {
    ar: "حالة الحركة",
    en: "Transaction State",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  valueDinar: {
    ar: "القيمة دينار",
    en: "Value dinar",
  },
  rate: {
    ar: "تسعيرة",
    en: "Rate",
  },
  valueDollar: {
    ar: "القيمة دولار",
    en: "Value dollar",
  },
  treasuryTransactions: {
    ar: "حركات الخزينة",
    en: "Treasury transactions",
  },
  totalDeposit: {
    ar: "الإيذاعات",
    en: "Total deposit",
  },
  totalDepositUSD: {
    ar: "الإيذاعات بالدولار",
    en: "Total deposit in USD",
  },
  totalDepositLYD: {
    ar: "الإيذاعات بالدينار",
    en: "Total deposit in LYD",
  },
  totalDepositEURO: {
    ar: "الإيذاعات باليورو",
    en: "Total deposit in EURO",
  },
  totalReturnedToCustomers: {
    ar: "قيم التسوية",
    en: "Total returned to customers",
  },
  totalReturnedToCustomersInUSD: {
    ar: "قيم التسوية بالدولار",
    en: "Total returned to customers in USD",
  },
  totalReturnedToCustomersInLYD: {
    ar: "قيم التسوية بالدينار",
    en: "Total returned to customers in LYD",
  },
  totalReturnedToCustomersInEURO: {
    ar: "قيم التسوية باليورو",
    en: "Total returned to customers in EURO",
  },
  totalShipmentPayments: {
    ar: "سداد الشحن",
    en: "Total shipment payments",
  },
  totalShipmentPaymentsInUSD: {
    ar: "سداد الشحن بالدولار",
    en: "Total shipment payments in USD",
  },
  totalShipmentPaymentsLYD: {
    ar: "سداد الشحن بالدينار",
    en: "Total shipment payments in LYD",
  },
  totalShipmentPaymentsInEURO: {
    ar: "سداد الشحن باليورو",
    en: "Total shipment payments in EURO",
  },
  totalMoneyTransfers: {
    ar: "الحوالات",
    en: "Total money transfers",
  },
  totalMoneyTransfersInUSD: {
    ar: "الحوالات بالدولار",
    en: "Total money transfers in USD",
  },
  totalMoneyTransfersInEURO: {
    ar: "الحوالات باليورو",
    en: "Total money transfers in EURO",
  },
  totalMoneyTransfersInLYD: {
    ar: "الحوالات بالدينار",
    en: "Total money transfers in LYD",
  },
  currency: {
    ar: "العملة",
    en: "Currency",
  },
  selectOption: {
    ar: "اختيار حقل",
    en: "Select Option",
  },
  total: {
    ar: "المجموع",
    en: "Total"
  }
};
