import React, { useState } from "react";

// router
import { useRouteMatch, useParams } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
//content
import content from "./content";

//common
import commonContent from "components/common/commonContent";

// core components
import SimpleHeader from "components/Headers/SimpleHeader";
import AccountCard from "components/common/accountInEntryCard";
import BalanceCard from "components/common/BalanceCard";
import AccountCategoryCard from "components/common/AccountCategoryCard";
import AccountLevelCard from "components/common/AccountLevelCard";
import CustomerCard from "components/common/CustomerCard";
import Spinner from "components/Spinner";
import useApi from "hooks/useApi";
import account from "api/account";
import { useForm } from "react-hook-form";
import UpdateAccount from "./components/UpdateAccount";
import TextPopup from "components/common/TextModal";
import useAccountDetails from "./useAccountDetails";
import useAlert from "hooks/useAlert";
import useUpdate from "hooks/useUpdata";
import useFetchById from "hooks/useFetchById";
import useDelete from "hooks/useDelete";

function AccountDetails() {
  let rout = useRouteMatch();

  let culture = getCulture(rout.url);

  const { id } = useParams();

  const [data, setData] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [financiallyRevisedModal, setFinanciallyRevisedModal] = useState(false);
  const accountDetails = useApi(account.getDetails);
  const updateAccountApi = useApi(account.updateAccount);
  const deleteAccountApi = useApi(account.deleteAccount);
  const financiallyReviseCustomerApi = useApi(
    account.financiallyReviseCustomer
  );

  //alert message
  const { alert, sweetAlert } = useAlert();

  // This useForm hook is for update account api
  const {
    handleSubmit: updateSubmit,
    formState: { errors: updateErrors },
    control: updateControl,
    reset: updateReset,
    watch: updateWatch,
    setValue: updateSetValue,
  } = useForm();

  useFetchById(accountDetails, id, setData);

  const { handleUpdateAccount, updateAccountModal } = useAccountDetails();
  const deleteHandler = useDelete(
    data,
    setData,
    setSpinner,
    setDeleteModal,
    sweetAlert
  );

  const updateHandler = useUpdate(
    updateAccountApi,
    data,
    setData,
    setSpinner,
    setUpdateModal,
    sweetAlert,
    updateReset
  );

  //
  const handleCancel = () => {
    setDeleteModal(false);
    setUpdateModal(false);
    setFinanciallyRevisedModal(false);
  };

  //delete request
  let handleDelete = () => {
    setDeleteModal(true);
  };

  const handleUpdate = () =>
    updateAccountModal(data, updateSetValue, setUpdateModal);

  const updateAccount = async (formData) => {
    const accountData = handleUpdateAccount(formData);

    await updateHandler(id, accountData);
  };

  const deleteAccount = async () => deleteHandler(deleteAccountApi, id);

  const handleFinanciallyRevised = async () => {
    setSpinner(true);
    setFinanciallyRevisedModal(false);
    const res = await financiallyReviseCustomerApi.request(
      data.customerAccountId
    );
    if (res.status === 200) {
      setData((prevVal) => ({ ...prevVal, financiallyRevised: true }));
      sweetAlert(commonContent.done[culture]);
    }
    setSpinner(false);
  };

  if (accountDetails.loading) return <Spinner bg="#fff" gate="#42ba96" />;

  return (
    <>
      {alert}
      {accountDetails.errorAlert}
      {deleteAccountApi.errorAlert}
      {updateAccountApi.errorAlert}
      {financiallyReviseCustomerApi.errorAlert}
      {/* loading for edit and delete request */}
      {spinner ? <Spinner opacity bg="#fff" gate="#42ba96" /> : null}
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col>
                <h3
                  classyear={`mb-0 text-md-${content.accountDetails[culture]}`}
                >
                  {content.accountDetails[culture]}
                </h3>
              </Col>
              {data.accountClassification === 4 && !data.financiallyRevised && (
                <Button
                  className="btn mr-2"
                  color="success"
                  size="sm"
                  onClick={() => setFinanciallyRevisedModal(true)}
                >
                  <span>{content.financiallyRevised[culture]}</span>
                </Button>
              )}
              <Button
                className="btn mr-2"
                color="info"
                size="sm"
                onClick={handleUpdate}
              >
                <span className="btn-inner--icon mr-1 ml-1">
                  <i className="fas fa-pencil-alt" />
                </span>
                <span>{content.edit[culture]}</span>
              </Button>
              <Button
                className="btn mr-2"
                color="danger"
                size="sm"
                onClick={handleDelete}
              >
                <span className="btn-inner--icon mr-1 ml-1">
                  <i className="fas fa-trash" />
                </span>
                <span>{content.delete[culture]}</span>
              </Button>
            </Row>
          </CardHeader>
          <CardBody>
            <AccountCard list={data} culture={culture} />
          </CardBody>
        </Card>
        {/* {data.accountClassification === 4 ? (
          <>
            <Card className="mb-4">
              <CardHeader>
                <h3 classyear={`mb-0 text-md-${commonContent.alignt[culture]}`}>
                  {content.custommerDetails[culture]}
                </h3>
              </CardHeader>
              <CardBody>
                <CustomerCard culture={culture} list={data.custommerList} />
              </CardBody>
            </Card>
          </>
        ) : (
          <></>
        )} */}
        <Card className="mb-4">
          <CardHeader>
            <h3 classyear={`mb-0 text-md-${commonContent.alignt[culture]}`}>
              {content.balanceDetails[culture]}
            </h3>
          </CardHeader>
          <CardBody>
            <BalanceCard culture={culture} list={data.balance || {}} />
          </CardBody>
        </Card>

        <Card className="mb-4">
          <CardHeader>
            <h3 classyear={`mb-0 text-md-${commonContent.alignt[culture]}`}>
              {content.accountLevelId[culture]}
            </h3>
          </CardHeader>
          <CardBody>
            <AccountLevelCard
              culture={culture}
              list={data.accountLevel || {}}
            />
          </CardBody>
        </Card>
        <Card className="mb-4">
          <CardHeader>
            <h3 classyear={`mb-0 text-md-${commonContent.alignt[culture]}`}>
              {content.AccountCategory[culture]}
            </h3>
          </CardHeader>
          <CardBody>
            <AccountCategoryCard
              culture={culture}
              list={data.accountCategory || {}}
            />
          </CardBody>
        </Card>
      </Container>
      <TextPopup
        modal={updateModal}
        text={content.edit[culture]}
        handleCancel={handleCancel}
        fn={updateSubmit(updateAccount)}
        color="info"
      >
        <UpdateAccount
          culture={culture}
          watch={updateWatch}
          errors={updateErrors}
          control={updateControl}
          setValue={updateSetValue}
          accountLevels={[data.accountLevel]}
          accountCategories={[data.accountCategory]}
          accountClassifications={[data.accountClassifications]}
        />
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={content.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteAccount}
        color="danger"
        name={data.name}
      >
        <CardBody>
          <h2>{content.deletePopup[culture]}</h2>
        </CardBody>
      </TextPopup>
      <TextPopup
        modal={financiallyRevisedModal}
        text={content.continue[culture]}
        handleCancel={handleCancel}
        fn={handleFinanciallyRevised}
        color="success"
        name={data.name}
      >
        <CardBody>
          <h2>{content.revisedModal[culture]}</h2>
        </CardBody>
      </TextPopup>
    </>
  );
}

export default AccountDetails;
