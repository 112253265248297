import React from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";
// import content from "Screen/menues/AccountLevel/content";

const content = {
  title: {
    ar: "المستوى المحاسبي",
    en: "Account Level",
  },
  name: {
    ar: "اسم المستوى المحاسبي",
    en: "Account's Name",
  },
  digits: {
    ar: "عدد الخانات",
    en: "Digits",
  },
};

function AccountLevelCard({ list, culture }) {
  return (
    <Row>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.name[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.name[culture]}
            type="text"
            value={list.name ? list.name : ""}
            style={{ color: "black" }}
            readOnly
          />
        </FormGroup>
      </Col>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.digits[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.digits[culture]}
            type="text"
            value={list.digits ? list.digits : ""}
            style={{ color: "black" }}
            readOnly
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default AccountLevelCard;
