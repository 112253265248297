import React, { useState } from 'react';

import {
  //BrowserRouter as Router,
  useRouteMatch,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from 'components/common/CustomTable';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";
import Spinner from 'components/Spinner';
import catchAsync from 'helper/catchAsync';
import ReactBSAlert from "react-bootstrap-sweetalert";

const EditChequebook = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [nextChequebook, setNextChequebook] = useState(0);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [branches, setBranches] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [schema, setSchema] = useState({});
  const { id } = useParams();

  const headers = [
    content.from[culture],
    content.to[culture],
    content.size[culture],
    content.actions[culture]
  ];

  const columns = ["from", "to", "size"];

  const data2 = [
    {id: 1, from: 101, to: 150, size: 50},
    {id: 2, from: 131, to: 140, size: 30},
    {id: 3, from: 121, to: 1550, size: 50},
  ]

  const handleLink = obj => {
    setNextChequebook(obj.from);
  }

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/ChequeBooks/Details/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })

          if (result.status === 200) {
            console.log({result});
            setData(result.data.data);
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      />
      :<>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.bankName[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="الحمهرية"
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchName[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="القادسية"
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchCode[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="5444"
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.accountNumber[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    defaultValue={data.bankAccount.accountNumber}
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.from[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    defaultValue={data.beginOfSequence}
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.to[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    defaultValue={data.endOfSequence}
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.currentSequence[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    defaultValue={data.currentSequence}
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.dimensions[culture]}
                  </label>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Input
                          id="example3cols1Input"
                          placeholder={content.width[culture]}
                          defaultValue={data.chequeLength}
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Input
                          id="example3cols1Input"
                          placeholder={content.height[culture]}
                          defaultValue={data.chequeHeight}
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.beginning[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value={nextChequebook}
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Button 
                color="primary" 
                type="button" 
              >
                {content.save[culture]}
              </Button>
            </Row>
          </CardBody>
          <Table
            headers={headers}
            columns={columns}
            data={data2}
            handleLink={handleLink}
          >
            <span className="mr-2 mr-3 ml-3" fun={"handleLink"}>
              <div id="details1" className="table-action cursor-pointer">
                <i className="fas fa-link fa-lg hover-success"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="details1"
              >
                {content.link[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
        </Card>
      </Container>
      :</>}
    </div>
  )
}

export default EditChequebook
