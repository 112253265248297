export default {
  categoryName: {
    ar: 'المحاسبة',
    en: 'Accounting',
  },
  title: {
    ar: 'مركز التكلفة',
    en: 'Cost center'
  },
  sequence: {
    ar: 'التسلسل',
    en: 'Sequence'
  },
  editPopup: {
    ar: 'تعديل التصنيف',
    en: 'Edit category'
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذالتصنيف ؟",
    en: "Are you sure you want to delete this category ?"
  },
  name: {
    ar: 'الاسم',
    en: 'Name'
  },
  costCenterMasterId: {
    ar: 'متفرع من',
    en: 'Branched from'
  },
  costCenterType: {
    ar: 'التصنيف',
    en: 'Category'
  },
  costCenterType:{
    ar: "نوع مركز التكلفة",
    en: "Cost center type"
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  createdDate: {
    ar: 'تاريخ الانشاء',
    en: 'Created date'
  },
  editedDate: {
    ar: 'تاريخ التعديل',
    en: 'Edited date'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  creatorName:{
    ar: "إسم المنشئ",
    en: "Creator name"
  },
  add: {
    ar: "إضافة",
    en: "add"
  },
  done: {
    ar: 'تمت العملية بنجاح',
    en: 'Done'
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  print: {
    ar: 'طباعة',
    en: 'Print'
  },
  actions: {
    ar: 'العمليات',
    en: 'actions'
  },
  addCostCenter: {
    ar: 'اضافة مركز تكلفة',
    en: 'Add cost center'
  }
}