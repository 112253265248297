import React, { useState, useEffect, useRef } from "react";
import {
  //BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
//content
import content from "./content";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import Spinner from "components/Spinner";
import TextPopup from "components/common/TextModal";
import Pagination from "components/common/Pagination";
import { useForm, Controller } from "react-hook-form";
import useAlert from "hooks/useAlert";
import useApi from "hooks/useApi";
import commonContent from "components/common/commonContent";
import VoucherDesign from "./components/VoucherDesign";
import { useReactToPrint } from "react-to-print";
import entry from "api/entry";
import useFetch from "hooks/useFetch";
import env from "env";
import PrintTextField from "./components/PrintTextField";

const Receipt = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);
  const headers = [
    content.actions[culture],
    content.entryNo[culture],
    content.client[culture],
    content.entryState[culture],
    content.currency[culture],
    content.date[culture],
  ];

  const columns = [
    "serialNumberDisplayed",
    "client",
    "entryStateText",
    "currencyText",
    "date",
  ];

  const printApi = useApi(entry.printEntry);
  const filterApi = useApi(entry.filterEntry);

  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [printRow, setPrintRow] = useState({});
  const [printModal, setPrintModal] = useState(false);

  const voucherRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => voucherRef.current,
  });

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // apis

  const editResponse = (item) => {
    item.date = item.date.split("T")[0];
    item.entryTypeText = env.entryType[item.entryType - 1][culture];
    item.entryStateText = env.entryState[item.entryState - 1][culture];
    item.currencyText = env.currency[item.currency - 1][culture];
    item.shipmentTypeText = env.shipmentType[item.shipmentType][culture];

    // Assign client field
    item.entryDetails.find((details) => {
      if (details.account.accountClassification === 4) {
        item.client = details.account.name;
      }
    });

    return item;
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const {
    handleSubmit: PrintSubmit,
    formState: { errors: printErrors },
    control: printControl,
    reset: printReset,
    setValue: printSetValue,
  } = useForm();

  const fitlerEntries = async (data = {}) => {
    const schema = {
      ...data,
    };
    schema.entryType =
      env.entryType.findIndex((entry) => entry["en"] === "Customer Payment") +
      1;
    const res = await filterApi.request(schema, pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data.forEach((item) => editResponse(item));
      setData(res.data.data);
    }
  };

  const printHandler = (obj) => {
    setRowId(obj.id);
    printSetValue("enterReason", obj.statement);
    setPrintModal(true);
  };

  const getPrintData = async (formData) => {
    setPrintModal(false);
    setSpinner(true);
    const res = await printApi.request(rowId);
    if (res.status === 200) {
      const selectedRow = data.find((row) => row.id === rowId);
      const currency = selectedRow?.currency ?? "";
      const serialNumberDisplayed = selectedRow?.serialNumberDisplayed;
      const appUser = selectedRow.appUser;
      setPrintRow({
        ...res.data.data,
        enterReason: formData.enterReason,
        currency,
        serialNumberDisplayed,
        appUser,
      });
    }
    setRowId("");
    printReset();
    setSpinner(false);
  };

  useFetch({ fun: fitlerEntries, pageNo, docsPerPage, isSearch: false });

  useEffect(() => {
    if (Object.keys(printRow).length) handlePrint();
  }, [printRow]);

  if (filterApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <div>
      {alert}
      {printApi.errorAlert}
      {filterApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Form onSubmit={fitlerEntries}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      {content.voucherno[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="receiptNumber"
                      rules={{
                        required: `${content.voucherno[culture]} field is required`,
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="name"
                          type="number"
                          className={errors.name && "error"}
                          value={value ?? ""}
                          onChange={({ target: { value } }) => onChange(+value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button className="mr-2" color="info" size="md" type="submit">
                <span className="btn-inner--icon me-1">
                  <i className="fas fa-search" />
                </span>
                <span>{commonContent.search[culture]}</span>
              </Button>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Pagination
            dataLength={data.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              headers={headers}
              data={data}
              columns={columns}
              printHandler={printHandler}
            >
              <span to={`/${culture}-admin/transaction/entryDetails`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commonContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
              <span className="cmr-2" fun="printHandler">
                <div id="print" className="table-action cursor-pointer">
                  <i className="fas fa-print fa-lg hover-info" />
                </div>
                <UncontrolledTooltip delay={0} target="print">
                  {content.print[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <div ref={voucherRef}>
        <VoucherDesign printRow={printRow} />
      </div>
      <TextPopup
        modal={printModal}
        text={content.continue[culture]}
        handleCancel={() => setPrintModal(false)}
        fn={PrintSubmit(getPrintData)}
        color="info"
      >
        <PrintTextField errors={printErrors} control={printControl} />
      </TextPopup>
    </div>
  );
};

export default Receipt;
