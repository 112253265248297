export default {
  customerBalances: {
    ar: "أرصدة الزبائن",
    en: "Customer balances",
  },
  customer: {
    ar: "الزبون",
    en: "Customer",
  },
  customerBalaneWithMainTreasury: {
    ar: "رصيد الزبون مع الخزينة الرئيسية",
    en: "Customer's balance with the main treasury",
  },
  customerBalaneWithAnyTreasury: {
    ar: "رصيد الزبون مع أي الخزينة",
    en: "Customer's balance with Any treasury",
  },
  customerBalaneWithRevenue: {
    ar: "رصيد الزبون مع الايراد",
    en: "Customer's balance with Revenue",
  },
  customerWithTransfersAccount: {
    ar: "رصيد حساب الحوالات",
    en: "Customer's balance transfers account",
  },
  balanceUSD: {
    ar: "صافي القيمة بالدولار",
    en: "Balance USD",
  },
  balanceLYD: {
    ar: "صافي القيمة بالدينار",
    en: "Balance LYD",
  },
  balance: {
    ar: "الرصيد",
    en: "Balance",
  },
  account: {
    ar: "الحساب",
    en: "Account",
  },
  calculate: {
    ar: "احتساب",
    en: "calculate",
  },
  balanceEuro: {
    ar: "صافي القيمة باليورو",
    en: "Balance Euro",
  }
};
