import React, { useMemo } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  CardBody,
} from "reactstrap";
import { useParams, useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import content from "./content";
// import Pagination from "components/common/Pagination";
import entry from "api/entry";
import useApi from "hooks/useApi";
import Spinner from "components/Spinner";
import { useEffect } from "react";
import { useState } from "react";
import env from "env";
import commonContent from "components/common/commonContent";
import TextPopup from "components/common/TextModal";
import useUpdate from "hooks/useUpdata";
import useAlert from "hooks/useAlert";

const EntryDetails = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const editResponse = (item) => {
    item.date = item.date.split("T")[0];
    item.entryTypeText = env.entryType[item.entryType - 1][culture];
    item.entryStateText = env.entryState[item.entryState - 1][culture];
    item.currencyText = env.currency[item.currency - 1][culture];
    item.shipmentTypeText = env.shipmentType[item.shipmentType][culture];

    return item;
  };

  const { id } = useParams();

  const [data, setData] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [unconfirmModal, setUnconfirmModal] = useState(false);

  const { alert, sweetAlert } = useAlert();

  const confirmApi = useApi(entry.confirmEntry);
  const unconfirmApi = useApi(entry.unconfirmEntry);
  const cancelEntryApi = useApi(entry.cancelEntry);

  const updateData = (obj) => {
    editResponse(obj);
    setData(obj);
  };

  const confirmEntry = useUpdate(
    confirmApi,
    data,
    updateData,
    setSpinner,
    setConfirmModal,
    sweetAlert
  );
  const unConfrimEntry = useUpdate(
    unconfirmApi,
    data,
    updateData,
    setSpinner,
    setUnconfirmModal,
    sweetAlert
  );
  const cancelEntry = useUpdate(
    cancelEntryApi,
    data,
    updateData,
    setSpinner,
    setCancelModal,
    sweetAlert
  );

  const getEntryDetailsApi = useApi(entry.findDetailsAsync);

  const headers = [
    content.number[culture],
    content.accountName[culture],
    content.debtor[culture],
    content.creditor[culture],
    content.note[culture],
  ];

  const columns = ["account.number", "account.name", "debit", "credit", "note"];
  const getEntryDetails = async () => {
    const res = await getEntryDetailsApi.request(id);

    if (res.status === 200) {
      res.data.data = editResponse(res.data.data);
      setData(res.data.data);
    }
  };

  const confirmHandler = async () => await confirmEntry(data.id);

  const unconfirmHandler = async () => await unConfrimEntry(data.id);

  const cancelHandler = async () => await cancelEntry(data.id);

  const handleCancel = () => {
    setConfirmModal(false);
    setUnconfirmModal(false);
    setCancelModal(false);
  };

  const shipmentEntryNo =
    useMemo(
      () => env.entryType.findIndex((entry) => entry["en"] === "Shipment"),
      []
    ) + 1;
  const internalTransferNo =
    useMemo(
      () =>
        env.entryType.findIndex((entry) => entry["en"] === "Internal Transfer"),
      []
    ) + 1;
  const shipmentPaymentNo =
    useMemo(
      () =>
        env.entryType.findIndex((entry) => entry["en"] === "Shipping Payment"),
      []
    ) + 1;

  const dontShowCancel = [
    shipmentEntryNo,
    internalTransferNo,
    shipmentPaymentNo,
  ];
  const dontShowConfirm = [shipmentEntryNo];

  useEffect(() => {
    getEntryDetails();
  }, []);

  const excellData = data.entryDetails ? [...data.entryDetails] : [];

  if (getEntryDetailsApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {getEntryDetailsApi.errorAlert}
      {confirmApi.errorAlert}
      {unconfirmApi.errorAlert}
      {cancelEntryApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0`}>{content.title[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="entryno">
                    {content.entryNo[culture]}
                  </label>
                  <Input
                    id="entryno"
                    type="text"
                    value={data.serialNumberDisplayed || ""}
                    onChange={() => {}}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="entryType">
                    {content.entryType[culture]}
                  </label>
                  <Input
                    id="entryType"
                    type="text"
                    value={
                      env.entryType?.[data?.entryType - 1]?.[culture] || ""
                    }
                    onChange={() => {}}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="entryType">
                    {content.currency[culture]}
                  </label>
                  <Input
                    id="entryType"
                    type="text"
                    value={env.currency?.[data.currency - 1]?.[culture] || ""}
                    onChange={() => {}}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="entryType">
                    {content.entryState[culture]}
                  </label>
                  <Input
                    id="entryType"
                    type="text"
                    value={
                      env.entryState?.[data.entryState - 1]?.[culture] || ""
                    }
                    onChange={() => {}}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="entryType">
                    {content.costCenter[culture]}
                  </label>
                  <Input
                    id="entryType"
                    type="text"
                    value={data.costCenter?.name || ""}
                    onChange={() => {}}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="date">
                    {content.date[culture]}
                  </label>
                  <Input
                    id="date"
                    type="date"
                    value={data.date || ""}
                    disabled
                    onChange={() => {}}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="shipmentType">
                    {content.shipmentType[culture]}
                  </label>
                  <Input
                    id="shipmentType"
                    type="text"
                    value={data.shipmentTypeText || ""}
                    disabled
                    onChange={() => {}}
                  />
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="statement">
                    {content.statement[culture]}
                  </label>
                  <Input
                    id="statement"
                    type="textarea"
                    value={data.statement || ""}
                    disabled
                    onChange={() => {}}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          {/* <Pagination 
            pageNo={pageNo} 
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          > */}
          <Table
            pageName={content.title[culture]}
            headers={headers}
            columns={columns}
            data={data.entryDetails}
          />
          <CardBody className="text-center">
            {!dontShowConfirm.includes(data.entryType) &&
              data.entryState === 1 && (
                <Button
                  color="success"
                  className="cml-2"
                  type="button"
                  onClick={() => setConfirmModal(true)}
                >
                  <span className="btn-inner--icon me-1">
                    <i className="fas fa-check" />
                  </span>
                  <span className="btn-inner--text">
                    {content.confirm[culture]}
                  </span>
                </Button>
              )}
            {!dontShowCancel.includes(data.entryType) &&
              data.entryState !== 1 && (
                <Button
                  color="warning"
                  className="cml-2"
                  type="button"
                  onClick={() => setUnconfirmModal(true)}
                >
                  <span className="btn-inner--text">
                    {content.unconfirm[culture]}
                  </span>
                </Button>
              )}
            {!dontShowCancel.includes(data.entryType) &&
              data.entryState !== 3 && (
                <Button
                  color="danger"
                  className="cml-2"
                  type="button"
                  onClick={() => setCancelModal(true)}
                >
                  <span className="btn-inner--text">
                    {content.cancel[culture]}
                  </span>
                </Button>
              )}
          </CardBody>
          {/* </Pagination> */}
        </Card>
        <TextPopup
          modal={confirmModal}
          text={commonContent.continue[culture]}
          handleCancel={handleCancel}
          fn={confirmHandler}
          color="success"
        >
          <CardBody>
            <h2>{content.confirmPopup[culture]}</h2>
          </CardBody>
        </TextPopup>
        <TextPopup
          modal={unconfirmModal}
          text={commonContent.continue[culture]}
          handleCancel={handleCancel}
          fn={unconfirmHandler}
          color="warning"
        >
          <CardBody>
            <h2>{content.unconfirmPopup[culture]}</h2>
          </CardBody>
        </TextPopup>
        <TextPopup
          modal={cancelModal}
          text={commonContent.continue[culture]}
          handleCancel={handleCancel}
          fn={cancelHandler}
          color="danger"
        >
          <CardBody>
            <h2>{content.cancelPopup[culture]}</h2>
          </CardBody>
        </TextPopup>
      </Container>
    </>
  );
};

export default EntryDetails;
