import client from './client';

const endpoint = '/v1/FiscalYear';

const getFiscalYears = (page, docsPerPage) => client.get(`${endpoint}?Page=${page}&PostsPerPage=${docsPerPage}`)

const reopenYear = id => client.put(`${endpoint}/ReOpenFiscalYear/${id}`);

const lockYear = id => client.put(`${endpoint}/CloseFiscalYear/${id}`);

const deleteYear = id => client.delete(`${endpoint}/${id}`);

const createYear = schema => client.post(`${endpoint}`, schema);

export default {
  getFiscalYears,
  reopenYear,
  lockYear,
  deleteYear,
  createYear
}