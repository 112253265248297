export default {
  title: {
    ar: 'المستويات المحاسبية',
    en: 'Accounting levels'
  },  
  name: {
    ar: 'الاسم',
    en: 'Name'
  },
  previousLevel: {
    ar: 'المستوى السابق',
    en: 'Previous level'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  mainDoor: {
    ar: 'باب رئيسي',
    en: 'Main door'
  },
  account: {
    ar: 'بند',
    en: 'Account'
  },
  subEntry: {
    ar: 'قيد فرعي',
    en: 'Sub-entry'
  },
  NoOfDigits: {
    ar: 'عدد الخانات',
    en: 'Number of digits'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  accountingLevel: {
    ar: 'مستوى محاسبي',
    en: 'Accounting level'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  none: {
    ar: 'لا يوجد',
    en: 'None'
  },
  new: {
    ar: 'جديد',
    en: 'New'
  },
  constructions: {
    ar: "التشييدات",
    en: "Constructions"
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا المستوى المحاسبي ؟",
    en: "Are you sure you want to delete this accounting level"
  },
  done: {
    ar: 'تمت العملية بنجاح',
    en: 'Done'
  },
  editPopup: {
    ar: 'تعديل مستوى الحساب',
    en: 'Edit account level'
  },
}