import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  Link
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';

const CancelWrongCheque = () => {
  
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  return (
    <div>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.bankName[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={"first"}
                    onChange={e => {}}
                    disabled={true}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">الجهرية</option>
                    <option value="second">الوحدة</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchName[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={"first"}
                    onChange={e => {}}
                    disabled={true}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">قرجي</option>
                    <option value="second">القادسية</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchCode[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="number"
                    value={"44"}
                    onChange={e => {}}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.bankAccountNumber[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="number"
                    value={"010203"}
                    onChange={e => {}}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.linkedAccount[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="number"
                    value={"010203"}
                    onChange={e => {}}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.accountNumber[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="number"
                    value={"010203"}
                    onChange={e => {}}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.chequeDate[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="date"
                    value={"2020-05-01"}
                    onChange={e => {}}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.paymentMethod[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={"first"}
                    disabled={true}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">صك</option>
                    <option value="second">كاش</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.transactionNumber[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="number"
                    value={"100"}
                    onChange={e => {}}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.creditor[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="number"
                    value={"100"}
                    onChange={e => {}}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.debit[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="number"
                    value={"0"}
                    onChange={e => {}}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.recordedAt[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="date"
                    value={"2020-01-01"}
                    disabled={true}
                    onChange={e => {}}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.cancellationDate[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="date"
                    onChange={e => {}}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary">
              {content.save[culture]}
            </Button>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default CancelWrongCheque
