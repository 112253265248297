export default {
  arLanguage: {
    ar: "العربية",
    en: "Ar",
  },
  account: {
    ar: "الحساب",
    en: "Account",
  },
  number: {
    ar: "الرقم",
    en: "Number",
  },
  accountClassification: {
    ar: "تصنيف الحساب",
    en: "Acc.Classification",
  },
  calculate: {
    ar: "إحتساب",
    en: "Calculate",
  },
  transactionDate: {
    ar: "تاريخ الصفقة",
    en: "Transaction Date",
  },
  transactionState: {
    ar: "حالة الصفقة",
    en: "Transaction Stat",
  },
  credit: {
    ar: "الدائن",
    en: "Credit",
  },
  debit: {
    ar: "المدين",
    en: "Debit",
  },
  currentBalanceLYD: {
    ar: "الرصيد الحالي بالدينار الليبي",
    en: "CurrentBalanceLYD",
  },
  currentBalanceUSD: {
    ar: "الرصيد الحالي بالدولار الامريكي",
    en: "CurrentBalanceUSD",
  },
  note: {
    ar: "ملاحظة",
    en: "Note",
  },
  accountLevel: {
    ar: "مستوى الحساب",
    en: "accountLevel",
  },
  enLanguage: {
    ar: "الإنجليزية",
    en: "En",
  },

  align: {
    ar: "left",
    en: "right",
  },

  new: {
    ar: "جديد",
    en: "New",
  },

  folder: {
    ar: "مجلد",
    en: "Folder",
  },

  newFolder: {
    ar: "مجلد جديد",
    en: "New folder",
  },

  fileUpload: {
    ar: "تحميل ملف",
    en: "File upload",
  },

  folderUpload: {
    ar: "تحميل مجلد",
    en: "Folder upload",
  },

  search: {
    ar: "بحث",
    en: "Search",
  },
  serialNumber: {
    ar: "الرقم التسلسلي",
    en: "Serial Number",
  },
  entryStatement: {
    ar: "بيان الدخول",
    en: "Entry Statement",
  },
  currency: {
    ar: "العملة",
    en: "currency",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  align: {
    ar: "left",
    en: "right",
  },

  alignt: {
    ar: "right",
    en: "left",
  },

  close: {
    ar: "إغلاق",
    en: "Close",
  },

  cancel: {
    ar: "إلغاء",
    en: "Cancel",
  },

  create: {
    ar: "إنشاء",
    en: "Create",
  },

  folderName: {
    ar: "إسم المجلد",
    en: "Folder name",
  },

  fileName: {
    ar: "إسم الملف",
    en: "File name",
  },

  uploadTracking: {
    ar: "تتبع التحميل",
    en: "Upload tracking",
  },

  upload: {
    ar: " تحميل",
    en: "Upload",
  },

  file1: {
    ar: "ملف 1",
    en: "File 1",
  },

  file2: {
    ar: "ملف 2",
    en: "File 2",
  },

  chooseFile: {
    ar: "اختر ملف",
    en: "Choose file",
  },

  chooseFiles: {
    ar: "اختر ملف",
    en: "Choose files",
  },
  title: {
    ar: "ملفات قابلة للمشاركة",
    en: "Shareable Files",
  },

  alignt: {
    ar: "right",
    en: "left",
  },

  lead: {
    ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
    en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account",
  },

  fileName: {
    ar: "إسم الملف",
    en: "File name",
  },

  filesIveCreated: {
    ar: "ملفات قمت بإنشائها ",
    en: "Files I've Created",
  },

  datecreated: {
    ar: "تاريخ الإنشاء",
    en: "Date created",
  },

  size: {
    ar: "الحجم",
    en: "Size",
  },

  owner: {
    ar: "المنشئ",
    en: "Owner",
  },

  lastModified: {
    ar: "آخر تعديل",
    en: "Last modified",
  },

  language: {
    ar: "ar",
    en: "en",
  },

  align: {
    ar: "left",
    en: "right",
  },

  yes: {
    ar: "نعم",
    en: "Yes",
  },

  no: {
    ar: "لا",
    en: "No",
  },

  add: {
    ar: "إضافة",
    en: "Add",
  },
  search: {
    ar: "بحث",
    en: "search",
  },

  copy: {
    ar: "نسخ",
    en: "Copy",
  },

  print: {
    ar: "طباعة",
    en: "Print",
  },

  edit: {
    ar: "تعديل",
    en: "Edit",
  },

  deleting: {
    ar: "حذف",
    en: "Delete",
  },

  delete: {
    ar: "حذف",
    en: "Delete",
  },

  printTip: {
    ar: "سيؤدي هذا إلى فتح صفحة طباعة بالصفوف المرئية من الملف",
    en: "This will open a print page with the visible rows of the table.",
  },

  copyTip: {
    ar: "سيؤدي هذا إلى نسخ الصفوف المرئية من الجدول",
    en: "This will copy to your clipboard the visible rows of the table.",
  },

  alertMes1: {
    ar: "! أحسنت",
    en: "Good job!",
  },

  alertMes2: {
    ar: "! تم النسخ",
    en: "Copied to clipboard!",
  },

  show: {
    ar: "عرض",
    en: "Show",
  },

  entries: {
    ar: "إدخالات",
    en: "entries",
  },

  title: {
    ar: "أنواع الملفات ",
    en: " File Types",
  },

  alignt: {
    ar: "right",
    en: "left",
  },

  lead: {
    ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
    en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account",
  },

  fileName: {
    ar: "إسم الملف",
    en: "File name",
  },

  filesIveCreated: {
    ar: "ملفات قمت بإنشائها ",
    en: "Files I've Created",
  },

  datecreated: {
    ar: "تاريخ الإنشاء",
    en: "Date created",
  },

  size: {
    ar: "الحجم",
    en: "Size",
  },

  owner: {
    ar: "المنشئ",
    en: "Owner",
  },

  lastModified: {
    ar: "آخر تعديل",
    en: "Last modified",
  },

  align: {
    ar: "left",
    en: "right",
  },

  yes: {
    ar: "نعم",
    en: "Yes",
  },

  no: {
    ar: "لا",
    en: "No",
  },

  add: {
    ar: "إضافة",
    en: "Add",
  },

  search: {
    ar: "بحث",
    en: "search",
  },

  copy: {
    ar: "نسخ",
    en: "Copy",
  },

  print: {
    ar: "طباعة",
    en: "Print",
  },

  edit: {
    ar: "تعديل",
    en: "Edit",
  },

  deleting: {
    ar: "حذف",
    en: "Delete",
  },

  printTip: {
    ar: "سيؤدي هذا إلى فتح صفحة طباعة بالصفوف المرئية من الملف",
    en: "This will open a print page with the visible rows of the table.",
  },

  copyTip: {
    ar: "سيؤدي هذا إلى نسخ الصفوف المرئية من الجدول",
    en: "This will copy to your clipboard the visible rows of the table.",
  },

  alertMes1: {
    ar: "! أحسنت",
    en: "Good job!",
  },

  alertMes2: {
    ar: "! تم النسخ",
    en: "Copied to clipboard!",
  },

  show: {
    ar: "عرض",
    en: "Show",
  },

  entries: {
    ar: "إدخالات",
    en: "entries",
  },

  ownerName: {
    ar: " إسم المالك",
    en: "Owner name",
  },

  fileType: {
    ar: "نوع الملف",
    en: "File type",
  },
  permissionType: {
    ar: "نوع الصلاحيات",
    en: "Permission type",
  },
  file: {
    ar: "الملف",
    en: "File",
  },

  close: {
    ar: "إغلاق",
    en: "Close",
  },

  next: {
    ar: "التالي",
    en: "Next",
  },

  save: {
    ar: "حفظ",
    en: "Save",
  },

  saveAndConfirm: {
    ar: "حفظ واعتماد",
    en: "Save and confirm",
  },

  language: {
    ar: "ar",
    en: "en",
  },
  has: {
    ar: "لديه",
    en: "has",
  },
  accessPermission: {
    ar: "صلاحيات الوصول",
    en: "Access permission",
  },
  editPermission: {
    ar: "صلاحيات التعديل",
    en: "Edit permission",
  },
  deletePermission: {
    ar: "صلاحيات الازالة",
    en: "Delete permission",
  },
  createPermission: {
    ar: "صلاحيات الانشاء",
    en: "Create Permission",
  },
  sharePermission: {
    ar: "صلاحيات المشاركة",
    en: "Share permission",
  },
  employees: {
    ar: "الموظفين",
    en: "Employees",
  },
  ok: {
    ar: "موافق",
    en: "Ok",
  },
  name: {
    ar: "إسم الحساب",
    en: "Account name",
  },
  code: {
    ar: "رمز الحساب",
    en: "Account No.",
  },
  Reset: {
    ar: "إعادة تعيين",
    en: "Reset",
  },
  selectOption: {
    ar: "اختر خيار",
    en: "Select an option",
  },
  actions: {
    ar: "العمليات",
    en: "Actions",
  },
  customerCode: {
    ar: "رمز الزبون",
    en: "Customer code",
  },
  treasuryCode: {
    ar: "رمز الخزينة",
    en: "treasury code",
  },
  customerName: {
    ar: "اسم الزبون",
    en: "Customer name",
  },
  treasuryName: {
    ar: "اسم الخزينة",
    en: "treasury name",
  },
  costCenter: {
    ar: "مركز التكلفة",
    en: "Cost center",
  },
  ShipmentPayment: { ar: "سداد شحن", en: "Shipping Payment" },
  InternalTransfer: { ar: "حوالة داخلية", en: "Internal Transfer" },
  MoneyTransferOnly: { ar: "حوالة فقط", en: "Money Transfer Only" },
  CustomerLoan: { ar: "صرف دين لعميل", en: "Customer Loan" },
  MoneyTransferForOrder: { ar: "حوالة لشحنة", en: "Money Transfer For Order" },
  ShippingPayment: { ar: "سداد شحن", en: "Shipping Payment" },
  Custody: { ar: "عهدة", en: "Custody" },
  Loan: { ar: "سلفة", en: "Loan" },
  Expenses: { ar: "مصروفات", en: "Expenses" },
  Salary: { ar: "مرتبات", en: "Salary" },
  BalanceTransfer: { ar: "نقل ارصدة", en: "Balance Transfer" },
  CustomerPayment: { ar: "ايداع زبون", en: "Customer Payment" },
  Manual: { ar: "قيد يدوي", en: "Manual" },
  Shipment: { ar: "شحنة", en: "Shipment" },
  CustomerLoanPayment: { ar: "سداد دين عميل", en: "Customer Loan Payment" },
  BrokerPayment: { ar: "سداد بروكر", en: "Broker Payment" },
  ResizingOrder: { ar: "إعادة تحجيم", en: "Resizing Order" },
  RepricingOrder: { ar: "إعادة تسعير", en: "Repricing Order" },
  AdjustmentRecompense: {
    ar: "تعويض تعديل طلبية",
    en: "Adjustment Recompense",
  },
  BulkExpenses: {
    ar: "مصروفات البلك",
    en: "Bulk expenses",
  },
  BrokerPayment: { ar: "مصروفات بلك", en: "Bulk Expenses" },
  TransfersClaim: { ar: "إستحقاق حوالة", en: "Transfers Claim" },
  SettlementTransaction: { ar: "حركة تسوية ", en: "Settlement Transaction" },

  USD: {
    ar: "دولار أمريكي",
    en: "USD",
  },
  LYD: {
    ar: "دينار ليبي",
    en: "LYD",
  },
  EURO: {
    ar: "اليورو",
    en: "EURO",
  },
  Normal: {
    ar: "طبيعي",
    en: "Normal",
  },
  Bank: {
    ar: "مصرف",
    en: "Bank",
  },
  Treasury: {
    ar: "خزينة",
    en: "Treasury",
  },
  Employee: {
    ar: "موظف",
    en: "Employee",
  },
  Person: {
    ar: "شخصي",
    en: "Person",
  },
  Customer: {
    ar: "العميل",
    en: "Customer",
  },
  Revenue: {
    ar: "ايراد",
    en: "Revenue",
  },
  Broker: {
    ar: "بروكر",
    en: "Broker",
  },
  country: {
    ar: "البلد",
    en: "Country",
  },
  done: {
    ar: "تمت العملية بنجاح",
    en: "Done",
  },
  approve: {
    ar: "اعتماد",
    en: "Approve",
  },
  value: {
    ar: "القيمة",
    en: "Value",
  },
  date: {
    ar: "التاريخ",
    en: "Date",
  },
  showDetails: {
    en: "Show details",
    ar: "مزيد من التفاصيل",
  },
  shipmentType: {
    ar: "نوع الشحن",
    en: "Shipment type",
  },
  None: {
    ar: "غير شحنه",
    en: "None",
  },
  Air: {
    ar: "جوي",
    en: "Air",
  },
  LCL: {
    ar: "بحري مشترك",
    en: "LCL",
  },
  FCL: {
    ar: "حاوية كاملة",
    en: "FCL",
  },
};
