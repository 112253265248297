import React, { useRef, useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch
} from "react-router-dom";
// get culture from utils 
import getCulture from "utils/getCulture";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form,
  Button
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import Spinner from 'components/Spinner';
import content from './content';
import catchAsync from 'helper/catchAsync';
import ReactBSAlert from "react-bootstrap-sweetalert";
import axios from 'axios';
import env from 'env';

function CreateFiscalYear() {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const yearRef = useRef();
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState();
  const handleSubmit = e => catchAsync(async () => {
    e.preventDefault();
    setSpinner(true);
    
    const schema = {
      year: yearRef.current,
      fiscalYearState: 1
    }

    const result = await axios.post(`${env.activeServer()}/FiscalYears`, schema, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`
      }
    })

    if (result.status === 200)
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => setAlert('')}
          onCancel={() => setAlert('')}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
          btnSize=""
        >
          {result.statusText}
        </ReactBSAlert>
      )

  }, () => setSpinner(false), setAlert);

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md-${culture}`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <Row className='align-items-center'>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.year[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      placeholder={content.year[culture]}
                      type="number"
                      defaultValue={2000}
                      min="2000"
                      onChange={e => yearRef.current = e.target.value}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.fiscalYearState[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      defaultValue="open"
                      disabled
                      onChange={e => {}}
                    >
                      <option value="open">{content.open[culture]}</option>
                      <option value="close">{content.close[culture]}</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <Button className="mr-2" color="primary" size="md" type="submit">
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>
                      {content.save[culture]}
                    </span>
                  </Button>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h3>{content.settings[culture]}</h3>
            <Form>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label mr-2 ml-2"
                      htmlFor="first-check"
                      >
                      <Input type='checkbox' value="" id="first-check" />
                      <span className='mr-3 ml-3'>
                        {content.checkPermission[culture]}
                      </span>
                    </label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label mr-2 ml-2"
                      htmlFor="second-check"
                      >
                      <Input type='checkbox' value="" id="second-check" />
                      <span className='mr-3 ml-3'>
                        {content.checkRestriction[culture]}
                      </span>
                    </label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="first-select"
                    >
                      {content.taxCalc[culture]}
                    </label>
                    <Input
                      id="first-select"
                      type="select"
                      value="test1"
                      onChange={e => {}}
                    >
                      <option value="test1">{content.test[culture]}</option>
                      <option value="test2">{content.test[culture]}</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="second-select"
                    >
                      {content.bankCalc[culture]}
                    </label>
                    <Input
                      id="second-select"
                      type="select"
                      value="test1"
                      onChange={e => {}}
                    >
                      <option value="test1">{content.test[culture]}</option>
                      <option value="test2">{content.test[culture]}</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="third-select"
                    >
                      {content.inventoryAccount[culture]}
                    </label>
                    <Input
                      id="third-select"
                      type="select"
                      value="test1"
                      onChange={e => {}}
                    >
                      <option value="test1">{content.test[culture]}</option>
                      <option value="test2">{content.test[culture]}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default CreateFiscalYear
