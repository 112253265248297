import React, { useState, useContext } from "react";
import content from "./content";
import Spinner from "components/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader";
import useCulture from "hooks/useCulture";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import useApi from "hooks/useApi";
import { useParams } from "react-router";
import Tables from "components/common/CustomTable";
import TextPopup from "components/common/TextModal";
import commonContent from "components/common/commonContent";
import useAlert from "hooks/useAlert";
import roles from "api/roles";
import useSearchPopup from "hooks/useSearchPopup";
import usePrevRequest from "hooks/usePrevRequest";
import usePagination from "hooks/usePagination";
import env from "env";
import { PaginationContext } from "context/PaginationContext";
import SearchModal from "components/common/SearchModal";
import { Controller, useForm } from "react-hook-form";
import useCreate from "hooks/useCreate";
import Pagination from "components/common/Pagination";
import EditRole from "./components/EditRole";
import useUpdate from "hooks/useUpdata";
import useDelete from "hooks/useDelete";

function Roles() {
  const culture = useCulture();
  const { alert, sweetAlert } = useAlert();

  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const getAllApi = useApi(roles.getAll);
  const updateRolesApi = useApi(roles.updateRoles);
  const getByColumn = useApi(roles.filter);
  const createOneApi = useApi(roles.createOne);
  const updateOneApi = useApi(roles.updateOne);
  const deleteOneApi = useApi(roles.deleteOne);

  const updateData = (d) => setData(d);
  const headers = [
    commonContent.actions[culture],
    commonContent.name[culture],
    commonContent.description[culture],
  ];
  const columns = ["name", "description"];

  const pageName = "roles";

  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination(pageName);
  const paginationCtx = useContext(PaginationContext);

  const editResponse = (item) => {
    return item;
  };

  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data.data,
    editResponse
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const {
    handleSubmit: updateHandleSubmit,
    formState: { errors: updateErrors },
    control: updateControl,
    reset: updateReset,
    setValue: updateSetValue,
  } = useForm();

  const createHook = useCreate(
    createOneApi,
    data,
    setData,
    setSpinner,
    sweetAlert,
    reset
  );

  const updateHook = useUpdate(
    updateOneApi,
    data,
    setData,
    setSpinner,
    setEditModal,
    sweetAlert,
    updateReset
  );

  const deleteHook = useDelete(data, setData, setSpinner, setDeleteModal, sweetAlert)

  const deleteHandler = async () => deleteHook(deleteOneApi, rowId);

  const createHandler = async (formData) => await createHook(formData);

  const getAll = async () => {
    const res = await getAllApi.request(pageNo, docsPerPage);

    if (res.status === 200) {
      // res.data.data.allRoles.forEach(role => editResponse(role, res.data.data.userRoles));
      setData(res.data.data);
    }
  };

  const handleDelete = obj => {
    setRowId(obj.id);
    setDeleteModal(true);
  }

  const handleCancel = () => {
    setEditModal(false);
    setDeleteModal(false);
  };

  const handleUpdate = (obj) => {
    setEditModal(true);
    updateSetValue("id", obj.id);
    updateSetValue("name", obj.name);
    updateSetValue("description", obj.description);
  };

  const updateHanlder = async (formData) =>
    await updateHook(formData.id, formData);

  usePrevRequest(
    getAll,
    searchPopup,
    pageNo,
    docsPerPage,
    updateData,
    prevPageCheck
  );

  if (getAllApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {getAllApi.errorAlert}
      {updateRolesApi.errorAlert}
      {createOneApi.errorAlert}
      {updateOneApi.errorAlert}
      {deleteOneApi.errorAlert}
      {spinner ? <Spinner opacity bg="#fff" gate="#42ba96" /> : null}
      <SimpleHeader parents={[content.roles[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3>{content.roles[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(createHandler)}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      {commonContent.name[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="name"
                      rules={{
                        required: `${commonContent.name[culture]} field is required`,
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="name"
                          type="text"
                          className={errors.name && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="description">
                      {commonContent.description[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="description"
                      rules={{
                        required: `description field is required`,
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="description"
                          type="text"
                          className={errors.description && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button color="info" className="mr-2" size="sm" type="submit">
                <span className="btn-inner--icon me-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">
                  {commonContent.create[culture]}
                </span>
              </Button>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Pagination
            dataLength={data.length}
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
            setPageNo={
              searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
            }
            docsPerPage={
              searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
            }
            setDocsPerPage={
              searchPopup.isSelected
                ? searchPopup.setDocsPerPage
                : setDocsPerPage
            }
          >
            <Tables
              headers={headers}
              columns={columns}
              data={data || []}
              handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              onHeaderClick={searchPopup.handleHeaderClicked}
              visitedRowId={paginationCtx.visitedRowId}
              // when the user was searching useing the popup and select a row
              // store the popup pagination in the context
              // otherwise store the normal pagination in the context
              setPagination={
                searchPopup.isSelected
                  ? (rowId) =>
                      paginationCtx.setPagination(
                        searchPopup.pageNo,
                        searchPopup.docsPerPage,
                        rowId,
                        pageName
                      )
                  : (rowId) =>
                      paginationCtx.setPagination(
                        pageNo,
                        docsPerPage,
                        rowId,
                        pageName
                      )
              }
            >
              <span to={`/${culture}-admin/role-permissions`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commonContent.show[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleUpdate">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commonContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span className="ms-1" fun="handleDelete">
                <div id="delete1" className="table-action cursor-pointer">
                  <i className="fas fa-trash fa-lg hover-danger"></i>
                </div>
                <UncontrolledTooltip delay={0} target="delete1">
                  {commonContent.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Tables>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={editModal}
        text={commonContent.save[culture]}
        handleCancel={handleCancel}
        fn={updateHandleSubmit(updateHanlder)}
        color="info"
      >
        <CardBody>
          <EditRole control={updateControl} errors={updateErrors} />
        </CardBody>
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={commonContent.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteHandler}
        color="danger"
      >
        <CardBody>
          <h3>{content.deletePopup[culture]}</h3>
        </CardBody>
      </TextPopup>
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={editResponse}
          popupSearchCtx={paginationCtx.popupSearch}
          extraField={{ header: commonContent.name[culture], column: "name" }}
        />
      )}
    </>
  );
}

export default Roles;
