import React, { useState } from "react";
import content from "../Users/content";
import Spinner from "components/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader";
import useCulture from "hooks/useCulture";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import useApi from "hooks/useApi";
import users from "api/users";
import { useParams } from "react-router";
import { useEffect } from "react";
import Tables from "components/common/CustomTable";
import TextPopup from "components/common/TextModal";
import commonContent from "components/common/commonContent";
import useAlert from "hooks/useAlert";
import usePermissions from "hooks/usePermissions";

function UserPermissions() {
  const culture = useCulture();
  const { alert, sweetAlert } = useAlert();

  const checkPermission = usePermissions("AuthUsers");

  const [data, setData] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [disableModal, setDisableModal] = useState(false);
  const [rowId, setRowId] = useState(false);

  const { id } = useParams();

  const getOneApi = useApi(users.getOne);
  const updateRolesApi = useApi(users.updateRoles);

  const headers = [
    commonContent.actions[culture],
    content.permissionName[culture],
    content.status[culture],
  ];
  const columns = ["name", "statusText"];

  const user = data?.user || {};
  const allRoles = data?.allRoles || [];
  const userRoles = data?.userRoles || [];

  const editResponse = (role, myRoles) => {
    const _roles = myRoles ? myRoles : userRoles;

    role.status = _roles.find((item) => item.id === role.id) ? true : false;
    role.statusText = role.status
      ? content.active[culture]
      : content.notActive[culture];
  };

  const getUser = async () => {
    const res = await getOneApi.request(id);

    if (res.status === 200) {
      res.data.data.allRoles.forEach((role) =>
        editResponse(role, res.data.data.userRoles)
      );
      setData(res.data.data);
    }
  };

  const renderUpdateRole = () => checkPermission("UpdateRolesAsync");

  const renderActivation = (obj) => !obj.status && renderUpdateRole();

  const renderDisabled = (obj) => obj.status && renderUpdateRole();

  const handleCancel = () => {
    setActiveModal(false);
    setDisableModal(false);
  };

  const updateRolesHandler = async (arrIds) => {
    setSpinner(true);
    handleCancel();
    const res = await updateRolesApi.request(id, arrIds);
    if (res.status === 200) {
      setData((oldVal) => {
        const newData = { ...oldVal };
        newData.allRoles.forEach((item) =>
          editResponse(item, res.data.data.userRoles)
        );
        newData.userRoles = res.data.data.userRoles;
        return newData;
      });
      sweetAlert(commonContent.done[culture]);
    }
    setSpinner(false);
  };

  const preActivation = () => {
    const arrIds = data.userRoles.map((item) => item.id);
    arrIds.push(rowId);
    updateRolesHandler(arrIds);
  };

  const preDisable = () => {
    const userIds = data.userRoles.map((item) => item.id);
    const userUpdatedIds = userIds.filter((item) => item !== rowId);
    updateRolesHandler(userUpdatedIds);
  };

  const activeHandler = (obj) => {
    setActiveModal(true);
    setRowId(obj.id);
  };

  const disableHandler = (obj) => {
    setDisableModal(true);
    setRowId(obj.id);
  };

  useEffect(() => {
    getUser();
  }, []);

  if (getOneApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {getOneApi.errorAlert}
      {updateRolesApi.errorAlert}
      {spinner ? <Spinner opacity bg="#fff" gate="#42ba96" /> : null}
      <SimpleHeader parents={[content.permission[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3>{content.users[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="username">
                    {content.username[culture]}
                  </label>
                  <Input
                    id="username"
                    placeholder={content.username[culture]}
                    type="text"
                    value={user.userName || ""}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <Tables
            headers={headers}
            columns={columns}
            data={allRoles || []}
            renderActivation={renderActivation}
            renderDisabled={renderDisabled}
            activeHandler={activeHandler}
            disableHandler={disableHandler}
          >
            <span
              fun="activeHandler"
              className="me-1"
              condition="renderActivation"
            >
              <Button color="success" size="sm" className="px-4">
                {content.activation[culture]}
              </Button>
            </span>
            <span fun="disableHandler" condition="renderDisabled">
              <Button color="danger" size="sm" className="px-4">
                {content.disable[culture]}
              </Button>
            </span>
          </Tables>
        </Card>
      </Container>
      <TextPopup
        modal={activeModal}
        text={commonContent.save[culture]}
        handleCancel={handleCancel}
        fn={preActivation}
        color="info"
      >
        <CardBody>
          <h3>{content.activationPopup[culture]}</h3>
        </CardBody>
      </TextPopup>
      <TextPopup
        modal={disableModal}
        text={commonContent.save[culture]}
        handleCancel={handleCancel}
        fn={preDisable}
        color="danger"
      >
        <CardBody>
          <h3>{content.disablePopup[culture]}</h3>
        </CardBody>
      </TextPopup>
    </>
  );
}

export default UserPermissions;
