import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form,
  Button,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import { useRouteMatch, Link } from "react-router-dom";
import getCulture from "utils/getCulture";
import entry from "api/entry";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import content from "./content";
import useApi from "hooks/useApi";
import usePagination from "hooks/usePagination";
import { PaginationContext } from "context/PaginationContext";
import useSearchPopup from "hooks/useSearchPopup";
import useDelete from "hooks/useDelete";
import SearchModal from "components/common/SearchModal";
import Pagination from "components/common/Pagination";
import Spinner from "components/Spinner";
import env from "env";
import commonContent from "components/common/commonContent";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import { useForm, Controller } from "react-hook-form";
import usePermissions from "hooks/usePermissions";
import { addStyleToEntry } from "utils/addStyleToEntry";

const TransfersRelatedEntries = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const checkPermission = usePermissions("AccountCategory");

  const headers = [
    content.actions[culture],
    content.entryNo[culture],
    content.entryType[culture],
    content.entryState[culture],
    content.currency[culture],
    content.shipmentType[culture],
    content.date[culture],
  ];

  const columns = [
    "serialNumberDisplayed",
    "entryTypeText",
    "entryStateText",
    "currencyText",
    "shipmentTypeText",
    "date",
  ];

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const rowId = useRef(null);

  // Apis
  const entriesApi = useApi(entry.getTransfersRelatedEntries);
  const deleteEntryApi = useApi(entry.deleteEntry);
  const filterEntryApi = useApi(entry.filterEntry);

  const { alert, sweetAlert } = useAlert();

  // States
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const paginationCtx = useContext(PaginationContext);

  const pageName = "journalEntry";
  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination(pageName);

  const filteredEntries = [
    "Money Transfer Only",
    "Broker Payment",
    "Balance Transfer",
    "Transfers Claim",
    "Settlement Transaction",
  ];

  const editResponse = (item) => {
    item.date = item.date.split("T")[0];
    item.entryTypeText = env.entryType[item.entryType - 1][culture];
    item.entryStateText = env.entryState[item.entryState - 1][culture];
    item.currencyText = env.currency[item.currency - 1][culture];
    item.shipmentTypeText = env.shipmentType[item.shipmentType][culture];
    addStyleToEntry(item);
    return item;
  };

  const deleteHandler = useDelete(
    data,
    setData,
    setSpinner,
    setDeleteModal,
    sweetAlert
  );

  const getEntries = async () => {
    const res = await entriesApi.request(pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data = res.data.data.map((item) => editResponse(item));
      setData(res.data.data);
    }
  };

  const updateData = (arr) => {
    const editedData = arr.map((res) => editResponse(res));
    setData(editedData);
  };

  const handleCancel = () => {
    rowId.current = null;
    setDeleteModal(false);
  };

  const handleDelete = (obj) => {
    rowId.current = obj.id;
    setDeleteModal(true);
  };

  const deleteAccount = async () =>
    deleteHandler(deleteEntryApi, rowId.current);

  const filterEntries = async (formData) => {
    const schema = {};
    Object.keys(formData ?? {}).forEach((key) => {
      if (!formData[key]) return;
      schema[key] = formData[key];
    });

    const entryType = env.entryType?.findIndex(
      (entry) => entry[culture] === formData.entryType
    );
    schema.entryType = entryType > -1 ? entryType + 1 : null;

    const res = await filterEntryApi.request(schema, pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data.forEach((item) => editResponse(item));
      setData(res.data.data);
      setIsFilter(true);
    }
  };

  const clearForm = () => {
    reset();
    getEntries();
    setIsFilter(false);
  };

  const renderEdit = () => checkPermission("UpdateAsync");
  const renderDelete = () => checkPermission("DeleteAsync");

  useEffect(() => {
    if (isFilter) return filterEntries();
    getEntries();
  }, [pageNo, docsPerPage]);

  if (entriesApi.loading || filterEntryApi.loading)
    return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {entriesApi.errorAlert}
      {deleteEntryApi.errorAlert}
      {filterEntryApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.title[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.title[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(filterEntries)}>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="entryNo">
                      {content.entryNo[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="serialNumber"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="entryNo"
                          type="number"
                          onWheel={(e) => e.target?.blur()}
                          onChange={({ target: { value } }) => onChange(+value)}
                          value={value || ""}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="entryType">
                      {content.entryType[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="entryType"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="entryType"
                          type="select"
                          onChange={({ target: { value } }) => onChange(+value)}
                          value={value || "disabled"}
                        >
                          <option disabled value="disabled">
                            -- {commonContent.selectOption[culture]} --
                          </option>
                          {env.entryType?.map((item, index) => (
                            <option value={index + 1} key={index}>
                              {item[culture]}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="entryState">
                      {content.entryState[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="entryState"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="entryState"
                          type="select"
                          onChange={({ target: { value } }) => onChange(+value)}
                          value={value || "disabled"}
                        >
                          <option disabled value="disabled">
                            -- {commonContent.selectOption[culture]} --
                          </option>
                          {env.entryState?.map((item, index) => (
                            <option value={index + 1} key={index}>
                              {item[culture]}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="currency">
                      {content.currency[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="currency"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="currency"
                          type="select"
                          onChange={({ target: { value } }) => onChange(+value)}
                          value={value || "disabled"}
                        >
                          <option disabled value="disabled">
                            -- {commonContent.selectOption[culture]} --
                          </option>
                          {env.currency?.map((item, index) => (
                            <option value={index + 1} key={index}>
                              {item[culture]}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="shipmentType"
                    >
                      {content.shipmentType[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="shipmentType"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="shipmentType"
                          type="select"
                          onChange={({ target: { value } }) => onChange(+value)}
                          value={value || "disabled"}
                        >
                          <option disabled value="disabled">
                            -- {commonContent.selectOption[culture]} --
                          </option>
                          {env.shipmentType?.map((item, index) => (
                            <option value={index} key={index}>
                              {item[culture]}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="from">
                      {content.from[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="from"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="from"
                          type="date"
                          value={value || "disabled"}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="from">
                      {content.to[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="to"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="to"
                          type="date"
                          value={value || "disabled"}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                className="mr-2"
                color="info"
                size="md"
                type="submit"
                disabled={!isDirty}
              >
                <span className="btn-inner--icon cmr-2">
                  <i className="fab fa-sistrix"></i>
                </span>
                <span>{commonContent.search[culture]}</span>
              </Button>
              {isFilter && (
                <Button
                  className="mr-2"
                  size="md"
                  type="button"
                  onClick={clearForm}
                >
                  <span>{commonContent.clear[culture]}</span>
                </Button>
              )}
            </Form>
          </CardBody>
        </Card>
        <Card>
          {checkPermission("CreateAsync") && (
            <CardBody>
              <Link to={`/${culture}-admin/transaction/createentry`}>
                <Button color="info" type="button" className="cml-2" size="sm">
                  <span className="btn-inner--icon me-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">
                    {content.entry[culture]}
                  </span>
                </Button>
              </Link>
            </CardBody>
          )}
          <Pagination
            dataLength={data?.length}
            // dataLength={searchPopup.isSelected ? searchPopup.hookData?.length : data.data?.length }
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              headers={headers}
              columns={columns}
              data={data}
              handleDelete={handleDelete}
              renderEdit={renderEdit}
              renderDelete={renderDelete}
            >
              <span to={`/${culture}-admin/transaction/entryDetails`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commonContent.show[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="cmr-2"
                fun="handleDelete"
                condition="renderDelete"
              >
                <div
                  id="delete"
                  className="table-action-delete table-action cursor-pointer hover-danger"
                >
                  <i className="fas fa-trash fa-lg"></i>
                </div>
                <UncontrolledTooltip delay={0} target="delete">
                  {commonContent.deleting[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                to={`/${culture}-admin/transaction/editentry`}
                condition="renderEdit"
              >
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commonContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
          <TextPopup
            modal={deleteModal}
            text={commonContent.delete[culture]}
            handleCancel={handleCancel}
            fn={deleteAccount}
            color="danger"
          >
            <Container>{content.deletePopup[culture]}</Container>
          </TextPopup>
        </Card>
      </Container>
    </>
  );
};

export default TransfersRelatedEntries;
