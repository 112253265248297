import client from "./client";

const endpoint = "/v1/Auth/AuthUsers";

const getAll = (pageNo, docsPerPage) =>
  client.get(`${endpoint}?Page=${pageNo}&PostsPerPage=${docsPerPage}`);

const deleteOne = (id) => client.delete(`${endpoint}/${id}`);

const updateOne = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const getOne = (id) => client.get(`${endpoint}/${id}`);

const updateRoles = (id, schema) => client.put(`${endpoint}/${id}/updateroles`, schema);

const filter = (schema, pageNo, docsPerPage) =>
  client.put(
    `${endpoint}/filter?Page=${pageNo}&PostsPerPage=${docsPerPage}`,
    schema
  );

export default {
  getAll,
  getOne,
  updateOne,
  deleteOne,
  updateRoles,
  filter,
};
