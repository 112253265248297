import React, { useEffect, useState } from "react";

// router
import { useRouteMatch, useParams } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Modal,
  Button,
  UncontrolledTooltip,
  Container,
  Row,
  Col,
} from "reactstrap";
//content
import content from "./content";

//common
import commenContent from "components/common/commonContent";

// Table
import Table from "components/common/CustomTable";

// core components
import SimpleHeader from "components/Headers/SimpleHeader";
import { useForm, Controller } from "react-hook-form";
import TextPopup from "components/common/TextModal";
import AccountPopUpTable from "components/common/AaccountPopUpTable";
import Spinner from "components/Spinner";
import useAlert from "hooks/useAlert";
import useApi from "../../../hooks/useApi";
import AccountApi from "api/account";
import FiscalYearsApi from "api/fiscalYear";
import env from "env";
import commonContent from "components/common/commonContent";
import CostCenterTable from "components/common/CostCenterTable";
import { useMemo } from "react";

function AccountTransactionReport() {
  const rout = useRouteMatch();

  const culture = getCulture(rout.url);
  //data table

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [tableModal, setTableModal] = useState(false);
  const [costCenterModal, setCostCenterModal] = useState(false);

  const { id, accountName, date } = useParams();

  // const accountApi = useApi(AccountApi.getAccounts);
  const transactionApi = useApi(AccountApi.transactionA);
  const fiscalYearstApi = useApi(FiscalYearsApi.getFiscalYears);

  //alert message
  const { alert, sweetAlert } = useAlert();
  let log, total;

  // Use form hook
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm();

  const [headers, setHeaders] = useState([
    commenContent.actions[culture],
    commenContent.date[culture],
    commenContent.type[culture],
    content.serialNumber[culture],
    content.entryStatement[culture],
    content.credit[culture],
    content.debit[culture],
    content.currentBalance[culture],
  ]);

  const [columns, setColumns] = useState([
    "transactionDate",
    "entryTypeText",
    "serialNumber",
    "entryStatement",
    "credit",
    "debit",
    "currentBalanceUSD", // This is for current Balance
  ]);

  const handleCancel = () => {
    setTableModal(false);
    setCostCenterModal(false);
  };

  // const getAccounts = async () => {
  //   const res = await accountApi.request(pageNo, docsPerPage);

  //   if (res.status === 200) {
  //     setData(res.data.data);
  //   }
  // };
  const editResponse = (item) => {
    item.transactionDate = item.transactionDate?.split("T")[0];
    item.currency = env.currency[item.currency - 1][culture];
    item.transactionState = content[item.transactionState][culture];
    item.entryTypeText = commenContent[item.entryType]?.[culture] || "";
    item.id = item.entryId;
    item.shipmentType = commenContent[item.shipmentType]?.[culture] || "";
    return item;
  };
  const editEnum = (item) => {
    item.account.accountClassification =
      env.accountClassifications[item.account.accountClassification - 1][
        culture
      ];
    item.account.accountNature =
      env.accountNature[item.account.accountNature - 1][culture];
    return item;
  };
  // const getFiscalYears = async () => {
  //   const res = await fiscalYearstApi.request(pageNo, docsPerPage);

  //   if (res.status === 200) {
  //     setData(res.data.data);
  //   }
  // };
  const handleCalculate = async (formData) => {
    setSpinner(true);
    const res = await transactionApi.request({
      ...formData,
      accountId: formData?.accountId || id,
      date: formData?.date || `${date}-1-1`,
      // formData.upto
    });
    if (res.ok) {
      res.data.data.detailsInfo.map(editResponse);
      editEnum(res.data.data);
      setData(res.data.data);
    }
    setSpinner(false);
  };

  useEffect(() => {
    if (id && date) handleCalculate();
  }, [id, date]);

  if (data.detailsInfo?.length) {
    log = (
      <CardBody>
        <Row>
          <Col md="2">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {commenContent.number[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.account.number || ""}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {commenContent.accountName[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.account.name || ""}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {commenContent.accountClassification[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.account.accountClassification || ""}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {commenContent.accountNature[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.account.accountNature || ""}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    );

    total = (
      <CardBody>
        <Row>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {commenContent.totalDebitLYD[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.totalDebitLYD}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {commenContent.totalCreditLYD[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.totalCreditLYD}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {content.balanceLYD[culture]}
              </label>
              <Input id="client" type="text" value={data.balanceLYD} readOnly />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {commenContent.totalDebitUSD[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.totalDebitUSD}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {commenContent.totalCreditUSD[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.totalCreditUSD}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {content.balanceUSD[culture]}
              </label>
              <Input id="client" type="text" value={data.balanceUSD} readOnly />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {content.totalDebitEURO[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.totalDebitEURO}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {content.totalCreditEURO[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.totalCreditEURO}
                readOnly
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="account">
                {content.balanceEuro[culture]}
              </label>
              <Input
                id="client"
                type="text"
                value={data.balanceEURO}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    );
  }

  const printFooter = [
    {
      text: commenContent.totalDebitLYD[culture],
      value: data.totalDebitLYD,
    },
    {
      text: commenContent.totalCreditLYD[culture],
      value: data.totalCreditLYD,
    },
    {
      text: commenContent.totalCreditUSD[culture],
      value: "$" + data.totalCreditUSD,
    },
    {
      text: commenContent.totalDebitUSD[culture],
      value: "$" + data.totalDebitUSD,
    },
  ];

  const selectedCurrency = watch("currency") ?? 1;

  const balanceArray = useMemo(
    () => ["currentBalanceUSD", "currentBalanceLYD", "currentBalanceEURO"],
    []
  );

  useEffect(() => {
    setColumns((prevVal) => {
      const newColumns = [...prevVal];
      newColumns[newColumns.length - 1] = balanceArray[selectedCurrency - 1];
      return newColumns;
    });
  }, [selectedCurrency]);
  if (fiscalYearstApi.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <>
      {alert}
      {fiscalYearstApi.errorAlert}
      {transactionApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {/* loading for edit and delete request */}

      <SimpleHeader parents={["Customer Movement"]} />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row>
              <Col md="10">
                <h3 classyear={`mb-0 text-md-${content.title[culture]}`}>
                  {content.title[culture]}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(handleCalculate)}>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="account">
                      {commenContent.account[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="account"
                      // rules={{ required: "Client field is required" }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="account"
                          placeholder={commenContent.account[culture]}
                          type="text"
                          className={errors.account && "error"}
                          value={value || accountName || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onClick={() => setTableModal(true)}
                          disabled={id}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="date">
                      {content.transactionAfter[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="date"
                      // rules={{ required: "Client field is required" }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="date"
                          type="date"
                          className={errors.date && "error"}
                          value={value || `${date}-01-01` || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          disabled={date}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="upto">
                      {commenContent.to[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="upto"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="upto"
                          type="date"
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          disabled={date}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="currency">
                      {commonContent.currency[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="currency"
                      rules={{ required: true }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="currency"
                          type="select"
                          className={errors.currency && "error"}
                          onChange={({ target: { value } }) => onChange(+value)}
                          value={value || "disabled"}
                        >
                          <option disabled value="disabled">
                            -- {commonContent.selectOption[culture]} --
                          </option>
                          {env.currency?.map((item, index) => (
                            <option value={index + 1} key={index}>
                              {item[culture]}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="entryState">
                      {content.entryState[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="entryState"
                      rules={{ required: true }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="entryState"
                          type="select"
                          onChange={({ target: { value } }) => onChange(+value)}
                          value={value || "disabled"}
                          className={errors.entryState && "error"}
                        >
                          <option disabled value="disabled">
                            -- {commonContent.selectOption[culture]} --
                          </option>
                          {env.entryState?.map((item, index) => (
                            <option value={index + 1} key={index}>
                              {item[culture]}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="costCenter">
                      {commenContent.costCenter[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="costCenter"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="costCenter"
                          placeholder={`-- ${commenContent.costCenter[culture]} --`}
                          type="text"
                          className={errors.costCenter && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onClick={() => setCostCenterModal(true)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup className="form-check">
                    <Controller
                      control={control}
                      name="excludeLoans"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="excludeLoans"
                          placeholder={`-- ${content.debtExclusion[culture]} --`}
                          type="checkbox"
                          className={errors.debtExclustion && "error"}
                          value={value || ""}
                          onChange={({ target: { checked } }) =>
                            onChange(checked)
                          }
                        />
                      )}
                    />
                    <label
                      className={`form-control-label ${
                        culture === "ar" ? "ms-2" : ""
                      }`}
                      htmlFor="excludeLoans"
                    >
                      {content.debtExclusion[culture]}
                    </label>
                  </FormGroup>
                </Col>
              </Row>
              <Button
                color="success"
                type="submit"
                disabled={!watch("account") || !watch("date")}
              >
                <span className="btn-inner--icon me-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>{commenContent.calculate[culture]}</span>
              </Button>
            </form>
          </CardBody>
        </Card>
        <Card>{log}</Card>
        <Card>
          <Table
            printFooter={printFooter}
            pageName={content.title[culture]}
            headers={headers}
            columns={columns}
            setColumns={setColumns}
            setHeaders={setHeaders}
            renderShowDetials={(obj) => obj.debit || obj.credit}
            // when the user search using the popup and select a row
            // after then only use the hookData states from searchPopup hook
            data={data.detailsInfo}
            setData={(d) =>
              setData((prevData) => ({ ...prevData, detailsInfo: d }))
            }
          >
            <span
              to={`/${culture}-admin/transaction/entryDetails`}
              condition="renderShowDetials"
            >
              <div id="details1" className="table-action cursor-pointer">
                <i className="far fa-eye fa-lg hover-success"></i>
              </div>
              <UncontrolledTooltip delay={0} target="details1">
                {commenContent.showDetails[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
        </Card>
        <Card>{total}</Card>
      </Container>
      <TextPopup
        modal={tableModal}
        text={commenContent.next[culture]}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <AccountPopUpTable
            setTableModal={setTableModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={costCenterModal}
        text={commenContent.next[culture]}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <CostCenterTable
            // setCountry={setCountry}
            setModal={setCostCenterModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
    </>
  );
}

export default AccountTransactionReport;
