import React from 'react'
import { Col, Input, FormGroup } from "reactstrap";
import content from '../content';

function CreateAccountPopup({ accountNo, setAccountNo, culture }) {
  return (
    <div>
      <FormGroup>
        <label
          className="form-control-label"
          htmlFor="example3cols1Input"
        >
          {content.bankAccountNumber[culture]}
        </label>
        <Input
          id="example3cols1Input"
          type="number"
          value={accountNo}
          onChange={e => setAccountNo(e.target.value)}
        />
      </FormGroup>
    </div>
  )
}

export default CreateAccountPopup