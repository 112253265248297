import React from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";
import content from "Screen/Accounts/customerMovment/accountContent";

function BalanceCard({ list, culture }) {

  return (
    <Row>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.totalDebitUSD[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.totalDebitUSD[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={list.totalDebitUSD ? list.totalDebitUSD : 0}
          />
        </FormGroup>
      </Col>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.totalCreditUSD[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.totalCreditUSD[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={list.totalCreditUSD ? list.totalCreditUSD : 0}
          />
        </FormGroup>
      </Col>

      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.balanceUSD[culture]}
          </label>

          <Input
            id="example3cols1Input"
            placeholder={content.balanceUSD[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={
              list.balanceUSD
                ? list.balanceUSD
                : list.totalBalanceUSD
                ? list.totalBalanceUSD
                : 0
            }
          />
        </FormGroup>
      </Col>

      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.totalDebitLYD[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.totalDebitLYD[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={list.totalDebitLYD ? list.totalDebitLYD : 0}
          />
        </FormGroup>
      </Col>

      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.totalCreditLYD[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.totalCreditLYD[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={list.totalCreditLYD ? list.totalCreditLYD : 0}
          />
        </FormGroup>
      </Col>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.balanceLYD[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.balanceLYD[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={
              list.balanceLYD
                ? list.balanceLYD
                : list.totalBalanceLYD
                ? list.totalBalanceLYD
                : 0
            }
          />
        </FormGroup>
      </Col>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.totalDebitEURO[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.totalDebitEURO[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={list.totalDebitEURO ? list.totalDebitEURO : 0}
          />
        </FormGroup>
      </Col>

      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.totalCreditEURO[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.totalCreditEURO[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={list.totalCreditEURO ? list.totalCreditEURO : 0}
          />
        </FormGroup>
      </Col>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.balanceEURO[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.balanceEURO[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={
              list.balanceEURO
                ? list.balanceEURO
                : list.totalBalanceEURO
                ? list.totalBalanceEURO
                : 0
            }
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default BalanceCard;
