export default {
  title: {
    ar: 'تسجيل الصك الخاطئ',
    en: 'Record wrong cheque'
  },
  bankName: {
    ar: 'اسم المصرف',
    en: 'Bank name'
  },
  branchName: {
    ar: 'اسم الفرع',
    en: 'Branch name'
  },
  branchCode: {
    ar: 'رمز الفرع',
    en: 'Branch code'
  },
  bankAccountNumber: {
    ar: 'رقم الحساب المصرفي',
    en: 'Bank account number'
  },
  save: {
    ar: 'حفظ',
    en: 'Save'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  linkedAccount: {
    ar: 'البند المرتبط',
    en: 'Linked account'
  },
  accountNumber: {
    ar: 'رقم البند',
    en: 'Account number'
  },
  chequeDate: {
    ar: 'التاريخ الصك',
    en: 'Cheque dte'
  },
  paymentMethod: {
    ar: 'طريقة الدفع',
    en: 'Payment method'
  },
  transactionNumber: {
    ar: 'رقم المعاملة',
    en: 'Transaction number'
  },
  creditor: {
    ar: 'دائن',
    en: 'Creditor'
  },
  debit: {
    ar: 'مدين',
    en: 'Debit'
  },
  recordedAt: {
    ar: 'تاريخ التسجيل',
    en: 'Recorded At'
  },
}