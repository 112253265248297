import { useState } from "react"
import ReactBSAlert from "react-bootstrap-sweetalert";

const useAlert = () => {
  const [alert, setAlert] = useState(null);

  // set sweet alert
  const sweetAlert = (text, warning = false) => {
    setAlert(
      <ReactBSAlert
        show={true}
        warning={warning}
        success={!warning}
        style={{ display: "block", marginTop: "-100px" }}
        title={""}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle={warning ? "warning" : "info"}
        confirmBtnText="Ok"
        btnSize=""
      >
        {text}
      </ReactBSAlert>
    ); 
  }

  return { alert, setAlert, sweetAlert };
}

export default useAlert;