import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  Link
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from 'components/common/CustomTable';

const LinkNewAccount = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  const headers = [
    content.accountNumber[culture],
    content.name[culture],
    content.accountNature[culture],
    content.accountCategory[culture],
    content.accountingLevel[culture],
    content.group[culture],
    content.analytical[culture],
    content.actions[culture],
  ];

  const data = [
    { id: 1, accountNumber: 1, name: 'باب المرتبات', accountNature: 'مدين', accountCategory: 'اصل', accountingLevel: 'باب رئيسي', group: 'لايوجد', analytical: 'لا'},
    { id: 2, accountNumber: 1, name: 'المرتبات الرئيسية', accountNature: 'مدين', accountCategory: 'اصل', accountingLevel: 'باب رئيسي', group: 'لايوجد', analytical: 'لا'},
  ];

  const columns = [
    "accountNumber",
    "name",
    "accountNature",
    "accountCategory",
    "accountingLevel",
    "group",
    "analytical",
  ]

  return (
    <div>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.categoryName[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="text"
                    value="زوائد"
                    disabled 
                    onChange={e => {}}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button 
              className="mr-2" 
              color="primary" 
              size="md" 
              onClick={() => {}}
            >
              <span>
                {content.save[culture]}
              </span>
            </Button>
          </CardBody>
          <Table
            headers={headers}
            columns={columns}
            data={data}
          >
            <span className="mr-2 mr-3 ml-3">
              <div id="details1" className="table-action cursor-pointer">
                <i className="fas fa-link fa-lg hover-success"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="details1"
              >
                {content.link[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
        </Card>
      </Container>
    </div>
  )
}

export default LinkNewAccount
