/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// router
import {
  //BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";

// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

// core components
import CardsHeader from "./CustomCardsHeader";

import { chartOptions, parseOptions, chartExample3 } from "variables/charts.js";
import getCulture from "utils/getCulture";
import Spinner from "../../../components/Spinner";
import content from "./content";
import useApi from "hooks/useApi";
import ReactBSAlert from "react-bootstrap-sweetalert";
// import user from "api/user";

function Dashboard() {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [usersStatics, setUsersStatics] = useState([]);
  const [airStatics, setAirStatics] = useState([]);
  const [lclStatics, setLclStatics] = useState([]);
  const [fclStatics, setFclStatics] = useState([]);
  const [alert, setAlert] = useState(null);

  // Apis
  // const dashboard = useApi(user.dashboard);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const preload = async () => {
    setLoading(true);
    // const res = await dashboard.request();

    // if (res.status === 200 ) {
    if (true) {
      const demoObj = {
        graphes: [
          {
            graphesValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          },
          {
            graphesValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          },
          {
            graphesValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          },
          {
            graphesValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          },
        ],
      };
      calcStatic(demoObj, 1, setUsersStatics);
      calcStatic(demoObj, 0, setAirStatics);
      calcStatic(demoObj, 2, setLclStatics);
      calcStatic(demoObj, 3, setFclStatics);
    } else {
      // sweetAlert(dashboard.data, true);
    }
    setLoading(false);
  };

  const calcStatic = (resData, index, setState) => {
    const arrayOfValues = resData.graphes[index]?.graphesValues?.map(
      (item) => item.value || Math.floor(Math.random() * 10)
    );
    setState(arrayOfValues);
  };

  const chart1 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: usersStatics,
          label: content.accounts[culture],
        },
      ],
    },
  };

  const chart2 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: airStatics,
          label: content.value[culture],
        },
      ],
    },
  };

  const chart3 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: lclStatics,
          label: content.value[culture],
        },
      ],
    },
  };

  const chart4 = {
    ...chartExample3,
    data: {
      labels: content.months[culture],
      datasets: [
        {
          data: fclStatics,
          label: content.value[culture],
        },
      ],
    },
  };

  // set sweet alert
  const sweetAlert = (text, warning = false) => {
    setAlert(
      <ReactBSAlert
        show={true}
        warning={warning}
        success={!warning}
        style={{ display: "block", marginTop: "-100px" }}
        title={""}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle={warning ? "warning" : "info"}
        confirmBtnText="Ok"
        btnSize=""
      >
        {text}
      </ReactBSAlert>
    );
  };

  useEffect(() => {
    preload();
  }, []);

  // useEffect(
  //   () => dashboard.error && dashboard.data && sweetAlert(dashboard.data, true),
  //   [dashboard.data]
  // );

  return (
    <>
      {alert}
      {loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <CardsHeader
            name={content.title[culture]}
            parentName=""
            data={data}
          />
          <Container className="mt--6" fluid>
            <Row>
              <Col xl="6">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">{content.accountsNo[culture]}</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Line
                        data={chart1.data}
                        options={chart1.options}
                        id="chart-sales"
                        className="chart-canvas"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6">
                <Card>
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h5 className="h3 mb-0">
                          {content.mainTreasuryBalance[culture]}
                        </h5>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Bar
                        data={chart2.data}
                        options={chart2.options}
                        className="chart-canvas"
                        id="chart-bars"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">
                      {content.shipmentValues[culture]}
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Bar
                        data={chart3.data}
                        options={chart3.options}
                        className="chart-canvas"
                        id="chart-doughnut"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6">
                <Card>
                  <CardHeader>
                    <h5 className="h3 mb-0">
                      {content.chinaTreasuryBalance[culture]}
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Line
                        data={chart4.data}
                        options={chart4.options}
                        className="chart-canvas"
                        id="chart-pie"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default Dashboard;
