import { Button, Modal } from "reactstrap";
import getCulture from "utils/getCulture";
import content from './content';
import { useRouteMatch } from "react-router-dom";

const ModalPopup = ({ modal, children, color, text, fn, name, handleCancel}) => {

    let rout = useRouteMatch()
    let culture = getCulture(rout.url);
    
    return (
        <Modal
            className="modal-dialog-centered custom-modal"
            isOpen={modal}
            style={{zIndex: 1049}}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    {name}
                </h5>
                <button
                    aria-label="Close"
                    className="close custom-button-modal"
                    data-dismiss="modal"
                    type="button"
                    onClick={handleCancel}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                fn();
            }}>
                {children}
                <div className="modal-footer">
                    <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={handleCancel}
                    >
                        {content.close[culture]}
                    </Button>
                    <Button 
                        color={color || 'primary'} type="button" 
                        className="modal-custom-button"
                        onClick={fn} 
                    >
                        {text}
                    </Button>
                </div>
            </form>
        </Modal >)
}

export default ModalPopup;