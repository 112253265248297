import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useParams,
  Link
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from 'components/common/CustomTable';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";
import Spinner from 'components/Spinner';
import TextPopup from 'components/common/TextModal';
import catchAsync from 'helper/catchAsync';
import ReactBSAlert from "react-bootstrap-sweetalert";
import CreateAccountPopup from "./components/CreateAccountPopup";

const BankBranchDetails = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [accountNo, setAccountNo] = useState();
  const { id } = useParams();

  const headers = [
    content.accountNumber[culture],
    content.currentNotebook[culture],
    content.nextNotebook[culture],
    content.itemName[culture],
    content.itemNo[culture],
    content.actions[culture],
  ];

  const data2 = [
    {id: 1, code: 1121, current: "100 - 1", next: "150 - 101", itemName: "مصرف الجمهرية في القادسية...", itemNo: 10025},
    {id: 1, code: 1121, current: "100 - 1", next: "150 - 101", itemName: "مصرف الجمهرية في القادسية...", itemNo:8888},
  ]

  const columns = ["accountNumber", "chequeBook.currentNotebook", "chequeBook.nextNotebook", "account.name", "account.number"];

  const handleCancel = () => {
    setCreateModal(false);
  }

  const createAccount = () => catchAsync(async () => {
    setCreateModal(false);
    setSpinner(true);
    const schema = {
      accountNumber: accountNo,
      bankBranchId: id
    }

    const result = await axios.post(`${env.activeServer()}/BankAccounts`, schema, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    if(result.status === 200) {
      const newData = {...data};
      const bank = result.data.data;
      // 1) if the bank.chequebook is undefined assign a new object to it
      bank.chequeBook = bank.chequeBook || {};
      // 2) create new instance called "currentNoteBook"
      // 3) assign to it a combination of beginOfSequence and endOfSequence or none text
      bank.chequeBook.currentNotebook = bank.chequeBook.beginOfSequence ? `${bank.chequeBook.beginOfSequence} / ${bank.chequeBook.endOfSequence}` : content.none[culture];
      // 4) create another instance called "nextNoteBook"
      // 5) assign to it a combination of newxChequeBook.beginOfSequence and nextChequeBook.endOfSequence or none text 
      bank.chequeBook.nextNotebook = bank.chequeBook.nextChequeBook ? `${bank.chequeBook?.nextChequeBook.beginOfSequence} / ${bank.chequeBook?.nextChequeBook.endOfSequence}` : content.none[culture];
      
      newData.bankAccounts.push(bank);
      setData(newData);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => setAlert('')}
          onCancel={() => setAlert('')}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
          btnSize=""
        >
          {result.statusText}
        </ReactBSAlert>
      )
      setAccountNo();    
    }
    
  }, () => setSpinner(false), setAlert)

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/BankBranchs/Details/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })

          if (result.status === 200) {
            const bankAccounts = [...result.data.data.bankAccounts];
            
            bankAccounts.forEach(bank => {
              // 1) if the bank.chequebook is undefined assign a new object to it
              bank.chequeBook = bank.chequeBook || {};
              // 2) create new instance called "currentNoteBook"
              // 3) assign to it a combination of beginOfSequence and endOfSequence or none text
              bank.chequeBook.currentNotebook = bank.chequeBook.beginOfSequence ? `${bank.chequeBook.beginOfSequence} / ${bank.chequeBook.endOfSequence}` : content.none[culture];
              // 4) create another instance called "nextNoteBook"
              // 5) assign to it a combination of newxChequeBook.beginOfSequence and nextChequeBook.endOfSequence or none text 
              bank.chequeBook.nextNotebook = bank.chequeBook.nextChequeBook ? `${bank.chequeBook?.nextChequeBook.beginOfSequence} / ${bank.chequeBook?.nextChequeBook.endOfSequence}` : content.none[culture];
            })

            result.data.data.bankAccounts = bankAccounts;

            setData(result.data.data);
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      />
      :<>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.bankName[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value={data.bankName}
                    type="text "
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchName[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value={data.name}
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchCode[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value={data.code}
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                {/* <Link to={`/${culture}-admin/addbankaccount`}> */}
                  <Button className="mr-2" color="primary" size="md" onClick={() => setCreateModal(true)}>
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>
                      {content.account[culture]}
                    </span>
                  </Button>
                {/* </Link> */}
              </Col>
            </Row>
          </CardBody>
          <Table 
            headers={headers}
            data={data.bankAccounts}
            columns={columns}
          > 
            <span to={`/${culture}-admin/bankaccountdetails`}>
              <div id="details1" className="table-action cursor-pointer">
                <i className="fas fa-info-circle fa-lg hover-warning"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="details1"
              >
                {content.details[culture]}
              </UncontrolledTooltip>
            </span>
            <span to={`/${culture}-admin/managingwrongcheques/:id`}>
              <div id="manage1" className="table-action cursor-pointer">
                <i className="fas fa-money-check hover-info"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="manage1"
              >
                {content.managingWrongCheques[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
        </Card>
        <TextPopup
          modal={createModal}
          text={content.create[culture]}
          handleCancel={handleCancel}
          fn={createAccount}
          color='primary'
        >
          <Container>
            <CreateAccountPopup
              culture={culture}
              accountNo={accountNo}
              setAccountNo={setAccountNo}
            />
          </Container>
        </TextPopup>
      </Container>
      </>}
    </div>
  )
}

export default BankBranchDetails
