import React, { useState } from "react";
import BalanceCard from "components/common/BalanceCard";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
  Input,
  Button,
  TabContent,
  TabPane,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import useAlert from "hooks/useAlert";
import useApi from "hooks/useApi";
import entry from "api/entry";
import { Controller, useForm } from "react-hook-form";
import SimpleHeader from "components/Headers/SimpleHeader";
import content from "./content";
import commonContent from "components/common/commonContent";
import FilterEntries from "components/common/FilterEntries";
import env from "env";
import TextPopup from "components/common/TextModal";
import BranchTable from "components/common/BranchTable";
import Spinner from "components/Spinner";
import { useMemo } from "react";
import Tabs from "components/common/Tabs";
import { useEffect } from "react";
import CountryTreasuryBalance from "./components/CountryTreasuryBalance";

function TreasuryBalance() {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const { alert, sweetAlert } = useAlert();

  const balanceOfMainTreasuryByBranchApi = useApi(
    entry.balanceOfMainTreasuryByBranch
  );

  const [data, setData] = useState([]);
  const [accountModal, setAccountModal] = useState(false);
  const [branchModal, setBranchModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm();

  const {
    watch: countryWatch,
    control: countryControl,
    setValue: countrySetValue,
    formState: { errors: countryErrors },
    handleSubmit: countryHandleSubmit,
    reset: countryReset,
  } = useForm();

  const fitlerEntries = async (data) => {
    const schema = { ...data };
    delete schema.treasuryAccount;
    const res = await balanceOfMainTreasuryByBranchApi.request(schema);
    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const handleCancel = () => {
    setBranchModal(false);
    setAccountModal(false);
  };

  const tabs = useMemo(
    () => [
      content.treasuryBalance[culture],
      content.countryTeasuryRealBalance[culture],
    ],
    []
  );

  useEffect(() => {
    setData([]);
    reset();
    countryReset();
  }, [tabIndex]);

  return (
    <div>
      {alert}
      {balanceOfMainTreasuryByBranchApi.errorAlert}
      {balanceOfMainTreasuryByBranchApi.loading && (
        <Spinner gate="#11cdef" bg="#fff" opacity />
      )}

      <SimpleHeader parents={[content.treasuryBalance[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>
                {content.treasuryBalance[culture]}
              </h3>
            </div>
          </CardHeader>
        </Card>
        <div style={{ marginBottom: 20 }}>
          <Tabs tabs={tabs} tabIndex={tabIndex} setTabIndex={setTabIndex} />
        </div>
        <TabContent activeTab={tabIndex}>
          <TabPane tabId={0}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(fitlerEntries)}>
                  <Row>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="treasuryAccount"
                        >
                          {commonContent.account[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="treasuryAccount"
                          rules={{ required: true }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="treasuryAccount"
                              accountName={commonContent.account[culture]}
                              type="text"
                              className={errors.treasuryAccount && "error"}
                              value={value ?? ""}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                              onClick={() => setAccountModal(true)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="branch">
                          {commonContent.branch[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="branch"
                          rules={{ required: true }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="branch"
                              accountName={commonContent.branch[culture]}
                              type="text"
                              className={errors.branch && "error"}
                              value={value ?? ""}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                              onClick={() => setBranchModal(true)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="align-self-center mt-2">
                      <Button color="info">{content.calculate[culture]}</Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
            <Card className="mb-4">
              <CardHeader>
                <h3 classyear={`mb-0 text-md-${commonContent.alignt[culture]}`}>
                  {content.balanceDetails[culture]}
                </h3>
              </CardHeader>
              <CardBody>
                <BalanceCard culture={culture} list={data || {}} />
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId={1}>
            <CountryTreasuryBalance
              data={data}
              setData={setData}
              setAccountModal={setAccountModal}
              fitlerEntries={fitlerEntries}
              errors={countryErrors}
              control={countryControl}
              handleSubmit={countryHandleSubmit}
            />
          </TabPane>
        </TabContent>
      </Container>
      <TextPopup
        modal={accountModal}
        text={commonContent.next[culture]}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <FilterEntries
            watch={tabIndex === 0 ? watch : countryWatch}
            setValue={tabIndex === 0 ? setValue : countrySetValue}
            setAccountModal={setAccountModal}
            fieldName="treasury"
            accountClassification={
              env.accountClassifications.findIndex(
                (classification) => classification.en === "Treasury"
              ) + 1
            }
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={branchModal}
        text={commonContent.next[culture]}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <BranchTable
            setValue={setValue}
            sweetAlert={sweetAlert}
            setModal={setBranchModal}
          />
        </Container>
      </TextPopup>
    </div>
  );
}

export default TreasuryBalance;
