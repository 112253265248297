const permissionsReducer = (state, actions) => {
  if (actions.type === "FETCH") return actions.data;

  if (actions.type === "CLICKED") {
    const newState = { ...state };
    const index = newState.appRolePermissions.findIndex(
      (item) => item.cotroller === actions.controller
    );

    if (index < 0) {
      newState.appRolePermissions.push({
        cotroller: actions.controller,
        appPermissions: [actions.permission],
      });
      return newState;
    }

    const perIndex = newState.appRolePermissions[
      index
    ]?.appPermissions.findIndex((item) => item.id === actions.permission.id);
    if (perIndex < 0) {
      newState.appRolePermissions[index].appPermissions.push(
        actions.permission
      );
      return newState;
    }

    newState.appRolePermissions[index].appPermissions.splice(perIndex, 1);

    return newState;
  }

  if (actions.type === "ROW_SELECTED") {
    
    const newState = { ...state };

    const index = newState.appRolePermissions.findIndex(
      (item) => item.cotroller === actions.controller.cotroller
    );

    if (index < 0) {
      newState.appRolePermissions.push(actions.controller);
      return newState;
    }

    if (newState.appRolePermissions[index]?.appPermissions?.length === actions.controller?.appPermissions?.length) {
      newState.appRolePermissions.splice(index, 1);
      return newState;
    }

    newState.appRolePermissions[index] = {...actions.controller};
    return newState
  }

  return state;
};

export default permissionsReducer;
