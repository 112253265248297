/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

// our file
import "assets/css/app.css";

// en layuots
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import IndexView from "views/Index.js";

// ar layuots
import RtlAuthLayout from "layouts/authRTL.js";
import RtlAdminLayout from "layouts/RTL";
import Titlebar from "components/Titlebar/Titlebar";
import PaginationProvider from "context/PaginationContext";

ReactDOM.render(
  <>
    {/* <Titlebar systemName="archive" style={{ zIndex: 1500 }} /> */}
    <PaginationProvider>
      <HashRouter>
        <Switch>
          <Route
            path="/en-admin"
            render={(props) => <AdminLayout {...props} />}
          />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route
            path="/en-auth"
            render={(props) => <AuthLayout {...props} />}
          />
          <Route
            path="/ar-auth"
            render={(props) => <RtlAuthLayout {...props} />}
          />
          <Route
            path="/ar-admin"
            render={(props) => <RtlAdminLayout {...props} />}
          />
          <Route path="/" render={(props) => <IndexView {...props} />} />
          <Redirect from="*" to="/ar-auth/login" />
        </Switch>
      </HashRouter>
    </PaginationProvider>
  </>,
  document.getElementById("root")
);
