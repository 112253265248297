import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  Link
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from 'components/common/CustomTable';

const RecordAuthorization = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  const headers = [
    content.authorizationNo[culture],
    content.authorizedAccountNo[culture],
    content.authorizedAccount[culture],
    content.authorizationDate[culture],
    content.authorizationStartDate[culture],
    content.authorizationEndtDate[culture],
    content.authorizedValue[culture],
    content.liquidatedValue[culture],
    content.actions[culture],
  ];

  const data = [
    {
      id: 1,
      authorizationNo: "5501",
      accountNumber: "030101",
      authorizedAccount: "اثاث مكيف",
      authorizationDate: "2020-02-11",
      authorizationStartDate: "2021-11-12",
      authorizationEndtDate: "2022-11-03",
      authorizationValue: 1000,
      liquidatedValue: 1100
    },
    {
      id: 2,
      authorizationNo: "4404",
      accountNumber: "030121",
      authorizedAccount: "اثاث قدس",
      authorizationDate: "2020-12-11",
      authorizationStartDate: "2021-10-12",
      authorizationEndtDate: "2022-09-03",
      authorizationValue: 900,
      liquidatedValue: 800
    }
  ];

  const columns = [
    "authorizationNo",
    "accountNumber",
    "authorizedAccount",
    "authorizationDate",
    "authorizationStartDate",
    "authorizationEndtDate",
    "authorizationValue",
    "liquidatedValue",
  ]

  return (
    <div>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.mainDoor[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={"first"}
                    onChange={e => {}}
                    disabled={true}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">الجهرية</option>
                    <option value="second">الوحدة</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.mainDoorNo[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="33"
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.authorizationNo[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="33"
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.authorizationDate[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="2022-01-01"
                    type="date"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.authorizationStartDate[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="2022-01-01"
                    type="date"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.authorizationEndtDate[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="2022-01-01"
                    type="date"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.creditAccount[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="مصرف الجمهرية"
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.authorizationStatus[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="غير معتمد"
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.totalAuthorized[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value="10000"
                    type="number"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <h3>{content.authorizationDetailsForAccounts[culture]}</h3>
          </CardBody>
          <Table
            headers={headers}
            columns={columns}
            data={data}
          >
            <span to={`/${culture}-admin/dashcoard`}>
              <div id="edit1" className="table-action cursor-pointer">
                <i className="fas fa-pencil-alt fa-lg hover-info"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="edit1"
                >
                {content.edit[culture]}
              </UncontrolledTooltip>
            </span>
            <span className="mr-2 mr-3 ml-3">
              <div id="delete" className="table-action-delete table-action cursor-pointer hover-danger">
                <i className="fas fa-trash fa-lg"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="delete"
              >
                {content.delete[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
          <CardBody>
            <h4>
              اجمالي القيمة التفويض 
              <span>1000</span>  
            </h4>
            <h4>
              اجمالي القيمة المسيلة 
              <span>1000</span>  
            </h4>
            <Row>
              <Button className="mr-2" color="primary" size="md">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>
                  {content.add[culture]}
                </span>
              </Button>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default RecordAuthorization
