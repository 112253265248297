import React, { useEffect, useState } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form,
  Button,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import { useParams, useRouteMatch } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import content from "../CreateEntry/content";
import TextPopup from "components/common/TextModal";
import Spinner from "components/Spinner";
import useAlert from "hooks/useAlert";
import commonContent from "components/common/content";
import useEditEntry from "./useEditEntry";
import env from "env";
import EditEntryDetails from "./components/EditEntryDetails";
import FilterEntries from "components/common/FilterEntries";

const EditEntry = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const { alert, sweetAlert } = useAlert();

  const headers = [
    commonContent.actions[culture],
    commonContent.number[culture],
    content.accountName[culture],
    content.debtor[culture],
    content.creditor[culture],
    commonContent.note[culture],
  ];

  const columns = ["account.number", "account.name", "debit", "credit", "note"];

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
  } = useForm();

  const {
    handleSubmit: handleRowSubmit,
    formState: { errors: rowErrors },
    control: rowControl,
    reset: rowReset,
    setValue: rowSetValue,
  } = useForm();

  const {
    handleSubmit: handleEntryDetails,
    formState: { errors: entryDetailsErrors },
    control: entryDetailsControl,
    reset: entryDetailsReset,
    setValue: entryDetailsSetValue,
  } = useForm();

  const {
    data,
    fieldName,
    editModal,
    deleteModal,
    spinner,
    updateEntry,
    debtorAcc,
    handleCancel,
    addRow,
    handleEdit,
    handleDelete,
    deleteRow,
    setFieldName,
    errorMessage,
    findDetailsAsync,
    updateEntryDetails
  } = useEditEntry(sweetAlert, reset, rowReset, entryDetailsSetValue);

  useEffect(() => {
    if (!Object.keys(data).length) return;
    console.log({ data });
    setValue("statement", data.statement);
    setValue("serialNumber", data.serialNumber);
  }, [data]);

  if (findDetailsAsync.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <>
      {alert}
      {errorMessage}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.title[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="statement">
                    {commonContent.serialNumber[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="serialNumber"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="serialNumber"
                        type="number"
                        className={errors.serialNumber && "error"}
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                        onWheel={(e) => e.target.blur()}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="entryType">
                    {content.entryType[culture]}
                  </label>
                  <Input
                    id="entryType"
                    type="text"
                    readOnly
                    value={env.entryType[data?.entryType - 1]?.[culture] || ""}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="currency">
                    {content.currency[culture]}
                  </label>
                  <Input
                    id="currency"
                    type="text"
                    readOnly
                    value={env.currency[data?.currency - 1]?.[culture] || ""}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="date">
                    {content.entryDate[culture]}
                  </label>
                  <Input
                    id="date"
                    type="date"
                    readOnly
                    value={data.date?.split("T")[0] || ""}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="date">
                    {commonContent.costCenter[culture]}
                  </label>
                  <Input
                    id="costCenterId"
                    type="text"
                    readOnly
                    value={data.costCenter?.name || ""}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="statement">
                    {content.statement[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="statement"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="statement"
                        type="textarea"
                        className={errors.statement && "error"}
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button
              type="button"
              color="success"
              className="cml-2"
              disabled={!isDirty}
              onClick={handleSubmit(updateEntry)}
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-plus" />
              </span>
              <span className="btn-inner--text">
                {content.saveData[culture]}
              </span>
            </Button>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="debtorAccount">
                    {content.accountName[culture]}
                  </label>
                  <Controller
                    control={rowControl}
                    name="debtorAccount"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="debtorAccount"
                        type="text"
                        className={rowErrors.debtorAccount && "error"}
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                        onClick={debtorAcc}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="debit">
                    {content.debtor[culture]}
                  </label>
                  <Controller
                    control={rowControl}
                    name="debit"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="debit"
                        type="text"
                        value={value || ""}
                        placeholder={content.debtor[culture]}
                        className={rowErrors.debit && "error"}
                        onChange={({ target: { value } }) => {
                          onChange(+value);
                          rowSetValue("credit", 0);
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="credit">
                    {content.creditor[culture]}
                  </label>
                  <Controller
                    control={rowControl}
                    name="credit"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="credit"
                        type="text"
                        placeholder={content.creditor[culture]}
                        value={value || ""}
                        className={rowErrors.credit && "error"}
                        onChange={({ target: { value } }) => {
                          onChange(+value);
                          rowSetValue("debit", 0);
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="note">
                    {content.notes[culture]}
                  </label>
                  <Controller
                    control={rowControl}
                    name="note"
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="note"
                        type="textarea"
                        placeholder={content.notes[culture]}
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button
              size="sm"
              type="button"
              className="cml-2"
              onClick={handleRowSubmit(addRow)}
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-plus" />
              </span>
              <span className="btn-inner--text">
                {content.addField[culture]}
              </span>
            </Button>
          </CardBody>
        </Card>
        <Card>
          <Table
            headers={headers}
            columns={columns}
            handleEdit={handleEdit}
            data={data.entryDetails}
            handleDelete={handleDelete}
          >
            <span className="cmr-2" fun="handleDelete">
              <div
                id="delete"
                className="table-action-delete table-action cursor-pointer hover-danger"
              >
                <i className="fas fa-trash hover-danger fa-lg" />
              </div>
              <UncontrolledTooltip delay={0} target="delete">
                {content.deleteRow[culture]}
              </UncontrolledTooltip>
            </span>
            <span fun="handleEdit">
              <div id="edit1" className="table-action cursor-pointer">
                <i className="fas fa-pencil-alt fa-lg hover-info" />
              </div>
              <UncontrolledTooltip delay={0} target="edit1">
                {commonContent.edit[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
        </Card>
      </Container>
      <TextPopup
        modal={!!fieldName}
        noBtn
        handleCancel={handleCancel}
        fn={() => {}}
        name={fieldName}
        color="success"
      >
        <FilterEntries
          // culture={culture}
          // handleSelect={handleSelect}
          setValue={rowSetValue}
          fieldName={fieldName}
          setFieldName={setFieldName}
        />
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={content.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteRow}
        color="danger"
      >
        <CardBody>
          <h2>{content.deletePopup[culture]}</h2>
        </CardBody>
      </TextPopup>
      <TextPopup
        modal={editModal}
        text={commonContent.save[culture]}
        handleCancel={handleCancel}
        fn={handleEntryDetails(updateEntryDetails)}
        color="info"
      >
        <EditEntryDetails
          control={entryDetailsControl}
          errors={entryDetailsErrors}
          setValue={entryDetailsSetValue}
        />
      </TextPopup>
    </>
  );
};

export default EditEntry;
