import React from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import content from "./content";
import useCulture from "hooks/useCulture";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import FilterEntries from "components/common/FilterEntries";
import TextPopup from "components/common/TextModal";
import MainTreasuryCard from "./components/MainTreasuryCard";
import BalanceWithRevenue from "./components/BalanceWithRevenue";
import BalanceWithAnyTreasury from "./components/BalanceWithAnyTreasury";
import BalanceWithTransferAccount from "./components/BalanceWithTransferAccount";
import { useEffect } from "react";

function CustomerBalance() {
  const culture = useCulture();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm();

  return (
    <>
      {/* {alert}
      {getActiveApi.errorAlert}
      {createOneApi.errorAlert}
      {updateOneApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />} */}
      <SimpleHeader parents={[content.customerBalances[culture]]} />
      <Container className="mt--6" fluid>
        <form>
          <MainTreasuryCard
            errors={errors}
            control={control}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
          />
          <BalanceWithRevenue
            errors={errors}
            control={control}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
          />
          <BalanceWithTransferAccount
            errors={errors}
            control={control}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
          />
          <BalanceWithAnyTreasury
            errors={errors}
            control={control}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
          />
        </form>
      </Container>
    </>
  );
}

export default CustomerBalance;
