import client from "./client";

const endpoint = "/v1/Auth/AuthRoles";

const getAll = (pageNo, docsPerPage) =>
  client.get(`${endpoint}?Page=${pageNo}&PostsPerPage=${docsPerPage}`);

const deleteOne = (id) => client.delete(`${endpoint}/${id}`);

const updateOne = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const getOne = (id) => client.get(`${endpoint}/${id}`);

const updateRoles = (id, schema) =>
  client.put(`${endpoint}/${id}/updateroles`, schema);

const filter = (col, val, page, docsPerPage) =>
  client.put(`${endpoint}/filter?Page=${page}&PostsPerPage=${docsPerPage}`, {
    [col]: val,
  });

const createOne = (schema) => client.post(`${endpoint}/create`, schema);

const updatePermissions = (id, schema) =>
  client.put(`${endpoint}/updatePermissions/${id}`, schema);

export default {
  getAll,
  getOne,
  updateOne,
  deleteOne,
  updateRoles,
  filter,
  createOne,
  updatePermissions
};
