import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Tables from './Tables';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";
import TextPopup from 'components/common/TextModal';
import Table from 'components/common/CustomTable';
import AddPopup from './components/AddPopup';
import Spinner from 'components/Spinner';
import catchAsync from 'helper/catchAsync';
import ReactBSAlert from "react-bootstrap-sweetalert";

function BankDetails() {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);
  const history = useHistory();
  const [addModal, setAddModal] = useState(false);
  const [data, setData] = useState({});
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addSchema, setAddSchema] = useState({});
  const [spinner, setSpinner] = useState(false);
  const { id } = useParams();

  const headers = [
    content.code[culture],
    content.name[culture],
    content.actions[culture]
  ];

  const dummyData = [
    { id: 1, name: 'bank1', code: 11 },
    { id: 2, name: 'bank2', code: 22 },
    { id: 3, name: 'bank3', code: 33 },
  ]

  const columns = ["code", "name"];

  const handleCancel = () => {
    setAddModal(false);
  }

  const handleCreate = e => catchAsync(async () => {
    e.preventDefault();
    setAddModal(false)
    setSpinner(true);

    const schema = {
      ...addSchema,
      bankId: id
    } 
    const result = await axios.post(`${env.activeServer()}/BankBranchs`, schema, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    if (result.status === 200) {
      setAddSchema({});
      // add branch in the data logic
    }

  }, () => { setSpinner(false) }, setAlert)

  return (
    <div className='bank-details'>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity /> }
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/Banks/Details/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })

          if (result.status === 200) {
            setData(result.data.data);
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      />
      :<>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.bankName[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    value={data.name}
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <Button className="mr-2" color="primary" size="md" onClick={() => setAddModal(true)}>
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>
                    {content.branch[culture]}
                  </span>
                </Button>
              </Col>
            </Row>
          </CardBody>
            <Table 
              headers={headers}
              data={data.bankBranches}
              columns={columns}
            >
              <span to={`/${culture}-admin/bankbranchdetails`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="fas fa-info-circle fa-lg hover-warning"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="details1"
                >
                  {content.details[culture]}
                </UncontrolledTooltip>
              </span>
              <span to={`/${culture}-admin/bankbranchdetails`} target="_blank">
                <div id="details2" className="table-action cursor-pointer">
                  <i className="fas fa-external-link-alt hover-success" />
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="details2"
                >
                  {content.bankBranchDetailsNewTap[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
            {/* <Tables data={data.bankBranches} /> */}
        </Card>
        <TextPopup
          modal={addModal}
          text={content.save[culture]}
          handleCancel={handleCancel}
          fn={handleCreate}
          color='primary'
          name={content.addBranch[culture]}
        >
          <Container>
            <AddPopup 
              culture={culture} 
              schema={addSchema}
              setSchame={setAddSchema}
              bank={data.name}
            />
          </Container>
        </TextPopup>
      </Container>
      </>}
    </div>
  )
}

export default BankDetails
