import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  Link,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";
import Spinner from 'components/Spinner';
import catchAsync from 'helper/catchAsync';
import ReactBSAlert from "react-bootstrap-sweetalert";

const EditBankBranch = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [data, setData] = useState({});
  const [schema, setSchema] = useState({});
  const { id } = useParams();

  const handleEdit = e => catchAsync(async () => {
    setSpinner(true);
    e.preventDefault();
    const result = await axios.put(`${env.activeServer()}/BankBranchs/${id}`, schema, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    if (result.status === 200) {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => setAlert('')}
          onCancel={() => setAlert('')}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
          btnSize=""
        >
          {result.statusText}
        </ReactBSAlert>
      )
    }

  }, () => { setSpinner(false) }, setAlert);

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/BankBranchs/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })

          if (result.status === 200) {
            console.log({result});
            setData(result.data.data);
            setSchema({
              name: result.data.data.name,
              code: result.data.data.code
            })
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      />
      : <>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleEdit}>
              <Row className='align-items-center'>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.bankName[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      defaultValue={data.bankId}
                      disabled={true}
                    >
                      <option disabled value> -- {content.selectOption[culture]} -- </option>
                      <option value="first">الجمهرية</option>
                      <option value="second">الوحدة</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.branchName[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      type="text"
                      value={schema.name}
                      onChange={e => { setSchema({...schema, name: e.target.value })}}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.branchCode[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      type="number"
                      value={schema.code}
                      onChange={e => { setSchema({...schema, code: e.target.value })}}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button className="mr-2" color="primary" size="md" type="submit">
                  <span>
                    {content.save[culture]}
                  </span>
                </Button>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Container>
      </>}
    </div>
  )
}

export default EditBankBranch
