import React, { useEffect, useState } from "react";
import Table from "components/common/CustomTable";
import { FormGroup, Input, Row, Col, Button } from "reactstrap";
import { useRouteMatch } from "react-router";
import customerApi from "api/customer";
import useApi from "../../hooks/useApi";
import Spinner from "../Spinner";
import Pagination from "components/common/Pagination";
import commonContent from "components/common/content";
import getCulture from "utils/getCulture";
import CustomerAccount from "api/customersAccounts";
import customer from "api/customer";

function PopupTable({ setCustomer, setTableModal, setValue, sweetAlert }) {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  // States
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [client, setClient] = useState("");
  const [docsPerPage, setDocsPerPage] = useState(5);

  // Apis
  const customers = useApi(customer.getAllCustomers);
  const findByCode = useApi(customer.getByColumn);

  const getAllCustomers = async () => {

    const res = await customers.request(pageNo, docsPerPage);

    if (res.status === 200)
      setData(res.data.data)
  }

  const findClientByCode = async () => {
    if (!client) return setPageNo(1);

    const res = await findByCode.request(
      "customerCode",
      client,
      pageNo,
      docsPerPage
    );

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const handleSelect = (obj) => {
    // otherwise store an object with the customer
    setCustomer(obj);
    setTableModal(false);
    setValue("client", obj.customerName);
  };

  useEffect(() => {
    findClientByCode();
  }, [pageNo, docsPerPage]);
  
  useEffect(() => {
    if (client.trim().length) return findClientByCode();
    getAllCustomers();
  }, [pageNo, docsPerPage])

  useEffect(
    () =>
      findByCode.error && findByCode.data && sweetAlert(findByCode.data, true),
    [findByCode.data]
  );

  if (findByCode.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      <div className="padding-around pb-0">
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="customer-code">
                {commonContent.customerCode[culture]}
              </label>
              <Input
                id="customer-code"
                placeholder={commonContent.search[culture]}
                type="text"
                value={client}
                onChange={(e) => setClient(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && findClientByCode()}
              />
            </FormGroup>
          </Col>
          <span style={{ paddingTop: 7 }}>
            <Button
              color="success"
              type="button"
              className="mt-4"
              onClick={findClientByCode}
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-search" />
              </span>
              <span>{commonContent.search[culture]}</span>
            </Button>
          </span>
        </Row>
      </div>
      <Pagination
        dataLength={data.length}
        pageNo={pageNo}
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
      >
        <Table
          headers={[
            commonContent.actions[culture],
            commonContent.customerName[culture],
            commonContent.customerCode[culture]
          ]}
          columns={["customerName", "customerCode"]}
          data={data}
          setData={setData}
          handleSelect={handleSelect}
          isPrint={false}
        >
          <span className="me-1" fun="handleSelect">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-check fa-lg hover-info"></i>
            </div>
          </span>
        </Table>
      </Pagination>
    </div>
  );
}

export default PopupTable;
