import React from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  Link
} from "react-router-dom";
// get culture from utils 
import getCulture from "utils/getCulture";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import SimpleHeader from 'components/Headers/SimpleHeader';
import content from './content';
import Table from 'components/common/CustomTable';

const AddCustodySettlement = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  const headers = [
    content.custodyNo[culture],
    content.debitAccount[culture],
    content.value[culture],
    content.transactionBranch[culture],
    content.date[culture],
    content.notes[culture],
    content.actions[culture],
  ]

  const data = [
    {
      id: 1,
      custodyNo: 2121,
      value: 12121,
      debitAccount: "محمد محسن خالد",
      type: "اغلاق",
      date: "2022-10-11",
      notes: "تحت المراجعة",
      transactionBranch: 550
    },
    {
      id: 2,
      custodyNo: 2121,
      value: 12121,
      debitAccount: "محمد محسن خالد",
      type: "اغلاق",
      date: "2022-10-11",
      notes: "تحت المراجعة",
      transactionBranch: 550
    },
  ]

  const columns = [
    "custodyNo", 
    'debitAccount',
    'value',
    'transactionBranch',
    'date',
    'notes',
  ]
  
  return (
    <div>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md-${culture}`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.serialNumber[culture]}
                  </label>
                  <Input
                      id="example3cols1Input"
                      type="number"
                      min="2000"
                  />
                </FormGroup>
              </Col>    
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.custodyHolder[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="text"
                    placeholder={content.custodyHolder[culture]}
                  />
                </FormGroup>
              </Col>    
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.typeOfCustody[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">صك</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.custodyDate[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="date"
                  />
                </FormGroup>
              </Col>    
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.transactionBranch[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">{content.test[culture]}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.statement[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="textarea"
                  />
                </FormGroup>
              </Col>  
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.creditAccount[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">{content.test[culture]}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.value[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="text"
                    placeholder={content.value[culture]}
                  />
                </FormGroup>
              </Col>  
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.notes[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="text"
                    placeholder={content.notes[culture]}
                  />
                </FormGroup>
              </Col>  
            </Row>
            <Row>
              <Button className="mr-2" color="primary" size="md">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>
                  {content.add[culture]}
                </span>
              </Button>
            </Row>
          </CardBody>
          <Table
            headers={headers}
            columns={columns}
            data={data}
          >
            <span className="mr-2 mr-3 ml-3">
              <div id="delete" className="table-action-delete table-action cursor-pointer hover-danger">
                <i className="fas fa-trash fa-lg"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="delete"
              >
                {content.delete[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
        </Card>
      </Container>
    </div>
  )
}

export default AddCustodySettlement
