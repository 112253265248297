import React, { useState } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import Spinner from "components/Spinner";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import { useForm, Controller } from "react-hook-form";
import commonContent from "components/common/content";
import content from "./content";
import useApi from "hooks/useApi";
import entry from "api/entry";
import env from "env";
import CountryTable from "components/common/CountryTable";
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import useFetch from "hooks/useFetch";
import { useHistory } from "react-router-dom";

function FollowUpShipments() {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const { alert, sweetAlert } = useAlert();

  const history = useHistory();

  const filterApi = useApi(entry.filterEntry);

  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [countryTable, setCountryTable] = useState(false);

  const headers = [
    content.actions[culture],
    content.entryNo[culture],
    content.entryType[culture],
    content.entryState[culture],
    content.bulkNumber[culture],
    content.bulkState[culture],
    content.currency[culture],
    commonContent.shipmentType[culture],
    content.date[culture],
  ];

  const columns = [
    "serialNumberDisplayed",
    "entryTypeText",
    "entryStateText",
    "bulkNumber",
    "shipmentClosedText",
    "currencyText",
    "shipmentTypeText",
    "date",
  ];

  const editResponse = (item) => {
    item.date = item.date.split("T")[0];
    item.entryTypeText = env.entryType[item.entryType - 1][culture];
    item.entryStateText = env.entryState[item.entryState - 1][culture];
    item.currencyText = env.currency[item.currency - 1][culture];
    item.shipmentTypeText = env.shipmentType[item.shipmentType][culture];
    item.shipmentClosedText = item.shipmentClosed
      ? content.closed[culture]
      : "";
    return item;
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const handleCancel = () => {
    setCountryTable(false);
  };

  const fitlerEntries = async (data = {}) => {
    const schema = {
      ...data,
      bulkNumber: data.bulkNumber > 0 ? data.bulkNumber : null,
    };
    schema.entryType =
      env.entryType.findIndex((entry) => entry["en"] === "Shipment") + 1;
    const res = await filterApi.request(schema, pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data = res.data.data.map((item) => editResponse(item));
      setData(res.data.data);
    }
  };

  const showHandler = (obj) => {
    history.push(
      `/${culture}-admin/reports/follow-up-shipments/${obj.bulkNumber}/${obj.countryId}/${obj.shipmentType}`
    );
  };

  useFetch({ fun: fitlerEntries, pageNo, docsPerPage, isSearch: false });

  if (filterApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <div>
      {alert}
      {filterApi.errorAlert}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>
                {content.pageTitle[culture]}
              </h3>
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(fitlerEntries)}>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="entryNo">
                      {content.entryNo[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="serialNumber"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="entryNo"
                          type="number"
                          onWheel={(e) => e.target?.blur()}
                          onChange={({ target: { value } }) => onChange(+value)}
                          value={value || ""}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="bulkNumber">
                      {content.bulkNumber[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="bulkNumber"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="bulkNumber"
                          placeholder={content.bulkNumber[culture]}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={value || ""}
                          className={errors.bulkNumber && "error"}
                          onChange={({ target: { value } }) => onChange(+value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="country">
                      {commonContent.country[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="country"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="country"
                          type="text"
                          value={value || ""}
                          className={errors.country && "error"}
                          placeholder={`-- ${commonContent.selectOption[culture]} --`}
                          onChange={() => {}}
                          onClick={() => setCountryTable(true)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="shipmentType"
                    >
                      {commonContent.shipmentType[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="shipmentType"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="shipmentType"
                          type="select"
                          value={value ?? ""}
                          className={errors.shipmentType && "error"}
                          onChange={({ target: { value } }) => onChange(+value)}
                        >
                          <option disabled value="">
                            -- {commonContent.selectOption[culture]} --
                          </option>
                          {env.shipmentType.map((ship, index) => (
                            <option key={index} value={index}>
                              {ship[culture]}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="from">
                      {content.from[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="from"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="from"
                          type="date"
                          value={value || "disabled"}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="from">
                      {content.to[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="to"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="to"
                          type="date"
                          value={value || "disabled"}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button
                // className="mr-2"
                color="info"
                size="md"
                type="submit"
              >
                <span className="btn-inner--icon mr-1 ml-1">
                  <i className={`fab fa-sistrix`}></i>
                </span>
                <span>{commonContent.search[culture]}</span>
              </Button>
            </form>
          </CardBody>
        </Card>
        <Card>
          <Pagination
            dataLength={data?.length}
            // dataLength={searchPopup.isSelected ? searchPopup.hookData?.length : data.data?.length }
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              headers={headers}
              columns={columns}
              data={data}
              showHandler={showHandler}
            >
              <span fun="showHandler">
                <div id="details1" className="table-action cursor-pointer">
                  <i className="fas fa-paste fa-lg hover-success" />
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commonContent.show[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <CardBody>
          <CountryTable
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </CardBody>
      </TextPopup>
    </div>
  );
}

export default FollowUpShipments;
