export default {
  title: {
    ar: "إنشاء حساب 😃",
    en: "Create an accunt 😃",
  },
  lead: {
    ar: "يمكنك إنشاء حساب للدخول الى نظام قيتواي إي آر بي عن طريق تعبئة البيانات الموجودة على الشاشة ومن ثم يتم تسجيل حسابك، وانتظار لجنة الموارد البشرية لمنحه اذن الدخول الى النظام ",
    en: "You can create an account to enter the Gateway ERP system by filling in the data on the screen, and then your account will be registered, and waiting for the Human Resources Committee to grant it access to the system,",
  },
  signupTitle: {
    ar: "التسجيل بالنظام",
    en: "Register",
  },
  plsFill: {
    ar: "الرجاء ادخال بياناتك",
    en: "please fill your data",
  },
  userName: {
    ar: "اسم المستخدم",
    en: "username",
  },
  email: {
    ar: "البريز الإلكتروني",
    en: "email",
  },
  password: {
    ar: "كلمة المرور",
    en: "password",
  },
  confermPassword: {
    ar: "تأكيد كلمة المرور",
    en: "confirm password",
  },
  login: {
    ar: "لدي حساب بالفعل",
    en: "I already have an account",
  },

  create: {
    ar: "انشاء حساب",
    en: "Create account",
  },
  firstName: {
    ar: "الاسم الأول",
    en: "First name",
  },
  lastName: {
    ar: "الاسم الأخير",
    en: "Last name",
  },
  phoneNumber: {
    ar: "رقم الهاتف",
    en: "Phone number",
  },
  branch: {
    ar: "الفرع",
    en: "Branch"
  }
};
