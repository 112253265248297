import React, { useEffect, useState } from "react";
import { Card, Container, CardHeader, CardBody, Button } from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import content from "./content";
import List from "./components/List";
import useApi from "hooks/useApi";
import account from "api/account";
import Spinner from "components/Spinner";
import CreateAccount from "./components/CreateAccount";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import UpdateAccount from "./components/UpdateAccount";
import usePermissions from "hooks/usePermissions";
import AccumulationBalance from "./components/AccumulationBalance";

const AccountingGuide = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const checkPermission = usePermissions("Account");

  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [accumulationModal, setAccumulationModal] = useState(false);

  const getRootsApi = useApi(account.getRoots);
  const getChildrenApi = useApi(account.getChildren);
  const createAccountApi = useApi(account.createAccount);
  const updateAccountApi = useApi(account.updateAccount);
  const deleteAccountApi = useApi(account.deleteAccount);

  const { alert, sweetAlert } = useAlert();

  // This useForm hook is for create account api
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm();

  // This useForm hook is for update account api
  const {
    handleSubmit: updateSubmit,
    formState: { errors: updateErrors },
    control: updateControl,
    reset: updateReset,
    watch: updateWatch,
    setValue: updateSetValue,
  } = useForm();

  const getAccounts = async () => {
    const res = await getRootsApi.request();
    if (res.status === 200) {
      res.data.data.accountLevels.reverse();
      res.data.data.data.forEach((item) => {
        item.isLoaded = false;
      });
      setData(res.data.data);
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  if (getRootsApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  const getChildren = async (id) => {
    const res = await getChildrenApi.request(id);

    if (res.status === 200) {
      const newData = { ...data };

      findParent(id, newData.data, res.data.data);

      setData(newData);
    }
  };

  const findParent = (parentId, accounts, resData) => {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === parentId) {
        accounts[i].isLoaded = true;
        resData.forEach((acc) => (acc.isLoaded = false));
        return (accounts[i].subAccounts = resData);
      } else if (accounts[i].hasOwnProperty("subAccounts")) {
        findParent(parentId, accounts[i].subAccounts, resData);
      }
    }
  };

  const handleCancel = () => {
    setAddModal(false);
    setUpdateModal(false);
    setDeleteModal(false);
    setAccumulationModal(false);
    reset();
    setValue("accountNature", 0);
    updateReset();
  };

  const createRootAccount = () => {
    setRowId("");
    setValue("accountLevelId", data.accountLevels[0].id);
    setValue("digits", data.accountLevels[0].digits);
    setValue("parentNo", "");
    setAddModal(true);
  };

  const handleCreate = (obj, accountLvl) => {
    setRowId(obj.id);
    setValue("parentNo", obj.number);
    setValue("accountLevelId", data.accountLevels[accountLvl].id);
    setValue(
      "digits",
      data.accountLevels[accountLvl].digits - obj.number.length
    );
    setValue("accountNature", obj.accountNature);
    setAddModal(true);
  };

  const createAccount = async (formData) => {
    setAddModal(false);
    setSpinner(true);
    const schema = {
      ...formData,
      number: formData.parentNo + formData.number,
    };

    if (formData.accountClassification !== 4) delete schema.customerAccountId;

    delete schema.client;
    delete schema.parentNo;
    delete schema.digits;

    // if (!schema.customerAccountId) delete schema.customerAccountId;

    const res = await createAccountApi.request(schema);

    if (res.status === 200) {
      const newData = { ...data };

      // if the rowId is empty this means it's on the root
      if (rowId === "") {
        newData.data.unshift(res.data.data);
      } else {
        // other wise find the parent and append the reponse in parent subAccounts
        appendAccount(newData.data, res.data.data);
      }

      setData(newData);
      sweetAlert(content.done[culture]);
      reset();
      setRowId("");
    }
    setSpinner(false);
  };

  const appendAccount = (accounts, resData) => {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === rowId) {
        resData.isLoaded = false;
        // if the account has "isLoaded = false" that means it's accordion is closed
        if (!accounts[i].isLoaded) {
          return;

          // if the account has a subAccounts and has "isLoaded = true" append the data with the old ones
        } else if (accounts[i].subAccounts) {
          return accounts[i].subAccounts.unshift(resData);
        }
        // else this means it's opened and doesn't have any sub Accounts
        return (accounts[i].subAccounts = [resData]);
      } else if (accounts[i].hasOwnProperty("subAccounts")) {
        appendAccount(accounts[i].subAccounts, resData);
      }
    }
  };

  const handleUpdate = (obj, accountLvl) => {
    const allowedDigit =
      obj.number.length - (data.accountLevels[accountLvl - 2]?.digits || 0);

    const parentDigit =
      data.accountLevels[accountLvl - 1].digits - allowedDigit;

    const parentNumber = obj.number.substring(0, parentDigit);

    const number = obj.number.substring(parentDigit);

    setRowId(obj.id);
    updateSetValue("name", obj.name);
    updateSetValue("number", number);
    updateSetValue("parentNo", parentNumber);
    updateSetValue("accountCategoryId", obj.accountCategoryId);
    updateSetValue("accountLevelId", data.accountLevels[accountLvl - 1].id);
    updateSetValue("digits", allowedDigit);
    updateSetValue("accountClassification", obj.accountClassification);
    updateSetValue("customerAccountId", obj.customerAccountId);
    updateSetValue("accountNature", obj.accountNature);

    setUpdateModal(true);
  };

  const updateAccount = async (formData) => {
    setUpdateModal(false);
    setSpinner(true);
    const schema = {
      ...formData,
      number: formData.parentNo + formData.number,
    };

    delete schema.parentNo;
    delete schema.digits;
    // delete schema.number;
    delete schema.accountLevelId;

    if (formData.classification !== 4) {
      delete schema.customer;
      delete schema.customerAccountId;
    }

    const res = await updateAccountApi.request(rowId, schema);

    if (res.status === 200) {
      const newData = { ...data };
      updateAccountRecursively(newData.data, res.data.data);
      setData(newData);
      updateReset();
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const updateAccountRecursively = (accounts, resData) => {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === rowId) {
        return (accounts[i] = {
          ...accounts[i],
          ...resData,
        });
      } else if (accounts[i].hasOwnProperty("subAccounts")) {
        updateAccountRecursively(accounts[i].subAccounts, resData);
      }
    }
  };

  const handleDelete = (id) => {
    setRowId(id);
    setDeleteModal(true);
  };

  const deleteAccount = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteAccountApi.request(rowId);

    if (res.status === 200) {
      const newData = { ...data };
      deleteAccountRecursively(newData.data);
      setData(newData);
      sweetAlert(content.done[culture]);
    }
    setRowId("");
    setSpinner(false);
  };

  const deleteAccountRecursively = (accounts) => {
    for (let i = accounts.length - 1; i >= 0; i--) {
      if (accounts[i].id === rowId) {
        return accounts.splice(i, 1);
      } else if (accounts[i].hasOwnProperty("subAccounts")) {
        deleteAccountRecursively(accounts[i].subAccounts);
      }
    }
  };

  const handleAccumulationBalance = (obj) => {
    setRowId(obj.id);
    setAccumulationModal(true);
  };

  return (
    <>
      {alert}
      {getRootsApi.errorAlert}
      {getChildrenApi.errorAlert}
      {createAccountApi.errorAlert}
      {updateAccountApi.errorAlert}
      {deleteAccountApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.title[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            {checkPermission("CreateAsync") && (
              <>
                <Button
                  className="mr-2"
                  color="info"
                  size="md"
                  type="submit"
                  onClick={createRootAccount}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>{content.account[culture]}</span>
                </Button>
                <hr />
              </>
            )}
            <h4 className="mb-3">{content.accountsTree[culture]}</h4>
            {data.data?.map((item) => (
              <List
                key={item.id}
                account={item}
                culture={culture}
                getChildren={getChildren}
                handleCreate={handleCreate}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                handleAccumulationBalance={handleAccumulationBalance}
                maxAccountLevel={data.accountLevels?.length || 1}
              />
            ))}
          </CardBody>
        </Card>
      </Container>
      <TextPopup
        modal={addModal}
        text={content.add[culture]}
        handleCancel={handleCancel}
        fn={handleSubmit(createAccount)}
        color="primary"
      >
        <CreateAccount
          watch={watch}
          errors={errors}
          control={control}
          culture={culture}
          setValue={setValue}
          sweetAlert={sweetAlert}
          accountLevels={data.accountLevels}
          accountCategories={data.accountCategories}
          accountClassifications={data.accountClassifications}
        />
      </TextPopup>
      <TextPopup
        modal={updateModal}
        text={content.edit[culture]}
        handleCancel={handleCancel}
        fn={updateSubmit(updateAccount)}
        color="info"
      >
        <UpdateAccount
          culture={culture}
          watch={updateWatch}
          errors={updateErrors}
          control={updateControl}
          setValue={updateSetValue}
          accountLevels={data.accountLevels}
          accountCategories={data.accountCategories}
          accountClassifications={data.accountClassifications}
        />
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={content.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteAccount}
        name={data.data?.find((item) => item.id === rowId)?.name}
        color="danger"
      >
        <CardBody>
          <h2>{content.deletePopup[culture]} </h2>
        </CardBody>
      </TextPopup>
      <TextPopup
        modal={accumulationModal}
        text={content.edit[culture]}
        handleCancel={handleCancel}
        // fn={updateSubmit(updateAccount)}
        color="info"
        noBtn
      >
        <AccumulationBalance rowId={rowId} />
      </TextPopup>
    </>
  );
};

export default AccountingGuide;
