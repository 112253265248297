export default {
  title: {
    ar: 'تفاصيل المصرف',
    en: 'Bank details'
  },
  theBranch: {
    ar: 'الفرع',
    en: 'Branch'
  },
  branches: {
    ar: 'الفروع',
    en: 'Branches'
  },
  branch: {
    ar: 'فرع',
    en: 'Branch'
  },
  bankAccounts: {
    ar: 'الحسابات المصرفية',
    en: 'Bank accounts'
  },
  code: {
    ar: 'الرمز',
    en: 'Code'
  },
  name: {
    ar: 'الاسم',
    en: 'Name'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  details: {
    ar: 'التفاصبل',
    en: 'Details'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  ok: {
    ar: 'موافق',
    en: 'Ok'
  },
  bankName: {
    ar: 'اسم المصرف',
    en: 'Bank name'
  },
  bankBranchDetailsNewTap: {
    ar: "التفاصيل في صفحة جديدة",
    en: "Details on a new tab"
  },
  branchName: {
    ar: 'اسم الفرع',
    en: 'Branch name'
  },
  branchCode: {
    ar: 'رمز الفرع',
    en: 'Branch code'
  },
  save: {
    ar: "حفظ",
    en: "Save"
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  addBranch: {
    ar: 'اضافة حساب مصرفي',
    en: 'Add bank account'
  }
}