import React from "react";
import content from "../content";
import useCulture from "hooks/useCulture";
import { Controller } from "react-hook-form";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";

function PrintTextField({ errors, control }) {
  const culture = useCulture();

  return (
    <Container>
      <Row>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="enterReason">
              {content.enterReason[culture]}
            </label>
            <Controller
              control={control}
              name="enterReason"
              rules={{
                required: `${content.enterReason[culture]} field is required`,
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="name"
                  type="text"
                  className={errors.enterReason && "error"}
                  value={value ?? ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default PrintTextField;
