import React from 'react';
import {
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import content from '../content';

function AddPopup({ culture, schema, setSchame, bank }) {

  const handleChange = (key, val) => {
    setSchame({
      ...schema,
      [key]: val
    })
  }

  return (
    <div>
      <Row className='align-items-center'>
        <Col md="4">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example3cols1Input"
            >
              {content.bankName[culture]}
            </label>
            <Input
              id="exampleFormControlSelect2"
              type="select"
              value="1"
              disabled
            >
              <option disabled value="1">{bank}</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example3cols1Input"
            >
              {content.branchName[culture]}
            </label>
            <Input
              id="example3cols1Input"
              type="text"
              value={schema.name || ""}
              onChange={e => { handleChange("name", e.target.value) }}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="example3cols1Input"
            >
              {content.branchCode[culture]}
            </label>
            <Input
              id="example3cols1Input"
              type="number"
              value={schema.code || ""}
              onChange={e => { handleChange("code", parseInt(e.target.value))}}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default AddPopup