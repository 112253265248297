import React from "react";

// reactstrap components
import { Button, Row, Col } from "reactstrap";
//color
import maincolor from "./mainColor";
import commonContent from "components/common/content";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   solid,
//   regular,
//   brands,
// } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

class OneButton extends React.Component {
  render() {
    const {
      Function,
      textBtn1,
      culture,
      addIcon,
      disabled,
      search,
      noIcon,
      editIcon,
      color,
    } = this.props;

    return (
      <>
        <Row>
          <Col
            className={`text-md-${commonContent.align[culture]} `}
            lg="12"
            md="12"
            xs="12"
          >
            <Button
              className="mr-2"
              color={color ? color : maincolor.color}
              size="md"
              disabled={disabled}
              outline={editIcon}
              onClick={() => {
                Function();
              }}
            >
              <span>{textBtn1}</span>
              <span className="btn-inner--icon mr-1">
                {search ? (
                  <>
                    <span className="btn-inner--icon mr-1">
                      <i className="fab fa-sistrix" />
                    </span>
                  </>
                ) : addIcon ? (
                  <>
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                  </>
                ) : noIcon ? (
                  <></>
                ) : editIcon ? (
                  <span className="btn-inner--icon mr-1 ml-1">
                    <i className={`fas fa-edit text-${maincolor.color}`}></i>
                  </span>
                ) : (
                  <span className="btn-inner--icon mr-1 ml-1">
                  <i className={`fas fa-edit text-${maincolor.color}`}></i>
                </span>
                )}
              </span>
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default OneButton;
