export default {
  title: {
    ar: 'تسويات العهود',
    en: 'Custody settlement'
  },
  serialNumber: {
    ar: 'الرقم التسلسلي',
    en: 'Serial number'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  type: {
    ar: 'النوع',
    en: 'Type'
  },
  date: {
    ar: 'التاريخ',
    en: 'Date'
  },
  transactionType: {
    ar: 'نوع المعاملة',
    en: 'Transaction type'
  },
  creditAccount: {
    ar: 'حساب الدائن',
    en: 'credit account'
  },
  documentStatus: {
    ar: 'حالة المستند',
    en: 'Document status'
  },
  administrativeStatus: {
    ar: 'الحالة الادارية',
    en: 'Administrative Status'
  },
  test: {
    ar: 'تجربة',
    en: 'Test'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  record: {
    ar: 'اعتماد',
    en: 'Record'
  },
  cancel: {
    ar: 'الغاء',
    en: 'Cancel'
  },
  custodyHolderName: {
    ar: 'اسم صاحب العهدة',
    en: "Custody Holder Name"
  },
  custodyHolder: {
    ar: 'صاحب العهدة',
    en: 'Custody holder'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  add: {
    ar: 'اضافة',
    en: 'Add'
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  totalValue: {
    ar: 'اجمالي القيمة',
    en: 'Total value'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  typeOfCustody: {
    ar: 'نوع التسوية',
    en: 'Type of custody'
  },
  custodyDate: {
    ar: 'تاريخ التسوية',
    en: 'Custody date'
  },
  transactionBranch: {
    ar: 'فرع المعاملة',
    en: 'Transaction branch'
  },
  statement: {
    ar: 'البيان',
    en: 'Statement'
  },
  value: {
    ar: 'القيمة',
    en: 'Value'
  },
  notes: {
    ar: 'الملاحظات',
    en: 'Notes'
  },
  custodyNo: {
    ar: 'رقم التسوية',
    en: 'Custody number'
  },
  debitAccount: {
    ar: 'الحساب المدين',
    en: 'Debit account'
  }
}