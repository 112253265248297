import React from "react";
import classes from "./PrintTable.module.css";
import { useRouteMatch } from "react-router";
import getCulture from "utils/getCulture";

const PrintTable = (props) => {

  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const nestedObj = (object, columns) => {
    // 1) get all the keys as an array
    const keys = columns.split(".");
    // 2) loop throw the keys with the object parameters as an default value
    const nestedVal = keys.reduce(
      (obj, prop) => (obj[prop] ? obj[prop] : " "),
      object
    );
    // 3) return the nested value
    return nestedVal;
  };

  const data = props.data || [];

  const headers = [...props.headers] || [];
  const index = headers.findIndex((item) => item.toLowerCase() === "actions" || item === "العمليات");
  if (index > -1) headers.splice(index, 1);

  const direction = culture === "ar" ? "rtl" : "ltr";

  return (
    <table className={classes.printTable}>
      <thead>
        <tr>
          {headers?.map((header) => (
            <th key={header} scope="col" style={{direction}}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={index}>
            {props.columns?.map((column, index) => (
              <td data-label="Account" key={index} className={classes.cell}>
                {column.includes(".") ? nestedObj(item, column) : item[column]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PrintTable;
