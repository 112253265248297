import React, { useState } from "react";
import useCulture from "hooks/useCulture";
import { Controller } from "react-hook-form";
import { CardBody, Col, FormGroup, Input, Row, Container } from "reactstrap";
import content from "../content";
import TextPopup from "components/common/TextModal";
import commonContent from "components/common/commonContent";
import BranchTable from "components/common/BranchTable";

function EditUsers({ errors, control, setValue }) {
  const culture = useCulture();
  const [branchModal, setBranchModal] = useState(false);

  const handleCancel = () => {
    setBranchModal(false);
  };

  return (
    <CardBody>
      <Row>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="firstName">
              {content.firstName[culture]}
            </label>
            <Controller
              control={control}
              name="firstName"
              rules={{ required: true }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="firstName"
                  placeholder={content.firstName[culture]}
                  type="text"
                  className={errors.firstName ? "error" : ""}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="lastName">
              {content.lastName[culture]}
            </label>
            <Controller
              control={control}
              name="lastName"
              rules={{ required: true }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="lastName"
                  placeholder={content.lastName[culture]}
                  type="text"
                  value={value || ""}
                  className={errors.lastName ? "error" : ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="email">
              {content.email[culture]}
            </label>
            <Controller
              control={control}
              name="email"
              rules={{
                required: true,
                pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
              }}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="email"
                  placeholder={content.email[culture]}
                  type="text"
                  className={errors.email ? "error" : ""}
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="phone">
              {content.phone[culture]}
            </label>
            <Controller
              control={control}
              name="phone"
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="phone"
                  placeholder={content.phone[culture]}
                  type="text"
                  value={value || ""}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col md="6" lg="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="branch">
              {content.branch[culture]}
            </label>
            <Controller
              control={control}
              name="branch"
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Input
                  {...field}
                  id="branch"
                  placeholder={commonContent.branch[culture]}
                  type="text"
                  className={errors.branch && "error"}
                  value={value ?? ""}
                  onChange={({ target: { value } }) => onChange(value)}
                  onClick={() => setBranchModal(true)}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <TextPopup
        modal={branchModal}
        text={commonContent.next[culture]}
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <BranchTable setValue={setValue} setModal={setBranchModal} />
        </Container>
      </TextPopup>
    </CardBody>
  );
}

export default EditUsers;
