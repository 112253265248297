import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form
} from "reactstrap";
//content 
import content from './content';

// core components
import SimpleHeader from 'components/Headers/SimpleHeader';

function AddBankAccount() {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const { id } = useParams();
  const [bank, setBank] = useState('')

  return (
    <div>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.bankName[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={id ? "first" : true}
                    onChange={e => {setBank(e.target.value)}}
                    disabled={id}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">الجمهرية</option>
                    <option value="second">الوحدة</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchName[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={id ? "first" : true}
                    onChange={e => {}}
                    disabled={id}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">حي الاندلس</option>
                    <option value="second">قرجي</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchCode[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="text"
                    disabled
                    value={"#DF402"}
                    onChange={e => {}}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.branchNumber[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="text"
                    onChange={e => {}}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Button className="mr-2" color="primary" size="md" 
                  onClick={() => {}}>
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>
                    {content.save[culture]}
                  </span>
                </Button>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AddBankAccount
