export default {
  title: {
    ar: "الاعدادات",
    en: "configuration",
  },
  configureConfiguration: {
    ar: "هل تريد تكوين اعدادات ؟",
    en: "Do you want to configure configuration settings",
  },
  createConfiguration: {
    ar: "انشاء اعدادات",
    en: "Create Configuration",
  },
  mainTreasury: {
    ar: "الخزينة الرئيسية",
    en: "Main treasury",
  },
  customersParentAccount: {
    ar: "حساب العملاء",
    en: "customers Parent Account",
  },
  revenueAccount: {
    ar: "حساب الايرادات لديون العملاء",
    en: "Revenue account for customer debts",
  },
  profitLossAccount: {
    ar: "حساب الارباح والخسائر",
    en: "Profit Loss Account",
  },
  usdToLyRate: {
    ar: "الدينار مقابل الدولار",
    en: "Usd to Dinar rate",
  },
  staffAccount: {
    ar: "حساب الموظفين",
    en: "Staff account",
  },
  euroToLYDRate: {
    ar: "سعر اليورو إلى دينار ليبي",
    en: "Euro To LYD Rate",
  },
  transferAccounts: {
    ar: "حسابات الحوالات",
    en: "Transfer accounts",
  },
  brokerAccount: {
    ar: "حساب البروكر",
    en: "Broker Account",
  },
  resizingRepricingAccount: {
    ar: "حساب اعادة التسعير والتحجيم",
    en: "Resizing repricing account",
  },
  bulkExpensesAccounts: {
    ar: "حسابات مصاريف البلك",
    en: "Bulk expenses accounts",
  },
  shippingExpensesAccount: {
    ar: "مصاريف الشحن",
    en: "Shipping expenses account",
  },
  customersExpensesAccount: {
    ar: "مصاريف الجمرك",
    en: "Customers expenses account",
  },
  operatorExpensesAccount: {
    ar: "مصاريف العمال",
    en: "Operator expensess account",
  },
  transportExpensesAccount: {
    ar: "مصاريف النقل",
    en: "Transport expenses account",
  },
  transfersCommissionAccount: {
    ar: "عمولات التحويل",
    en: "Transport expenses account",
  },
  otherBulkExpensesAccount: {
    ar: "مصاريف البلك الاخرى",
    en: "Other Bulk expenses account",
  },
};
