import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";
import Spinner from 'components/Spinner';
import catchAsync from 'helper/catchAsync';

function AddBranch() {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [schema, setSchame] = useState({});

  const handleChange = (key, val) => {
    console.log(typeof val);
    setSchame({
      ...schema,
      [key]: val
    })
  }

  const handleCreate = e => catchAsync(async () => {
    e.preventDefault();
    setSpinner(true);
    console.log('here');
    const result = await axios.post(`${env.activeServer()}/BankBranchs`, schema, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    console.log({result});
    if (result.status === 200) {
      for(let key in schema)
        delete schema[key];
    }

  }, () => { setSpinner(false) }, setAlert)

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/BankBranchs/Preload`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })

          if (result.status === 200) {
            console.log({result});
            setData(result.data.data);
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      />
      :<>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleCreate}>
              <Row className='align-items-center'>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.bankName[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      defaultValue={id ? "first": true}
                      value={schema?.bankId}
                      onChange={e => { handleChange("bankId", e.target.value) }}
                      disabled={id}
                    >
                      <option disabled value> -- {content.selectOption[culture]} -- </option>
                      {data.banks.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.branchName[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      type="text"
                      value={schema?.name || ""}
                      onChange={e => { handleChange("name", e.target.value) }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.branchCode[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      type="number"
                      value={schema?.code || ""}
                      onChange={e => { handleChange("code", parseInt(e.target.value))}}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button className="mr-2" color="primary" size="md" type="submit">
                  <span>
                    {content.save[culture]}
                  </span>
                </Button>
              </Row>
            </form>
          </CardBody>
        </Card>
      </Container>
      </>}
    </div>
  )
}

export default AddBranch
