import React, { useState } from "react";
import {
  Card,
  Container,
  Button,
  UncontrolledTooltip,
  CardHeader,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import content from "./content";
import account from "api/account";
import useApi from "hooks/useApi";
import Spinner from "components/Spinner";
import Pagination from "components/common/Pagination";
import useAlert from "hooks/useAlert";
import env from "env";
import CreateAccount from "./components/CreateAccount";
import TextPopup from "components/common/TextModal";
import UpdateAccount from "./components/UpdateAccount";
import { Controller, useForm } from "react-hook-form";
import usePermissions from "hooks/usePermissions";
import commonContent from "components/common/commonContent";
import useFetch from "hooks/useFetch";
import useFilter from "hooks/useFilter";

const Accounts = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const headers = [
    content.actions[culture],
    content.accountNo[culture],
    content.accountName[culture],
    content.category[culture],
    content.type[culture],
    content.createdDate[culture],
    content.editedDate[culture],
    content.createdBy[culture],
  ];

  const columns = [
    "number",
    "name",
    "accountCategory.name",
    "accountClassificationsText",
    "createdOn",
    "ModifiedDate",
    "CreatedBy",
  ];

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  const getAccountsApi = useApi(account.accountsPreload);
  const getRootsApi = useApi(account.getRoots);
  const getByColumn = useApi(account.getByColumn);
  const deleteAccountApi = useApi(account.deleteAccount);
  const updateAccountApi = useApi(account.updateAccount);
  const createAccountApi = useApi(account.createAccount);
  const getChildrenApi = useApi(account.getChildren);
  const filterApi = useApi(account.filter);

  const [data, setData] = useState({});
  const [listData, setListData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);

  // This useForm hook is for create account api
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm();

  const checkPermission = usePermissions("Account");

  // This useForm hook is for update account api
  const {
    handleSubmit: updateSubmit,
    formState: { errors: updateErrors },
    control: updateControl,
    reset: updateReset,
    watch: updateWatch,
    setValue: updateSetValue,
  } = useForm();

  const editResponse = (item) => {
    item.accountClassificationsText =
      env.accountClassifications[item.accountClassification - 1][culture];
    item.createdOn = item.createdOn.split("T")[0];
    return item;
  };

  const getAccounts = async () => {
    const res = await getAccountsApi.request(pageNo, docsPerPage);

    if (res.status === 200) {
      res.data.data.data = res.data.data.data.map((item) => editResponse(item));
      setData(res.data.data);
    }
  };

  const getRoots = async () => {
    const res = await getRootsApi.request();
    if (res.status === 200) {
      res.data.data.accountLevels.reverse();

      setListData(res.data.data);
    }
  };

  const getChildren = async (id) => {
    const res = await getChildrenApi.request(id);

    if (res.status === 200) {
      const newListData = { ...listData };

      findParent(id, newListData.data, res.data.data);

      setListData(newListData);
      console.log("lissst: ,", newListData);
    }
  };

  const findParent = (parentId, accounts, resData) => {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === parentId) {
        return (accounts[i].subAccounts = resData);
      } else if (accounts[i].hasOwnProperty("subAccounts")) {
        findParent(parentId, accounts[i].subAccounts, resData);
      }
    }
  };

  const handleUpdate = (obj) => {
    const accountLvl = data.accountLevels.find(
      (item) => item.id === obj.accountLevelId
    );

    setRowId(obj.id);
    updateSetValue("name", obj.name);
    updateSetValue("number", obj.number);
    updateSetValue("accountCategoryId", obj.accountCategoryId);
    updateSetValue("accountLevelId", accountLvl.id);

    setUpdateModal(true);
  };

  const updateData = (arr) => {
    const editedData = arr.map((res) => editResponse(res));
    setData((d) => ({
      ...d,
      data: editedData,
    }));
  };

  const handleCancel = () => {
    setAddModal(false);
    setUpdateModal(false);
    setDeleteModal(false);
    setRowId("");
    reset();
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const deleteAccount = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteAccountApi.request(rowId);

    if (res.status === 200) {
      const newData = { ...data };
      newData.data = newData.data.filter((item) => item.id !== rowId);

      setData(newData);
      sweetAlert(content.done[culture]);
    }
    setRowId("");
    setSpinner(false);
  };

  const updateAccount = async (formData) => {
    console.log("here");
    setUpdateModal(false);
    setSpinner(true);
    const schema = {
      ...formData,
      number: formData.parentNo + formData.number,
    };

    delete schema.parentNo;
    delete schema.digits;
    delete schema.number;
    delete schema.accountLevelId;

    const res = await updateAccountApi.request(rowId, schema);

    if (res.status === 200) {
      const newData = { ...data };
      const index = newData.data.findIndex((item) => item.id === rowId);
      newData.data[index] = editResponse(res.data.data);

      setData(newData);
      updateReset();
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const createRootAccount = () => {
    setRowId("");
    setValue("accountLevelId", data.accountLevels[0].id);
    setValue("digits", data.accountLevels[0].digits);
    setValue("parentNo", "");
    setAddModal(true);
  };

  const createAccount = async (formData) => {
    setAddModal(false);
    setSpinner(true);
    const schema = {
      ...formData,
      number: formData.parentNo + formData.number,
    };

    delete schema.parentNo;
    delete schema.digits;

    if (!schema.customerAccountId) delete schema.customerAccountId;

    const res = await createAccountApi.request(schema);

    if (res.status === 200) {
      const newData = { ...data };

      // if the rowId is empty this means it's on the root
      if (rowId === "") {
        newData.data.unshift(res.data.data);
      } else {
        // other wise find the parent and append the reponse in parent subAccounts
        appendAccount(newData.data, res.data.data);
      }

      setData(newData);
      sweetAlert(content.done[culture]);
      reset();
      setRowId("");
    }
    setSpinner(false);
  };

  const appendAccount = (accounts, resData) => {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === rowId) {
        if (accounts[i].subAccounts) {
          return accounts[i].subAccounts.unshift(resData);
        }
        return (accounts[i].subAccounts = resData);
      } else if (accounts[i].hasOwnProperty("subAccounts")) {
        appendAccount(accounts[i].subAccounts, resData);
      }
    }
  };

  const renderEdit = () => checkPermission("UpdateAsync");
  const renderDelete = () => checkPermission("DeleteAsync");

  const { handleSearch, isSearch } = useFilter({
    apiFun: filterApi,
    docsPerPage,
    setData: updateData,
    editResponse,
    setPageNo,
    pageNo,
  });

  useFetch({ fun: getAccounts, pageNo, docsPerPage, isSearch });

  if (getAccountsApi.loading || filterApi.loading)
    return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {getAccountsApi.errorAlert}
      {getByColumn.errorAlert}
      {deleteAccountApi.errorAlert}
      {getChildrenApi.errorAlert}
      {filterApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.title[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="accountNo">
                      {content.accountNo[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="number"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          placeholder={content.accountNo[culture]}
                          id="accountNo"
                          type="text"
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="accountName">
                      {content.accountName[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="name"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          placeholder={content.accountName[culture]}
                          id="accountName"
                          type="text"
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="accountCategoryId"
                    >
                      {content.category[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="accountCategoryId"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          placeholder={content.category[culture]}
                          id="accountCategoryId"
                          type="select"
                          value={value || "disabled"}
                          onChange={({ target: { value } }) => onChange(value)}
                        >
                          <option value="disabled" disabled>
                            -- {commonContent.selectAnOption[culture]} --
                          </option>
                          {data.accountCategories?.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="accountClassification"
                    >
                      {commonContent.type[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="accountClassification"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="accountClassification"
                          placeholder={content.type[culture]}
                          type="select"
                          value={value || "disabled"}
                          onChange={({ target: { value } }) => onChange(+value)}
                        >
                          <option value="disabled" disabled>
                            -- {commonContent.selectAnOption[culture]} --
                          </option>
                          {data?.accountClassifications?.map(
                            (classification, index) => (
                              <option key={index + 1} value={index + 1}>
                                {commonContent[classification][culture]}
                              </option>
                            )
                          )}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button className="mr-2" color="info" size="md" type="submit">
                <span className="btn-inner--icon me-1">
                  <i className="fas fa-search" />
                </span>
                <span>{commonContent.search[culture]}</span>
              </Button>
            </form>
          </CardBody>
        </Card>
        <Card>
          <Pagination
            dataLength={data.data?.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              headers={headers}
              columns={columns}
              data={data.data}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              renderEdit={renderEdit}
              renderDelete={renderDelete}
            >
              <span to={`/${culture}-admin/Accounting/account`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="far fa-eye fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip delay={0} target="details1">
                  {commonContent.showDetails[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleUpdate" condition="renderEdit">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {content.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="ms-1"
                fun="handleDelete"
                condition="renderDelete"
              >
                <div id="delete1" className="table-action cursor-pointer">
                  <i className="fas fa-trash fa-lg hover-danger"></i>
                </div>
                <UncontrolledTooltip delay={0} target="delete1">
                  {content.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      {/* <TextPopup
        modal={addModal}
        text={content.add[culture]}
        handleCancel={handleCancel}
        // fn={handleSubmit(createAccount)}
        fn={console.log("test!!")}
        color="info"
      >
        <CreateAccount
          watch={watch}
          errors={errors}
          control={control}
          culture={culture}
          setValue={setValue}
          customers={data.customers}
          accountLevels={data.accountLevels}
          accountCategories={data.accountCategories}
          accountClassifications={data.accountClassifications}
          getChildren={getChildren}
          listData={listData}
          getRoots={getRoots}
        />
      </TextPopup> */}
      <TextPopup
        modal={deleteModal}
        text={content.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteAccount}
        name={data.data?.find((item) => item.id === rowId)?.name}
        color="danger"
      >
        <CardBody>
          <h2>{content.deletePopup[culture]} </h2>
        </CardBody>
      </TextPopup>
      <TextPopup
        modal={updateModal}
        text={content.edit[culture]}
        handleCancel={handleCancel}
        fn={updateSubmit(updateAccount)}
        color="info"
      >
        <UpdateAccount
          culture={culture}
          watch={updateWatch}
          errors={updateErrors}
          control={updateControl}
          accountLevels={data.accountLevels}
          accountCategories={data.accountCategories}
        />
      </TextPopup>
    </>
  );
};

export default Accounts;
