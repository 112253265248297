import client from "./client";

const endpoint = "/v1/CostCenter";

const getCostCenters = (pageNo, docsPerPage) =>
  client.get(`${endpoint}?Page=${pageNo}&PostsPerPage=${docsPerPage}`);

const costCenterPreload = (pageNo, docsPerPage) =>
  client.get(`${endpoint}/Preload?Page=${pageNo}&PostsPerPage=${docsPerPage}`);

const createCostCenter = (schema) => client.post(`${endpoint}`, schema);

const deleteCostCenter = (id) => client.delete(`${endpoint}/${id}`);

const updateCostCenter = (id, schema) =>
  client.put(`${endpoint}/${id}`, schema);

const filter = (schema, page, docPerPage) =>
  client.put(
    `${endpoint}/filter?Page=${page}&PostsPerPage=${docPerPage}`,
    schema
  );

export default {
  getCostCenters,
  costCenterPreload,
  createCostCenter,
  deleteCostCenter,
  updateCostCenter,
  filter,
};
