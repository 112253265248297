import useCulture from "hooks/useCulture";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import content from "./content";
import SimpleHeader from "components/Headers/SimpleHeader";
import { Controller, useForm } from "react-hook-form";
import commonContent from "components/common/content";
import Tables from "components/common/CustomTable";
import useApi from "hooks/useApi";
import users from "api/users";
import Spinner from "components/Spinner";
import Pagination from "components/common/Pagination";
import TextPopup from "components/common/TextModal";
import EditUsers from "./components/EditUsers";
import useAlert from "hooks/useAlert";
import useUpdate from "hooks/useUpdata";
import useDelete from "hooks/useDelete";
import usePermissions from "hooks/usePermissions";

const Users = () => {
  const culture = useCulture();
  const checkPermission = usePermissions("AuthUsers");

  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [rowId, setRowId] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const { alert, sweetAlert } = useAlert();

  const usersApi = useApi(users.getAll);
  const filterApi = useApi(users.filter);
  const updateOneApi = useApi(users.updateOne);
  const deleteOneApi = useApi(users.deleteOne);

  const updateHandler = useUpdate(
    updateOneApi,
    data,
    () => {
      getUsers();
    },
    setSpinner,
    setEditModal,
    sweetAlert
  );

  const deleteHandler = useDelete(
    data,
    setData,
    setSpinner,
    setDeleteModal,
    sweetAlert
  );

  const { control, handleSubmit } = useForm();

  const {
    control: editControl,
    handleSubmit: editHandleSubmit,
    formState: { errors: editErrors },
    setValue: editSetValue,
  } = useForm();

  const headers = [commonContent.actions[culture], content.name[culture]];
  const columns = ["userName"];

  const filterData = async (formData = {}) => {
    setSpinner(true);
    if (!Object.keys(formData).length) return getUsers();

    const res = await filterApi.request(formData, pageNo, docsPerPage);

    if (res.status === 200) {
      setData(res.data.data);
      setIsFilter(true);
    }
    setSpinner(false);
  };

  const getUsers = async () => {
    const res = await usersApi.request(pageNo, docsPerPage);
    if (res.status === 200) {
      setData(res.data.data);
      setIsFilter(false);
    }
    setSpinner(false);
  };

  const handleCancel = () => {
    setEditModal(false);
    setDeleteModal(false);
  };

  const handleDelete = (obj) => {
    setDeleteModal(true);
    setRowId(obj.id);
  };

  const deleteUser = async () => await deleteHandler(deleteOneApi, rowId);

  const handleEdit = (obj) => {
    editSetValue("id", obj.id);
    editSetValue("firstName", obj.firstName);
    editSetValue("lastName", obj.lastName);
    editSetValue("email", obj.email);
    editSetValue("phone", obj.phone);
    editSetValue("branch", obj.branch?.name ?? "");
    editSetValue("branchId", obj.branch?.id ?? "");
    setEditModal(true);
  };

  const editHandler = async (formData) => updateHandler(formData.id, formData);

  const renderEdit = () => checkPermission("UpdateAsync");
  const renderDelete = () => checkPermission("DeleteAsync");
  const renderShowPermission = () => checkPermission("UpdateRolesAsync");

  useEffect(() => {
    if (isFilter) return filterData();
    getUsers();
  }, [pageNo, docsPerPage]);

  if (usersApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {usersApi.errorAlert}
      {filterApi.errorAlert}
      {updateOneApi.errorAlert}
      {deleteOneApi.errorAlert}
      {spinner ? <Spinner opacity bg="#fff" gate="#42ba96" /> : null}
      <SimpleHeader parents={[content.users[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3>{content.users[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(filterData)}>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="username">
                      {content.username[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="username"
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="username"
                          placeholder={content.username[culture]}
                          type="text"
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button type="submit" className="mt-4">
                <span className="btn-inner--icon me-1">
                  <i className="fas fa-search" />
                </span>
                <span>{content.search[culture]}</span>
              </Button>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Pagination
            data={data.length}
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Tables
              headers={headers}
              columns={columns}
              data={data}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              renderEdit={renderEdit}
              renderDelete={renderDelete}
              renderShowPermission={renderShowPermission}
            >
              <span
                to={`/${culture}-admin/users/permissions`}
                condition="renderShowPermission"
              >
                <div id="permission" className="table-action cursor-pointer">
                  <i className="fas fa-user-lock fa-lg hover-success" />
                </div>
                <UncontrolledTooltip delay={0} target="permission">
                  {content.permission[culture]}
                </UncontrolledTooltip>
              </span>
              <span fun="handleEdit" condition="renderEdit">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {commonContent.edit[culture]}
                </UncontrolledTooltip>
              </span>
              <span
                className="ms-1"
                fun="handleDelete"
                condition="renderDelete"
              >
                <div id="delete1" className="table-action cursor-pointer">
                  <i className="fas fa-trash fa-lg hover-danger"></i>
                </div>
                <UncontrolledTooltip delay={0} target="delete1">
                  {commonContent.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Tables>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={editModal}
        text={commonContent.edit[culture]}
        handleCancel={handleCancel}
        fn={editHandleSubmit(editHandler)}
        color="info"
      >
        <EditUsers
          control={editControl}
          errors={editErrors}
          setValue={editSetValue}
        />
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={commonContent.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteUser}
        color="danger"
        name={data.find((item) => item.id === rowId)?.userName}
      >
        <CardBody>
          <h3>{content.deletePopup[culture]}</h3>
        </CardBody>
      </TextPopup>
    </>
  );
};

export default Users;
