export default {
  categoryName: {
    ar: "التقارير",
    en: "Reports",
  },
  pageTitle: {
    ar: "تفاصيل البلك",
    en: "Bulk information",
  },
  bulkNumber: {
    ar: "رقم البلك",
    en: "Bulk number",
  },
  entryNo: {
    ar: "رقم القيد",
    en: "Entry number",
  },
  entryDate: {
    ar: "تاريخ القيد",
    en: "Entry date",
  },
  surplus: {
    ar: "فائض في خزينة الصين",
    en: "The surplus in the treasury of China",
  },
  creditor: {
    ar: "دائن",
    en: "Creditor",
  },
  debtor: {
    ar: "مدين",
    en: "Debtor",
  },
  account: {
    ar: "الحساب",
    en: "Account",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  costCenter: {
    ar: "مركز التكلفة",
    en: "Cost center",
  },
  currencyValue: {
    ar: "قيمة العملة",
    en: "Currency value",
  },
  entryType: {
    ar: "نوع القيد",
    en: "Entry type",
  },
  actions: {
    ar: "العمليات",
    en: "Actions",
  },
  entryState: {
    ar: "حالة القيد",
    en: "Entry state",
  },
  date: {
    ar: "التاريخ",
    en: "Date",
  },
  currency: {
    ar: "العملة",
    en: "Currency",
  },
  entry: {
    ar: "قيد",
    en: "Entry",
  },
  closed: {
    ar: "مغلق",
    en: "Closed",
  },
  bulkState: {
    ar: "حالة الشحنة",
    en: "Bulk state",
  },
  deservedRevenue: {
    ar: "الايراد المستحق",
    en: "Deserved revenue",
  },
  actualRevenue: {
    ar: "الايراد الفعلي",
    en: "Actual revenue",
  },
  totalResizedRepriced: {
    ar: "مجموع إعادة تسعير",
    en: "Total resized repriced",
  },
  netProfit: {
    ar: "صافي الربح",
    en: "Net profit",
  },
  remainder: {
    ar: "الباقي",
    en: "Remainder",
  },
  totalBulkExpenses: {
    ar: "مصاريف البلك",
    en: "Total bulk expenses",
  },
  accountName: {
    ar: "اسم الحساب",
    en: "Account name",
  },
  orderNumber: {
    ar: "معرٌف الطلب",
    en: "Order number",
  },
  debit: {
    ar: "مدين",
    en: "Debit",
  },
  credit: {
    ar: "دائن",
    en: "Credit",
  },
  paid: {
    ar: "مدفوع",
    en: "Paid",
  },
  shipmentType: {
    ar: "نوع الشحن",
    en: "Shipment type",
  },
  shipmentClosed: {
    ar: "حالة الشحنة",
    en: "Shipment state",
  },
  costCenter: {
    ar: "مركز التكلفة",
    en: "Cost center",
  },
  total: {
    ar: "مجاميع",
    en: "Totals",
  },
  open: {
    ar: "مفتوحة",
    en: "Open",
  },
};
