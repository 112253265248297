import React, { useState, useEffect, useContext } from "react";
// router
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
//content
import content from "./content";

import { useForm, Controller } from "react-hook-form";

// core components
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import Spinner from "components/Spinner";
import Pagination from "components/common/Pagination";
import TextPopup from "components/common/TextModal";
import fiscalYear from "api/fiscalYear";
import useAlert from "hooks/useAlert";
import useApi from "hooks/useApi";
import usePagination from "hooks/usePagination";
import useSearchPopup from "hooks/useSearchPopup";
import { PaginationContext } from "context/PaginationContext";
import SearchModal from "components/common/SearchModal";
import env from "env";
import usePrevRequest from "hooks/usePrevRequest";
import useUpdate from "hooks/useUpdata";
import useDelete from "hooks/useDelete";
import useCreate from "hooks/useCreate";
import usePermissions from "hooks/usePermissions";

const FiscalYear = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const checkPermission = usePermissions("FiscalYear");

  const headers = [
    content.actions[culture],
    content.year[culture],
    content.fiscalYearState[culture],
  ];

  const columns = ["year", "fiscalYearStateText"];

  const history = useHistory();
  const [data, setData] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [lockModal, setLockModal] = useState(false);
  const [reopenModal, setReopenModal] = useState(false);
  const [migrateModal, setMigrateModal] = useState(false);
  const [rowId, setRowId] = useState("");

  const paginationCtx = useContext(PaginationContext);

  const pageName = "fiscalYear";

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  // apis
  const fiscalYearApi = useApi(fiscalYear.getFiscalYears);
  const getByColumn = useApi(fiscalYear.getByColumn);
  const lockYear = useApi(fiscalYear.lockYear);
  const reopenYear = useApi(fiscalYear.reopenYear);
  const deleteYear = useApi(fiscalYear.deleteYear);
  const createYear = useApi(fiscalYear.createYear);

  const updateData = (arr) => {
    const rows = arr.map(editResponse);
    setData((data) => ({
      ...data,
      data: rows,
    }));
  };

  const lockHandler = useUpdate(lockYear, data.data, updateData, setSpinner, setLockModal, sweetAlert);
  const reopenHandler = useUpdate(reopenYear, data.data, updateData, setSpinner, setReopenModal, sweetAlert);
  
  const deleteHandler = useDelete(data.data, updateData, setSpinner, setDeleteModal, sweetAlert);

  // search popup
  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data.data,
    editResponse
  );

  const {
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    search,
    setSearch,
    prevPageCheck,
  } = usePagination("");

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const getFiscalYears = async () => {
    const res = await fiscalYearApi.request(pageNo, docsPerPage);
    res.data.data = res.data.data.map((item) => editResponse(item));
    setData(res.data);
  };

  const editResponse = (item) => {
    item.fiscalYearStateText =
      env.fiscalYearState[item.fiscalYearState - 1][culture];
    return item;
  };

  const searchClicked = () => {
    searchPopup.setIsSelected(false);
    paginationCtx.setSearch(search);

    // if the user navigate to another page then uses search return him in the first page
    if (pageNo > 1) return setPageNo(1);
    getFiscalYears();
  };

  const handleCancel = () => {
    setRowId("");
    setLockModal(false);
    setDeleteModal(false);
    setReopenModal(false);
    setMigrateModal(false);
  };

  const handleLock = (obj) => {
    setRowId(obj.id);
    setLockModal(true);
  };

  const lockFiscalYear = async () => await lockHandler(rowId);


  const handleReopen = (obj) => {
    setRowId(obj.id);
    setReopenModal(true);
  };

  const reopenFiscalYear = async () => await reopenHandler(rowId);

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const deleteFiscalYear = async () => await deleteHandler(deleteYear, rowId);

  const createFiscalYear = async (formData) => {
    setSpinner(true);
    const res = await createYear.request(formData);

    if (res.status === 200) {
      res.data.data = editResponse(res.data.data);
      setData((d) => ({
        ...d,
        data: [res.data.data, ...d.data],
      }));
      sweetAlert(content.done[culture]);
      reset();
    }
    setSpinner(false);
  };

  const renderClose = obj => obj.fiscalYearState === 1 && checkPermission("CloseFiscalYear");
  
  const renderReopen = obj => obj.fiscalYearState === 2 && checkPermission("ReOpenFiscalYear");
  
  const renderDelete = () => checkPermission("DeleteAsync");

  usePrevRequest(
    getFiscalYears,
    searchPopup,
    pageNo,
    docsPerPage,
    updateData,
    prevPageCheck,
  );

  if (fiscalYearApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <div>
      {alert}
      {fiscalYearApi.errorAlert}
      {getByColumn.errorAlert}
      {lockYear.errorAlert}
      {reopenYear.errorAlert}
      {deleteYear.errorAlert}
      {createYear.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          {checkPermission("CreateAsync") && <CardBody>
            <Form onSubmit={handleSubmit(createFiscalYear)}>
              <Row className="align-items-center">
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="year">
                      {content.fiscalYear[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="year"
                      rules={{
                        required: `${content.fiscalYear[culture]} field is required`,
                        minLength: 4,
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="year"
                          type="number"
                          className={errors.year && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(+value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col className="mt-2 mt-md-0  align-self-center" xs="4">
                  <Button className="mr-2" color="info" size="md" type="submit">
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>{content.add[culture]}</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>}
        </Card>
        <Card>
          <Pagination
            dataLength={data.data?.length}
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
            setPageNo={
              searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
            }
            docsPerPage={
              searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
            }
            setDocsPerPage={
              searchPopup.isSelected
                ? searchPopup.setDocsPerPage
                : setDocsPerPage
            }
          >
            <Table
              headers={headers}
              data={data?.data || []}
              columns={columns}
              onHeaderClick={searchPopup.handleHeaderClicked}
              visitedRowId={paginationCtx.visitedRowId}
              // when the user was searching useing the popup and select a row
              // store the popup pagination in the context
              // otherwise store the normal pagination in the context
              setPagination={
                searchPopup.isSelected
                  ? (rowId) =>
                      paginationCtx.setPagination(
                        searchPopup.pageNo,
                        searchPopup.docsPerPage,
                        rowId,
                        pageName
                      )
                  : (rowId) =>
                      paginationCtx.setPagination(
                        pageNo,
                        docsPerPage,
                        rowId,
                        pageName
                      )
              }
              renderClose={renderClose}
              renderReopen={renderReopen}
              renderDelete={renderDelete}
              handleLock={handleLock}
              handleReopen={handleReopen}
              handleDelete={handleDelete}
            >
              <span className="mr-2 mr-3 ml-3" fun="handleLock" condition="renderClose">
                <div id="close" className="table-action cursor-pointer">
                  <i className="fas fa-lock fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="close">
                  {content.close[culture]}
                </UncontrolledTooltip>
              </span>
              <span className="mr-2 mr-3 ml-3" fun="handleReopen" condition="renderReopen">
                <div id="reopen" className="table-action cursor-pointer">
                  <i className="fas fa-lock-open fa-lg hover-success"></i>
                </div>
                <UncontrolledTooltip delay={0} target="reopen">
                  {content.reopen[culture]}
                </UncontrolledTooltip>
              </span>
              {/* <span className="mr-2 mr-3 ml-3">
                <div
                  id="migrate"
                  className="table-action cursor-pointer"
                  onClick={() => {
                    setMigrateModal(true);
                  }}
                >
                  <i className="fas fa-exchange-alt fa-lg hover-warning"></i>
                </div>
                <UncontrolledTooltip delay={0} target="migrate">
                  {content.migrate[culture]}
                </UncontrolledTooltip>
              </span> */}
              <span className="mr-2" fun="handleDelete" condition="renderDelete">
                <div
                  id="delete"
                  className="table-action-delete table-action cursor-pointer hover-danger"
                >
                  <i className="fas fa-trash fa-lg"></i>
                </div>
                <UncontrolledTooltip delay={0} target="delete">
                  {content.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={lockModal}
        text={content.ok[culture]}
        handleCancel={handleCancel}
        fn={lockFiscalYear}
        color="info"
      >
        <div className="modal-body">{content.close[culture]}</div>
        <Container>
          <h2>{content.closePopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={reopenModal}
        setModal={setReopenModal}
        text={content.ok[culture]}
        handleCancel={handleCancel}
        fn={reopenFiscalYear}
        color="success"
      >
        <div className="modal-body">{content.reopen[culture]}</div>
        <Container>
          <h2>{content.reopenPopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={migrateModal}
        setModal={setMigrateModal}
        text={content.create[culture]}
        handleCancel={handleCancel}
        fn={() => history.replace(`/${culture}-admin/createfiscalyear`)}
        color="info"
      >
        <div className="modal-body">{content.migratePopup[culture]}</div>
        <Container>
          <Row>
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label mr-2 ml-2"
                  htmlFor="first-check"
                >
                  <Input type="checkbox" value="" id="first-check" />
                  <span className="mr-4">{content.firstCheck[culture]}</span>
                </label>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label mr-2 ml-2"
                  htmlFor="second-check"
                >
                  <Input type="checkbox" value="" id="second-check" />
                  <span className="mr-4">{content.secondCheck[culture]}</span>
                </label>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label mr-2 ml-2"
                  htmlFor="third-check"
                >
                  <Input type="checkbox" value="" id="third-check" />
                  <span className="mr-4">
                    {content.migrateSettings[culture]}
                  </span>
                </label>
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={content.ok[culture]}
        handleCancel={handleCancel}
        fn={deleteFiscalYear}
        color="danger"
      >
        <div className="modal-body">{content.delete[culture]}</div>
        <Container>
          <h2>{content.deletePopup[culture]}</h2>
        </Container>
      </TextPopup>
      {searchPopup.openPopup && (
        <SearchModal
          data={data.data}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={editResponse}
          popupSearchCtx={paginationCtx.popupSearch}
          page={pageName}
        />
      )}
    </div>
  );
};

export default FiscalYear;
