export default {
  title: {
    ar: 'اضافة معاملة مالية جديدة',
    en: 'Add Transaction',
  },
  serialNo: {
    ar: 'الرقم التسلسلي',
    en: 'Serial Number'
  },
  transactionType: {
    ar: 'نوع المعاملة',
    en: 'Transaction type'
  },
  transactionNo: {
    ar: 'رقم المعاملة',
    en: 'Transaction number'
  },
  paymentMethod: {
    ar: 'طريقة الدفع',
    en: 'Payment method'
  },
  transactionDate: {
    ar: 'تاريخ المعاملة',
    en: 'Transaction date'
  },
  value: {
    ar: 'القيمة',
    en: 'Value'
  },
  document: {
    ar: 'المستند',
    en: 'Document'
  },
  creditor: {
    ar: 'دائن',
    en: 'Creditor'
  },
  debit: {
    ar: 'مدين',
    en: 'Debit'
  },
  documentStatus: {
    ar: 'حالة المستند',
    en: 'Document status'
  },
  source: {
    ar: 'المصدر',
    en: 'Source'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  record: {
    ar: 'اعتماد',
    en: 'Record'
  },
  add: {
    ar: 'إضافة',
    en: 'Add'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  beneficiary: {
    ar: 'المستفيد',
    en: 'Beneficiary'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  test: {
    ar: 'تجربة',
    en: 'Test'
  },
  creditorAccount: {
    ar: 'الحساب الدائن',
    en: 'Creditor account'
  },
  debitAccount: {
    ar: 'الحساب المدين',
    en: 'Debit account'
  },
  statement: {
    ar: 'البيان',
    en: 'Statement'
  }
}