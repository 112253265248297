import React from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
//content 
import content from './content';

// core components
import SimpleHeader from 'components/Headers/SimpleHeader';
// import Tables from './Tables';
import Table from 'components/common/CustomTable';

const AddReleaseOrder = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  const headers = [
    content.releaseOrderNo[culture],
    content.mainDoor[culture],
    content.releaseOrder[culture],
    content.debitAccount[culture],
    content.beneficiary[culture],
    content.transactionNo[culture],
    content.value[culture],
    content.actions[culture]
  ]

  const data = [
    {
      id: 1,
      releaseOrderNo: 131,
      mainDoor: 'المصروفات العمومية',
      date: "2020-3-3",
      debitAccount: "مصرف الجمهرية",
      beneficiary: "شركة البناء والتاثيث",
      typeOfReleaseOrder: "عادي",
      value: "1211"
    },
    {
      id: 2,
      releaseOrderNo: 131,
      mainDoor: 'المصروفات العمومية',
      date: "2020-3-3",
      debitAccount: "مصرف الجمهرية",
      beneficiary: "شركة البناء والتاثيث",
      typeOfReleaseOrder: "عادي",
      value: "1211"
    },
  ];

  const columns = [
    "releaseOrderNo",
    "mainDoor",
    "date",
    "debitAccount",
    "beneficiary",
    "typeOfReleaseOrder",
    "value"
  ]

  return (
    <div>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.serial[culture]}
                  </label>
                  <Input
                      id="example3cols1Input"
                      type="text"
                      placeholder={content.serial[culture]}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.mainDoor[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">{content.generalExpenses[culture]}</option>
                    <option value="second">{content.projects[culture]}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.date[culture]}
                  </label>
                  <Input
                      id="example3cols1Input"
                      type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.typeOfReleaseOrder[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">{content.normal[culture]}</option>
                    <option value="second">{content.project[culture]}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.typeofTransaction[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">{content.instrument[culture]}</option>
                    <option value="second">{content.transfer[culture]}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.beneficiary[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="text"
                    placeholder={content.beneficiary[culture]}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.transactionNo[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.releaseOrderDetails[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.debitAccount[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">{content.test[culture]}</option>
                    <option value="second">{content.test[culture]}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.value[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="number"
                    defaultValue={true}
                    onChange={e => {}}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.beneficiary[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="text"
                    onChange={e => {}}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.transactionNo[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="text"
                    onChange={e => {}}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.notes[culture]}
                  </label>
                  <Input
                      id="example3cols1Input"
                      type="textarea"
                      placeholder={content.notes[culture]}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <Table
            headers={headers}
            columns={columns}
            data={data}
          >
                        <span className="mr-2 mr-3 ml-3">
              <div id="edit1" className="table-action cursor-pointer">
                <i className="fas fa-pencil-alt fa-lg hover-info"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="edit1"
              >
                {content.edit[culture]}
              </UncontrolledTooltip>
            </span>
            <span className="mr-2 mr-3 ml-3">
              <div id="category" className="table-action cursor-pointer">
                <i className="far fa-address-book fa-lg hover-success"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="category"
              >
                {content.category[culture]}
              </UncontrolledTooltip>
            </span>
            <span className="mr-2 mr-3 ml-3">
              <div id="details" className="table-action cursor-pointer">
                <i className="fas fa-info-circle fa-lg hover-warning"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="details"
              >
                {content.details[culture]}
              </UncontrolledTooltip>
            </span>
            <span className="mr-2">
              <div id="delete" className="table-action-delete table-action cursor-pointer hover-danger">
                <i className="fas fa-trash fa-lg"></i>
              </div>
              <UncontrolledTooltip
                delay={0}
                target="delete"
              >
                {content.delete[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
          <CardBody>
            <Row>
              <Button className="mr-2" color="primary" size="md">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>
                  {content.save[culture]}
                </span>
              </Button>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AddReleaseOrder;
