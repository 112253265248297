import React, { useEffect, useState } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Form,
  Button,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import content from "./content";
import TextPopup from "components/common/TextModal";
import Spinner from "components/Spinner";
import useAlert from "hooks/useAlert";
import commonContent from "components/common/content";
import useCreateEntry from "./useCreateEntry";
import FilterEntries from "components/common/FilterEntries";

const CreateEntry = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const { alert, sweetAlert } = useAlert();

  const headers = [
    content.actions[culture],
    `${content.accountName[culture]} - (${content.debtor[culture]})`,
    content.debtor[culture],
    `${content.accountName[culture]} - (${content.creditor[culture]})`,
    content.creditor[culture],
    content.notes[culture],
  ];

  const columns = [
    "debtorAccount",
    "debit",
    "creditorAccount",
    "debit",
    "note",
  ];

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const {
    handleSubmit: handleRowSubmit,
    formState: { errors: rowErrors },
    control: rowControl,
    reset: rowReset,
    setValue: rowSetValue,
    watch: rowWatch,
  } = useForm();

  const {
    data,
    fieldName,
    deleteModal,
    schema,
    spinner,
    debtorAcc,
    creditorAcc,
    handleCancel,
    preloadEntry,
    addRow,
    handleDelete,
    deleteRow,
    createEntry,
    setFieldName,
    preloadApi,
    createEntryApi,
    createAndConfirmEntry,
    createAndConfirmEntryApi
  } = useCreateEntry(sweetAlert, reset, rowReset);

  useEffect(() => {
    preloadEntry();
  }, []);

  if (preloadApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {preloadApi.errorAlert}
      {createEntryApi.errorAlert}
      {createAndConfirmEntryApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.title[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6" lg="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="entryType">
                    {content.entryType[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="entryType"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="entryType"
                        type="select"
                        className={errors.entryType && "error"}
                        onChange={({ target: { value } }) => onChange(+value)}
                        onWheel={(e) => e.target.blur()}
                        value={value || "disabled"}
                      >
                        <option disabled value="disabled">
                          -- {content.selectOption[culture]} --
                        </option>
                        {data?.entryType?.map((item, index) => (
                          <option value={index + 1} key={index}>
                            {commonContent[item][culture]}
                          </option>
                        ))}
                      </Input>
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="currency">
                    {content.currency[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="currency"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="currency"
                        type="select"
                        className={errors.currency && "error"}
                        onChange={({ target: { value } }) => onChange(+value)}
                        onWheel={(e) => e.target.blur()}
                        value={value || "disabled"}
                      >
                        <option disabled value="disabled">
                          -- {content.selectOption[culture]} --
                        </option>
                        {data?.currency?.map((item, index) => (
                          <option value={index + 1} key={index}>
                            {commonContent[item][culture]}
                          </option>
                        ))}
                      </Input>
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="date">
                    {content.entryDate[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="date"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="date"
                        type="date"
                        className={errors.date && "error"}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="3">
                <FormGroup>
                  <label className="form-control-label" htmlFor="date">
                    {commonContent.costCenter[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="costCenterId"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="costCenterId"
                        type="select"
                        value={value || ""}
                        className={errors.costCenterId && "error"}
                        onChange={({ target: { value } }) => onChange(value)}
                      >
                        <option disabled value="">
                          -- {commonContent.selectOption[culture]} --
                        </option>
                        {data?.costCenters?.map((cost) => (
                          <option key={cost.id} value={cost.id}>
                            {cost.name}
                          </option>
                        ))}
                      </Input>
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="statement">
                    {content.statement[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="statement"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="statement"
                        type="textarea"
                        className={errors.statement && "error"}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row>
              <Col md="6" lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="debtorAccount">
                    {content.accountName[culture]} ({content.debtor[culture]})
                  </label>
                  <Controller
                    control={rowControl}
                    name="debtorAccount"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="debtorAccount"
                        type="text"
                        className={rowErrors.debtorAccount && "error"}
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                        onClick={debtorAcc}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="debit">
                    {content.debtor[culture]}
                  </label>
                  <Controller
                    control={rowControl}
                    name="debit"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="debit"
                        type="text"
                        value={value || ""}
                        className={rowErrors.debit && "error"}
                        onChange={({ target: { value } }) => onChange(+value)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="creditorAccount"
                  >
                    {content.accountName[culture]} ({content.creditor[culture]})
                  </label>
                  <Controller
                    control={rowControl}
                    name="creditorAccount"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="creditorAccount"
                        type="text"
                        className={rowControl.creditorAccount && "error"}
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                        onClick={creditorAcc}
                        disabled={!!!rowWatch("debtorAccount")}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="credit">
                    {content.creditor[culture]}
                  </label>
                  <Controller
                    control={rowControl}
                    name="debit"
                    rules={{ required: `This field is required` }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="credit"
                        type="text"
                        placeholder={content.creditor[culture]}
                        value={value || ""}
                        className={rowErrors.credit && "error"}
                        onChange={({ target: { value } }) => onChange(+value)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="note">
                    {content.notes[culture]}
                  </label>
                  <Controller
                    control={rowControl}
                    name="note"
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="note"
                        type="textarea"
                        placeholder={content.notes[culture]}
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button
              size="sm"
              type="button"
              className="cml-2"
              onClick={handleRowSubmit(addRow)}
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-plus" />
              </span>
              <span className="btn-inner--text">
                {content.addField[culture]}
              </span>
            </Button>
          </CardBody>
        </Card>
        <Card>
          <Table
            headers={headers}
            columns={columns}
            data={schema}
            handleDelete={handleDelete}
          >
            <span
              className="cmr-2"
              fun="handleDelete"
              condition="renderDeleteIcon"
            >
              <div
                id="delete"
                className="table-action-delete table-action cursor-pointer hover-danger"
              >
                <i className="fas fa-trash"></i>
              </div>
              <UncontrolledTooltip delay={0} target="delete">
                {content.deleteRow[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
          <CardBody className="text-center">
            <Button
              color="success"
              className="cml-2"
              type="button"
              disabled={!!!schema.length}
              onClick={handleSubmit(createEntry)}
            >
              <span className="btn-inner--icon me-1">
                <i className="far fa-save" />
              </span>
              <span className="btn-inner--text">
                {content.saveData[culture]}
              </span>
            </Button>
            <Button
              color="primary"
              className="cml-2"
              type="button"
              disabled={!!!schema.length}
              onClick={handleSubmit(createAndConfirmEntry)}
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-check" />
              </span>
              <span className="btn-inner--text">
                {content.saveAndConfirm[culture]}
              </span>
            </Button>
          </CardBody>
        </Card>
      </Container>
      <TextPopup
        modal={!!fieldName}
        noBtn
        handleCancel={handleCancel}
        fn={() => {}}
        name={fieldName}
        color="success"
      >
        <FilterEntries
          // culture={culture}
          // handleSelect={handleSelect}
          setValue={rowSetValue}
          fieldName={fieldName}
          setFieldName={setFieldName}
          prevSelectedId={rowWatch("debtorId")}
        />
      </TextPopup>
      <TextPopup
        modal={deleteModal}
        text={content.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteRow}
        color="danger"
      >
        <CardBody>
          <h2>{content.deletePopup[culture]}</h2>
        </CardBody>
      </TextPopup>
    </>
  );
};

export default CreateEntry;
