export default {  
  title: {
    ar: 'إدارة الصكوك الخاطئة',
    en: 'Managing wrong cheques'
  },
  bankName: {
    ar: 'اسم المصرف',
    en: 'Bank name',
  },
  branchName: {
    ar: 'اسم الفرع',
    en: 'Branch name'
  },
  branchCode: {
    ar: 'اسم الفرع',
    en: 'Branch code'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  wrongCheque: {
    ar: 'صك خاطئ',
    en: 'Wrong cheque'
  },
  date: {
    ar: 'التاريخ',
    en: 'Date'
  },
  paymentMethod: {
    ar: 'طرق الدفغ',
    en: 'Payment method'
  },
  transactionNumber: {
    ar: 'رقم المعاملة',
    en: 'Transaction number'
  },
  creditor: {
    ar: 'دائن',
    en: 'Creditor'
  },
  debit: {
    ar: 'مدين',
    en: 'Debit'
  },
  registrationDate: {
    ar: 'تاريخ التسجيل',
    en: 'Registration date'
  },
  cancellationDate: {
    ar: 'تاريخ الالغاء',
    en: 'Cancellation date'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  register: {
    ar: 'تسجيل',
    en: 'Register'
  },
  cancel: {
    ar: 'الغاء',
    en: 'Cancel'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  bankAccountNumber: {
    ar: 'رقم الحساب المصرفي',
    en: 'Bank account number'
  },
  linkedAccount: {
    ar: 'البند المرتبط',
    en: 'Linked account'
  },
  accountNumber: {
    ar: 'رقم البند',
    en: 'Account number'
  }
}