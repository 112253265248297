import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import content from "../CreateEntry/content";
import entry from "api/entry";
import useApi from "hooks/useApi";
import useCulture from "hooks/useCulture";
import useFetchById from "hooks/useFetchById";
import useUpdate from "hooks/useUpdata";
import useDelete from "hooks/useDelete";
import entryDetail from "api/entryDetail";
import useCreate from "hooks/useCreate";

const useEditEntry = (sweetAlert, reset, rowReset, entryDetailsSetValue) => {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [rowId, setRowId] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // Api
  const findDetailsAsync = useApi(entry.findDetailsAsync);
  const updateEntryApi = useApi(entry.updateEntry);
  const createEntryDetailApi = useApi(entryDetail.createEntryDetails);
  const deleteEntryDetailApi = useApi(entryDetail.deleteEntryDetails);
  const updateEntryDetailsApi = useApi(entryDetail.updateEntryDetails);

  const updateEntryData = (data) => {
    setData((d) => ({
      ...data,
      entryDetails: d.entryDetails,
    }));
  };

  const updateEntryDetailsData = (data) => {
    setData((d) => ({
      ...d,
      entryDetails: data,
    }));
  };

  const createHandler = useCreate(
    createEntryDetailApi,
    data.entryDetails,
    updateEntryDetailsData,
    setSpinner,
    sweetAlert,
    rowReset
  );

  const updateHandler = useUpdate(
    updateEntryApi,
    data,
    updateEntryData,
    setSpinner,
    () => {},
    sweetAlert
  );

  const deleteHandler = useDelete(
    data.entryDetails,
    updateEntryDetailsData,
    setSpinner,
    setDeleteModal,
    sweetAlert
  );

  const updateEntryDetailsHandler = useUpdate(
    updateEntryDetailsApi,
    data.entryDetails,
    updateEntryDetailsData,
    setSpinner,
    setEditModal,
    sweetAlert
  );

  const debtorAcc = () => {
    setFieldName("debtor");
  };

  const handleCancel = () => {
    setRowId("");
    setFieldName("");
    setEditModal(false);
    setDeleteModal(false);
  };

  const handleEdit = (obj) => {
    setRowId(obj.id);
    setEditModal(true);
    entryDetailsSetValue("note", obj.note);
    entryDetailsSetValue("debit", obj.debit);
    entryDetailsSetValue("credit", obj.credit);
  };
  const addRow = async (data) => {
    const schema = {
      ...data,
      entryId: id,
      accountId: data.debtorId,
    };

    await createHandler(schema);
  };
  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const deleteRow = async () =>
    await deleteHandler(deleteEntryDetailApi, rowId);

  const updateEntry = (formData) => updateHandler(id, formData);

  const updateEntryDetails = (formData) => updateEntryDetailsHandler(rowId, formData);

  useFetchById(findDetailsAsync, id, setData);

  const errorMessage =
    updateEntryApi.errorAlert ||
    findDetailsAsync.errorAlert ||
    deleteEntryDetailApi.errorAlert ||
    createEntryDetailApi.errorAlert ||
    updateEntryDetailsApi.errorAlert;

  return {
    data,
    rowId,
    fieldName,
    editModal,
    deleteModal,
    spinner,
    errorMessage,
    updateEntry,
    setFieldName,
    debtorAcc,
    handleCancel,
    handleEdit,
    addRow,
    handleDelete,
    deleteRow,
    findDetailsAsync,
    updateEntryDetails
  };
};

export default useEditEntry;
