import { useState } from "react";
import commonContent from "components/common/commonContent";
import useCulture from "hooks/useCulture";
import { Controller, useForm } from "react-hook-form";
import { Button, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import content from "../content";
import useApi from "hooks/useApi";
import account from "api/account";
import Spinner from "components/Spinner";
import BalanceCard from "components/common/BalanceCard";
import commenContent from "components/common/commonContent";

function CountryTreasuryBalance({
  errors,
  control,
  setAccountModal,
  handleSubmit,
  data,
  setData,
}) {
  const culture = useCulture();

  const countryTreasuryRealBalanceApi = useApi(
    account.countryTreasuryRealBalance
  );

  // const [data, setData] = useState([]);

  const filter = async (data) => {
    console.log({ data });
    const res = await countryTreasuryRealBalanceApi.request(data.treasuryId);
    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  return (
    <>
      <Card>
        {countryTreasuryRealBalanceApi.loading && (
          <Spinner gate="#11cdef" bg="#fff" opacity />
        )}
        {countryTreasuryRealBalanceApi.errorAlert}
        <CardBody>
          <form onSubmit={handleSubmit(filter)}>
            <Row>
              <Col md="6" lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="treasuryAccount"
                  >
                    {commonContent.account[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="treasuryAccount"
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="treasuryAccount"
                        accountName={commonContent.account[culture]}
                        type="text"
                        className={errors.treasuryAccount && "error"}
                        value={value ?? ""}
                        onChange={({ target: { value } }) => onChange(value)}
                        onClick={() => setAccountModal(true)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col className="align-self-center mt-2">
                <Button color="info">{content.calculate[culture]}</Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="account">
                  {content.balanceLYD[culture]}
                </label>
                <Input
                  id="client"
                  type="text"
                  value={data.balanceLYD ?? ""}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="account">
                  {content.balanceUSD[culture]}
                </label>
                <Input
                  id="client"
                  type="text"
                  value={data.balanceUSD ?? ""}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="account">
                  {content.balanceEuro[culture]}
                </label>
                <Input
                  id="client"
                  type="text"
                  value={data.balanceEURO ?? ""}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default CountryTreasuryBalance;
