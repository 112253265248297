import React, { useState } from 'react';

import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  Link,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from 'components/common/CustomTable';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";
import Spinner from 'components/Spinner';
import catchAsync from 'helper/catchAsync';
import ReactBSAlert from "react-bootstrap-sweetalert";

const BankAccountDetails = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const { id } = useParams();

  const [data, setData] = useState({});
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);

  const headers = [
    content.from[culture],
    content.to[culture],
    content.size[culture],
    content.current[culture],
    content.dimensions[culture],
    content.effective[culture],
    content.beginningOfNextCheque[culture],
    content.actions[culture]
  ];

  const columns = [
    "beginOfSequence", "endOfSequence", "chequeSequenceSize", "currentSequence", "dimensions", "effective", "nextNotebook"
  ];

  const data2 = [
    {id: 1, from: 101, to: 150, size: 50, current: 119, dimensions: "113-45", effective: content.yes[culture], test: 200},
    {id: 2, from: 131, to: 140, size: 30, current: 159, dimensions: "110-43", effective: content.no[culture], test: 243},
    {id: 3, from: 121, to: 1550, size: 50, current: 115, dimensions: "134-45", effective: content.yes[culture], test: 103},
  ]

  const handleInfo = (obj) => {
    console.log('info', obj)
  }

  const handleDetails = (obj) => {
    console.log('details', obj)
  }

  const editResponse = item => {
    // 1) create dimensions instance and assign 'chequeLenght' and 'chequeHight' to it
    item.dimensions = `${item.chequeLength}x${item.chequeHeight}`;
    // 2) create another instance called "nextNoteBook"
    // 3) assign to it a combination of newxChequeBook.beginOfSequence and nextChequeBook.endOfSequence or none text 
    item.nextNotebook = item.nextChequeBook ? `${item.nextChequeBook.beginOfSequence} / ${item.nextChequeBook.endOfSequence}` : content.none[culture];

    // 4) retust item
    return item;
  }

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/BankAccounts/Details/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })

          if (result.status === 200) {
            result.data.data.chequeBooks = result.data.data.chequeBooks.map(item => editResponse(item))
            setData(result.data.data);
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      />
      :<>
        <SimpleHeader name={content.title[culture]} parentName="" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
            </CardHeader>
            <CardBody>
              <Row className='align-items-center'>
                <Col md="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.bankName[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      value={data.bankName || ""}
                      type="text"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.branchName[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      value={data.bankBranchName || ""}
                      type="text "
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.branchCode[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      value="1211"
                      type="number"
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.bankAccountNumber[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      value={data.accountNumber || ""}
                      type="number "
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.linkedAccount[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      value="مصرف الجمهرية فرع قرجي حساب رقم 2014"
                      type="text "
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.accountNumber[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      value="04050607"
                      type="number"
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.currentCheck[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      value="من 101 الى 105"
                      type="text "
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.currentSequence[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      value="119"
                      type="number "
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.dimensions[culture]}
                    </label>
                    <Input
                      id="example3cols1Input"
                      value="45 - 110"
                      type="text "
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="15">
                  <Link to={`/${culture}-admin/addbankaccount`}>
                    <Button className="mr-2" color="primary" size="md">
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span>
                        {content.chequebook[culture]}
                      </span>
                    </Button>
                  </Link>
                  <Link to={`/${culture}-admin/editbankaccount/df`}>
                    <Button className="mr-2" color="secondary" size="md">
                      <span>
                        {content.edit[culture]}
                      </span>
                    </Button>
                  </Link>
                  <Link to={`/${culture}-admin/managingwrongcheques/saf`}>
                    <Button className="mr-2" color="secondary" size="md">
                      <span>
                        {content.wrongCheques[culture]}
                      </span>
                    </Button>
                  </Link>
                  <Button className="mr-2" color="secondary" size="md">
                    <span>
                      {content.managingChequebook[culture]}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardBody>
              <Table 
                headers={headers}
                data={data.chequeBooks}
                columns={columns}
                handleInfo={handleInfo}
                handleDetails={handleDetails}
              >
                <span to={`/${culture}-admin/recordwrongcheque`}>
                  <div id="record1" className="table-action cursor-pointer">
                    <i className="far fa-file-alt fa-lg hover-success"></i>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="record1"
                  >
                    {content.record[culture]}
                  </UncontrolledTooltip>
                </span>
                {/* <Link to={`/${culture}-admin/editbankaccount`}> */}
                <span to={`/${culture}-admin/editchequebook`}>
                  <div id="edit1" className="table-action cursor-pointer">
                    <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="edit1"
                    >
                    {content.edit[culture]}
                  </UncontrolledTooltip>
                </span>
                {/* </Link> */}
                <span to={`/${culture}-admin/cancelwrongcheque`}>
                  <div id="cancel1" className="table-action-delete table-action cursor-pointer"> 
                    {/* <i className="fas fa-trash fa-lg"></i> */}
                    <i className="fas fa-times-circle fa-lg hover-warning"></i>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="cancel1"
                  >
                    {content.cancel[culture]}
                  </UncontrolledTooltip>
                </span>
              </Table>
          </Card>
        </Container>
      </>}
    </div>
  )
}

export default BankAccountDetails
