import React, { useMemo } from "react";
import styles from "./VoucherDesign.module.css";
import shaheen from "assets/img/brand/shaheen.png";
import env from "env";
import content from "../content";
import useCulture from "hooks/useCulture";

function VoucherDesign({ printRow = {} }) {
  const culture = useCulture();
  const dots = useMemo(() => Array(200).fill("."));
  const digits =
    printRow.receiptCustomerValue - parseInt(printRow.receiptCustomerValue);
  console.log(printRow);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={shaheen} alt="shahen-logo" />
        </div>
        <div className={styles.receipt}>
          <span>ايصال قبض</span>
          <span>Cash receipt</span>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.price}>
          {digits > 0 && (
            <>
              <span className={styles.field}>{digits.toFixed(2)}</span>
            </>
          )}
          <span className={styles.field}>
            {parseInt(printRow.receiptCustomerValue)}
          </span>
          <span>{env?.currency[printRow.currency - 1]?.ar}</span>
          <span className={styles.serialNumber}>
            {printRow.serialNumberDisplayed}
          </span>
          <div className={styles.date}>
            <span>التاريخ:</span>
            <span>{new Date().toLocaleDateString()}</span>
          </div>
        </div>
        <div className={styles.bodyContent}>
          <div className={styles.rows}>
            <div className={styles.row}>
              <span>استلمت من السيد:</span>
              <span>{printRow.receiptCustomerName}</span>
            </div>
            <div className={styles.row}>
              <span>وذلك عن قيمة:</span>
              <span>{printRow.enterReason}</span>
            </div>
            <div className={styles.row}>
              <span>مبلغا وقدره:</span>
              <span>{printRow.receiptCustomerValueArabic}</span>
            </div>
          </div>
          <div className={styles.seal}>
            <span>الختم</span>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.sig}>
          <div>اسم وتوقيع المسلم:</div>
          <div>اسم وتوقيع المستلم:</div>
        </div>
        <div className={styles.footer}>
          <div className={`${styles.sig} mt-5`}>
            {content.source[culture]}: {printRow.appUser?.name}
          </div>
        </div>
      </div>
      <div className={styles.cutLine}>{dots}</div>
    </div>
  );
}

export default VoucherDesign;
