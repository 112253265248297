import client from './client';

const endpoint = '/v1/auth/authentication';

const signIn = (schema) => client.post(`${endpoint}/Login`, schema);

const signUp = (schema) => client.post(`${endpoint}/register`, schema);

const dashboardSignup = schema => {
  const data = new FormData();
  data.append("FullName", schema?.fullName);
  data.append("UserName", schema?.username);
  data.append("Email", schema?.email);
  data.append("Password", schema?.password);
  data.append("ConfirmPassword", schema?.confirmPassword);
  data.append("CompanyName", schema?.companyName);
  data.append("WhatsappPhone", schema?.WhatsappPhone);
  data.append("UserType", schema?.userType);
  data.append("Culture", 'Ar');
  data.append("DocType", schema?.DocType);
  Array.from(schema.Files).forEach(file => {
    data.append("Files", file);
  })

  return client.post(`${endpoint}/admin/register`, data)
}

export default {
    signIn,
    signUp,
    dashboardSignup
} 