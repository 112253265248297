import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardHeader,
  CardBody,
} from "reactstrap";
import ModalPopup from "components/common/CustomModalPopup";
import TextPopup from "components/common/TextModal";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import content from "./content";
import costCenter from "api/costCenter";
import useApi from "hooks/useApi";
import Spinner from "components/Spinner";
import useAlert from "hooks/useAlert";
import env from "env";
import { useForm, Controller } from "react-hook-form";
import commonContent from "components/common/content";
import usePermissions from "hooks/usePermissions";

const CostCenter = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const headers = [
    content.actions[culture],
    content.name[culture],
    content.costCenterMasterId[culture],
    content.costCenterType[culture],
  ];

  const columns = ["name", "costCenterMaster.name", "costCenterTypeText"];

  const checkPermission = usePermissions("CostCenter");

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [spinner, setSpinner] = useState(false);

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  // apis
  const costCenterApi = useApi(costCenter.getCostCenters);
  const createCostCenterApi = useApi(costCenter.createCostCenter);
  const editCostCenterApi = useApi(costCenter.updateCostCenter);
  const deleteCostCenterApi = useApi(costCenter.deleteCostCenter);

  const editResponse = (row) => {
    row.costCenterTypeText =
      env.costCenterType[row.costCenterType - 1][culture];
    return row;
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm();

  const {
    handleSubmit: edithandleSubmit,
    formState: { errors: editErrors },
    control: editControl,
    setValue: editSetValue,
    reset: editReset,
  } = useForm();

  const createCostCenter = async (formData) => {
    setSpinner(true);
    const res = await createCostCenterApi.request(formData);
    if (res.status === 200) {
      res.data.data = editResponse(res.data.data);
      setData((d) => [res.data.data, ...d]);
      reset();
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const handleEdit = (obj) => {
    setRowId(obj.id);
    editSetValue("name", obj.name);
    editSetValue("creatorName", obj.creatorName);
    editSetValue("costCenterMasterId", obj.costCenterMasterId);
    editSetValue("costCenterType", obj.editSetValue);
    setEditModal(true);
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const handleCancel = () => {
    setRowId("");
    setEditModal(false);
    setDeleteModal(false);
  };

  const editCostCenter = async (formData) => {
    setEditModal(false);
    setSpinner(true);
    const res = await editCostCenterApi.request(rowId, formData);

    if (res.status === 200) {
      const newData = [...data];
      const index = newData.findIndex((item) => item.id === rowId);
      newData[index].name = formData.name;
      setData(newData);
      editReset();
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const deleteCostCenter = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteCostCenterApi.request(rowId);
    if (res.status === 200) {
      const newData = data.filter((item) => item.id !== rowId);
      setData(newData);
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const getCostCenters = async () => {
    const res = await costCenterApi.request(1, 50);
    if (res.status === 200) {
      res.data.data.forEach(editResponse);
      setData(res.data.data);
    }
  };

  const renderEdit = () => checkPermission("UpdateAsync");
  const renderDelete = () => checkPermission("DeleteAsync");

  useEffect(() => {
    getCostCenters();
  }, []);

  const costCenterType = watch("costCenterType");
  useEffect(() => {
    if (costCenterType === 1) setValue("costCenterMasterId", null);
  }, [costCenterType]);
  const branchedFrom = data.filter((branch) => branch.costCenterType === 1);

  if (costCenterApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {costCenterApi.errorAlert}
      {createCostCenterApi.errorAlert}
      {editCostCenterApi.errorAlert}
      {deleteCostCenterApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.title[culture]}</h3>
            </div>
          </CardHeader>
          {checkPermission("CreateAsync") && (
            <CardBody>
              <form onSubmit={handleSubmit(createCostCenter)}>
                <Row className="align-items-center">
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        {content.name[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: `${content.name[culture]} field is required`,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="name"
                            type="text"
                            className={errors.name && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="accountNature"
                      >
                        {content.costCenterType[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="costCenterType"
                        rules={{
                          required: `${content.costCenterType[culture]} field is required`,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="costCenterType"
                            type="select"
                            onChange={({ target: { value } }) => {
                              onChange(parseInt(value));
                            }}
                            name="costCenterType"
                            className={errors.costCenterType ? "error" : ""}
                            value={parseInt(value) || "disabled"}
                          >
                            <option disabled value="disabled">
                              --{content.selectOption[culture]}--
                            </option>
                            {env.costCenterType.map((type, index) => (
                              <option key={index + 1} value={index + 1}>
                                {type[`${culture}`]}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="exampleFormControlSelect1">
                        {content.costCenterMasterId[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="costCenterMasterId"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="costCenterMasterId"
                            type="select"
                            disabled={watch("costCenterType") !== 2}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            name="costCenterMasterId"
                            className={errors.costCenterMasterId ? "error" : ""}
                            value={value || "disabled"}
                          >
                            <option disabled value="disabled">
                              --{content.selectOption[culture]}--
                            </option>
                            {branchedFrom.map((type, index) => (
                              <option key={index} value={type.id}>
                                {type.name}
                              </option>
                            ))}
                          </Input>
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="creatorName"
                      >
                        {content.creatorName[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="creatorName"
                        rules={{
                          required: `${content.creatorName[culture]} field is required`,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="creatorName"
                            type="text"
                            className={errors.creatorName && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className={`text-md-${commonContent.align[culture]}`}>
                    <Button
                      className="mr-2"
                      color="info"
                      size="md"
                      type="submit"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span>{content.add[culture]}</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          )}
        </Card>
        <Card>
          <Table
            data={data}
            headers={headers}
            columns={columns}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            renderEdit={renderEdit}
            renderDelete={renderDelete}
          >
            <span className="cmr-2" fun="handleEdit" condition="renderEdit">
              <div id="edit1" className="table-action cursor-pointer">
                <i className="fas fa-pencil-alt fa-lg hover-info"></i>
              </div>
              <UncontrolledTooltip delay={0} target="edit1">
                {content.edit[culture]}
              </UncontrolledTooltip>
            </span>
            <span className="cmr-2" fun="handleDelete" condition="renderDelete">
              <div
                id="delete"
                className="table-action-delete table-action cursor-pointer hover-danger"
              >
                <i className="fas fa-trash fa-lg"></i>
              </div>
              <UncontrolledTooltip delay={0} target="delete">
                {content.delete[culture]}
              </UncontrolledTooltip>
            </span>
          </Table>
          <CardBody>
            <Button color="info" size="sm" type="button" className="cml-2">
              <span className="btn-inner--icon me-1">
                <i className="fas fa-print" />
              </span>
              <span className="btn-inner--text">{content.print[culture]}</span>
            </Button>
          </CardBody>
        </Card>
      </Container>
      <ModalPopup
        modal={editModal}
        text={content.edit[culture]}
        handleCancel={handleCancel}
        fn={edithandleSubmit(editCostCenter)}
        color="info"
      >
        <Container>
          <h2>{content.editPopup[culture]}</h2>
        </Container>
        <div className="modal-body">
          <Row className="align-items-center">
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="edit-name">
                  {content.name[culture]}
                </label>
                <Controller
                  control={editControl}
                  name="name"
                  rules={{
                    required: `${content.name[culture]} field is required`,
                  }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="edit-name"
                      type="text"
                      className={editErrors.name && "error"}
                      value={value || ""}
                      onChange={({ target: { value } }) => onChange(value)}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label htmlFor="edit-">
                  {content.costCenterMasterId[culture]}
                </label>
                <Controller
                  control={editControl}
                  name="costCenterMasterId"
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      id="edit-costCenterMasterId"
                      type="select"
                      onChange={({ target: { value } }) => onChange(value)}
                      name="costCenterMasterId"
                      className={editErrors.costCenterMasterId ? "error" : ""}
                      value={value || "disabled"}
                    >
                      <option disabled value="disabled">
                        --{content.selectOption[culture]}--
                      </option>
                      {data.map((type, index) => (
                        <option key={index} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </Input>
                  )}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="accountNature">
                  {content.costCenterType[culture]}
                </label>
                <Controller
                  control={editControl}
                  name="costCenterType"
                  rules={{
                    required: `${content.costCenterType[culture]} field is required`,
                  }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      id="edit-costCenterType"
                      type="select"
                      onChange={({ target: { value } }) => {
                        onChange(parseInt(value));
                      }}
                      name="costCenterType"
                      className={editErrors.costCenterType ? "error" : ""}
                      defaultValue={value}
                      value={parseInt(value) || "disabled"}
                    >
                      <option disabled value="disabled">
                        --{content.selectOption[culture]}--
                      </option>
                      {env.costCenterType.map((type, index) => (
                        <option key={index + 1} value={index + 1}>
                          {type[`${culture}`]}
                        </option>
                      ))}
                    </Input>
                  )}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="creatorName">
                  {content.creatorName[culture]}
                </label>
                <Controller
                  control={editControl}
                  name="creatorName"
                  rules={{
                    required: `${content.creatorName[culture]} field is required`,
                  }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="edit-creatorName"
                      type="text"
                      className={editErrors.creatorName && "error"}
                      value={value || ""}
                      onChange={({ target: { value } }) => onChange(value)}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </ModalPopup>
      <TextPopup
        modal={deleteModal}
        text={content.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteCostCenter}
        color="danger"
      >
        <Container>
          <h2>{content.deletePopup[culture]}</h2>
        </Container>
      </TextPopup>
    </>
  );
};

export default CostCenter;
