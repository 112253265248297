export default {
  title: {
    ar: 'تعديل بيانات الحساب المصرفي',
    en: 'Editing bank account information'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account Number'
  },
  currentnotebook: {
    ar: 'الدفتر الحالي',
    en: 'Current notebook'
  },
  dimensions: {
    ar: 'الابعاد',
    en: 'dimensions'
  },
  save: {
    ar: 'حفظ',
    en: 'Save'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
}