import React, { useState, useEffect } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  CardBody,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import Spinner from "components/Spinner";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import { useForm, Controller } from "react-hook-form";
import commonContent from "components/common/content";
import PopupTreasuryTable from "components/common/FilterEntries";
import content from "./content";
import useCreateEntry from "Screen/Transaction/CreateEntry/useCreateEntry";
import useApi from "hooks/useApi";
import entry from "api/entry";
import env from "env";

// import EditTreasuryReceipt from "./components/EditFinancialAuditors";

const TreasuryReceipt = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const headers = [
    content.actions[culture],
    content.treasury[culture],
    content.client[culture],
    content.value[culture],
  ];

  const columns = ["", "", ""];

  // const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [clientModal, setClientModal] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [accountClassification, setAccountClassification] = useState();

  const { alert, sweetAlert } = useAlert();

  const createEntryApi = useApi(entry.createEntry);
  const createAndConfirmApi = useApi(entry.createAndConfirm);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const { data, preloadApi, spinner, preloadEntry, setSpinner } =
    useCreateEntry(sweetAlert, reset);

    const modifyData = (data) => {
      return {
        ...data,
        entryType:
          env.entryType.findIndex((entry) => entry["en"] === "Customer Payment") + 1,
        // entryType: 8,
        entryDetails: [
          {
            accountId: data.debtorId,
            debit: data.debit,
            credit: 0,
            note: "",
          },
          {
            accountId: data.creditorId,
            debit: 0,
            credit: data.debit,
            note: "",
          },
        ],
      };
    };

  const createFinancial = async (data) => {
    setSpinner(true);
    const schema = modifyData(data);

    const res = await createEntryApi.request(schema);
    if (res.status === 200) {
      sweetAlert(content.done[culture]);
      reset();
    }
    setSpinner(false);
  };

  const handleSaveAndConfirm = async (data) => {
    setSpinner(true);
    const schema = modifyData(data);

    const res = await createAndConfirmApi.request(schema);
    if (res.status === 200) {
      sweetAlert(content.done[culture]);
      reset();
    }
    setSpinner(false);
  };

  const handleCancel = () => {
    setClientModal(false);
    setRowId("");
    setFieldName("");
  };

  useEffect(() => {
    preloadEntry();
  }, []);

  if (preloadApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <div>
      {alert}
      {createEntryApi.errorAlert}
      {createAndConfirmApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <h3 className={`mb-0 text-md-left`}>
              {content.pageTitle[culture]}
            </h3>
          </div>
        </CardHeader>
        <form onSubmit={handleSubmit(createFinancial)}>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <Col md="6" lg="3">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="currency">
                      {content.currency[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="currency"
                      rules={{ required: `This field is required` }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="currency"
                          type="select"
                          className={errors.currency && "error"}
                          onChange={({ target: { value } }) => onChange(+value)}
                          onWheel={(e) => e.target.blur()}
                          value={value || "disabled"}
                        >
                          <option disabled value="disabled">
                            -- {content.selectOption[culture]} --
                          </option>
                          {data?.currency?.map((item, index) => (
                            <option value={index + 1} key={index}>
                              {commonContent[item][culture]}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="3">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="date">
                      {commonContent.costCenter[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="costCenterId"
                      rules={{ required: `This field is required` }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="costCenterId"
                          type="select"
                          value={value || ""}
                          className={errors.costCenterId && "error"}
                          onChange={({ target: { value } }) => onChange(value)}
                        >
                          <option disabled value="">
                            -- {commonContent.selectOption[culture]} --
                          </option>
                          {data?.costCenters?.map((cost) => (
                            <option key={cost.id} value={cost.id}>
                              {cost.name}
                            </option>
                          ))}
                        </Input>
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="3">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="date">
                      {content.entryDate[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="date"
                      rules={{ required: `This field is required` }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="date"
                          type="date"
                          value={value || ""}
                          className={errors.date && "error"}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="statement">
                      {content.statement[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="statement"
                      rules={{ required: `This field is required` }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="statement"
                          type="textarea"
                          value={value || ""}
                          className={errors.statement && "error"}
                          onChange={({ target: { value } }) => onChange(value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row className="align-items-center">
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="client">
                      {content.client[culture]} ({content.creditor[culture]})
                    </label>
                    <Controller
                      control={control}
                      name="creditorAccount"
                      rules={{
                        required: `${content.client[culture]} field is required`,
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="client"
                          type="text"
                          placeholder={content.name[culture]}
                          className={errors.name && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onClick={() => {
                            setFieldName("creditor");
                            setAccountClassification(4);
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="treasury">
                      {content.treasury[culture]} ({content.debtor[culture]})
                    </label>
                    <Controller
                      control={control}
                      name="debtorAccount"
                      rules={{
                        required: `${content.name[culture]} field is required`,
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="treasury"
                          type="text"
                          placeholder={content.treasury[culture]}
                          className={errors.debtorAccount && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(value)}
                          onClick={() => {
                            setFieldName("debtor");
                            setAccountClassification(2);
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="debit">
                      {content.value[culture]}
                    </label>
                    <Controller
                      control={control}
                      name="debit"
                      rules={{
                        required: `${content.name[culture]} field is required`,
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        },
                      }}
                      render={({
                        field: { ref, onChange, value, ...field },
                      }) => (
                        <Input
                          {...field}
                          id="debit"
                          type="number"
                          placeholder={content.value[culture]}
                          className={errors.name && "error"}
                          value={value || ""}
                          onChange={({ target: { value } }) => onChange(+value)}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Button className="mr-2" color="info" size="md" type="submit">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>{commonContent.save[culture]}</span>
              </Button>
              <Button
                className="mr-2"
                color="secondary"
                size="md"
                type="button"
                onClick={handleSubmit(handleSaveAndConfirm)}
              >
                <span>{commonContent.saveAndConfirm[culture]}</span>
              </Button>
            </CardBody>
          </Card>
        </form>
      </Container>
      <TextPopup
        modal={fieldName}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <PopupTreasuryTable
            accountClassification={accountClassification}
            setValue={setValue}
            sweetAlert={sweetAlert}
            fieldName={fieldName}
            setFieldName={setFieldName}
          />
        </Container>
      </TextPopup>
    </div>
  );
};

export default TreasuryReceipt;
