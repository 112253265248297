import { useState } from "react";
import { useHistory } from "react-router-dom";
import content from "./content";
import entry from "api/entry";
import useApi from "hooks/useApi";
import useCulture from "hooks/useCulture";

const useCreateEntry = (sweetAlert, reset, rowReset) => {
  const culture = useCulture();
  const history = useHistory();

  const [data, setData] = useState({});
  const [rowId, setRowId] = useState(0);
  const [schema, setSchema] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [accountModal, setAccountModal] = useState(false);

  // Api
  const preloadApi = useApi(entry.preload);
  const createEntryApi = useApi(entry.createEntry);
  const createAndConfirmEntryApi = useApi(entry.createAndConfirm);

  const handleAccount = () => {
    setAccountModal(true);
  };

  const handleCancel = () => {
    setRowId("");
    setDeleteModal(false);
    setAccountModal(false);
  };

  const preloadEntry = async () => {
    const res = await preloadApi.request();

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  const addRow = (data) => {
    setSchema((oldSchema) => [...oldSchema, { id: oldSchema.length, ...data }]);
    rowReset();
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const deleteRow = () => {
    setDeleteModal(false);
    setSchema((oldSchema) => oldSchema.filter((row) => row.id !== rowId));
  };

  const arrangeEntryBody = (data) => {
    const sentData = {
      ...data,
      entryDetails: [],
    };

    schema.forEach((row) => {
      sentData.entryDetails.push({
        accountId: row.Id,
        debit: row.debit || 0,
        credit: row.credit || 0,
        note: row.note,
      });
    });

    return sentData;
  };

  const createEntry = async (data) => {
    setSpinner(true);
    const sentData = arrangeEntryBody(data);
    const res = await createEntryApi.request(sentData);
    if (res.status === 200) {
      sweetAlert(content.done[culture]);
      reset();
      rowReset();
      setSchema([]);
    }
    setSpinner(false);
  };

  const createAndConfirmEntry = async (data) => {
    setSpinner(true);
    const sentData = arrangeEntryBody(data);
    const res = await createAndConfirmEntryApi.request(sentData);
    if (res.status === 200) {
      sweetAlert(content.done[culture]);
      // reset fields
      reset();
      rowReset();
      setSchema([]);
      history.push(`/${culture}-admin/operations/journalentry`);
    }
    setSpinner(false);
  };

  return {
    data,
    rowId,
    deleteModal,
    schema,
    spinner,
    preloadApi,
    createEntryApi,
    createAndConfirmEntryApi,
    createAndConfirmEntry,
    setSpinner,
    handleCancel,
    preloadEntry,
    addRow,
    handleDelete,
    deleteRow,
    createEntry,
    handleAccount,
    accountModal,
    setAccountModal,
  };
};

export default useCreateEntry;
