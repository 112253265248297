import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from 'components/common/CustomTable';
import Spinner from 'components/Spinner';
import TextPopup from 'components/common/TextModal';
import RequestHandler from "helper/RequestHandler";
import axios from 'axios';
import env from "env";
import catchAsync from 'helper/catchAsync';
import ReactBSAlert from "react-bootstrap-sweetalert";

const CategoryDetails = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState({});
  const [alert, setAlert] = useState(null);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [spinner, setSpinner] = useState(false);

  const params = useParams();
  
  const headers = [
    content.accountNumber[culture],
    content.name[culture],
    content.accountNature[culture],
    content.accountCategory[culture],
    content.accountingLevel[culture],
    content.group[culture],
    content.analytical[culture],
    content.actions[culture],
  ];

  const data2 = [
    { id: 1, accountNumber: 1, name: 'باب المرتبات', accountNature: 'مدين', accountCategory: 'اصل', accountingLevel: 'باب رئيسي', group: 'لايوجد', analytical: 'لا'},
    { id: 2, accountNumber: 1, name: 'المرتبات الرئيسية', accountNature: 'مدين', accountCategory: 'اصل', accountingLevel: 'باب رئيسي', group: 'لايوجد', analytical: 'لا'},
  ];

  const columns = [
    "number",
    "name",
    "accountNature",
    "accountClassificaiton",
    "accountingLevel",
    "group",
    "isAnalytical",
  ]

  const handleCancel = () => {
    setId('');
    setName('');
    setDeleteModal(false);
  }

  const handleDelete = obj => {
    console.log(obj);
    setDeleteModal(true);
    setId(obj.id);
    setName(obj.name);
  }

  const deleteAccount = () => catchAsync(async () => {
    setDeleteModal(false);
    setSpinner(true);
    const result = await axios.delete(`${env.activeServer()}/AccountCategories/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    if (result.status === 200) {
      const updatedData = [...data].filter(item => item.id !== id)
      setData(updatedData);

      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => setAlert('')}
          onCancel={() => setAlert('')}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
          btnSize=""
        >
          {result.statusText}
        </ReactBSAlert>
      )
    }
  }, () => { setSpinner(false) }, setAlert)

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/AccountCategories/Details/${params.id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })
          if (result.status === 200) {
            result.data.data.accounts.forEach((account, index) => {
              result.data.data.accounts[index].isAnalytical = account.isAnalytical ? content.true[culture]: content.false[culture];
            })
            setData(result.data.data);
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      /> : <>
        <SimpleHeader name={content.title[culture]} parentName="" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
            </CardHeader>
            <CardBody>
              <Row className='align-items-center'>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.categoryName[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="text"
                      value={data.name}
                      disabled
                      onChange={e => {}}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Button 
                  className="mr-2" 
                  color="primary" 
                  size="md" 
                  onClick={() => {}}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-add" />
                  </span>
                  <span>
                    {content.linkNewAccount[culture]}
                  </span>
                </Button>
              </Row>
              <h5 className='mt-5'>{content.linkedAccounts[culture]}</h5>
            </CardBody>
            <Table 
              headers={headers}
              columns={columns}
              data={data.accounts}
              handleDelete={handleDelete}
            >
              <span to={`/${culture}-admin/accounting/main`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="fas fa-info-circle fa-lg hover-warning"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="details1"
                >
                  {content.details[culture]}
                </UncontrolledTooltip>
              </span>
              <span className="mr-2 mr-3 ml-3" fun="handleDelete">
                <div id="delete" className="table-action-delete table-action cursor-pointer hover-danger">
                  <i className="fas fa-trash fa-lg"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="delete"
                >
                  {content.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Card>
        </Container>
        <TextPopup
          modal={deleteModal}
          text={content.delete[culture]}
          handleCancel={handleCancel}
          fn={deleteAccount}
          color='danger'
        >
          <Container>
              <h2>{content.deletePopup[culture]}</h2>
          </Container>
        </TextPopup>
      </>}
    </div>
  )
}

export default CategoryDetails
