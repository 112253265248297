export default {
  categoryName: {
    ar: 'المحاسبة',
    en: 'Accounting',
  },
  title: {
    ar: 'الميزانية',
    en: 'Balance',
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  fiscalYear: {
    ar: 'السنة المالية',
    en: 'Fiscal year'
  },
  add: {
    ar: 'اضافة',
    en: 'Add'
  },
  calculate: {
    ar: 'إحتساب',
    en: 'Calculate'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  accountNo: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  name: {
    ar: 'الاسم',
    en: 'Name'
  },
  followTo: {
    ar: 'يتبع الى',
    en: 'Follow to'
  },
  test: {
    ar: 'تجربة',
    en: 'Test'
  },  
  accountClass: {
    ar: 'صنف الحساب',
    en: 'Account class'
  },
  accountNature: {
    ar: 'طبيعة الحساب',
    en: 'Account nature'
  },
  accountingLevel: {
    ar: 'المستوى المحاسبي',
    en: 'Accounting level'
  },  
  defaultCreditorAccount: {
    ar: 'حساب الدائن الافتراضي',
    en: 'Default creditor account'
  },
  category: {
    ar: 'التصنيف',
    en: 'Category'
  },
  bank: {
    ar: 'المصرف',
    en: 'Bank'
  },
  contract: {
    ar: 'الغقد',
    en: 'Contract'
  },
  custodyHolder: {
    ar: 'أمين العهدة',
    en: 'Custody holder'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  roads: {
    ar: 'طرق',
    en: 'Roads'
  },
  bridges: {
    ar: 'الجسور',
    en: 'Bridges'
  },
  employee: {
    ar: 'الموظفين',
    en: 'Employees'
  },
  branches: {
    ar: 'الفروع',
    en: 'Branches'
  },
  branch: {
    ar: 'الفرع',
    en: 'Branch'
  },
  bankAccounts: {
    ar: 'حسابات مصرفية',
    en: 'Bank accounts'
  },
  save: {
    ar: 'حفظ',
    en: 'Save'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  Credit: {
    ar: 'دائن',
    en: 'Credit'
  },
  Debit: {
    ar: 'مدين',
    en: 'Debit'
  },
  Unknown: {
    ar: 'غير معروف',
    en: 'Unknown'
  },
  phoneNumber: {
    ar: 'رقم الهاتف',
    en: 'Phone number'
  },
  customer: {
    ar: 'العميل',
    en: 'Customer'
  },
  done: {
    ar: 'تمت العملية بنجاح',
    en: 'Done'
  },
  account: {
    ar: 'حساب',
    en: 'Account'
  },
  accountsTree: {
    ar: 'شجرة الحسابات',
    en: 'Accounts tree'
  },
  deletePopup: {
    ar: 'هل انت متأكد من انك تريد حذف هذا الحساب ؟',
    en: 'Are you sure you want to delete this account ?'
  },
  viewExpenses: {
    ar: "عرض المصروفات",
    en: "View Expenses"
  }
}