/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// router
import {
  //BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import getCulture from "utils/getCulture";
import content from "./content";

function CardsHeader({ name, parentName, data }) {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const sizeByType = (type) => {
    if (!data.data) return 0;
    const date = new Date();
    const thisMonth = date.getMonth() + 1;
    let size = 0;
    try {
      data.data.forEach((item) => {
        if (item._id.type === type && item._id.month === thisMonth) {
          size += item.size;
        }
      });
    } catch (e) {}
    return Math.round(size * 100) / 100;
  };

  const getPercentage = (type) => {
    if (fullSize <= 0) return 0;

    const size = sizeByType(type);

    //get all size except this month with this type
    const fullSize = data.fullSize - size;

    if (size > fullSize) return 100;

    const percentage = (size / fullSize) * 100;
    return Math.round(percentage * 100) / 100;
  };

  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {content.accountsNo[culture]}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {data.airCount || 4}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="far fa-user" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" />
                        {data.parcents?.[2]?.parcent || 6}%
                      </span>{" "}
                      <span className="text-nowrap">
                        {content.sinceLastMonth[culture]}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {content.mainTreasuryBalance[culture]}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {data.lClCount || 5}
                        </span>
                      </div>
                      <Col className="col-auto">
                        {/* bg-gradient-orange */}
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="fas fa-money-check-alt" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" />{" "}
                        {data.parcents?.[0].parcent || 2}%
                      </span>{" "}
                      <span className="text-nowrap">
                        {content.sinceLastMonth[culture]}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {content.chinaTreasuryBalance[culture]}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {data.usersCompanyCount || 12}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="fas fa-yen-sign" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" />{" "}
                        {getPercentage("personal") || 30}%
                      </span>{" "}
                      <span className="text-nowrap">
                        {content.chinaTreasuryBalance[culture]}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {content.shipmentValues[culture]}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {data.usersPersnoalCount || 25}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                          <i className="fas fa-wallet" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" />{" "}
                        {getPercentage("share") || 84}%
                      </span>{" "}
                      <span className="text-nowrap">
                        {content.sinceLastMonth[culture]}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
