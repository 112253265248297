import React, { useRef, useState, useEffect } from "react";

// router
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useRouteMatch,
  useHistory,
  // useParams
} from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  // Label,
  FormGroup,
  //   Form,
  Input,
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
} from "reactstrap";
//content
import content from "./content";

//common
import commonContent from "components/common/content";

// Table
import Tables from "components/common/CustomTable";

// core components
import SimpleHeader from "components/Headers/SimpleHeader";

import catchAsync from "helper/catchAsync";
import env from "env";
import axios from "axios";

import RequestHanlder from "helper/RequestHandler";

import Spinner from "components/Spinner";
import Pagination from "components/common/Pagination";

import { Typeahead } from "react-bootstrap-typeahead";
import OneButton from "components/common/oneButton";
import ModalToPrint from "./ModalToPrint";
import BalanceCard from "components/common/BalanceCard";
import useApi from "hooks/useApi";
import fiscalYear from "api/fiscalYear";
import { useForm, Controller } from "react-hook-form";
import account from "api/account";

function ClientCredit() {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const headers = [
    content.customerAccountId[culture],
    content.code[culture],
    `${content.debit[culture]} ($)`,
    `${content.credit[culture]} ($)`,
    `${content.balance[culture]} ($)`,
    `${content.debit[culture]} (${content.lyd[culture]})`,
    `${content.credit[culture]} (${content.lyd[culture]})`,
    `${content.balance[culture]} (${content.lyd[culture]})`,
  ];

  const columns = [
    "accountName",
    "customerCode",
    "totalDebitUSD",
    "totalCreditUSD",
    "balanceUSD",
    "totalDebitLYD",
    "totalCreditLYD",
    "balanceLYD",
  ];

  var token = localStorage.getItem(btoa("token"));

  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };


  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm();

  const fiscalYearid = watch("fiscalYearid");

  //data table
  const [years, setYears] = useState([]);
  const [data, setData] = useState({});
  const [datafinal, setDataFinal] = useState([]);

  const [toggleP, setToggleP] = useState(false);
  const [PrintableData, setPrintableData] = useState("");

  //alert message
  const [alert, setAlert] = useState(null);

  //loading for edit delete
  const [action, setAction] = useState(false);

  //loading for the get request
  const [loading, setLoading] = useState(true);

  const ref = useRef();

  let variables = {
    fiscalYearid: useState(""),
    fiscalYearList: useState([]),
    balance: useState(""),

    limit: useState(0),
    total: useState(0),
    page: useState(0),
    pages: useState(0),
  };

  function setter(key, val) {
    variables[key][1](val);
  }

  const fiscalYearApi = useApi(fiscalYear.getFiscalYears);
  const customerSumReportApi = useApi(account.customerSumReport);

  const getFiscalYears = async () => {
    const res = await fiscalYearApi.request(1, 100);
    console.log({ res });
    if (res.status === 200) {
      setYears(res.data.data);
    }
  };

  const handleSearch = async () => {
    setAction(true);
    const res = await customerSumReportApi.request(fiscalYearid);
    console.log({ res });
    if (res.status === 200) {
      setData(res.data.data);
    }
    setAction(false);
  };

  const handlePrint = () => {
    setToggleP(true);
  };

  useEffect(() => {
    getFiscalYears();
  }, []);

  if (fiscalYearApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {fiscalYearApi.errorAlert}
      {customerSumReportApi.errorAlert}
      {action ? <Spinner opacity={1} bg="#fff" gate="#42ba96" /> : null}
      <SimpleHeader name="Form elements" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 classyear={`mb-0 text-md-${commonContent.alignt[culture]}`}>
              {content.title[culture]}
            </h3>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(handleSearch)}>
              <Row>
                <Col md="4" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.year[culture]}
                    </label>

                    <FormGroup>
                      <Typeahead
                        ref={ref}
                        id="myCheck"
                        labelKey={(option) => option.year.toString()}
                        onChange={(en) => {
                          en.map((e) => setValue("fiscalYearid", e.id));
                        }}
                        options={years}
                        placeholder={commonContent.search[culture]}
                      />
                    </FormGroup>
                  </FormGroup>
                </Col>
                <Col className="align-self-center">
                  <Button className="mt-2" color="info" size="md" type="submit">
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-search me-1" />
                    </span>
                    <span>{commonContent.search[culture]}</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        {datafinal.length != 0 ? (
          <>
            <Card className="mb-4">
              <CardHeader>
                <h3 classyear={`mb-0 text-md-${commonContent.alignt[culture]}`}>
                  {content.balanceDetails[culture]}
                </h3>
              </CardHeader>
              <CardBody>
                <BalanceCard culture={culture} list={variables.balance[0]} />
              </CardBody>
            </Card>
          </>
        ) : (
          <></>
        )}
        <Card>
          <Tables headers={headers} columns={columns} data={data.customersSums} pageName={content.title[culture]} />

          {/* <ModalToPrint
            toggleP={toggleP}
            setToggleP={setToggleP}
            culture={culture}
            // data={PrintableData}
            data={data}
          />
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 30,
              paddingBottom: 30
            }}
          >
            <OneButton
              Function={handlePrint}
              textBtn1={commonContent.print[culture]}
              culture={culture}
              noIcon
            />
          </Row> */}
        </Card>
      </Container>
    </>
  );
}

export default ClientCredit;

{
  /* <table class="table" style={{ justifyContent: "center" }}>
<>
  <thead>
    <tr>
      <th scope="col">
        {content.customerAccountId[culture]}
      </th>
      <th scope="col" style={{ textAlign: "center" }}>
        {content.code[culture]}
      </th>
      <th scope="col" style={{ textAlign: "center" }}>
        <tr scope="row" style={{ textAlign: "center" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {content.dollar[culture]}
          </span>
        </tr>
        <th scope="col" style={{ textAlign: "center" }}>
          {content.debit[culture]}
        </th>
        <th scope="col" style={{ textAlign: "center" }}>
          {content.credit[culture]}
        </th>
        <th scope="col" style={{ textAlign: "center" }}>
          {content.balance[culture]}
        </th>
      </th>
      <th scope="col">
        <span
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {content.LYD[culture]}
        </span>
        <th scope="col" style={{ textAlign: "center" }}>
          {content.debit[culture]}
        </th>
        <th scope="col" style={{ textAlign: "center" }}>
          {content.credit[culture]}
        </th>
        <th scope="col" style={{ textAlign: "center" }}>
          {content.balance[culture]}
        </th>
      </th>
    </tr>
  </thead>
  <tbody>
    {datafinal.map((e) => (
      <tr scope="row">
        <td
          style={{
            textAlign: "center",
          }}
        >
          {e.accountName}
        </td>
        <td
          style={{
            textAlign: "center",
          }}
        >
          {e.customerCode}
        </td>
        <tr scope="row" style={{ textAlign: "center" }}>
          <td
            style={{
              textAlign: "center",
            }}
          >
            {e.totalDebitUSD}
          </td>
          <td
            style={{
              textAlign: "center",
            }}
          >
            {e.totalCreditUSD}
          </td>
          <td
            style={{
              textAlign: "center",
            }}
          >
            {e.balanceUSD}
          </td>
        </tr>
        <tr scope="row" style={{ textAlign: "center" }}>
          <td
            style={{
              textAlign: "center",
            }}
          >
            {e.totalDebitLYD}
          </td>
          <td
            style={{
              textAlign: "center",
            }}
          >
            {e.totalCreditLYD}
          </td>
          <td
            style={{
              textAlign: "center",
            }}
          >
            {e.balanceLYD}
          </td>
        </tr>
      </tr>
    ))}
  </tbody>
</>
</table> */
}
