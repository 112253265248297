import { useRouteMatch, useHistory } from "react-router-dom";
import getCulture from "utils/getCulture";

const content = {
  done: {
    ar: "تمت العملية بنجاح",
    en: "Done",
  },
};

const useDelete = (data, setData, setSpinner, setModal, sweetAlert) => {
  const rout = useRouteMatch();
  const history = useHistory();
  const culture = getCulture(rout.url);

  const deleteHandler = async (apiFun, id) => {
    setSpinner(true);
    setModal(false);
    const res = await apiFun.request(id);
    if (res.status === 200) {
      if (Array.isArray(data)) {
        const newData = data.filter((item) => item.id !== id);
        setData(newData);
      } else if (Array.isArray(data.data)) {
        const newData = data.data.filter((item) => item.id !== id);
        setData((d) => ({ ...d, data: newData }));
      } else {
        history.go(-1);
      }
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  return deleteHandler;
};

export default useDelete;
