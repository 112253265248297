import React, { useState, useEffect, useContext } from "react";
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
//content
import content from "./content";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import ModalPopup from "components/common/CustomModalPopup";
import Pagination from "components/common/Pagination";
import TextPopup from "components/common/TextModal";
import Spinner from "components/Spinner";
import categories from "../../../api/categories";
import useAlert from "hooks/useAlert";
import useApi from "hooks/useApi";
import usePagination from "hooks/usePagination";
import useSearchPopup from "hooks/useSearchPopup";
import { PaginationContext } from "context/PaginationContext";
import SearchModal from "components/common/SearchModal";
import commonContent from "components/common/content";
import usePermissions from "hooks/usePermissions";

const Categories = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const headers = [
    content.actions[culture],
    content.name[culture],
    content.createdOn[culture],
  ];

  const columns = ["name", "createdOn"];

  const checkPermission = usePermissions("AccountCategory");

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [spinner, setSpinner] = useState(false);

  const paginationCtx = useContext(PaginationContext);

  const pageName = "categories";

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();
  // apis
  const categoriesApi = useApi(categories.categories);
  const getByColumn = useApi(categories.getByColumn);
  const createCategoryApi = useApi(categories.createCategory);
  const editCategoryApi = useApi(categories.editCategory);
  const deleteCategoryApi = useApi(categories.deleteCategory);

  const editResponse = (item) => {
    item.createdOn = item.createdOn.split("T")[0];
    return item;
  };
  // search popup
  const searchPopup = useSearchPopup(
    getByColumn,
    headers,
    columns,
    data.data,
    editResponse
  );

  const {
    pageNo,
    setPageNo,
    docsPerPage,
    setDocsPerPage,
    search,
    setSearch,
    prevPageCheck,
  } = usePagination("");

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const {
    handleSubmit: edithandleSubmit,
    formState: { errors: editErrors },
    control: editControl,
    setValue: editSetValue,
    reset: editReset,
  } = useForm();

  const updateData = (arr) => {
    setData(arr);
  };

  const createCategory = async (formData) => {
    setSpinner(true);
    const res = await createCategoryApi.request(formData);
    if (res.status === 200) {
      res.data.data = editResponse(res.data.data);
      setData((d) => [res.data.data, ...d]);
      reset();
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const handleEdit = (obj) => {
    setRowId(obj.id);
    editSetValue("name", obj.name);
    setEditModal(true);
  };

  const editCategory = async (formData) => {
    setEditModal(false);
    setSpinner(true);
    const res = await editCategoryApi.request(rowId, formData);

    if (res.status === 200) {
      const newData = [...data];
      const index = newData.findIndex((item) => item.id === rowId);
      newData[index].name = formData.name;
      setData(newData);
      editReset();
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const handleCancel = () => {
    setRowId("");
    setEditModal(false);
    setDeleteModal(false);
  };

  const handleDelete = (obj) => {
    setRowId(obj.id);
    setDeleteModal(true);
  };

  const deleteCategory = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteCategoryApi.request(rowId);
    if (res.status === 200) {
      const newData = data.filter((item) => item.id !== rowId);
      setData(newData);
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const handleSearch = () => {};

  const getCategories = async () => {
    const res = await categoriesApi.request(pageNo, docsPerPage);
    if (res.status === 200) {
      res.data.data = res.data.data.map((item) => editResponse(item));
      setData(res.data.data);
      console.log({ data });
    }
  };

  const renderEdit = () => checkPermission("UpdateAsync");
  const renderDelete = () => checkPermission("DeleteAsync");

  useEffect(() => {
    // if search popup has a values and it's the same page stored in the context
    // don't run these request
    // and go to the other useEffect
    if (searchPopup.isSelected && searchPopup.searchField && prevPageCheck())
      return;
    if (
      paginationCtx.searchedColumn &&
      paginationCtx.searchedContent &&
      prevPageCheck()
    )
      return;
    if (search) handleSearch();
    else getCategories();
  }, [pageNo, docsPerPage]);

  useEffect(() => {
    if (
      searchPopup.isSelected &&
      searchPopup.searchField &&
      searchPopup.openPopup
    ) {
      searchPopup.onSearch("", () => {});
    } else if (searchPopup.isSelected && searchPopup.searchField) {
      searchPopup.onSearch("", (data) => {
        updateData(data);
      });
    } else if (
      paginationCtx.searchedColumn &&
      paginationCtx.searchedContent &&
      prevPageCheck()
    ) {
      // if there's a popup search info in the context set it in the popup search
      // then set 'setIsSelected' to true so the search popup request will fire
      searchPopup.setColumn(paginationCtx.searchedColumn);
      searchPopup.setSearchField(paginationCtx.searchedContent);
      searchPopup.setPageNo(paginationCtx.pageNumber);
      searchPopup.setDocsPerPage(paginationCtx.docsPerPage);
      searchPopup.setIsSelected(true);
    } else {
      searchPopup.setIsSelected(false);
    }
  }, [searchPopup.pageNo, searchPopup.docsPerPage, searchPopup.isSelected]);

  if (categoriesApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <div>
      {alert}
      {categoriesApi.errorAlert}
      {getByColumn.errorAlert}
      {createCategoryApi.errorAlert}
      {editCategoryApi.errorAlert}
      {deleteCategoryApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          {checkPermission("CreateAsync") && (
            <CardBody>
              <form onSubmit={handleSubmit(createCategory)}>
                <Row className="align-items-center">
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        {content.name[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: `${content.name[culture]} field is required`,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="name"
                            type="text"
                            className={errors.name && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className={`text-md-${commonContent.align[culture]}`}>
                    <Button
                      className="mr-2"
                      color="info"
                      size="md"
                      type="submit"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span>{content.add[culture]}</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          )}
        </Card>
        <Card>
          <Pagination
            dataLength={data.length}
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={searchPopup.isSelected ? searchPopup.pageNo : pageNo}
            setPageNo={
              searchPopup.isSelected ? searchPopup.setPageNo : setPageNo
            }
            docsPerPage={
              searchPopup.isSelected ? searchPopup.docsPerPage : docsPerPage
            }
            setDocsPerPage={
              searchPopup.isSelected
                ? searchPopup.setDocsPerPage
                : setDocsPerPage
            }
          >
            <Table
              headers={headers}
              data={data || []}
              columns={columns}
              onHeaderClick={searchPopup.handleHeaderClicked}
              visitedRowId={paginationCtx.visitedRowId}
              // when the user was searching useing the popup and select a row
              // store the popup pagination in the context
              // otherwise store the normal pagination in the context
              setPagination={
                searchPopup.isSelected
                  ? (rowId) =>
                      paginationCtx.setPagination(
                        searchPopup.pageNo,
                        searchPopup.docsPerPage,
                        rowId,
                        pageName
                      )
                  : (rowId) =>
                      paginationCtx.setPagination(
                        pageNo,
                        docsPerPage,
                        rowId,
                        pageName
                      )
              }
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              renderEdit={renderEdit}
              renderDelete={renderDelete}
            >
              <span className="cmr-2" fun="handleEdit" condition="renderEdit">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {content.edit[culture]}
                </UncontrolledTooltip>
              </span>
              {/* <span to={`/${culture}-admin/categorydetails`}>
                  <div id="details1" className="table-action cursor-pointer">
                    <i className="fas fa-info-circle fa-lg hover-warning"></i>
                  </div>
                  <UncontrolledTooltip
                    delay={0}
                    target="details1"
                  >
                    {content.details[culture]}
                  </UncontrolledTooltip>
                </span> */}
              <span className="cmr-2" fun="handleDelete">
                <div
                  id="delete"
                  className="table-action-delete table-action cursor-pointer hover-danger"
                >
                  <i className="fas fa-trash fa-lg"></i>
                </div>
                <UncontrolledTooltip delay={0} target="delete">
                  {content.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <ModalPopup
        modal={editModal}
        text={content.edit[culture]}
        handleCancel={handleCancel}
        fn={edithandleSubmit(editCategory)}
        color="info"
      >
        <Container>
          <h2>{content.editPopup[culture]}</h2>
        </Container>
        <div className="modal-body">
          <Row className="align-items-center">
            <Col md="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="edit-name">
                  {content.name[culture]}
                </label>
                <Controller
                  control={editControl}
                  name="name"
                  rules={{
                    required: `${content.name[culture]} field is required`,
                  }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="edit-name"
                      type="text"
                      className={editErrors.name && "error"}
                      value={value || ""}
                      onChange={({ target: { value } }) => onChange(value)}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </ModalPopup>
      <TextPopup
        modal={deleteModal}
        text={content.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteCategory}
        color="danger"
      >
        <Container>
          <h2>{content.deletePopup[culture]}</h2>
        </Container>
      </TextPopup>
      {searchPopup.openPopup && (
        <SearchModal
          data={data}
          setData={setData}
          updateData={updateData}
          searchPopup={searchPopup}
          editResponse={editResponse}
          popupSearchCtx={paginationCtx.popupSearch}
          page={pageName}
        />
      )}
    </div>
  );
};

export default Categories;
