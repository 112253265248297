import React from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import content from "../content";
import useCulture from "hooks/useCulture";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import FilterEntries from "components/common/FilterEntries";
import TextPopup from "components/common/TextModal";
import useApi from "hooks/useApi";
import account from "api/account";
import Spinner from "components/Spinner";
import { useEffect } from "react";

function BalanceWithRevenue({ errors, control, setValue, watch, getValues }) {
  const culture = useCulture();
  const balanceWithRevenueApi = useApi(account.balanceWithRevenue);

  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);

  const handleCancel = () => {
    setModal(false);
  };

  const fetchData = async () => {
    const data = getValues();
    const res = await balanceWithRevenueApi.request(data.customerId);
    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  useEffect(() => {
    setData({});
  }, [watch("customerAccount")])

  return (
    <>
      {balanceWithRevenueApi.errorAlert}
      {balanceWithRevenueApi.loading && (
        <Spinner gate="#11cdef" bg="#fff" opacity />
      )}
      <Card>
        <CardHeader>
          <h3 className={`mb-0`}>
            {content.customerBalaneWithRevenue[culture]}
          </h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6" lg="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="mainTreasuryAccount"
                >
                  {content.customer[culture]}
                </label>
                <Controller
                  control={control}
                  name="customerAccount"
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="customer"
                      type="text"
                      className={errors.customerAccount && "error"}
                      value={value || ""}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                      }}
                      onClick={() => {
                        setModal(true);
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col xs="12">
              <Button
                type="button"
                disabled={!watch("customerAccount")}
                onClick={fetchData}
              >
                {content.calculate[culture]}
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="6" lg="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="mainTreasuryAccount"
                >
                  {content.balanceUSD[culture]}
                </label>
              </FormGroup>
              <Input disabled value={data.balanceUSD ?? ""} />
            </Col>
            <Col md="6" lg="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="mainTreasuryAccount"
                >
                  {content.balanceLYD[culture]}
                </label>
              </FormGroup>
              <Input disabled value={data.balanceLYD ?? ""} />
            </Col>
            <Col md="6" lg="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="mainTreasuryAccount"
                >
                  {content.balanceEuro[culture]}
                </label>
              </FormGroup>
              <Input disabled value={data.balanceEURO ?? ""} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <TextPopup
        modal={modal}
        noBtn
        handleCancel={handleCancel}
        fn={() => {}}
        name={content.account[culture]}
        color="success"
      >
        <FilterEntries
          watch={watch}
          setValue={setValue}
          setAccountModal={setModal}
          fieldName={"customer"}
          accountClassification={4}
        />
      </TextPopup>
    </>
  );
}

export default BalanceWithRevenue;
