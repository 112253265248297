export default {
  title: {
    ar: 'السنة المالية',
    en: 'Fiscal Year',
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  add: {
    ar: 'إنشاء جديد',
    en: 'Create'
  },
  year: {
    ar: 'السنة',
    en: 'Year',
  },
  fiscalYearState: {
    ar: 'حالة السنة المالية',
    en: 'Fiscal year state'
  },
  actions: {
    ar: 'العمليات',
    en: 'actions'
  },
  open: {
    ar: 'مفتوحة',
    en: 'Open'
  },
  Open: {
    ar: 'مفتوحة',
    en: 'Open'
  },
  PendingToOpen: {
    ar: "في انتظار الفتح",
    en: "Pending to open"
  },
  close: {
    ar: 'إغلاق',
    en: 'Close'
  },
  Closed: {
    ar: "مغلقه",
    en: "Closed"
  },
  PendingToClose: {
    ar: "في انتظار الإغلاق",
    en: "Pending to close"
  },
  PendingToReopen: {
    ar: "في انتظار اعادة الفتح",
    en: "Pending to reopen"
  },  
  reopen: {
    ar: 'إعادة فتح',
    en: 'Reopen'
  },
  migrate: {
    ar: 'تحويل',
    en: 'Migrate'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  closePopup: {
    ar: 'هل انت متأكد انك تريد إغلاق هذة السنة المالية ؟',
    en: 'Are you sure you want to close this fiscal year?'
  },
  reopenPopup: {
    ar: 'هل انت متأكد انك تريد إعادة فتح هذة السنة المالية ؟',
    en: 'Are you sure you want to reopen this fiscal year?'
  },
  deletePopup: {
    ar: 'هل انت متأكد انك تريد حذف هذة السنة المالية ؟',
    en: 'Are you sure you want to delete this fiscal year?'
  },
  migratePopup: {
    ar: 'تحويل السنة المالية',
    en: 'Fiscal year transfer'
  },
  ok: {
    ar: 'موافق',
    en: 'Ok'
  },
  fiscalYear: {
    ar: 'السنة المالية',
    en: 'Fiscal year'
  },
  migrateSettings: {
    ar: 'ترحيل الإعدادات',
    en: 'Migrate settings'
  },
  firstCheck: {
    ar: 'ترحيل الدليل المحاسبي',
    en: 'Accounting Manual Migration'
  },
  secondCheck: {
    ar: 'ترحيل الأرصدة الحالية',
    en: 'Carrying over current balances'
  },
  create: {
    ar: 'إنشاء',
    en: 'Create'
  },
  done: {
    ar: 'تمت العملية بنجاح',
    en: 'Done'
  }
}