export default {
  roles: {
    ar: "الصلاحيات",
    en: "Roles",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذه الصلاحية ؟",
    en: "Are you sure you want to delete this Role ?",
  },
};
