import React from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";

const content = {
  title: {
    ar: "تصنيف الحساب",
    en: "Account Caegory",
  },
  name: {
    ar: "الإسم",
    en: "Name",
  },
  number: {
    ar: "رمز الحساب",
    en: "Number",
  },
  accountName: {
    ar: "الحسابات المدرجة تحت هذا التصنيف",
    en: "Accounts listed under this category",
  },
};

function AccountLevelCard({ list, culture }) {
  return (
    <Row>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.name[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.name[culture]}
            type="text"
            style={{ color: "black" }}
            value={list.name === null ? "" : list.name}
            readOnly
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default AccountLevelCard;
