import { FormControl, FormGroup } from "@material-ui/core";
import commonContent from "components/common/commonContent";
import useCulture from "hooks/useCulture";
import React from "react";
import { useReducer } from "react";
import { Input, Table } from "reactstrap";
import content from "./content";

function PermissionsTable({
  allAppPermissions = [],
  appRolePermissions = [],
  dispatcher,
}) {
  const culture = useCulture();

  const isChecked = (controller, id) => {
    const index = appRolePermissions.findIndex(
      (item) => item.cotroller === controller
    );
    if (index < 0) return false;
    const permissionIndex = appRolePermissions[index]?.appPermissions.findIndex(
      (item) => item.id === id
    );
    if (permissionIndex < 0) return false;
    return true;
  };

  const handleChange = (controller, permission) => {
    dispatcher({ type: "CLICKED", controller, permission });
  };

  const rowSelected = (controller) => {
    dispatcher({ type: "ROW_SELECTED", controller });
  };

  const isRowChecked = (controller) => {
    const index = appRolePermissions.findIndex(
      (item) => item.cotroller === controller
    );
    if (index < 0) return false;
    const parentIndex = allAppPermissions.findIndex(
      (item) => item.cotroller === controller
    );

    if (
      appRolePermissions[index].appPermissions.length ===
      allAppPermissions[parentIndex].appPermissions.length
    )
      return true;

    return false;
  };

  return (
    <Table className="align-items-center" responsive>
      <thead className="thead-light">
        <tr className="">
          <th scope="col">{content.screen[culture]}</th>
          <th scope="col">{commonContent.actions[culture]}</th>
        </tr>
      </thead>
      <tbody>
        {allAppPermissions.map((role) => (
          <tr key={role.cotroller} className="table-row">
            <td className="px-5">
              <FormGroup className="form-check d-flex">
                <Input
                  id={role.cotroller}
                  className="ps-1 cursor-pointer"
                  checked={isRowChecked(role.cotroller)}
                  type="checkbox"
                  onChange={() => rowSelected(role)}
                />
                <label
                  htmlFor={role.cotroller}
                  className="ms-1 cursor-pointer"
                  style={{ fontSize: 13 }}
                >
                  <span>{role.cotrollerName}</span>
                </label>
                {/* {role.cotroller} */}
              </FormGroup>
            </td>
            <td className="d-flex gap-3">
              {role.appPermissions.map((per) => (
                <FormGroup className="form-check d-flex " key={per.id}>
                  <Input
                    id={per.id}
                    checked={isChecked(role.cotroller, per.id)}
                    type="checkbox"
                    className="cursor-pointer"
                    onChange={() => handleChange(role.cotroller, per)}
                  />
                  <label
                    htmlFor={per.id}
                    className="ms-1 cursor-pointer"
                    style={{ fontSize: 13 }}
                  >
                    <span>{per.actionNameName}</span>
                  </label>
                </FormGroup>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default PermissionsTable;
