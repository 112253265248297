import client from "./client";

const endpoint = "/v1/AccountCategory";

const categories = (page, docsPerPage) =>
  client.get(`${endpoint}?Page=${page}&PostsPerPage=${docsPerPage}`);

const createCategory = (schema) => client.post(`${endpoint}`, schema);

const editCategory = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const deleteCategory = (id) => client.delete(`${endpoint}/${id}`);

const getByColumn = (col, val, page, docsPerPage) =>
  client.put(`${endpoint}/filter?Page=${page}&PostsPerPage=${docsPerPage}`, {
    [col]: val,
  });
export default {
  categories,
  createCategory,
  editCategory,
  deleteCategory,
  getByColumn,
};
