import client from './client';

const endpoint = '/v1/Account';

const getYears = () => client.get(`/v1/FiscalYear/Preload`);

const accountsPreload = (pageNo, docsPerPage) => client.get(`${endpoint}/Preload?Page=${pageNo}&PostsPerPage=${docsPerPage}`);

const getRoots = (year) => client.get(`${endpoint}/GetRootsWithBalance?fiscalYearId=${year}`);

const getLevels = () => client.get(`/v1/AccountLevel`);

const getChildren = (id, year) => client.get(`${endpoint}/GetChildrenWithBalance?accountId=${id}&fiscalYearId=${year}`);

const createAccount = schema => client.post(`${endpoint}`, schema);

const updateAccount = (id, schema) => client.put(`${endpoint}/${id}`, schema);

const deleteAccount = id => client.delete(`${endpoint}/${id}`);

const getByColumn = (col, val, page, docsPerPage, shipmentType) => (
  client.get(`${endpoint}/bulk/${shipmentType}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
);


export default {
  getRoots,
  getChildren,
  createAccount,
  updateAccount,
  deleteAccount,
  getYears,
  getByColumn,
  accountsPreload,
  getLevels
}