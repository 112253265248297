import React from 'react'
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardHeader,
  CardBody
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import SimpleHeader from 'components/Headers/SimpleHeader';
import Table from "components/common/CustomTable";
import content from './content';
import classes from './profit&lossAccounts.module.css';

const ProfitLossAccounts = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const headers = [
    content.debtor[culture],
    content.detailed[culture],
    content.accountName[culture],
  ]
  const headers2 = [
    content.creditor[culture],
    content.detailed[culture],
    content.accountName[culture]
  ]

  return (
    <>
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.title[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6" lg='4'>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="level"
                  >
                    {content.level[culture]}
                  </label>
                  <Input
                    id="level"
                    type="text"
                    value="202200"
                    onChange={() => {}}
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg='4'>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="cost-center"
                  >
                    {content.constCenter[culture]}
                  </label>
                  <Input
                    id="cost-center"
                    type="select"
                  >
                    <option value="diabled">-- {content.selectOption[culture]}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="6" lg='4'>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="fromdate"
                  >
                    {content.fromDate[culture]}
                  </label>
                  <Input
                    id="fromdate"
                    type="date"
                  />
                </FormGroup>
              </Col>
              <Col md="6" lg='4'>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="to-date"
                  >
                    {content.toDate[culture]}
                  </label>
                  <Input
                    id="to-date"
                    type="date"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color='info' type="button" className='cml-2'>
              <span className="btn-inner--icon me-1">
                <i className="fas fa-search" />
              </span>
              <span className="btn-inner--text">
                {content.search[culture]}
              </span>
            </Button>
          </CardBody>
        </Card>
        <Card>
          <Row className='positon-relative'>
            <Col xs="6" className='p-0 ps-3'>
              <Table headers={headers} />
              <div className={`py-2 px-4 ${classes['border-bottom']}`}>
                {content.totalDebit[culture]}
              </div>
              <div className='text-center py-2'>
                {content.totalBlanace[culture]}
              </div>
            </Col>
            <span className={classes.sep}></span>
            <Col xs="6" className='p-0 pe-3'>
              <Table headers={headers2} />
              <div className={`py-2 px-4 ${classes['border-bottom']}`}>
                {content.totalCredit[culture]}
              </div>
              <div className='text-center py-2'>0</div>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  )
}

export default ProfitLossAccounts