export default {
  title: {
    ar: "أرصدة العملاء",
    en: "Customer Balance",
  },
  customerAccountId: {
    ar: "حساب الزبون",
    en: "Customer Account",
  },
  year: {
    ar: "السنة المالية",
    en: "Fiscal year",
  },
  debit: {
    ar: "مدين",
    en: "Debit",
  },
  credit: {
    ar: "دائن",
    en: "Credit",
  },
  balance: {
    ar: "رصيد",
    en: "Balance",
  },
  dollar: {
    ar: "دولار",
    en: "Dollar",
  },
  LYD: {
    ar: "دينار",
    en: "LYD",
  },
  code: {
    ar: "رمز",
    en: "Code",
  },
  balanceDetails: {
    ar: "مجموع الأرصدة",
    en: "Balance total",
  },
  lyd: {
    ar: "دل",
    en: "LYD"
  }
};
