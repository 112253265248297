import { useHistory } from "react-router";
import useCulture from "./useCulture";

const usePermissions = (pageName = "") => {
  const history = useHistory();
  const culture = useCulture() || "ar";

  const userPermission =
    localStorage.getItem(btoa("userPermissions")) !== null
      ? JSON.parse(localStorage.getItem(btoa("userPermissions")))
      : {};

  const checkPermission = (permission, page) => {
    if (!localStorage.getItem(btoa("userPermissions"))) return false;
    const index = userPermission?.findIndex(
      (role) => role.controller === (page || pageName)
    );

    if (index < 0 || index === null || index === undefined) return false;

    const permissionIndex = userPermission[
      index
    ]?.controllPermission?.findIndex((per) => per.actionName === permission);

    if (permissionIndex >= 0) return true;

    return false;
  };

  return checkPermission;
};

export default usePermissions;
