import React, { useEffect } from 'react';

import Spinner from '../components/Spinner';
// import swal from 'sweetalert';
import env from '../env';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

// const ipcRenderer = window.require('electron').ipcRenderer

export default function SimpleBackdrop(props) {

    const history = useHistory();

    
    useEffect(() => {

        props.fn().catch(function (err) {
            if (!err.response) {
                return props.setAlert(
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="please check your connection"
                        onConfirm={() => props.setAlert(null)}
                        onCancel={() => props.setAlert(null)}
                        confirmBtnBsStyle="warning"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                    </ReactBSAlert>
                );
            }
            //
            if (err.response.status === 401) {
                localStorage.clear();
                // ipcRenderer.invoke(`unauthorize-event`)
                props.setAlert(
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={err.response.data.message || err.response.data.messages}
                        onConfirm={() => props.setAlert(null)}
                        onCancel={() => props.setAlert(null)}
                        confirmBtnBsStyle="warning"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                    </ReactBSAlert>
                );
                return history.replace(`/ar-auth/login`)
            } else {
                props.setAlert(
                    <ReactBSAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={err.response.data.message || err.response.data.messages || "Error 404"}
                        onConfirm={() => props.setAlert(null)}
                        onCancel={() => props.setAlert(null)}
                        confirmBtnBsStyle="warning"
                        confirmBtnText="Ok"
                        btnSize=""
                    >
                    </ReactBSAlert>
                )
            }
    
        }).finally(function (params) {
            props.setLoading(false);
        })
    }, [])

    return <Spinner bg={props.bg} gate={props.gate} opacity={props.opacity} way={props.way} />
}