import React, { Fragment } from "react";
import useCulture from "hooks/useCulture";
import { Controller, useForm } from "react-hook-form";
import commonContent from "components/common/commonContent";
import { Card, CardBody, Col, FormGroup, Input, Row, Button } from "reactstrap";
import useApi from "hooks/useApi";
import entry from "api/entry";
import env from "env";
import Spinner from "components/Spinner";

function CurrencyPurchaseForm({ setData }) {
  const culture = useCulture();
  const convertApi = useApi(entry.lydToConvet);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const editResponse = (item) => {
    item.entryStateText = env.entryState[item.entryState - 1][culture];
    return item;
  };

  const handleSearch = async (formData) => {
    const res = await convertApi.request(formData);

    if (res.status === 200) {
      res.data.data.forEach(editResponse);
      setData(res.data.data);
    }
  };

  return (
    <Card>
      {convertApi.loading && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <CardBody>
        <form onSubmit={handleSubmit(handleSearch)}>
          <Row className="align-items-center">
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="currency">
                  {commonContent.currency[culture]}
                </label>
                <Controller
                  control={control}
                  name="currency"
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="currency"
                      type="select"
                      className={errors.currency && "error"}
                      onChange={({ target: { value } }) => onChange(+value)}
                      value={value || "disabled"}
                    >
                      <option disabled value="disabled">
                        -- {commonContent.selectOption[culture]} --
                      </option>
                      {env.currency?.map((item, index) =>
                        item.en.toLowerCase() === "lyd" ? (
                          <Fragment key={index} />
                        ) : (
                          <option value={index + 1} key={index}>
                            {item[culture]}
                          </option>
                        )
                      )}
                    </Input>
                  )}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="date">
                  {commonContent.date[culture]}
                </label>
                <Controller
                  control={control}
                  name="date"
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="date"
                      type="date"
                      className={errors.date && "error"}
                      onChange={({ target: { value } }) => onChange(value)}
                      value={value || "disabled"}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button className="mr-2" color="info" size="md" type="submit">
            <span className="btn-inner--icon me-1">
              <i className="fas fa-search" />
            </span>
            <span>{commonContent.search[culture]}</span>
          </Button>
        </form>
      </CardBody>
    </Card>
  );
}

export default CurrencyPurchaseForm;
