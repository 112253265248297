export default {
  categoryName: {
    ar: "التقارير",
    en: "Reports",
  },
  pageTitle: {
    ar: "متابعة الشحنات",
    en: "Follow up shipments",
  },
  bulkNumber: {
    ar: "رقم البلك",
    en: "Bulk number",
  },
  entryNo: {
    ar: "رقم القيد",
    en: "Entry number",
  },
  entryDate: {
    ar: "تاريخ القيد",
    en: "Entry date",
  },
  surplus: {
    ar: "فائض في خزينة الصين",
    en: "The surplus in the treasury of China",
  },
  creditor: {
    ar: "دائن",
    en: "Creditor",
  },
  debtor: {
    ar: "مدين",
    en: "Debtor",
  },
  account: {
    ar: "الحساب",
    en: "Account",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  costCenter: {
    ar: "مركز التكلفة",
    en: "Cost center",
  },
  currencyValue: {
    ar: "قيمة العملة",
    en: "Currency value",
  },
  entryType: {
    ar: "نوع القيد",
    en: "Entry type",
  },
  actions: {
    ar: "العمليات",
    en: "Actions",
  },
  entryState: {
    ar: "حالة القيد",
    en: "Entry state",
  },
  date: {
    ar: "التاريخ",
    en: "Date",
  },
  currency: {
    ar: "العملة",
    en: "Currency",
  },
  entry: {
    ar: "قيد",
    en: "Entry",
  },
  closed: {
    ar: "مغلق",
    en: "Closed",
  },
  bulkState: {
    ar: "حالة الشحنة",
    en: "Bulk state",
  },
  from: {
    ar: "من",
    en: "From",
  },
  to: {
    ar: "الى",
    en: "To",
  },
};
