
const useUpdateAccount = () => {

  const handleUpdateAccount = (formData) => {
    const schema = {
      ...formData,
      number: formData.parentNo + formData.number,
    };

    delete schema.parentNo;
    delete schema.digits;
    // delete schema.number;
    delete schema.accountLevelId;

    if (formData.classification !== 4) {
      delete schema.customer;
      delete schema.phoneNumber;
      delete schema.customerAccountId;
    }

    return { ...schema };
    // await updataHandler(id, formData);
  };

  const updateAccountModal = (data, setValue, setModal) => {
    const allowedDigit =
      data.number.length - (data.accountLevel?.prevLevel?.digits || 0);

    const parentDigit = data.accountLevel.digits - allowedDigit;
    const parentNumber = data.number.substring(0, parentDigit);
    const number = data.number.substring(parentDigit);

    setValue("name", data.name);
    setValue("number", number);
    setValue("parentNo", parentNumber);
    setValue("accountCategoryId", data.accountCategoryId);
    setValue("accountLevelId", data.accountLevel.id);
    setValue("digits", allowedDigit);
    setValue("accountClassification", data.accountClassification);
    setValue("phoneNumber", data.phoneNumber);
    setValue("customerAccountId", data.customerAccountId);
    setValue("customer", data.customerAccount?.name);
    setValue("accountNature", data.accountNature);

    setModal(true);
  };


  return { handleUpdateAccount, updateAccountModal };
};

export default useUpdateAccount;
