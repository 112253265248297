import React, { Fragment } from "react";
import useCulture from "hooks/useCulture";
import { Controller, useForm } from "react-hook-form";
import commonContent from "components/common/commonContent";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
  Button,
  CardHeader,
} from "reactstrap";
import useApi from "hooks/useApi";
import entry from "api/entry";
import env from "env";
import Spinner from "components/Spinner";
import content from "../content";
import account from "api/account";
import { useState } from "react";

function TreasuryTransactions() {
  const culture = useCulture();
  const [data, setData] = useState({});
  const mainTreasuryreportApi = useApi(account.mainTreasuryreport);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const handleSearch = async (formData) => {
    const res = await mainTreasuryreportApi.request(formData);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  return (
    <>
      <Card>
        {mainTreasuryreportApi.loading && (
          <Spinner gate="#11cdef" bg="#fff" opacity />
        )}
        <CardBody>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Row className="align-items-center">
              <Col md="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="date">
                    {commonContent.date[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="date"
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="date"
                        type="date"
                        className={errors.date && "error"}
                        onChange={({ target: { value } }) => onChange(value)}
                        value={value || "disabled"}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button className="mr-2" color="info" size="md" type="submit">
              <span className="btn-inner--icon me-1">
                <i className="fas fa-search" />
              </span>
              <span>{commonContent.search[culture]}</span>
            </Button>
          </form>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>{content.totalDeposit[culture]}</CardHeader>
        <CardBody>
          <Row className="align-items-center">
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="totalDepositLYD">
                  {content.totalDepositLYD[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalDepositLYD"
                type="text"
                value={data.totalDepositsLYD ?? ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="totalDepositUSD">
                  {content.totalDepositUSD[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalDepositUSD"
                type="text"
                value={data.totalDepositsUSD ?? ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalDepositEURO"
                >
                  {content.totalDepositEURO[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalDepositEURO"
                type="text"
                value={data.totalDepositsEURO ?? ""}
                readOnly
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>{content.totalReturnedToCustomers[culture]}</CardHeader>
        <CardBody>
          <Row className="align-items-center">
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalReturnedToCustomersInLYD"
                >
                  {content.totalReturnedToCustomersInLYD[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalReturnedToCustomersInLYD"
                type="text"
                value={data.totalReturnedToCustomersLYD ?? ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalReturnedToCustomersInUSD"
                >
                  {content.totalReturnedToCustomersInUSD[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalReturnedToCustomersInUSD"
                type="text"
                value={data.totalReturnedToCustomersUSD ?? ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalReturnedToCustomersInEURO"
                >
                  {content.totalReturnedToCustomersInEURO[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalReturnedToCustomersInEURO"
                type="text"
                value={data.totalReturnedToCustomersEURO ?? ""}
                readOnly
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>{content.totalMoneyTransfers[culture]}</CardHeader>
        <CardBody>
          <Row className="align-items-center">
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalMoneyTransfersInLYD"
                >
                  {content.totalMoneyTransfersInLYD[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalMoneyTransfersInLYD"
                type="text"
                value={data.totalMoneyTransfersLYD ?? ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalMoneyTransfersInUSD"
                >
                  {content.totalMoneyTransfersInUSD[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalMoneyTransfersInUSD"
                type="text"
                value={data.totalMoneyTransfersUSD ?? ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalMoneyTransfersInEURO"
                >
                  {content.totalMoneyTransfersInEURO[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalMoneyTransfersInEURO"
                type="text"
                value={data.totalMoneyTransfersEURO ?? ""}
                readOnly
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>{content.totalShipmentPayments[culture]}</CardHeader>
        <CardBody>
          <Row className="align-items-center">
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalShipmentPaymentsLYD"
                >
                  {content.totalShipmentPaymentsLYD[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalShipmentPaymentsLYD"
                type="text"
                value={data.totalShipmentPaymentsLYD ?? ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalShipmentPaymentsInUSD"
                >
                  {content.totalShipmentPaymentsInUSD[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalShipmentPaymentsInUSD"
                type="text"
                value={data.totalShipmentPaymentsUSD ?? ""}
                readOnly
              />
            </Col>
            <Col md="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="totalShipmentPaymentsInEURO"
                >
                  {content.totalShipmentPaymentsInEURO[culture]}
                </label>
              </FormGroup>
              <Input
                id="totalShipmentPaymentsInEURO"
                type="text"
                value={data.totalShipmentPaymentsEURO ?? ""}
                readOnly
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default TreasuryTransactions;
