export default {
  categoryName: {
    ar: 'المحاسبة',
    en: 'Accounting',
  },
  title: {
    en: 'Entry details',
    ar: 'تفاصيل القيد'
  },
  entryNo: {
    ar: 'رقم القيد',
    en: 'entry number'
  },
  entryDate: {
    ar: 'تاريخ القيد',
    en: 'Entry date'
  },
  surplus: {
    ar: 'فائض في خزينة الصين',
    en: 'The surplus in the treasury of China'
  },
  creditor: {
    ar: 'دائن',
    en: 'Creditor'
  },
  debtor: {
    ar: 'مدين',
    en: 'Debtor'
  },
  account: {
    ar: 'الحساب',
    en: 'Account'
  },
  statement: {
    ar: 'البيان',
    en: 'Statement'
  },
  costCenter: {
    ar: 'مركز التكلفة',
    en: 'Cost center'
  },
  currencyValue: {
    ar: 'قيمة العملة',
    en: 'Currency value'
  },
  print: {
    ar: 'طباعة',
    en: 'Print'
  },
  printPdf: {
    ar: 'طباعة بي دي اف',
    en: 'Print pdf',
  },
  attachments: {
    ar: 'المرفقات',
    en: 'Attachments'
  },
  manual: {
    ar: 'يدوي',
    en: 'Manual'
  },
  review: {
    ar: 'مراجعة',
    en: 'Review'
  },
  totalDebit: {
    ar: 'اجمالي مدين',
    en: 'Total debit'
  },
  totalCredit: {
    ar: 'اجمالي دائن',
    en: 'Total credit'
  },
  difference: {
    ar: 'الفرق',
    en: 'The difference'
  },
  entryType: {
    ar: 'نوع القيد',
    en: 'Entry type'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  date: {
    ar: 'تاريخ القيد',
    en: 'Entry Date'
  },
  accountName: {
    ar: 'اسم الحساب',
    en: 'Account name'
  },
  selectOption: {
    ar: 'اختر خيار',
    en: 'Select option'
  },
  dateFrom: {
    ar: 'من تاريخ',
    en: 'Date from'
  },
  toDate: {
    ar: 'الى',
    en: 'to date'
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  tableExport: {
    ar: 'تصدير الجدول',
    en: 'Table export'
  },
  number: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  note: {
    ar: 'الملاحظات',
    en: 'Notes'
  },
  debit: {
    ar: 'مدين',
    en: 'Debit'
  },
  credit: {
    ar: 'دائن',
    en: 'Credit'
  },
  currency: {
    ar: "العملة",
    en: "Currency"
  },
  entryState: {
    ar: "حالة القيد",
    en: "Entry State",
  },
  confirm: {
    ar: "اعتماد",
    en: "Confirm"
  },
  unconfirm: {
    ar: "الغاء الاعتماد",
    en: "Unconfirm"
  },
  cancel: {
    ar: "الغاء المعاملة",
    en: "Cancel"
  },
  confirmPopup: {
    ar: "هل انت متأكد من انك تريد اعتماد هذا القيد ؟",
    en: "Are you sure you want to confirm this entry ?"
  },
  unconfirmPopup: {
    ar: "هل انت متأكد من انك تريد الغاء اعتماد هذا القيد ؟",
    en: "Are you sure you want to unconfirm this entry ?"
  },
  cancelPopup: {
    ar: "هل انت متأكد من انك تريد الغاء هذه المعاملة ؟",
    en: "Are you sure you want to cancel this entry ?"
  },
  shipmentType: {
    ar: "نوع الشحن",
    en: "Shipment type"
  }
}