import { useEffect, useContext } from "react";
import { PaginationContext } from "context/PaginationContext";

const usePrevRequest = (
  getAll,
  searchPopup,
  pageNo,
  docsPerPage,
  updateData,
  prevPageCheck,
  search = "",
  handleSearch = () => {}
) => {
  
  const paginationCtx = useContext(PaginationContext);

  useEffect(() => {
    // if search popup has a values and it's the same page stored in the context
    // don't run these request
    // and go to the other useEffect
    if (searchPopup.isSelected && searchPopup.searchField && prevPageCheck())
      return;
    if (
      paginationCtx.searchedColumn &&
      paginationCtx.searchedContent &&
      prevPageCheck()
    )
      return;
    if (search) handleSearch();
    else getAll();
  }, [pageNo, docsPerPage]);

  useEffect(() => {
    if (
      searchPopup.isSelected &&
      searchPopup.searchField &&
      searchPopup.openPopup
    ) {
      searchPopup.onSearch("", () => {});
    } else if (searchPopup.isSelected && searchPopup.searchField) {
      searchPopup.onSearch("", (data) => {
        updateData(data);
      });
    } else if (
      paginationCtx.searchedColumn &&
      paginationCtx.searchedContent &&
      prevPageCheck()
    ) {
      // if there's a popup search info in the context set it in the popup search
      // then set 'setIsSelected' to true so the search popup request will fire
      searchPopup.setColumn(paginationCtx.searchedColumn);
      searchPopup.setSearchField(paginationCtx.searchedContent);
      searchPopup.setPageNo(paginationCtx.pageNumber);
      searchPopup.setDocsPerPage(paginationCtx.docsPerPage);
      searchPopup.setIsSelected(true);
    } else {
      searchPopup.setIsSelected(false);
    }
  }, [searchPopup.pageNo, searchPopup.docsPerPage, searchPopup.isSelected]);
};

export default usePrevRequest;
