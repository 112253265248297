import useCulture from "hooks/useCulture";
import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

function Tabs({ tabs = [], tabIndex, setTabIndex }) {
  return (
    <Nav fill pills style={{ cursor: "pointer" }}>
      {tabs.map((tab, index) => (
        <NavItem key={tab} onClick={() => setTabIndex(index)}>
          <NavLink
            className={tabIndex === index ? "tab-active" : ""}
            // style={{ backgroundColor: "var(--info)" }}
          >
            {tab}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
  {
    /* <TabContent activeTab="1">
    <TabPane tabId="1">
      <Row>
        <Col sm="12">
          <h4>Tab 1 Contents</h4>
        </Col>
      </Row>
    </TabPane>
    <TabPane tabId="2">
      <Row>
        <Col sm="6">
          <Card body>
            <CardTitle>Special Title Treatment</CardTitle>
            <CardText>
              With supporting text below as a natural lead-in to additional
              content.
            </CardText>
            <Button>Go somewhere</Button>
          </Card>
        </Col>
        <Col sm="6">
          <Card body>
            <CardTitle>Special Title Treatment</CardTitle>
            <CardText>
              With supporting text below as a natural lead-in to additional
              content.
            </CardText>
            <Button>Go somewhere</Button>
          </Card>
        </Col>
      </Row>
    </TabPane>
  </TabContent> */
  }
}

export default Tabs;
