import React from "react";
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
// import classes from "../../../components/Print/newstyle.css";
import { Button } from "reactstrap";
import commonContent from "components/common/content";

class ComponentToPrint extends React.Component {
  render() {
    const { data } = this.props;
    // console.log("data recieved in here! : ", data);
    let curr;
    if (data.currency === 1) curr = "دينار ليبي";
    else if (data.currency === 2) curr = "دولار";
    return (
      <div style={{ direction: "rtl" }}>
        <h1>أرصدة العملاء</h1>
        <div className="header">
          <span></span>
        </div>
        <table border="1" style={{ width: "100%" }}>
          {data.customersSums ? (
            <>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>حساب الزبون</th>
                  <th style={{ textAlign: "center" }}>رمز</th>
                  <th style={{ textAlign: "center" }}>مدين ($)</th>
                  <th style={{ textAlign: "center" }}>دائن ($)</th>
                  <th style={{ textAlign: "center" }}>رصيد ($)</th>
                  <th style={{ textAlign: "center" }}>مدين (د.ل)</th>
                  <th style={{ textAlign: "center" }}>دائن (د.ل)</th>
                  <th style={{ textAlign: "center" }}>رصيد (د.ل)</th>
                </tr>
              </thead>
              <tbody>
                {data.customersSums.map((e) => (
                  <tr key={e.id}>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {e.accountName}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {e.customerCode}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {e.totalDebitUSD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {e.totalCreditUSD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {e.balanceUSD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {e.totalDebitLYD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {e.totalCreditLYD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {e.balanceLYD}
                    </td>
                  </tr>
                ))}
                <tr>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      الإجمالي
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.totalDebitUSD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.totalCreditUSD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.totalBalanceUSD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.totalDebitLYD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.totalCreditLYD}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {data.totalBalanceLYD}
                    </td>
                  </tr>
              </tbody>
            </>
          ) : (
            <></>
          )}
        </table>
        {/* <div className="header">
          <span>
            قيمة المدين بالدولار : <span>{data.totalDebitUSD}</span>
          </span>
          <span>
            قيمة الدائن بالدولار : <span>{data.totalCreditUSD}</span>
          </span>
          <span>
            قيمة الرصيد بالدولار : <span>{data.totalBalanceUSD}</span>
          </span>
          <span>
            قيمة المدين بالدينار : <span>{data.totalDebitLYD}</span>
          </span>
          <span>
            قيمة الدائن بالدينار : <span>{data.totalCreditLYD}</span>
          </span>
          <span>
            قيمة الرصيد بالدينار : <span>{data.totalBalanceLYD}</span>
          </span>
        </div> */}
      </div>
    );
  }
}

class Example extends React.Component {
  render() {
    const { data, culture } = this.props;
    // console.log("data report print", data);
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <Button color="success">{commonContent.print[culture]}</Button>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          ref={(el) => {
            this.componentRef = el;
            // console.log("hola rep: ", el);
          }}
          data={data}
        />
      </div>
    );
  }
}

export default Example;
