import React, { useEffect, useState } from "react";
import Table from "components/common/CustomTable";
import { FormGroup, Input, Row, Col, Button, CardBody } from "reactstrap";
import useApi from "hooks/useApi";
import Spinner from "components/Spinner";
import Pagination from "components/common/Pagination";
import content from "../../Screen/Transaction/CreateEntry/content";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";
import commonContent from "components/common/commonContent";
import account from "api/account";
import env from "env";

const FilterEntries = (props) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const {
    setValue,
    prevSelectedId = "",
    fieldName = "",
    setFieldName = () => {},
    accountClassification,
    setAccountModal = () => {},
    watch = () => {},
  } = props;

  // States
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(5);
  const [classification, setClassification] = useState();

  // Apis
  const filterApi = useApi(account.filterWithoutIdEntry);

  const headers = [
    content.actions[culture],
    content.accountNo[culture],
    content.accountName[culture],
    content.createdOn[culture],
  ];

  const columns = ["number", "name", "createdOn"];

  const editResponse = (item) => {
    item.createdOn = item.createdOn.split("T")[0];
    return item;
  };

  const handleSearch = async () => {
    const schema = {
      name,
      number,
      isAnalytical: true,
    };

    if (classification || accountClassification)
      schema.accountClassification = classification || accountClassification;

    const res = await filterApi.request(
      schema,
      prevSelectedId,
      pageNo,
      docsPerPage
    );
    if (res.status === 200) {
      res.data.data = res.data.data.map((item) => editResponse(item));
      setData(res.data.data);
    }
  };

  const handleSelect = (obj) => {
    setValue(`${fieldName}Account`, obj.name);
    setValue(`${fieldName}Id`, obj.id);
    setFieldName("");
    setAccountModal(false);
  };

  const handleUnselect = () => {
    setValue(`${fieldName}Id`, "");
    setValue(`${fieldName}Account`, "");
  };

  const renderSelect = (obj) => obj.id !== watch(`${fieldName}Id`);
  const renderCancel = (obj) => obj.id === watch(`${fieldName}Id`);

  useEffect(() => {
    handleSearch();
  }, [pageNo, docsPerPage]);

  if (filterApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <div>
      {filterApi.errorAlert}
      <div className="padding-around pb-0">
        <Row>
          <Col md="6" lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="entryno">
                {content.accountNo[culture]}
              </label>
              <Input
                bsSize="sm"
                id="entryno"
                type="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </FormGroup>
          </Col>
          <Col md="6" lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="entryno">
                {content.accountName[culture]}
              </label>
              <Input
                bsSize="sm"
                id="entryno"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </FormGroup>
          </Col>
          {accountClassification ? (
            <></>
          ) : (
            <Col md="6" lg="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="account-classification"
                >
                  {content.accountClassification[culture]}
                </label>
                <Input
                  id="account-classification"
                  type="select"
                  bsSize="sm"
                  onChange={({ target: { value } }) =>
                    setClassification(+value)
                  }
                  onWheel={(e) => e.target.blur()}
                  value={classification || "disabled"}
                >
                  <option disabled value="disabled">
                    -- {content.selectOption[culture]} --
                  </option>
                  {env.accountClassifications.map((acc, index) => (
                    <option value={index + 1} key={index}>
                      {acc[culture]}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          )}
        </Row>
        <Button
          color="success"
          type="button"
          className="mt-4"
          onClick={handleSearch}
          // size="sm"
        >
          <span className="btn-inner--icon me-1">
            <i className="fas fa-search" />
          </span>
          <span>{content.search[culture]}</span>
        </Button>
      </div>
      {!!watch(`${fieldName}Account`) && (
        <ul className="popup-customer-list mt-4 px-3">
          <li>
            <i className="fas fa-times hover-danger" onClick={handleUnselect} />
            {watch(`${fieldName}Account`)}
          </li>
        </ul>
      )}
      <Pagination
        dataLength={data?.length || 0}
        pageNo={pageNo}
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
      >
        <Table
          headers={headers}
          columns={columns}
          data={data}
          setData={setData}
          handleSelect={handleSelect}
          handleUnselect={handleUnselect}
          isPrint={false}
          renderSelect={renderSelect}
          renderCancel={renderCancel}
        >
          <span className="me-1" fun="handleSelect" condition="renderSelect">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-check fa-lg hover-info" />
            </div>
          </span>
          <span className="ms-1" fun="handleUnselect" condition="renderCancel">
            <div id="cancel" className="table-action cursor-pointer">
              <i className="fas fa-times fa-lg hover-danger" />
            </div>
          </span>
        </Table>
      </Pagination>
    </div>
  );
};

export default FilterEntries;
