export default {
  title: {
    ar: 'إنشاء سنة مالية',
    en: 'Create fiscal year'
  },
  year: {
    ar: 'السنة',
    en: 'Year'
  },
  fiscalYearState: {
    ar: 'حالة السنة المالية',
    en: 'Fiscal year state'
  },
  open: {
    ar: 'مفتوحة',
    en: 'Open'
  },
  close: {
    ar: 'مغلقة',
    en: 'Close'
  },
  settings: {
    ar: 'الإعدادات',
    en: 'Settings'
  },
  checkPermission: {
    ar: 'تقييد اذونات الصرف بدون تفويض',
    en: 'Restricting Exchange Permits Without Authorization'
  },
  checkRestriction: {
    ar: 'تقييد اضافة معاملات بأثر رجعي',
    en: 'Restriction of adding retroactive transactions'
  },
  taxCalc: {
    ar: 'حساب الضرائب',
    en: 'Tax account'
  },
  test: {
    ar: 'تجربة',
    en: 'Test'
  },
  bankCalc: {
    ar: 'حساب المصارف',
    en: 'Bank account'
  },
  inventoryAccount: {
    ar: 'حساب الخزائن',
    en: 'Inventory  account'
  },
  save: {
    ar: 'حفظ',
    en: 'Save'
  }
}