export default {
  title: {
    ar: 'التصنيفات',
    en: 'Categories'
  },
  name: {
    ar: 'الاسم',
    en: 'Name'
  },
  NoOfClassifiedAccounts: {
    ar: 'عدد الحسابات تحت التصنيف',
    en: 'The number of classified accounts'
  },
  category: {
    ar: 'تصنيف',
    en: 'Category'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  add: {
    ar: 'إضافة',
    en: 'Add'
  },
  addPopup: {
    ar: 'اضافة تصنيف جديد',
    en: 'Add a new category'
  },
  editPopup: {
    ar: 'تعديل التصنيف',
    en: 'Edit category'
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذالتصنيف ؟",
    en: "Are you sure you want to delete this category ?"
  },
  createdOn: {
    ar: 'تاريخ الانشاء',
    en: 'Created on'
  },
  done: {
    ar: 'تمت العملية بنجاح',
    en: 'Done'
  }
}