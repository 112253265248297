/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// router 
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory
} from "react-router-dom";

  // get culture from utils 
import getCulture from "utils/getCulture";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";

// content 
import content from "./content";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Spinner from 'components/Spinner';
import axios from 'axios';
import env from 'env';

function Lock() {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  const [focused, setFocused] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [password, setPassword] = useState("");
  const [spinner, setSpinner ] = useState(false);
  const [alert, setAlert] = useState('');
  const history = useHistory();

  localStorage.setItem(btoa('token'), "")
  
  // const unlock = e => catchAsync(async () => {
  //   e.preventDefault();
  //   setSpinner(true)
  //   const schema = {
  //     username: atob(localStorage.getItem(btoa('username'))),
  //     password
  //   }
  //   const result = await axios.post(`${env.activeServer()}/auth/login`, schema)
  //   if (result.status === 200) {
  //     localStorage.setItem(btoa('token'), result.data.token);
  //     history.replace(`/${culture}-admin/dashboard`)
  //   }
  // }, () => { setSpinner(false)}, setAlert)

  // useEffect(() => {

  //   return () => {
  //     setSpinner(false)
  //   }
  // }, [])

  const unlock = async e => {
    try {
      e.preventDefault();
      setSpinner(true)
      const schema = {
        username: atob(localStorage.getItem(btoa('username'))),
        password
      }
      const result = await axios.post(`${env.identity()}/auth/login`, schema)
      if (result.status === 200) {
        localStorage.setItem(btoa('token'), result.data.token);
        history.replace(`/${culture}-admin/accounting/main`)
      }
    } catch(err) {
      if (!err.response) {
        return setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="please check your connection"
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                btnSize=""
            >
            </ReactBSAlert>
        );
      }
      if (err.response.status === 401) {
        setAlert(                
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={err.response.data.message}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
          >
          </ReactBSAlert>
        );
      }
      if (err.response.status === 400) {
        setAlert(                
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={err.response.data.message}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
          >
          </ReactBSAlert>
        );
      }
    }
    setSpinner(false);
  }

  useEffect(() => {

    return () => {
      setSpinner(false)
    }
  }, [])

  return (
    <>
      {alert}
      {spinner ? <Spinner opacity /> : null}
      <AuthHeader         
        title={content.title[culture]}
        lead={content.lead[culture]}
        />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="card-profile bg-secondary mt-5">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <img
                      alt="..."
                      className="rounded-circle border-secondary"
                      // src={require("assets/img/theme/team-4.jpg").default}
                      src={atob(localStorage.getItem(btoa('imgUrl')))}
                    />
                  </div>
                </Col>
              </Row>
              <CardBody className="pt-7 px-5">
                <div className="text-center mb-4">
                  <h3>{atob(localStorage.getItem(btoa('username')))}</h3>
                </div>
                <Form role="form" onSubmit={unlock}>
                <FormGroup
                    className={classnames({
                      focused: focused,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={content.password[culture]}
                        type={ showPassword ? "text" : "password"}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onChange={e => setPassword(e.target.value)}
                      />
                      <Button color="secondary" outline type="button" onClick={() => setShowpassword(!showPassword)} >
                      <i className={ showPassword ? "fas fa-eye" : "fas fa-eye-slash" }></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button 
                      className="mt-2" 
                      color="success" 
                      type="submit"
                      >
                     {content.unlock[culture]}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">

<Col className="text-center" xs="6">
  <Link  style={{ color: "#BFC8D0"}} to={ culture == "ar" ? "/ar-auth/login" : "/en-auth/login"} ><small>{content.login[culture]}</small> </Link>
</Col>              

<Col className="text-center" xs="6">
 { culture == "ar" ?
  <Link  style={{ color: "#2DCE89"}} to="/en-auth/lock" >English</Link>
  :
  <Link  style={{ color: "#2DCE89"}} to="/ar-auth/lock" >العربية	&nbsp; 	&nbsp;</Link>


  
}
</Col>
</Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Lock;
