import React, { useEffect, useState } from "react";
import Table from "components/common/CustomTable";
import { FormGroup, Input, Row, Col, Button } from "reactstrap";
import { useRouteMatch } from "react-router";
import useApi from "../../hooks/useApi";
import Spinner from "../Spinner";
import Pagination from "components/common/Pagination";
import commonContent from "components/common/content";
import getCulture from "utils/getCulture";
import env from "env";
import AccountApi from "api/account";

function AccountPopUpTable({ setTableModal, setValue, sweetAlert }) {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  // States
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [account, setAccount] = useState("");
  const [docsPerPage, setDocsPerPage] = useState(5);

  // Apis
  const findByName = useApi(AccountApi.filterAccounts);

  const editResponse = (item) => {
    item.accountClassification = env.accountClassifications[item.accountClassification - 1][culture];
  };
  const findAccountByName = async () => {
    const schema = {};

    if (account) schema.name = account;

    const res = await findByName.request(pageNo, docsPerPage, schema);

    if (res.status === 200) {
      res.data.data.map(editResponse);
      setData(res.data.data);
    }
  };

  const handleSelect = (obj) => {
    // otherwise store an object with the customer
    setTableModal(false);
    setValue("account", obj.name);
    setValue("accountId", obj.id);
  };

  useEffect(() => {
    findAccountByName();
  }, [pageNo, docsPerPage]);

  useEffect(
    () =>
      findByName.error && findByName.data && sweetAlert(findByName.data, true),
    [findByName.data]
  );

  if (findByName.loading) return <Spinner gate="#29bb64" bg="#fff" />;

  return (
    <div>
      <div className="padding-around pb-0">
        <Row>
          <Col md="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="customer-code">
                {commonContent.account[culture]}
              </label>
              <Input
                id="customer-code"
                placeholder={commonContent.search[culture]}
                type="text"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && findAccountByName()}
              />
            </FormGroup>
          </Col>
          <span style={{ paddingTop: 7 }}>
            <Button
              color="success"
              type="button"
              className="mt-4"
              onClick={findAccountByName}
            >
              <span className="btn-inner--icon me-1">
                <i className="fas fa-search" />
              </span>
              <span>{commonContent.search[culture]}</span>
            </Button>
          </span>
        </Row>
      </div>
      <Pagination
        dataLength={data.length}
        pageNo={pageNo}
        setPageNo={setPageNo}
        docsPerPage={docsPerPage}
        setDocsPerPage={setDocsPerPage}
      >
        <Table
          headers={[
            commonContent.actions[culture],
            commonContent.number[culture],
            commonContent.name[culture],
            commonContent.accountClassification[culture],
            commonContent.accountLevel[culture],
          ]}
          columns={[
            "number",
            "name",
            "accountClassification",
            "accountLevel.name",
          ]}
          data={data}
          setData={setData}
          handleSelect={handleSelect}
          isPrint={false}
        >
          <span className="me-1" fun="handleSelect">
            <div id="approve" className="table-action cursor-pointer">
              <i className="fas fa-check fa-lg hover-info"></i>
            </div>
          </span>
        </Table>
      </Pagination>
    </div>
  );
}

export default AccountPopUpTable;
