import React from "react";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import content from "./content";
import useCulture from "hooks/useCulture";
import useApi from "hooks/useApi";
import configuration from "api/configuration";
import { useEffect } from "react";
import Spinner from "components/Spinner";
import { useState } from "react";
import useAlert from "hooks/useAlert";
import commonContent from "components/common/content";
import TextPopup from "components/common/TextModal";
import FilterEntries from "components/common/FilterEntries";
import { Controller, useForm } from "react-hook-form";

const Settings = () => {
  const culture = useCulture();

  const getActiveApi = useApi(configuration.getActive);
  const createOneApi = useApi(configuration.createOne);
  const updateOneApi = useApi(configuration.updateOne);

  const { alert, sweetAlert } = useAlert();
  const [spinner, setSpinner] = useState(false);
  const [isConfiguration, setIsConfiguration] = useState(false);
  const [mainTreasuryModal, setMainTreasuryModal] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [accountClassification, setAccountClassification] = useState();
  const [pageId, setPageId] = useState("");

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm();

  const getActiveConfiguration = async () => {
    const res = await getActiveApi.request();

    if (res.status === 200) {
      if (res.data.data === null) setIsConfiguration(false);
      else {
        setPageId(res.data.data.id);
        setValue(
          "customersParentAccount",
          res.data.data?.customersParent?.name || ""
        );
        setValue("customersParentId", res.data.data?.customersParentId || "");
        setValue("mainTreasuryId", res.data.data?.mainTreasuryId || "");
        setValue(
          "mainTreasuryAccount",
          res.data.data?.mainTreasury?.name || ""
        );
        setValue(
          "profitLossAccountId",
          res.data.data?.profitLossAccountId || ""
        );
        setValue(
          "profitLossAccountAccount",
          res.data.data?.profitLossAccount?.name || ""
        );
        setValue("revenueAccountId", res.data.data?.revenueAccountId || "");
        setValue(
          "revenueAccountAccount",
          res.data.data?.revenueAccount?.name || ""
        );
        setValue("usersParentId", res.data.data?.usersParentId || "");
        setValue("usersParentAccount", res.data.data?.usersParent?.name || "");
        setValue("transfersAccountId", res.data.data?.transfersAccountId || "");
        setValue(
          "transfersAccountAccount",
          res.data.data?.transfersAccount?.name || ""
        );
        setValue(
          "resizingRepricingAccountId",
          res.data.data?.resizingRepricingAccountId || ""
        );
        setValue(
          "resizingRepricingAccountAccount",
          res.data.data?.resizingRepricingAccount?.name || ""
        );
        setValue("brokersParentId", res.data.data?.brokersParentId || "");
        setValue(
          "brokersParentAccount",
          res.data.data?.brokersParent?.name || ""
        );
        setValue(
          "shippingExpensesAccountId",
          res.data.data?.shippingExpensesAccountId || ""
        );
        setValue(
          "shippingExpensesAccountAccount",
          res.data.data?.shippingExpensesAccount?.name || ""
        );
        setValue(
          "customsExpensesAccountId",
          res.data.data?.customsExpensesAccountId || ""
        );
        setValue(
          "customsExpensesAccountAccount",
          res.data.data?.customsExpensesAccount?.name || ""
        );
        setValue(
          "operatorExpensesAccountId",
          res.data.data?.operatorExpensesAccountId || ""
        );
        setValue(
          "operatorExpensesAccountAccount",
          res.data.data?.operatorExpensesAccount?.name || ""
        );
        setValue(
          "transportExpensesAccountId",
          res.data.data?.transportExpensesAccountId || ""
        );
        setValue(
          "transportExpensesAccountAccount",
          res.data.data?.transportExpensesAccount?.name || ""
        );
        setValue(
          "transfersCommissionAccountId",
          res.data.data?.transfersCommissionAccountId || ""
        );
        setValue(
          "transfersCommissionAccountAccount",
          res.data.data?.transfersCommissionAccount?.name || ""
        );
        setValue(
          "otherBulkExpensesAccountId",
          res.data.data?.otherBulkExpensesAccountId || ""
        );
        setValue(
          "otherBulkExpensesAccountAccount",
          res.data.data?.otherBulkExpensesAccount?.name || ""
        );
        setValue("usdToLYDRate", res.data.data?.usdToLYDRate || "");
        setValue("euroToLYDRate", res.data.data?.euroToLYDRate || "");
        setIsConfiguration(true);
      }
    }
  };

  const createConfiguration = async () => {
    setSpinner(true);
    const res = await createOneApi.request({});
    if (res.status === 200) {
      setPageId(res.data.data.id);
      setIsConfiguration(true);
      sweetAlert(commonContent.done[culture]);
    }
    setSpinner(false);
  };

  const updateConfiguration = async (schema) => {
    setSpinner(true);
    const res = await updateOneApi.request(pageId, schema);
    if (res.status === 200) {
      sweetAlert(commonContent.done[culture]);
    }
    setSpinner(false);
  };

  const handleCancel = () => {
    setAccountClassification(0);
    setMainTreasuryModal(false);
  };

  useEffect(() => {
    getActiveConfiguration();
  }, []);

  if (getActiveApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {getActiveApi.errorAlert}
      {createOneApi.errorAlert}
      {updateOneApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.title[culture]]} />
      <Container className="mt--6" fluid>
        {!isConfiguration ? (
          <Card>
            <CardHeader>
              <h3 className={`mb-0`}>{content.title[culture]}</h3>
            </CardHeader>
            <CardBody>
              <h4>{content.configureConfiguration[culture]}</h4>
              <Button
                size="sm"
                type="button"
                className="cml-2 mt-2"
                color="primary"
                onClick={createConfiguration}
              >
                <span className="btn-inner--icon me-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">
                  {content.createConfiguration[culture]}
                </span>
              </Button>
            </CardBody>
          </Card>
        ) : (
          <form onSubmit={handleSubmit(updateConfiguration)}>
            <Card>
              <CardHeader>
                <h3 className={`mb-0`}>{content.title[culture]}</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="mainTreasuryAccount"
                      >
                        {content.mainTreasury[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="mainTreasuryAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="mainTreasuryAccount"
                            type="text"
                            className={errors.mainTreasuryAccount && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                            }}
                            onClick={() => {
                              setFieldName("mainTreasury");
                              setAccountClassification(2);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="customersParentAccount"
                      >
                        {content.customersParentAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="customersParentAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="customersParentAccount"
                            type="text"
                            className={errors.customersParentAccount && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("customersParent");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="usersParentAccount"
                      >
                        {content.staffAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="usersParentAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="usersParentAccount"
                            type="text"
                            className={errors.usersParentAccount && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("usersParent");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="revenueAccountAccount"
                      >
                        {content.revenueAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="revenueAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="revenueAccountAccount"
                            type="text"
                            className={errors.revenueAccountAccount && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("revenueAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="profitLossAccountAccount"
                      >
                        {content.profitLossAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="profitLossAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="profitLossAccountAccount"
                            type="text"
                            className={
                              errors.profitLossAccountAccount && "error"
                            }
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("profitLossAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="transfersAccountAccount"
                      >
                        {content.transferAccounts[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="transfersAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="transfersAccountAccount"
                            type="text"
                            className={
                              errors.transfersAccountAccount && "error"
                            }
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("transfersAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="brokersParentAccount"
                      >
                        {content.brokerAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="brokersParentAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="brokersParentAccount"
                            type="text"
                            className={errors.brokersParent && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("brokersParent");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="resizingRepricingAccountAccount"
                      >
                        {content.resizingRepricingAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="resizingRepricingAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="resizingRepricingAccountAccount"
                            type="text"
                            className={
                              errors.resizingRepricingAccountAccount && "error"
                            }
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("resizingRepricingAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="usdToLYDRate"
                      >
                        {content.usdToLyRate[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="usdToLYDRate"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="usdToLYDRate"
                            type="number"
                            className={errors.usdToLYDRate && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="euroToLYDRate"
                      >
                        {content.euroToLYDRate[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="euroToLYDRate"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="euroToLYDRate"
                            type="number"
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(+value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h3 className={`mb-0`}>
                  {content.bulkExpensesAccounts[culture]}
                </h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="shippingExpensesAccountAccount"
                      >
                        {content.shippingExpensesAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="shippingExpensesAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="shippingExpensesAccountAccount"
                            type="text"
                            className={
                              errors.shippingExpensesAccountAccount && "error"
                            }
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("shippingExpensesAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="customsExpensesAccountAccount"
                      >
                        {content.customersExpensesAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="customsExpensesAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="customsExpensesAccountAccount"
                            type="text"
                            className={
                              errors.customsExpensesAccountAccount && "error"
                            }
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("customsExpensesAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="operatorExpensesAccount"
                      >
                        {content.operatorExpensesAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="operatorExpensesAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="operatorExpensesAccount"
                            type="text"
                            className={
                              errors.operatorExpensesAccountAccount && "error"
                            }
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("operatorExpensesAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="transportExpensesAccount"
                      >
                        {content.transportExpensesAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="transportExpensesAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="transportExpensesAccount"
                            type="text"
                            className={
                              errors.transportExpensesAccountAccount && "error"
                            }
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("transportExpensesAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="transfersCommissionAccount"
                      >
                        {content.transfersCommissionAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="transfersCommissionAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="transportExpensesAccount"
                            type="text"
                            className={
                              errors.transfersCommissionAccountAccount && "error"
                            }
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("transfersCommissionAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="otherBulkExpensesAccount"
                      >
                        {content.otherBulkExpensesAccount[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="otherBulkExpensesAccountAccount"
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="otherBulkExpensesAccount"
                            type="text"
                            className={
                              errors.otherBulkExpensesAccountAccount && "error"
                            }
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                            onClick={() => {
                              setFieldName("otherBulkExpensesAccount");
                              setAccountClassification(0);
                              setMainTreasuryModal(true);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button className="mr-2" color="info" size="md" type="submit">
                  <span>{commonContent.save[culture]}</span>
                </Button>
              </CardBody>
            </Card>
          </form>
        )}
        <TextPopup
          modal={mainTreasuryModal}
          noBtn
          handleCancel={handleCancel}
          fn={() => {}}
          name={content.mainTreasury[culture]}
          color="success"
        >
          <FilterEntries
            watch={watch}
            setValue={setValue}
            setAccountModal={setMainTreasuryModal}
            fieldName={fieldName}
            accountClassification={accountClassification || null}
          />
        </TextPopup>
      </Container>
    </>
  );
};

export default Settings;
