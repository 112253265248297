import useCulture from "hooks/useCulture";
import React from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";

const content = {
  title: {
    ar: "حساب العميل",
    en: "Cusomer Account",
  },
  name: {
    ar: "اسم الحساب",
    en: "Account's Name",
  },
  code: {
    ar: "رمز الحساب",
    en: "Account's Code",
  },
  type: {
    ar: "نوع الحساب",
    en: "Account's Type",
  },
  company: {
    ar: "شركة",
    en: "Company",
  },
  personal: {
    ar: "شخصي",
    en: "personal",
  },
  category: {
    ar: "تصنيف",
    en: "category",
  },
  normal: {
    ar: "عادى",
    en: "normal",
  },
  vip: {
    ar: "مهم",
    en: "VIP",
  },
};

function CustomerCard({ list }) {
  const culture = useCulture();

  const setEnum = (type) => {
    switch (type) {
      case 1:
        return content.company[culture];
      case 2:
        return content.personal[culture];
      default:
        return type;
    }
  };

  return (
    <Row>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.name[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.name[culture]}
            type="text"
            readOnly
            style={{ color: "black" }}
            value={list?.name ? list.name : ""}
          />
        </FormGroup>
      </Col>
      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.code[culture]}
          </label>
          <Input
            id="example3cols1Input"
            placeholder={content.code[culture]}
            type="number"
            readOnly
            style={{ color: "black" }}
            value={list?.code ? list.code : ""}
          />
        </FormGroup>
      </Col>

      <Col md="4" lg="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="example3cols1Input">
            {content.type[culture]}
          </label>

          <Input
            id="example3cols1Input"
            placeholder={content.type[culture]}
            type="text"
            readOnly
            style={{ color: "black" }}
            value={list?.type ? setEnum(list.type) : ""}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export default CustomerCard;
