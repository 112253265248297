import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";

const content = {
  done: {
    ar: "تمت العملية بنجاح",
    en: "Done",
  },
};

const useUpdate = (
  apiFun,
  data,
  setData,
  setSpinner,
  setModal,
  sweetAlert,
  reset = () => {}
) => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const updateHandler = async (id, formData = "") => {
    setModal(false);
    setSpinner(true);
    const res = await apiFun.request(id, formData);
    if (res.status === 200) {
      let newData;
      if (Array.isArray(data)) {
        newData = [...data];
        const rowIndex = newData.findIndex((item) => item.id === id);
        newData[rowIndex] = { ...res.data.data };
      } else {
        newData = { ...res.data.data };
      }
      setData(newData);
      sweetAlert(content.done[culture]);
    }
    reset();
    setSpinner(false);
  };

  return updateHandler;
};

export default useUpdate;
