// import Alternative from "views/pages/dashboards/Alternative.js";
// import Buttons from "views/pages/components/Buttons.js";
// import Calendar from "views/pages/Calendar.js";
// import Cards from "views/pages/components/Cards.js";
// import Charts from "views/pages/Charts.js";
// import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
// import Elements from "views/pages/forms/Elements.js";
// import Google from "views/pages/maps/Google.js";
// import Grid from "views/pages/components/Grid.js";
// import Icons from "views/pages/components/Icons.js";
//import Lock from "views/pages/examples/Lock.js";
//import Login from "views/pages/examples/Login.js";

//Main
import Lock from "Screen/lock/lock";
import Login from "Screen/Login/Login";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import Register from "Screen/register/register";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Timeline from "views/pages/examples/Timeline.js";

//Accounting
import MainAccounting from "Screen/Accounting/MainAccounting/MainAccounting";
import Journal from "Screen/Accounting/Journal/Journal";
import Ledger from "Screen/Accounting/Ledger/Ledger";

//Accounts
import CategoryDetails from "Screen/Accounts/CategoryDetails/CategoryDetails";
import CreateAccountingLevel from "Screen/Accounts/CreateAccountingLevel/CreateAccountingLevel";
import AccountingLevelDetails from "Screen/Accounts/AccountingLevelDetails/AccountingLevelDetails";
import EditAccountingLevel from "Screen/Accounts/EditAccountingLevel/EditAccountingLevel";

//Extra
import AddReleaseOrder from "Screen/Extra pages/AddReleaseOrder/AddReleaseOrder";
// import AddAnAccount from "Screen/AddAnAccount/AddAnAccount";
// import CustodySettlement from "Screen/CustodySettlement/CustodySettlement";
import AddCustodySettlement from "Screen/Extra pages/AddCustodySettlement/AddCustodySettlement";
// import Transactions from "Screen/Transactions/Transactions";
import AddTransaction from "Screen/Extra pages/AddTransaction/AddTransaction";
import TrialBalance from "Screen/Accounting/TrialBalance/TrialBalance";
import AddBankAccount from "Screen/Extra pages/AddBankAccount/AddBankAccount";
import BankDetails from "Screen/Extra pages/BankDetails/BankDetails";
import AddBranch from "Screen/Extra pages/AddBranch/AddBranch";
import BankBranchDetails from "Screen/Extra pages/BankBranchDetails/BankBranchDetails";
import BankAccountDetails from "Screen/Extra pages/BankAccountDetails/BankAccountDetails";
import EditBankAccount from "Screen/Extra pages/EditBankAccount/EditBankAccount";
import EditBankBranch from "Screen/Extra pages/EditBankBranch/EditBankBranch";
import ManagingWrongCheques from "Screen/Extra pages/ManagingWrongCheques/ManagingWrongCheques";
import EditChequebook from "Screen/Extra pages/EditChequebook/EditChequebook";
import RecordWrongCheque from "Screen/Extra pages/RecordWrongCheque/RecordWrongCheque";
import CancelWrongCheque from "Screen/Extra pages/CancelWrongCheque/CancelWrongCheque";
import AddWrongCheque from "Screen/Extra pages/AddWrongCheque/AddWrongCheque";
import CreateChequebook from "Screen/Extra pages/CreateChequebook/CreateChequebook";
import ChequebookDetails from "Screen/Extra pages/ChequebookDetails/ChequebookDetails";
import EditWrongCheque from "Screen/Extra pages/EditWrongCheque/EditWrongCheque";
import LinkNewAccount from "Screen/Extra pages/LinkNewAccount/LinkNewAccount";
import RecordAuthorization from "Screen/Extra pages/RecordAuthorization/RecordAuthorization";
// import AccountingManual from "Screen/Accounting/AccountingManual/AccountingManual";
// import CustomerAccount from "Screen/Settings/CustomerAccount/CustomerAccount";

//FinancialReposts
import Balancesheet from "Screen/FinancialReposts/Balancesheet/Balancesheet";
import IncomeStatement from "Screen/FinancialReposts/IncomeStatement/IncomeStatement";
import OperatingAccount from "Screen/FinancialReposts/OperatingAccount/OperatingAccount";
import TradingAccounts from "Screen/FinancialReposts/TradingAccounts/TradingAccounts";

//Fiscal Year
import CreateFiscalYear from "Screen/FiscalYear/CreateFiscalYear/CreateFiscalYear";
// import LogFiscalYear from "Screen/FiscalYear/LogFiscalYear/LogFiscalYear";

//Transaction
import CreateEntry from "Screen/Transaction/CreateEntry/CreateEntry";
import CreateFreeEntry from "Screen/Transaction/CreateFreeEntry/CreateFreeEntry";
import EditEntry from "Screen/Transaction/EditEntry/EditEntry";
import EntryDetials from "Screen/Transaction/EntryDetails/EntryDetails";
// import ReleaseOrder from "Screen/Transaction/ReleaseOrder/ReleaseOrder";

//Review
import FinancialAuditReport from "Screen/Review/FinancialAuditReport/FinancialAuditReport";

//Settings
import CostCenter from "Screen/Settings/CostCenter/CostCenter";
// import Currency from "Screen/Settings/Currency/Currency";
import FiscalYear from "Screen/Settings/FiscalYear/FiscalYear";
import FinancialAuditors from "Screen/Settings/financialAuditors/financialAuditors";
import Settings from "Screen/Settings/Settings/Settings";

//Accounting Guide
import AccountingLevels from "Screen/Accounts/AccountingLevels/AccountingLevels";
import Categories from "Screen/Accounts/Categories/Categories";
import Accounts from "Screen/Accounting/Accounts/Accounts";
import AccountingGuide from "Screen/Accounting/AccountingGuide/AccountingGuide";

//Operations
import Receipt from "Screen/Voucher/receipt/receipt";
import JournalEntry from "Screen/Transaction/JournalEntry/JournalEntry";
import TreasuryReceipt from "Screen/Operations/TreasuryReceipt/treasuryReceipt";

//Reports
import ClientCredit from "Screen/Accounts/clientCredit/clientCredit";
import ProfitLossAccounts from "Screen/FinancialReposts/Profit&LossAccounts/profit&lossAccounts";
import Balance from "Screen/Accounting/Balance/Balance";
import AccountDetails from "Screen/Accounting/AccountingGuide/AccountDetails";
import Users from "Screen/UsersSettings/Users/Users";
import UserPermissions from "Screen/UsersSettings/UserPermissions/UserPermissions";
import Roles from "Screen/UsersSettings/Roles/Roles";
import RolePermissions from "Screen/UsersSettings/RolePermissions/RolePermissions";
import DebtForCustomer from "Screen/Operations/DebtForCustomer/DebtForCustomer";
import CustomerBalance from "Screen/Accounting/CustomerBalance/CustomerBalance";
import BulkExpensesEntry from "Screen/Operations/BulkExpensesEntry/BulkExpensesEntry";
import FollowUpShipments from "Screen/reports/FollowUpShipments/FollowUpShipments";
import BulkInformation from "Screen/reports/BulkInformation/BulkInformation";
import TransfersRelatedEntries from "Screen/Transaction/TransfersRelatedEntries/TransfersRelatedEntries";
import TreasuryBalance from "Screen/reports/TreasuryBalance/TreasuryBalance";
import AccountTransactionReport from "Screen/reports/AccountTransactionReport/AccountTransactionReport";
//Voucher
// import Payment from "Screen/Voucher/payment/payment";

const routes = [
  // { // single
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // { multi
  //   collapse: true, // in case of single dont emport it or declare false
  //   name: "Dashboards",
  //   icon: "ni ni-shop text-primary",
  //   state: "dashboardsCollapse",
  //   // views: [
  //   {
  //     path: "/dashboard",
  //     name: "Dashboard",
  //     miniName: "D",
  //     component: Dashboard,
  //     layout: "/ar-admin",
  //   },
  //   {
  //     path: "/dashboard",
  //     name: "Dashboard",
  //     miniName: "D",
  //     component: Dashboard,
  //     layout: "/en-admin",
  //   },
  //   {
  //     path: "/alternative-dashboard",
  //     name: "Alternative",
  //     miniName: "A",
  //     component: Alternative,
  //     layout: "/admin",
  //   },
  // ],
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    arName: "لوحة القيادة",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/en-admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    arName: "لوحة القيادة",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/ar-admin",
  },
  // {
  //   path: "/logfiscalyear",
  //   name: "Log fiscal year",
  //   arName: "تسجيل سنة مالية",
  //   icon: "fas fa-solid fa-calendar",
  //   // <i class="fa-solid fa-calendar"></i>
  //   component: LogFiscalYear,
  //   layout: "/ar-auth",
  // },
  // {
  //   path: "/logfiscalyear",
  //   name: "Log fiscal year",
  //   arName: "تسجيل سنة مالية",
  //   icon: "fa-solid fa-calendar-days",
  //   component: LogFiscalYear,
  //   layout: "/en-admin",
  // },
  /// general

  {
    collapse: true,
    name: "auth",
    icon: "far fa-user text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "P",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/ar-auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/en-auth",
      },
      // {
      //   path: "/logfiscalyear",
      //   name: "Log fiscal year",
      //   miniName: "L",
      //   component: LogFiscalYear,
      //   layout: "/ar-auth",
      // },
      // {
      //   path: "/logfiscalyear",
      //   name: "log fiscalyear",
      //   miniName: "L",
      //   component: LogFiscalYear,
      //   layout: "/en-auth",
      // },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/en-auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/ar-auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/en-auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/ar-auth",
      },
      {
        path: "/timeline",
        name: "Timeline",
        miniName: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        miniName: "RS",
        component: RTLSupport,
        layout: "/rtl",
      },
      //hidden route
      {
        path: "/addbankaccount/:id",
        component: AddBankAccount,
        layout: "/en-admin",
      },
      {
        path: "/addbankaccount/:id",
        component: AddBankAccount,
        layout: "/ar-admin",
      },
      {
        path: "/addbankaccount",
        component: AddBankAccount,
        layout: "/en-admin",
      },
      {
        path: "/addbankaccount",
        component: AddBankAccount,
        layout: "/ar-admin",
      },
      {
        path: "/createfiscalyear",
        component: CreateFiscalYear,
        layout: "/ar-admin",
      },
      {
        path: "/createfiscalyear",
        component: CreateFiscalYear,
        layout: "/en-admin",
      },
      {
        path: "/bankdetails/:id",
        component: BankDetails,
        layout: "/en-admin",
      },
      {
        path: "/bankdetails/:id",
        component: BankDetails,
        layout: "/ar-admin",
      },
      {
        path: "/addbranch/:id",
        component: AddBranch,
        layout: "/ar-admin",
      },
      {
        path: "/addbranch/:id",
        component: AddBranch,
        layout: "/en-admin",
      },
      {
        path: "/addbranch",
        component: AddBranch,
        layout: "/ar-admin",
      },
      {
        path: "/addbranch",
        component: AddBranch,
        layout: "/en-admin",
      },
      {
        path: "/bankbranchdetails/:id",
        component: BankBranchDetails,
        layout: "/en-admin",
      },
      {
        path: "/bankbranchdetails/:id",
        component: BankBranchDetails,
        layout: "/ar-admin",
      },
      {
        path: "/bankaccountdetails/:id",
        component: BankAccountDetails,
        layout: "/en-admin",
      },
      {
        path: "/bankaccountdetails/:id",
        component: BankAccountDetails,
        layout: "/ar-admin",
      },
      {
        path: "/editbankaccount/:id",
        component: EditBankAccount,
        layout: "/en-admin",
      },
      {
        path: "/editbankaccount/:id",
        component: EditBankAccount,
        layout: "/ar-admin",
      },
      {
        path: "/editbankbranch/:id",
        component: EditBankBranch,
        layout: "/en-admin",
      },
      {
        path: "/editbankbranch/:id",
        component: EditBankBranch,
        layout: "/ar-admin",
      },
      {
        path: "/managingwrongcheques/:id",
        component: ManagingWrongCheques,
        layout: "/ar-admin",
      },
      {
        path: "/managingwrongcheques/:id",
        component: ManagingWrongCheques,
        layout: "/en-admin",
      },
      {
        path: "/editchequebook/:id",
        component: EditChequebook,
        layout: "/ar-admin",
      },
      {
        path: "/editchequebook/:id",
        component: EditChequebook,
        layout: "/en-admin",
      },
      {
        path: "/recordwrongcheque/:id",
        component: RecordWrongCheque,
        layout: "/en-admin",
      },
      {
        path: "/recordwrongcheque/:id",
        component: RecordWrongCheque,
        layout: "/ar-admin",
      },
      {
        path: "/cancelwrongcheque/:id",
        component: CancelWrongCheque,
        layout: "/en-admin",
      },
      {
        path: "/cancelwrongcheque/:id",
        component: CancelWrongCheque,
        layout: "/ar-admin",
      },
      {
        path: "/addwrongcheque/:id",
        component: AddWrongCheque,
        layout: "/en-admin",
      },
      {
        path: "/addwrongcheque/:id",
        component: AddWrongCheque,
        layout: "/ar-admin",
      },
      {
        path: "/addwrongcheque",
        component: AddWrongCheque,
        layout: "/en-admin",
      },
      {
        path: "/addwrongcheque",
        component: AddWrongCheque,
        layout: "/ar-admin",
      },
      {
        path: "/createchequebook",
        component: CreateChequebook,
        layout: "/en-admin",
      },
      {
        path: "/createchequebook",
        component: CreateChequebook,
        layout: "/ar-admin",
      },
      {
        path: "/chequebookdetails/:id",
        component: ChequebookDetails,
        layout: "/en-admin",
      },
      {
        path: "/chequebookdetails/:id",
        component: ChequebookDetails,
        layout: "/ar-admin",
      },
      {
        path: "/editwrongcheque/:id",
        component: EditWrongCheque,
        layout: "/en-admin",
      },
      {
        path: "/editwrongcheque/:id",
        component: EditWrongCheque,
        layout: "/ar-admin",
      },
      {
        path: "/createaccountinglevel",
        component: CreateAccountingLevel,
        layout: "/en-admin",
      },
      {
        path: "/createaccountinglevel",
        component: CreateAccountingLevel,
        layout: "/ar-admin",
      },
      {
        path: "/accountingleveldetails/:id",
        component: AccountingLevelDetails,
        layout: "/en-admin",
      },
      {
        path: "/accountingleveldetails/:id",
        component: AccountingLevelDetails,
        layout: "/ar-admin",
      },
      {
        path: "/editaccoutninglevel/:id",
        component: EditAccountingLevel,
        layout: "/en-admin",
      },
      {
        path: "/editaccountinglevel/:id",
        component: EditAccountingLevel,
        layout: "/ar-admin",
      },
      {
        path: "/categorydetails/:id",
        component: CategoryDetails,
        layout: "/en-admin",
      },
      {
        path: "/categorydetails/:id",
        component: CategoryDetails,
        layout: "/ar-admin",
      },
      {
        path: "/linknewaccount/:id",
        component: LinkNewAccount,
        layout: "/en-admin",
      },
      {
        path: "/linknewaccount/:id",
        component: LinkNewAccount,
        layout: "/ar-admin",
      },
      {
        path: "/recordauthorization",
        component: RecordAuthorization,
        layout: "/en-admin",
      },
      {
        path: "/recordauthorization",
        component: RecordAuthorization,
        layout: "/ar-admin",
      },
      {
        path: "/addreleaseorder",
        component: AddReleaseOrder,
        layout: "/ar-admin",
      },
      {
        path: "/addreleaseorder",
        component: AddReleaseOrder,
        layout: "/en-admin",
      },
      {
        path: "/addcustodysettlement",
        component: AddCustodySettlement,
        layout: "/en-admin",
      },
      {
        path: "/addcustodysettlement",
        component: AddCustodySettlement,
        layout: "/ar-admin",
      },
      {
        path: "/addtransaction",
        component: AddTransaction,
        layout: "/ar-admin",
      },
      {
        path: "/addtransaction",
        component: AddTransaction,
        layout: "/ar-admin",
      },
      {
        path: "/transaction/createentry",
        component: CreateEntry,
        layout: "/ar-admin",
      },
      {
        path: "/transaction/createentry",
        component: CreateEntry,
        layout: "/en-admin",
      },
      {
        path: "/transaction/EditEntry/:id",
        component: EditEntry,
        layout: "/en-admin",
      },
      {
        path: "/transaction/EditEntry/:id",
        component: EditEntry,
        layout: "/ar-admin",
      },
      {
        path: "/transaction/entryDetails/:id",
        component: EntryDetials,
        layout: "/ar-admin",
      },
      {
        path: "/transaction/entryDetails/:id",
        component: EntryDetials,
        layout: "/en-admin",
      },
      {
        path: "/Accounting/account/:id",
        component: AccountDetails,
        layout: "/ar-admin",
      },
      {
        path: "/Accounting/account/:id",
        component: AccountDetails,
        layout: "/en-admin",
      },
      {
        path: "/reports/customer-transaction/:accountName/:id/:date",
        component: AccountTransactionReport,
        layout: "/en-admin",
      },
      {
        path: "/reports/customer-transaction/:accountName/:id/:date",
        component: AccountTransactionReport,
        layout: "/ar-admin",
      },
      {
        path: "/users/permissions/:id",
        component: UserPermissions,
        layout: "/ar-admin",
      },
      {
        path: "/users/permissions/:id",
        component: UserPermissions,
        layout: "/en-admin",
      },
      {
        path: "/role-permissions/:id",
        component: RolePermissions,
        layout: "/en-admin",
      },
      {
        path: "/role-permissions/:id",
        component: RolePermissions,
        layout: "/ar-admin",
      },
      {
        path: "/reports/follow-up-shipments/:bulkNumber/:countryId/:shipmentType",
        component: BulkInformation,
        layout: "/ar-admin",
      },
      {
        path: "/reports/follow-up-shipments/:bulkNumber/:countryId/:shipmentType",
        component: BulkInformation,
        layout: "/en-admin",
      },
      // {
      //   path: '/addanaccount',
      //   component: AddAnAccount,
      //   layout: '/en-admin'
      // },
      // {
      //   path: '/addanaccount',
      //   component: AddAnAccount,
      //   layout: '/ar-admin'
      // },
    ],
  },

  //Accounts Guide ar
  {
    collapse: true,
    name: "Accounts Guide",
    arName: "الدليل المحاسبي",
    icon: "fas fa-server text-green",
    state: "accountsGuide",
    layout: "/ar-admin",
    views: [
      {
        path: "/accountinglevels",
        component: AccountingLevels,
        arName: "المستويات المحاسبية",
        name: "Accounting levels",
        miniName: "AL",
        layout: "/ar-admin",
        pageName: "AccountLevel",
      },
      {
        path: "/categories",
        component: Categories,
        arName: "التصنيفات",
        name: "Categories",
        miniName: "Ca",
        layout: "/ar-admin",
        pageName: "AccountCategory",
      },
      {
        path: "/accountsGuide/accounts",
        name: "Accounts",
        miniName: "A",
        arName: "الحسابات",
        component: Accounts,
        layout: "/ar-admin",
        pageName: "Account",
      },
      {
        path: "/accountsGuide/AccountingGuide",
        name: "Accounts tree",
        miniName: "D",
        arName: "شجرة الحسابات",
        component: AccountingGuide,
        layout: "/ar-admin",
        pageName: "Account",
      },
      {
        path: "/accountsGuide/customer-balances",
        name: "Customer balances",
        miniName: "CB",
        arName: "ارصدة الزبائن",
        component: CustomerBalance,
        layout: "/ar-admin",
        pageName: "Account",
      },
    ],
  },

  //Accounts Guide en
  {
    collapse: true,
    name: "Accounts Guide",
    arName: "الدليل المحاسبي",
    icon: "fas fa-server text-green",
    state: "accountsGuide",
    layout: "/en-admin",
    views: [
      {
        path: "/accountinglevels",
        component: AccountingLevels,
        arName: "المستويات المحاسبية",
        name: "Accounting levels",
        miniName: "AL",
        layout: "/en-admin",
        pageName: "AccountLevel",
      },
      {
        path: "/categories",
        component: Categories,
        arName: "التصنيفات",
        name: "Categories",
        miniName: "Ca",
        layout: "/en-admin",
        pageName: "AccountCategory",
      },
      {
        path: "/accountsGuide/accounts",
        name: "Accounts",
        miniName: "A",
        arName: "الحسابات",
        component: Accounts,
        layout: "/en-admin",
        pageName: "Account",
      },
      {
        path: "/accountsGuide/AccountingGuide",
        name: "Accounts tree",
        miniName: "D",
        arName: "شجرة الحسابات",
        component: AccountingGuide,
        layout: "/en-admin",
        pageName: "Account",
      },
      {
        path: "/accountsGuide/customer-balances",
        name: "Customer balances",
        miniName: "CB",
        arName: "ارصدة الزبائن",
        component: CustomerBalance,
        layout: "/en-admin",
        pageName: "Account",
      },
    ],
  },

  //Operations ar
  {
    collapse: true,
    name: "Operations",
    icon: "fas fa-exchange-alt text-yellow",
    state: "operations",
    arName: "العمليات",
    layout: "/ar-admin",
    views: [
      {
        path: "/operations/receipt",
        name: "Receipt",
        miniName: "R",
        arName: "إيصالات القبض",
        component: Receipt,
        layout: "/ar-admin",
      },
      {
        path: "/operations/journalentry",
        name: "Journal entry",
        miniName: "J",
        arName: "جدول القيود",
        component: JournalEntry,
        layout: "/ar-admin",
        pageName: "Entry",
      },
      {
        path: "/operations/transfersrelatedentries",
        name: "Transers related entries",
        miniName: "TE",
        arName: "قيود الحوالات",
        component: TransfersRelatedEntries,
        layout: "/ar-admin",
        pageName: "Entry",
      },
      {
        path: "/operations/createfreeentry",
        name: "Create free entry",
        miniName: "FE",
        arName: "اضافة قيد حر",
        component: CreateFreeEntry,
        layout: "/ar-admin",
        pageName: "Entry",
      },
      // {
      //   path: "/operations/listoffinancial",
      //   name: "Review",
      //   miniName: "R",
      //   arName: "قائمة المراجع المالي",
      //   component: ListOfFinancialRef,
      //   layout: "/ar-admin",
      // },
      {
        path: "/financial-auditors",
        name: "Financial Auditors",
        arName: "المراجعيين الماليين",
        miniName: "FA",
        component: FinancialAuditors,
        layout: "/ar-admin",
      },
      {
        path: "/operations/treasury-receipt",
        name: "Treasury Receipt",
        miniName: "TR",
        arName: "قبض الخزينة",
        component: TreasuryReceipt,
        layout: "/ar-admin",
        pageName: "Entry",
        ability: "CreateAsync",
      },
      {
        path: "/operations/debtForCustomer",
        name: "A DC entry for a customer",
        miniName: "TR",
        arName: "قيد دين لعميل",
        component: DebtForCustomer,
        layout: "/ar-admin",
        // pageName: "Entry",
        // ability: "CreateAsync",
      },
      {
        path: "/operations/bulk-expenses-entry",
        name: "Bulk Expenses Entry",
        miniName: "BE",
        arName: "قيد مصاريف الـ Bulk",
        component: BulkExpensesEntry,
        layout: "/ar-admin",
        pageName: "Entry",
        ability: "CreateAsync",
      },
    ],
  },

  //Operations en
  {
    collapse: true,
    name: "Operations",
    icon: "fas fa-exchange-alt text-yellow",
    state: "operations",
    arName: "العمليات",
    layout: "/en-admin",
    views: [
      {
        path: "/operations/receipt",
        name: "Receipt",
        miniName: "R",
        arName: "إيصالات القبض",
        component: Receipt,
        layout: "/en-admin",
      },
      {
        path: "/operations/journalentry",
        name: "Journal entry",
        miniName: "J",
        arName: "جدول القيود",
        component: JournalEntry,
        layout: "/en-admin",
        pageName: "Entry",
      },
      {
        path: "/operations/transfersrelatedentries",
        name: "Transers related entries",
        miniName: "TE",
        arName: "قيود الحوالات",
        component: TransfersRelatedEntries,
        layout: "/en-admin",
        pageName: "Entry",
      },
      {
        path: "/operations/createfreeentry",
        name: "Create free entry",
        miniName: "FE",
        arName: "اضافة قيد حر",
        component: CreateFreeEntry,
        layout: "/en-admin",
        pageName: "Entry",
      },
      // {
      //   path: "/operations/listoffinancial",
      //   name: "Review",
      //   miniName: "R",
      //   arName: "قائمة المراجع المالي",
      //   component: ListOfFinancialRef,
      //   layout: "/en-admin",
      // },
      {
        path: "/financial-auditors",
        name: "Financial Auditors",
        arName: "المراجعيين الماليين",
        miniName: "FA",
        component: FinancialAuditors,
        layout: "/en-admin",
      },
      {
        path: "/operations/treasury-receipt",
        name: "Treasury Receipt",
        miniName: "TR",
        arName: "قبض الخزينة",
        component: TreasuryReceipt,
        layout: "/en-admin",
        pageName: "Entry",
      },
      {
        path: "/operations/debtForCustomer",
        name: "A debt entry for a customer",
        miniName: "DC",
        arName: "قيد دين لعميل",
        component: DebtForCustomer,
        layout: "/en-admin",
        // pageName: "Entry",
        // ability: "CreateAsync",
      },
      {
        path: "/operations/bulk-expenses-entry",
        name: "Bulk Expenses Entry",
        miniName: "BE",
        arName: "قيد مصاريف الـ Bulk",
        component: BulkExpensesEntry,
        layout: "/ar-admin",
        pageName: "Entry",
        ability: "CreateAsync",
      },
    ],
  },
  //Reports ar
  {
    collapse: true,
    name: "Reports",
    arName: "التقارير",
    icon: "fa fa-file-invoice-dollar text-red",
    state: "report",
    layout: "/ar-admin",
    views: [
      {
        path: "/reports/profit-loss-accounts",
        name: "Profit and Loss Accounts",
        miniName: "PL",
        arName: "حساب الأرباح والخسائر",
        component: ProfitLossAccounts,
        layout: "/ar-admin",
      },
      {
        path: "/reports/balance",
        name: "Balance",
        miniName: "B",
        // arName: "الميزانية",
        arName: "ميزان المراجعة",
        component: Balance,
        layout: "/ar-admin",
        pageName: "Account",
      },
      {
        path: "/reports/account-transaction-report",
        name: "Account transaction report",
        miniName: "CM",
        // arName: "كشف حركات الحساب",
        arName: "دفتر الاستاذ",
        component: AccountTransactionReport,
        layout: "/ar-admin",
        pageName: "Account",
      },
      {
        path: "/reports/clientCredit",
        name: "Client's Credit",
        arName: "أرصدة العملاء",
        miniName: "CC",
        component: ClientCredit,
        layout: "/ar-admin",
        pageName: "Account",
      },
      {
        path: "/reports/follow-up-shipments",
        name: "Follow up shipments",
        arName: "متابعة الشحنات",
        miniName: "FUS",
        component: FollowUpShipments,
        layout: "/ar-admin",
        // pageName: "entry",
      },
      {
        path: "/reports/treasury-balance",
        name: "Treasury balance",
        arName: "رصيد الخزينة",
        miniName: "TB",
        component: TreasuryBalance,
        layout: "/ar-admin",
        pageName: "Account",
      },
    ],
  },

  //Reports en
  {
    collapse: true,
    name: "Reports",
    arName: "التقارير",
    icon: "fa fa-file-invoice-dollar text-red",
    state: "report",
    layout: "/en-admin",
    views: [
      {
        path: "/reports/profit-loss-accounts",
        name: "Profit and Loss Accounts",
        miniName: "PL",
        arName: "حساب الأرباح والخسائر",
        component: ProfitLossAccounts,
        layout: "/en-admin",
      },
      {
        path: "/reports/balance",
        name: "Balance",
        miniName: "B",
        // arName: "الميزانية",
        arName: "ميزان المراجعة",
        component: Balance,
        layout: "/en-admin",
        pageName: "Account",
      },
      {
        path: "/reports/account-transaction-report",
        name: "Account transaction report",
        miniName: "CM",
        // arName: "كشف حركات الحساب",
        arName: "دفتر الاستاذ",
        component: AccountTransactionReport,
        layout: "/en-admin",
        pageName: "Account",
      },
      {
        path: "/reports/clientCredit",
        name: "Client's Credit",
        arName: "أرصدة العملاء",
        miniName: "CC",
        component: ClientCredit,
        layout: "/en-admin",
        pageName: "Account",
      },
      {
        path: "/reports/follow-up-shipments",
        name: "Follow up shipments",
        arName: "متابعة الشحنات",
        miniName: "FUS",
        component: FollowUpShipments,
        layout: "/en-admin",
        // pageName: "entry",
      },
      {
        path: "/reports/treasury-balance",
        name: "Treasury balance",
        arName: "رصيد الخزينة",
        miniName: "TB",
        component: TreasuryBalance,
        layout: "/en-admin",
        pageName: "Account",
      },
    ],
  },

  // customer settings
  {
    collapse: true,
    name: "Users Settings",
    arName: "إعدادات المستخدمين",
    icon: "fas fa-users-cog text-gray",
    state: "users-settings",
    layout: "/en-admin",
    views: [
      {
        path: "/users",
        name: "Users",
        arName: "المستخدمين",
        miniName: "U",
        component: Users,
        layout: "/en-admin",
        pageName: "AuthUsers",
      },
      {
        path: "/roles",
        name: "Roles",
        arName: "الصلاحيات",
        miniName: "R",
        component: Roles,
        layout: "/en-admin",
        pageName: "AuthRoles",
      },
    ],
  },
  {
    collapse: true,
    name: "Users Settings",
    arName: "إعدادات المستخدمين",
    icon: "fas fa-users-cog text-gray",
    state: "users-settings",
    layout: "/ar-admin",
    views: [
      {
        path: "/users",
        name: "Users",
        arName: "المستخدمين",
        miniName: "Users",
        component: Users,
        layout: "/ar-admin",
        pageName: "AuthUsers",
      },
      {
        path: "/roles",
        name: "Roles",
        arName: "الصلاحيات",
        miniName: "R",
        component: Roles,
        layout: "/ar-admin",
        pageName: "AuthRoles",
      },
    ],
  },

  // Settings ar
  {
    collapse: true,
    name: "Settings",
    arName: "الإعدادات",
    icon: "fas fa-cog text-gray",
    state: "settings",
    layout: "/ar-admin",
    views: [
      {
        path: "/fiscal-year",
        name: "Fiscal Year",
        arName: "السنة المالية",
        miniName: "FY",
        component: FiscalYear,
        layout: "/ar-admin",
        pageName: "FiscalYear",
      },
      {
        path: "/settings/constcenter",
        name: "Const center",
        miniName: "C",
        arName: "مركز التكلفة",
        component: CostCenter,
        layout: "/ar-admin",
        pageName: "CostCenter",
      },
      // {
      //   path: "/financial-auditors",
      //   name: "Financial Auditors",
      //   arName: "المراجعيين الماليين",
      //   miniName: "FA",
      //   component: FinancialAuditors,
      //   layout: "/ar-admin",
      // },
      {
        path: "/settings/settings",
        name: "Settings",
        miniName: "S",
        arName: "إعدادات",
        component: Settings,
        layout: "/ar-admin",
      },
      // {
      //   path: "/settings/cutomeraccounts",
      //   name: "Customers accounts",
      //   miniName: "CA",
      //   arName: "حسابات العملاء",
      //   component: CustomerAccount,
      //   layout: "/ar-admin",
      // },
      // {
      //   path: "/settings/currency",
      //   name: "Currency",
      //   miniName: "C",
      //   arName: "العملة",
      //   component: Currency,
      //   layout: "/ar-admin",
      // }
    ],
  },
  //Settings en
  {
    collapse: true,
    name: "Settings",
    arName: "الإعدادات",
    icon: "fas fa-cog text-gray",
    state: "settings",
    layout: "/en-admin",
    views: [
      {
        path: "/fiscal-year",
        name: "Fiscal Year",
        arName: "السنة المالية",
        miniName: "FY",
        component: FiscalYear,
        layout: "/en-admin",
        pageName: "FiscalYear",
      },
      {
        path: "/settings/constcenter",
        name: "Const center",
        miniName: "C",
        arName: "مركز التكلفة",
        component: CostCenter,
        layout: "/en-admin",
        pageName: "CostCenter",
      },
      // {
      //   path: "/financial-auditors",
      //   name: "Financial Auditors",
      //   arName: "المراجعيين الماليين",
      //   miniName: "FA",
      //   component: FinancialAuditors,
      //   layout: "/en-admin",
      // },
      {
        path: "/settings/settings",
        name: "Settings",
        miniName: "S",
        arName: "إعدادات",
        component: Settings,
        layout: "/en-admin",
      },

      // {
      //   path: "/settings/cutomeraccounts",
      //   name: "Customers accounts",
      //   miniName: "CA",
      //   arName: "حسابات العملاء",
      //   component: CustomerAccount,
      //   layout: "/en-admin",
      // },
      // {
      //   path: "/settings/currency",
      //   name: "Currency",
      //   miniName: "C",
      //   arName: "العملة",
      //   component: Currency,
      //   layout: "/en-admin",
      // }
    ],
  },
  // //Vouchers ar
  // {
  //  collapse: true,
  //  name: "Voucher",
  //  icon: "fa fa-receipt text-red",
  //  state: "vouchers",
  //  arName: "الإيصالات",
  //  layout: "/ar-admin",
  //  views: [
  //    {
  //      path: "/voucher/receipt",
  //      name: "Receipt",
  //      miniName: "R",
  //      arName: "إيصالات القبض",
  //      component: Receipt,
  //      layout: "/ar-admin",
  //    },
  //    {
  //      path: "/voucher/payment",
  //      name: "Payment",
  //      miniName: "P",
  //      arName: "إيصالات الدفع",
  //      component: Payment,
  //      layout: "/ar-admin",
  //    }]},
  // //Vouchers en
  // {
  //  collapse: true,
  //  name: "Voucher",
  //  icon: "ni ni-shop text-primary",
  //  state: "vouchers",
  //  arName: "الإيصالات",
  //  layout: "/en-admin",
  //  views: [
  //    {
  //      path: "/voucher/receipt",
  //      name: "Receipt",
  //      miniName: "R",
  //      arName: "إيصالات القبض",
  //      component: Receipt,
  //      layout: "/en-admin",
  //    },
  //    {
  //      path: "/voucher/payment",
  //      name: "Payment",
  //      miniName: "P",
  //      arName: "إيصالات الدفع",
  //      component: Payment,
  //      layout: "/en-admin",
  //    }]},
  // en shareable files
  // {
  //   path: "/shareable-files",
  //   name: "Shareable Files",
  //   arName: "SF",
  //   icon: "fas fa-file-export text-yellow",
  //   layout: "/en-admin",
  //   component: ShareableFiles,
  // },

  //   // en user manegment
  //   {
  //     collapse: true,
  //     name: "User Management",
  //     icon: "far fa-user text-orange",
  //     state: "examplesCollapse",
  //     layout: "/en-admin",
  //     views: [
  //       {
  //         path: "/pricing",
  //         name: "Pricing",
  //         miniName: "P",
  //         component: Pricing,
  //         layout: "/auth",
  //       },
  //       {
  //         path: "/login",
  //         name: "Login",
  //         miniName: "L",
  //         component: Login,
  //         layout: "/ar-auth",

  //       },
  //       {
  //         path: "/login",
  //         name: "Login",
  //         miniName: "L",
  //         component: Login,
  //         layout: "/en-auth",

  //       },
  //       {
  //         path: "/register",
  //         name: "Register",
  //         miniName: "R",
  //         component: Register,
  //         layout: "/en-auth",
  //       },
  //       {
  //         path: "/register",
  //         name: "Register",
  //         miniName: "R",
  //         component: Register,
  //         layout: "/ar-auth",
  //       },
  //       {
  //         path: "/lock",
  //         name: "Lock",
  //         miniName: "L",
  //         component: Lock,
  //         layout: "/en-auth",
  //       },      {
  //         path: "/lock",
  //         name: "Lock",
  //         miniName: "L",
  //         component: Lock,
  //         layout: "/ar-auth",
  //       },
  //       {
  //         path: "/timeline",
  //         name: "Timeline",
  //         miniName: "T",
  //         component: Timeline,
  //         layout: "/admin",
  //       },
  //       {
  //         path: "/profile",
  //         name: "Profile",
  //         miniName: "P",
  //         component: Profile,
  //         layout: "/admin",
  //       },
  //       {
  //         path: "/rtl-support",
  //         name: "RTL Support",
  //         miniName: "RS",
  //         component: RTLSupport,
  //         layout: "/rtl",
  //       },
  //     ],
  //   },

  // // ar user manegment
  // {
  //   collapse: true,
  //   name: "User Management",
  //   arName: "إدارة المستخدمين",
  //   icon: "far fa-user text-orange",
  //   state: "examplesCollapse",
  //   layout: "/ar-admin",
  //   views: [
  //     {
  //       path: "/pricing",
  //       arName: "إدارة صلاحيات المستخدمين",
  //       name: "Pricing",
  //       miniName: "P",
  //       component: userActivity,
  //       layout: "/ar-admin",
  //     },
  //     {
  //       path: "/login",
  //       arName: "إدارة أدوار المستخدمين",
  //       miniName: "L",
  //       component: Login,
  //       layout: "/ar-auth",

  //     },

  //     {
  //       path: "/register",
  //       name: "Register",
  //       miniName: "R",
  //       component: Register,
  //       layout: "/ar-auth",
  //     },

  //   ],
  // },

  //   // en activitys
  //   {
  //     collapse: true,
  //     name: "Activities",
  //     arName: "الأنشطة",
  //     icon: "fas fa-history text-info",
  //     state: "componentsCollapse",
  //     layout: "/en-admin",
  //     views: [
  //       {
  //         path: "/buttons",
  //         name: "Buttons",
  //         miniName: "B",
  //         component: Buttons,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/cards",
  //         name: "Cards",
  //         miniName: "C",
  //         component: Cards,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/grid",
  //         name: "Grid",
  //         miniName: "G",
  //         component: Grid,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/notifications",
  //         name: "Notifications",
  //         miniName: "N",
  //         component: Notifications,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/icons",
  //         name: "Icons",
  //         miniName: "I",
  //         component: Icons,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/typography",
  //         name: "Typography",
  //         miniName: "T",
  //         component: Typography,
  //         layout: "/en-admin",
  //       },
  //       {
  //         collapse: true,
  //         name: "Multi Level",
  //         miniName: "M",
  //         state: "multiCollapse",
  //         views: [
  //           {
  //             path: "#pablo",
  //             name: "Third level menu",
  //             component: () => { },
  //             layout: "/",
  //           },
  //           {
  //             path: "#pablo",
  //             name: "Just another link",
  //             component: () => { },
  //             layout: "/",
  //           },
  //           {
  //             path: "#pablo",
  //             name: "One last link",
  //             component: () => { },
  //             layout: "/",
  //           },
  //         ],
  //       },
  //     ],
  //   },

  //     // ar activitys
  //     {
  //       collapse: true,
  //       name: "Activities",
  //       arName: "الأنشطة",
  //       icon: "fas fa-history text-info",
  //       state: "componentsCollapse",
  //       layout: "/ar-admin",
  //       views: [
  //         {
  //           path: "/buttons",
  //           name: "Buttons",
  //           miniName: "B",
  //           component: Buttons,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/cards",
  //           name: "Cards",
  //           miniName: "C",
  //           component: Cards,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/grid",
  //           name: "Grid",
  //           miniName: "G",
  //           component: Grid,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/notifications",
  //           name: "Notifications",
  //           miniName: "N",
  //           component: Notifications,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/icons",
  //           name: "Icons",
  //           miniName: "I",
  //           component: Icons,
  //           layout: "/en-admin",
  //         },
  //         {
  //           path: "/typography",
  //           name: "Typography",
  //           miniName: "T",
  //           component: Typography,
  //           layout: "/en-admin",
  //         },
  //         {
  //           collapse: true,
  //           name: "Multi Level",
  //           miniName: "M",
  //           state: "multiCollapse",
  //           views: [
  //             {
  //               path: "#pablo",
  //               name: "Third level menu",
  //               component: () => { },
  //               layout: "/",
  //             },
  //             {
  //               path: "#pablo",
  //               name: "Just another link",
  //               component: () => { },
  //               layout: "/",
  //             },
  //             {
  //               path: "#pablo",
  //               name: "One last link",
  //               component: () => { },
  //               layout: "/",
  //             },
  //           ],
  //         },
  //       ],
  //     },

  //   // en system anegment
  //   {
  //     collapse: true,
  //     name: "System Manegment",
  //     arName: "إدارة الأنظمة",
  //     icon: "fab fa-ubuntu text-pink",
  //     state: "formsCollapse",
  //     layout: "/en-admin",

  //     views: [
  //       {
  //         path: "/elements",
  //         name: "Elements",
  //         miniName: "E",
  //         component: Elements,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/components",
  //         name: "Components",
  //         miniName: "C",
  //         component: Components,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/validation",
  //         name: "Validation",
  //         miniName: "V",
  //         component: Validation,
  //         layout: "/en-admin",
  //       },
  //     ],
  //   },
  //   // ar system anegment
  //   {
  //     collapse: true,
  //     name: "System Manegment",
  //     arName: "إدارة الأنظمة",
  //     icon: "fab fa-ubuntu text-pink",
  //     state: "formsCollapse",
  //     layout: "/ar-admin",

  //     views: [
  //       {
  //         path: "/elements",
  //         name: "Elements",
  //         miniName: "E",
  //         component: Elements,
  //         layout: "/ar-admin",
  //       },
  //       {
  //         path: "/components",
  //         name: "Components",
  //         miniName: "C",
  //         component: Components,
  //         layout: "/ar-admin",
  //       },
  //       {
  //         path: "/validation",
  //         name: "Validation",
  //         miniName: "V",
  //         component: Validation,
  //         layout: "/ar-admin",
  //       },
  //     ],
  //   },

  //   // en reports
  //   {
  //     collapse: true,
  //     name: "Reports",
  //     arName: "التقارير",
  //     icon: "far fa-newspaper text-default",
  //     state: "tablesCollapse",
  //     layout: "/en-admin",

  //     views: [
  //       {
  //         path: "/tables",
  //         name: "Tables",
  //         miniName: "T",
  //         component: userActivity,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/sortable",
  //         name: "Sortable",
  //         miniName: "S",
  //         component: Sortable,
  //         layout: "/en-admin",
  //       },
  //       {
  //         path: "/react-bs-table",
  //         name: "React BS Tables",
  //         miniName: "RBT",
  //         component: ReactBSTables,
  //         layout: "/en-admin",
  //       },
  //     ],
  //   },
  //     // ar reports
  //     {
  //       collapse: true,
  //       name: "Reports",
  //       arName: "التقارير",
  //       icon: "far fa-newspaper text-default",
  //       state: "tablesCollapse",
  //       layout: "/ar-admin",

  //       views: [
  //         {
  //           path: "/tables",
  //           name: "Tables",
  //           miniName: "T",
  //           component: Tables,
  //           layout: "/ar-admin",
  //         },
  //         {
  //           path: "/sortable",
  //           name: "Sortable",
  //           miniName: "S",
  //           component: Sortable,
  //           layout: "/ar-admin",
  //         },
  //         {
  //           path: "/react-bs-table",
  //           name: "React BS Tables",
  //           miniName: "RBT",
  //           component: ReactBSTables,
  //           layout: "/ar-admin",
  //         },
  //       ],
  //     },

  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       miniName: "G",
  //       component: Google,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       miniName: "V",
  //       component: Vector,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: Calendar,
  //   layout: "/admin",
  // },
];

export default routes;
