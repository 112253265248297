import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import classes from "./List.module.css";
import { UncontrolledTooltip } from "reactstrap";
import content from "../content";

const List = (props) => {
  const { date, culture, account, parentLvl, getChildren, maxAccountLevel } =
    props;

  const [loading, setLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [thisLevel] = useState(parentLvl + 1 || 1);

  const openedClass =
    isOpened || loading
      ? classes[`${culture}-opened`]
      : classes[`${culture}-closed`];

  const handleOpen = () => {
    setLoading(true);
    // if the account doesn't have a subLink attribute send the request
    if (!account.hasOwnProperty("subAccounts")) {
      getChildren(account.id, account.number);
    }

    setIsOpened((open) => !open);
  };

  const accountCondition = account.hasOwnProperty("subAccounts")
    ? account.subAccounts?.length
    : true;

  const isMaxLevel = thisLevel === maxAccountLevel;

  const icon =
    isMaxLevel || !accountCondition ? (
      <button className={classes.listBtn}>
        {/* <i className={`fas fa-book-open ${classes.icon}`} /> */}
        <i className={`far fa-stop-circle ${classes.icon}`}></i>
      </button>
    ) : (
      <button className={classes.listBtn}>
        <i className={`fas fa-sort-down ${classes.icon} ${openedClass}`}></i>
      </button>
    );

  useEffect(() => {
    if (account.hasOwnProperty("subAccounts")) setLoading(false);
  }, [isOpened]);

  useEffect(() => {
    if (props.account.hasOwnProperty("subAccounts")) setLoading(false);
  }, [props]);

  return (
    <ul className={classes.list}>
      <li>
        <div
          className={`${classes.content} ${classes[`level-${thisLevel}`]}`}
          onClick={isMaxLevel ? () => {} : handleOpen}
        >
          {icon}
          <h5>
            {account.number} {account.name}
          </h5>
          <div
            className={classes.iconsWrapper}
            onClick={(e) => e.stopPropagation()}
          >
            {account.isAnalytical && (
              <Link
                className="me-1"
                to={`/${culture}-admin/reports/customer-transaction/${account.name}/${account.id}/${date}`}
              >
                <span className="me-1" id={`treasury`}>
                  <div id="approve" className="table-action cursor-pointer">
                    {/* <i className="far fa-money-bill-alt hover-warning" /> */}
                    <i className="fas fa-dollar-sign hover-success" />
                  </div>
                </span>
                <UncontrolledTooltip delay={0} target="treasury">
                  {content.viewExpenses[culture]}
                </UncontrolledTooltip>
              </Link>
            )}
            <span className="cmr-2 fw-bold">{account.balanceLYD} LYD</span>
            <span
              className="cmr-2 fw-bold"
              dir="ltr"
              style={{ paddingInlineStart: 11 }}
            >
              $ {account.balanceUSD}
            </span>
          </div>
        </div>
        {loading && (
          <ReactLoading
            type="spin"
            color="#eee"
            style={{
              fill: "var(--gray)",
              height: "30px",
              width: "30px",
            }}
          />
        )}
        {isOpened &&
          account.subAccounts &&
          account.subAccounts.map((item) => (
            <List
              date={date}
              key={item.id}
              account={item}
              culture={culture}
              getChildren={getChildren}
              maxAccountLevel={maxAccountLevel}
              parentLvl={thisLevel}
            />
          ))}
      </li>
    </ul>
  );
};

export default List;
