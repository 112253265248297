import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";

const useCulture = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  return culture;
}

export default useCulture;