import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Button,
  Form,
  Col,
  Row,
  FormGroup,
  Input,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import content from "./content";
import List from "./components/List";
import useApi from "hooks/useApi";
import balance from "api/balance";
import account from "api/account";
import Spinner from "components/Spinner";
// import CreateAccount from './components/CreateAccount';
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
// import UpdateAccount from './components/UpdateAccount';

const Balance = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);
  const [years, setYears] = useState([]);
  const [levels, setLevels] = useState([]);
  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [fiscalYear, setFiscalYear] = useState("");

  const getRootsApi = useApi(balance.getRoots);
  const getYearsApi = useApi(balance.getYears);
  const getChildrenApi = useApi(balance.getChildren);
  const getLevelsApi = useApi(balance.getLevels);

  const { alert } = useAlert();

  // This useForm hook is for create account api
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm();

  // This useForm hook is for update account api
  const {
    handleSubmit: updateSubmit,
    formState: { errors: updateErrors },
    control: updateControl,
    reset: updateReset,
    watch: updateWatch,
    setValue: updateSetValue,
  } = useForm();

  const getYears = async () => {
    const res = await getYearsApi.request();
    if (res.status === 200) {
      // res.data.data.data.reverse();
      setYears(res.data.data.data);
    }
    const res2 = await getLevelsApi.request();
    if (res2.status === 200) {
      // res.data.data.data.reverse();
      setLevels(res2.data.data);
      console.log({ levels });
    }
  };

  useEffect(() => {
    getYears();
  }, []);

  if (getYearsApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  const getChildren = async (id) => {
    const res = await getChildrenApi.request(id, fiscalYear);

    if (res.status === 200) {
      const newData = { ...data };

      findParent(id, newData.data, res.data.data);

      setData(newData);
    }
  };

  const findParent = (parentId, accounts, resData) => {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === parentId) {
        return (accounts[i].subAccounts = resData);
      } else if (accounts[i].hasOwnProperty("subAccounts")) {
        findParent(parentId, accounts[i].subAccounts, resData);
      }
    }
  };

  const appendAccount = (accounts, resData) => {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === rowId) {
        if (accounts[i].subAccounts) {
          return accounts[i].subAccounts.unshift(resData);
        }
        return (accounts[i].subAccounts = resData);
      } else if (accounts[i].hasOwnProperty("subAccounts")) {
        appendAccount(accounts[i].subAccounts, resData);
      }
    }
  };

  const submitFiscalYear = async () => {
    setSpinner(true);
    const res = await getRootsApi.request(fiscalYear);

    if (res.status === 200) {
      // res.data.data.accountLevels.reverse();

      setData(res.data);
    }
    setSpinner(false);
  };

  return (
    <>
      {alert}
      {getRootsApi.errorAlert}
      {getChildrenApi.errorAlert}
      {getYearsApi.errorAlert}
      {getLevelsApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>{content.title[culture]}</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(submitFiscalYear)}>
              <Row className="align-items-center">
                <Col md="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="year">
                      {content.fiscalYear[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect1"
                      type="select"
                      defaultValue={true}
                      onChange={(e) => setFiscalYear(e.target.value)}
                    >
                      <option disabled value>
                        {" "}
                        -- {content.selectOption[culture]} --{" "}
                      </option>
                      {years.map((year) => (
                        <option key={year.id} value={year.id}>
                          {year.year}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="mt-2 mt-md-0  align-self-center" xs="4">
                  <Button className="mr-2" color="info" size="md" type="submit">
                    <span>{content.calculate[culture]}</span>
                  </Button>
                </Col>
              </Row>
            </Form>
            <hr />
            <h4 className="mb-3">{content.accountsTree[culture]}</h4>
            {data.data?.map((item) => (
              <List
                date={years.find((year) => year.id === fiscalYear).year}
                key={item.id}
                account={item}
                culture={culture}
                getChildren={getChildren}
                maxAccountLevel={levels?.length || 1}
              />
            ))}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Balance;
