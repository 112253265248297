import { useEffect } from "react";

function useFetch({ fun, pageNo, docsPerPage, isSearch = false }) {
  useEffect(() => {

    if (isSearch) return;
    let controller = new AbortController();
    fun();
    return () => controller?.abort();
  }, [pageNo, docsPerPage, isSearch]);
}

export default useFetch;
