import React, { useState } from 'react'
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useParams
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
//content 
import content from './content';
import SimpleHeader from 'components/Headers/SimpleHeader';
import RequestHandler from "helper/RequestHandler";
import catchAsync from 'helper/catchAsync';
import Spinner from 'components/Spinner';
import axios from 'axios';
import env from "env";
import ReactBSAlert from "react-bootstrap-sweetalert";

const CreateAccountingLevel = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [schema, setSchema] = useState({});
  const [spinner, setSpinner] = useState(false);

  const handleChange = (key, val) => {
    setSchema({
      ...schema,
      [key]: val
    })
  }

  const handleCreate = () => catchAsync(async () => {
    setSpinner(true);
    const result = await axios.post(`${env.activeServer()}/AccountLevels`, schema, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
        'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
      }
    })

    if (result.status === 200) {
      setData([
        result.data.data,
        ...data,
      ]);

      setSchema({
        name: "",
        digits: result.data.data.digits + 1
      })

      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          onConfirm={() => setAlert('')}
          onCancel={() => setAlert('')}
          confirmBtnBsStyle="info"
          confirmBtnText="Ok"
          btnSize=""
        >
          {result.statusText}
        </ReactBSAlert>
      )
    }
  }, () => { setSpinner(false) }, setAlert)

  return (
    <div>
      {alert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      {loading ? <RequestHandler 
        fn={async () => {
          const result = await axios.get(`${env.activeServer()}/AccountLevels`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem(btoa('token'))}`, 
              'FiscalYearToken': localStorage.getItem(btoa('FiscalYearToken'))
            }
          })

          if (result.status === 200) {
            setData(result.data.data);
            setSchema({
              ...schema,
              digits: Math.max.apply(Math, result.data.data.map(function(item) { return item.digits })) + 1 
            })            
          }
        }}
        setAlert={setAlert}
        setLoading={setLoading}
        gate="#11cdef"
        bg="#fff"
      />
      :<>
        <SimpleHeader name={content.title[culture]} parentName="" />
        <Container className="mt--6" fluid>
          <Card className="mb-4">
            <CardHeader>
              <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
            </CardHeader>
            <CardBody>
              <Row className='align-items-center'>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.previousLevel[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="select"
                      value={data[0].id}
                      disabled
                    >
                      <option disabled value> -- {content.selectOption[culture]} -- </option>
                      {data.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.NoOfDigits[culture]}
                    </label>
                    <Input
                      min={data[0].digits}
                      value={data[0].digits}
                      disabled
                      id="exampleFormControlSelect2"
                      type="number"
                      onChange={e => {}}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.levelName[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      type="text"
                      placeholder={content.levelName[culture]}
                      onChange={e => { handleChange("name", e.target.value) }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example3cols1Input"
                    >
                      {content.NoOfDigits[culture]}
                    </label>
                    <Input
                      id="exampleFormControlSelect2"
                      min={Math.max.apply(Math, data.map(function(item) { return item.digits })) + 1}
                      value={schema.digits || Math.max.apply(Math, data.map(function(item) { return item.digits })) + 1}
                      type="number"
                      onChange={e => { handleChange("digits", e.target.value) }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Button className="mr-2" color="primary" size="md" onClick={handleCreate}>
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span>
                      {content.accountingLevel[culture]}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </>}
    </div>
  )
}

export default CreateAccountingLevel
