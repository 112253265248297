export const addStyleToEntry = (entry) => {
  let style = {};
  if (entry.entryType === 5)
    style = { backgroundColor: "black", color: "white" };

  if (entry.entryType === 3)
    style = { backgroundColor: "green", color: "white" };

  if (entry.entryType === 1) style = { backgroundColor: "cyan" };

  if (entry.entryType === 19)
    style = { backgroundColor: "red", color: "white" };

  if (entry.entryType === 20)
    style = { backgroundColor: "gray", color: "white" };

  if (entry.entryType === 14) style = { backgroundColor: "ornage" };

  entry.style = style;
};
