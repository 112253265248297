export default {
  title: {
    ar: 'تفاصيل الحساب المصرفي',
    en: 'Bank account details'
  },
  branchName: {
    ar: 'اسم الفرع',
    en: 'Branch name'
  },
  branchCode: {
    ar: 'رمز الفرع',
    en: 'Branch code'
  },
  bankName: {
    ar: 'اسم المصرف',
    en: 'Bank name'
  },
  account: {
    ar: 'حساب',
    en: 'Account'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  currentCheck: {
    ar: 'دفتر الشيكات الحالي',
    en: 'Current check book'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  currentSequence: {
    ar: 'التسلسل الحالي',
    en: 'Current sequence'
  },
  dimensions: {
    ar: 'الابعاد',
    en: 'Dimensions'
  },
  from: {
    ar: 'من',
    en: 'From'
  },
  to: {
    ar: 'الى',
    en: 'To'
  },
  size: {
    ar: 'الحجم',
    en: 'The Size'
  },
  current: {
    ar: 'الحالي',
    en: 'Current'
  },
  effective: {
    ar: 'فعال',
    en: 'Effective'
  },
  test: {
    ar: 'تجربة',
    en: 'Test'
  },
  yes: {
    ar: 'نعم',
    en: 'Yes'
  },
  no: {
    ar: 'لا',
    en: 'No'
  },
  beginningOfNextCheque: {
    ar: 'بداية الدفتر التالي',
    en: 'Beginning of the next chequebook'
  },
  chequebook: {
    ar: 'دفتر الشيكات',
    en: 'Chequebook'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  wrongCheques: {
    ar: 'الصكوك الخاطئة',
    en: 'Wrong cheques'
  },
  managingChequebook: {
    ar: 'ادارة الدفاتر',
    en: 'Managing chequebook'
  },
  linkedAccount: {
    ar: 'اسم البند المرتبط',
    en: 'Linked account'
  },
  bankAccountNumber: {
    ar: 'رقم الحساب المصرفي',
    en: 'bank account number'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  register: {
    ar: 'تسجيل',
    en: 'Register'
  },
  record: {
    ar: 'تسجيل',
    en: 'Record'
  },
  cancel: {
    ar: 'الغاء',
    en: 'Cancel At'
  },
  none: {
    ar: "لا يوجد",
    en: "None"
  },
}