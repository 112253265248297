import client from "./shipping";

const endpoint = "/v1/branch";

// This api for filter and has no permission
// const getByPopupCoulum = (col, val, page, docsPerPage) =>
//   client.get(
//     `${endpoint}/getByName?name=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
//   );
const getByPopupCoulum = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getByCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

export default {
  getByPopupCoulum,
};
