export default {
  categoryName: {
    ar: 'تقارير مالية',
    en: 'Financial reports',
  },
  title: {
    ar: 'حساب الأرباح والخسائر',
    en: 'Profit and Loss Accounts'
  },
  level: {
    ar: 'المستوى',
    en: 'Level'
  },
  constCenter: {
    ar: 'مركز التكلفة',
    en: 'Cost center'
  },
  fromDate: {
    ar: 'من تاريخ',
    en: 'From date'
  },
  toDate: {
    ar: 'الى تاريخ',
    en: 'To date'
  },
  creditor: {
    ar: 'دائن',
    en: 'Creditor'
  },
  debtor: {
    ar: 'مدين',
    en: 'Debtor'
  },
  detailed: {
    ar: 'تفصيلي',
    en: 'detailed'
  },
  accountName: {
    ar: 'اسم الحساب',
    en: 'Account name'
  },
  totalDebit: {
    ar: 'اجمالي مدين',
    en: 'Total debit'
  },
  totalCredit: {
    ar: 'اجمالي دائن',
    en: 'Total credit'
  },
  print: {
    ar: 'طباعة',
    en: 'Print'
  },
  exportTable: {
    ar: 'تصدير الجدول',
    en: 'Export table'
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  selectOption: {
    ar: 'اختر خيار',
    en: 'Select option'
  },
  totalBlanace: {
    ar: 'الرصيد الاجمالي',
    en: 'Total balance'
  }
}