import { useEffect } from "react";

const useFetchById = (apiFun, id, setData) => {
  const fetchData = async () => {
    const res = await apiFun.request(id);
    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

};

export default useFetchById;