export default {
  title: {
    ar: 'اضافة حساب مصرفي',
    en: 'Add bank account'
  },
  bankName: {
    ar: 'اسم المصرف',
    en: 'Bank name'
  },
  branchName: {
    ar: 'اسم الفرع',
    en: 'Branch name'
  },
  branchCode: {
    ar: 'رمز الفرع',
    en: 'Branch code'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  save: {
    ar: 'حفظ',
    en: 'Save'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
}