// import swal from 'sweetalert';
import env from '../env';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

// const ipcRenderer = window.require('electron').ipcRenderer

export default (fn, action, setAlert, login = false) => {
    fn().catch(function (err) {
        if (!err.response) {
            return setAlert(
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="please check your connection"
                    onConfirm={() => setAlert(null)}
                    onCancel={() => setAlert(null)}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                </ReactBSAlert>
            );
        }
        //
        if (err.response.status === 401) {
            localStorage.clear();
            // ipcRenderer.invoke(`unauthorize-event`);

            setAlert(
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={err.response.data.message || err.response.data.messages}
                    onConfirm={() => setAlert(null)}
                    onCancel={() => setAlert(null)}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                </ReactBSAlert>
            );
            // this need to be fixed
            // comment this if you work with electron js
            // if the request not coming from login page rdirect the user to login page
            if (!login) window.location.replace(`${env.frontUrl()}/ar-auth/login`);
        
        } else {
            setAlert(
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={err.response.data.message || err.response.data.messages || "Error 404"}
                    onConfirm={() => setAlert(null)}
                    onCancel={() => setAlert(null)}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                </ReactBSAlert>
            );
            // this need to be fixed
            // window.location.replace(`${env.Origin()}/ar-auth/login`);
        }


    }).finally(function (params) {
        action();
    })
}