export default {
  title: {
    ar: 'دفاتر الشيكات',
    en: 'Chequebook'
  },
  search: {
    ar: 'بحث',
    en: 'Search'
  },
  chequebook: {
    ar: 'دفتر شيكات',
    en: 'Chequebook'
  },
  bank: {
    ar: 'المصرف',
    en: 'Bank'
  },
  branch: {
    ar: 'الفرع',
    en: 'Branch'
  },
  account: {
    ar: 'الحساب',
    en: 'Account'
  },
  from: {
    ar: 'من',
    en: 'From'
  },
  to: {
    ar: 'الى',
    en: 'To'
  },
  size: {
    ar: 'الحجم',
    en: 'Size',
  },
  dimensions: {
    ar: 'الأبعاد',
    en: 'Dimensions'
  },
  current: {
    ar: 'الحالي',
    en: 'Current'
  },
  next: {
    ar: 'القادم',
    en: 'Next'
  },
  details: {
    ar: 'تفاصيل',
    en: 'Details'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  save: {
    ar: 'حفظ',
    en: 'Save'
  },
  width: {
    ar: "العرض",
    en: "Width"
  },
  height: {
    ar: "الارتفاع",
    en: "Height"
  }
}