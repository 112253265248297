import React, { useState, useContext, useEffect } from "react";
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  Link,
} from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
//content
import content from "./content";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import Spinner from "components/Spinner";
import TextPopup from "components/common/TextModal";
import { PaginationContext } from "context/PaginationContext";
import SearchModal from "components/common/SearchModal";
import Pagination from "components/common/Pagination";
import accoutingLevel from "api/accoutingLevel";
import { useForm, Controller } from "react-hook-form";
import useAlert from "hooks/useAlert";
import useApi from "hooks/useApi";
import usePagination from "hooks/usePagination";
import commonContent from "components/common/content";
import usePermissions from "hooks/usePermissions";

const AccountingLevels = () => {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const checkPermission = usePermissions("AccountLevel");

  const headers = [
    content.actions[culture],
    content.accountingLevel[culture],
    content.name[culture],
    content.NoOfDigits[culture],
  ];

  const columns = ["index", "name", "digits"];

  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const paginationCtx = useContext(PaginationContext);

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // apis
  const accountingApi = useApi(accoutingLevel.getAccountingLevel);
  const createAccountApi = useApi(accoutingLevel.createAccountingLevel);
  const deleteAccountApi = useApi(accoutingLevel.deleteAccountingLevel);
  const updateAccountApi = useApi(accoutingLevel.updateAccountingLevel);

  const editResponse = (item) => item;

  const { pageNo, setPageNo, docsPerPage, setDocsPerPage, prevPageCheck } =
    usePagination("");

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm();
  const {
    handleSubmit: edithandleSubmit,
    formState: { errors: editErrors },
    control: editControl,
    setValue: editSetValue,
    reset: editReset,
  } = useForm();

  const digits = watch("digits");

  const updateData = (arr) => {
    setData(arr);
  };

  const handleCancel = () => {
    setRowId("");
    setDeleteModal(false);
    setEditModal(false);
  };

  const handleDelete = (obj) => {
    setDeleteModal(true);
    setRowId(obj.id);
  };

  const renderEdit = () => checkPermission("UpdateAsync");
  const renderDelete = () => checkPermission("DeleteAsync");

  const handleEdit = (obj) => {
    setRowId(obj.id);
    editSetValue("name", obj.name);
    editSetValue("digits", obj.digits);
    setEditModal(true);
  };

  const getAccountLvl = async () => {
    setSpinner(true);
    const res = await accountingApi.request(pageNo, docsPerPage);
    if (res.status === 200) {
      const newData = res.data.data.reverse();
      for (let i = 0; i < newData.length; i++) newData[i]["index"] = i + 1;
      setData(newData);
    }
    setSpinner(false);
  };

  const createAccountLvl = async (formData) => {
    setSpinner(true);
    const res = await createAccountApi.request(formData);
    if (res.status === 200) {
      res.data.data.index = data.length + 1;
      setData((d) => [...d, res.data.data]);
      reset();
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const deleteAccount = async () => {
    setDeleteModal(false);
    setSpinner(true);
    const res = await deleteAccountApi.request(rowId);
    if (res.status === 200) {
      const newData = data.filter((item) => item.id !== rowId);
      setData(newData);
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  const editAccount = async (formData) => {
    setEditModal(false);
    setSpinner(true);
    const res = await updateAccountApi.request(rowId, formData);

    if (res.status === 200) {
      const newData = [...data];
      const index = newData.findIndex((item) => item.id === rowId);
      newData[index].name = formData.name;
      newData[index].digits = formData.digits;
      setData(newData);
      editReset();
      sweetAlert(content.done[culture]);
    }
    setSpinner(false);
  };

  useEffect(() => {
    getAccountLvl();
  }, [pageNo, docsPerPage]);

  if (accountingApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  const name = data.find((item) => item.id === rowId)?.name;

  return (
    <div>
      {alert}
      {accountingApi.errorAlert}
      {createAccountApi.errorAlert}
      {deleteAccountApi.errorAlert}
      {updateAccountApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          {checkPermission("CreateAsync") && (
            <CardBody>
              <Form onSubmit={handleSubmit(createAccountLvl)}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        {content.name[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: `${content.name[culture]} field is required`,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="name"
                            type="text"
                            className={errors.name && "error"}
                            value={value || ""}
                            onChange={({ target: { value } }) =>
                              onChange(value)
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="digits	">
                        {content.NoOfDigits[culture]}
                      </label>
                      <Controller
                        control={control}
                        name="digits"
                        rules={{
                          required: `${content.NoOfDigits[culture]} field is required`,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            id="digits"
                            type="number"
                            className={errors.digits && "error"}
                            value={parseInt(value) || ""}
                            onChange={({ target: { value } }) =>
                              onChange(parseInt(value))
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className={`text-md-${commonContent.align[culture]}`}>
                    <Button
                      className="mr-2"
                      color="info"
                      size="md"
                      type="submit"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span>{commonContent.add[culture]}</span>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          )}
        </Card>
        <Card>
          <Pagination
            dataLength={data.length}
            // when the user search using the popup and select a row
            // after then only use the pagination states from searchPopup hook
            pageNo={pageNo}
            setPageNo={setPageNo}
            docsPerPage={docsPerPage}
            setDocsPerPage={setDocsPerPage}
          >
            <Table
              headers={headers}
              data={data}
              columns={columns}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              renderEdit={renderEdit}
              renderDelete={renderDelete}
            >
              <span className="cmr-2" fun="handleEdit" condition="renderEdit">
                <div id="edit1" className="table-action cursor-pointer">
                  <i className="fas fa-pencil-alt fa-lg hover-info"></i>
                </div>
                <UncontrolledTooltip delay={0} target="edit1">
                  {content.edit[culture]}
                </UncontrolledTooltip>
              </span>
              {/* <span to={`/${culture}-admin/accountingleveldetails`}>
                <div id="details1" className="table-action cursor-pointer">
                  <i className="fas fa-info-circle fa-lg hover-warning"></i>
                </div>
                <UncontrolledTooltip
                  delay={0}
                  target="details1"
                >
                  {content.details[culture]}
                </UncontrolledTooltip>
              </span> */}
              <span
                className="cmr-2"
                fun="handleDelete"
                condition="renderDelete"
              >
                <div
                  id="delete"
                  className="table-action-delete table-action cursor-pointer hover-danger"
                >
                  <i className="fas fa-trash fa-lg"></i>
                </div>
                <UncontrolledTooltip delay={0} target="delete">
                  {content.delete[culture]}
                </UncontrolledTooltip>
              </span>
            </Table>
          </Pagination>
        </Card>
      </Container>
      <TextPopup
        modal={deleteModal}
        text={content.delete[culture]}
        handleCancel={handleCancel}
        fn={deleteAccount}
        color="danger"
        name={name}
      >
        <Container>
          <h2>{content.deletePopup[culture]}</h2>
        </Container>
      </TextPopup>
      <TextPopup
        modal={editModal}
        text={content.edit[culture]}
        handleCancel={handleCancel}
        fn={edithandleSubmit(editAccount)}
        color="info"
      >
        <Container>
          <h2>{content.editPopup[culture]}</h2>
        </Container>
        <div className="modal-body">
          <Row className="align-items-center">
            <Col md="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="edit-name">
                  {content.name[culture]}
                </label>
                <Controller
                  control={editControl}
                  name="name"
                  rules={{
                    required: `${content.name[culture]} field is required`,
                  }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="edit-name"
                      type="text"
                      className={editErrors.name && "error"}
                      value={value || ""}
                      onChange={({ target: { value } }) => onChange(value)}
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="digits	">
                  {content.NoOfDigits[culture]}
                </label>
                <Controller
                  control={editControl}
                  name="digits"
                  rules={{
                    required: `${content.NoOfDigits[culture]} field is required`,
                  }}
                  render={({ field: { ref, onChange, value, ...field } }) => (
                    <Input
                      {...field}
                      id="digits"
                      type="number"
                      className={editErrors.digits && "error"}
                      value={parseInt(value) || ""}
                      onChange={({ target: { value } }) =>
                        onChange(parseInt(value))
                      }
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </TextPopup>
    </div>
  );
};

export default AccountingLevels;
