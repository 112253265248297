import React, { useState } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import Spinner from "components/Spinner";
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import { useForm, Controller } from "react-hook-form";
import commonContent from "components/common/content";
import content from "./content";
import useApi from "hooks/useApi";
import entry from "api/entry";
import env from "env";
import CountryTable from "components/common/CountryTable";
import Table from "components/common/CustomTable";
import Pagination from "components/common/Pagination";
import useFetch from "hooks/useFetch";
import { useHistory, useParams } from "react-router-dom";

function BulkInformation() {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const { alert, sweetAlert } = useAlert();

  const { bulkNumber, countryId, shipmentType } = useParams();

  const shipmentInformationApi = useApi(entry.shipmentInformation);

  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [countryTable, setCountryTable] = useState(false);

  const headers = [
    content.accountName[culture],
    content.orderNumber[culture],
    content.debit[culture],
    content.credit[culture],
    content.paid[culture],
  ];

  const columns = ["account.name", "orderNumber", "debit", "credit", "paid"];

  const editResponse = (item) => {
    item.shipmentEntry.shipmentClosed = item.shipmentEntry.shipmentClosed
      ? content.closed[culture]
      : content.open[culture];

    item.shipmentEntry.entryDetails.forEach((entry) => {
      entry.paid = entry.paid ? content.paid[culture] : "";
    });

    return item;
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const handleCancel = () => {
    setCountryTable(false);
  };

  const fitlerEntries = async () => {
    const res = await shipmentInformationApi.request(
      bulkNumber,
      countryId,
      shipmentType
    );
    console.log({ res });
    if (res.status === 200) {
      editResponse(res.data.data);
      setData(res.data.data);
    }
  };

  useFetch({ fun: fitlerEntries, pageNo, docsPerPage, isSearch: false });

  if (shipmentInformationApi.loading)
    return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <div>
      {alert}
      {shipmentInformationApi.errorAlert}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <form onSubmit={(e) => e.preventDefault()}>
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between">
                <h3 className={`mb-0 text-md-left`}>
                  {content.pageTitle[culture]}
                </h3>
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="date">
                      {content.date[culture]}
                    </label>
                    <Input
                      id="date"
                      placeholder={content.date[culture]}
                      type="date"
                      value={data?.shipmentEntry?.date?.split("T")[0] || ""}
                      className={errors.date && "error"}
                      readOnly
                      // onChange={({ target: { value } }) => onChange(+value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="shipmentType"
                    >
                      {content.shipmentType[culture]}
                    </label>
                    <Input
                      id="shipmentType"
                      placeholder={content.shipmentType[culture]}
                      type="text"
                      value={
                        env.shipmentType?.[data.shipmentEntry?.shipmentType]?.[
                          culture
                        ] || ""
                      }
                      className={errors.shipmentType && "error"}
                      readOnly
                      // onChange={({ target: { value } }) => onChange(+value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="bulkNumber">
                      {content.bulkNumber[culture]}
                    </label>

                    <Input
                      id="bulkNumber"
                      placeholder={content.bulkNumber[culture]}
                      type="text"
                      value={data.shipmentEntry?.bulkNumber || ""}
                      className={errors.bulkNumber && "error"}
                      readOnly
                      // onChange={({ target: { value } }) => onChange(+value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="shipmentClosed"
                    >
                      {content.shipmentClosed[culture]}
                    </label>

                    <Input
                      id="shipmentClosed"
                      placeholder={content.shipmentClosed[culture]}
                      type="text"
                      value={data.shipmentEntry?.shipmentClosed ?? ""}
                      // value={value || ""}
                      className={errors.shipmentClosed && "error"}
                      readOnly
                      // onChange={({ target: { value } }) => onChange(+value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="costCenter">
                      {content.costCenter[culture]}
                    </label>

                    <Input
                      id="costCenter"
                      placeholder={content.costCenter[culture]}
                      type="text"
                      value={data.shipmentEntry?.costCenter.name || ""}
                      className={errors.costCenter && "error"}
                      readOnly
                      // onChange={({ target: { value } }) => onChange(+value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="currency">
                      {commonContent.currency[culture]}
                    </label>

                    <Input
                      id="currency"
                      type="text"
                      value={
                        env.currency?.[data.shipmentEntry?.currency - 1]?.[
                          culture
                        ] || ""
                      }
                      className={errors.currency && "error"}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <div>
                <h3 className={`mb-0 text-md-left`}>
                  {content.total[culture]}
                </h3>
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="deservedRevenue"
                    >
                      {content.deservedRevenue[culture]}
                    </label>

                    <Input
                      id="deservedRevenue"
                      type="text"
                      value={data.deservedRevenue ?? ""}
                      className={errors.deservedRevenue && "error"}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="actualRevenue"
                    >
                      {content.actualRevenue[culture]}
                    </label>

                    <Input
                      id="actualRevenue"
                      type="text"
                      value={data.actualRevenue ?? ""}
                      className={errors.actualRevenue && "error"}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="totalResizedRepriced"
                    >
                      {content.totalResizedRepriced[culture]}
                    </label>

                    <Input
                      id="totalResizedRepriced"
                      type="text"
                      value={data.totalResizedRepriced ?? ""}
                      className={errors.totalResizedRepriced && "error"}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="netProfit">
                      {content.netProfit[culture]}
                    </label>

                    <Input
                      id="netProfit"
                      type="text"
                      value={data.netProfit ?? ""}
                      className={errors.netProfit && "error"}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="remainder">
                      {content.remainder[culture]}
                    </label>

                    <Input
                      id="remainder"
                      type="text"
                      value={data.remainder ?? ""}
                      className={errors.remainder && "error"}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col md="6" lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="totalBulkExpenses"
                    >
                      {content.totalBulkExpenses[culture]}
                    </label>

                    <Input
                      id="totalBulkExpenses"
                      type="text"
                      value={data.totalBulkExpenses ?? ""}
                      className={errors.totalBulkExpenses && "error"}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
        <Card>
          <Table
            headers={headers}
            columns={columns}
            data={data.shipmentEntry?.entryDetails ?? []}
          />
        </Card>
      </Container>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <CardBody>
          <CountryTable
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </CardBody>
      </TextPopup>
    </div>
  );
}

export default BulkInformation;
