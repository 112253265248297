import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  CardHeader,
  CardBody,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import getCulture from "utils/getCulture";
import SimpleHeader from "components/Headers/SimpleHeader";
import Table from "components/common/CustomTable";
import useApi from "hooks/useApi";
import Spinner from "components/Spinner";
import TextPopup from "components/common/TextModal";
import env from "env";
import { useForm, Controller } from "react-hook-form";
import commonContent from "components/common/content";
import content from "./content";
import FilterEntries from "components/common/FilterEntries";
import entry from "api/entry";
import useAlert from "hooks/useAlert";
import Tabs from "./components/Tabs";
import CurrencyPurchaseForm from "./components/CurrencyPurchaseForm";
import TreasuryTransactions from "./components/TreasuryTransactions";

const FinancialAuditors = () => {
  const rout = useRouteMatch();
  const culture = getCulture(rout.url);

  const getAllApi = useApi(entry.getNotApprovedEntries);
  const approveEntriesApi = useApi(entry.approveEntries);
  const notApprovedEntriesApi = useApi(entry.getNoApprovedEntriesOfUser);

  const headers = useMemo(
    () => [
      content.actions[culture],
      commonContent.date[culture],
      commonContent.serialNumber[culture],
      commonContent.currency[culture],
      content.name[culture],
      commonContent.value[culture],
    ],
    []
  );

  const headersSecondTab = useMemo(
    () => [
      content.transactionState[culture],
      commonContent.costCenter[culture],
      content.statement[culture],
      content.valueDinar[culture],
      content.rate[culture],
      content.valueDollar[culture],
    ],
    []
  );

  const columns = [
    "date",
    "serialNumberDisplayed",
    "currencyText",
    "appUser.name",
    "value",
  ];

  const columnsSecondTab = useMemo(
    () => [
      "entryStateText",
      "costCenter.name",
      "statement",
      "valueLYD",
      "rate",
      "valueConverted",
    ],
    []
  );

  const tabs = useMemo(
    () => [
      content.customerDeposit[culture],
      content.currencyPurchaseTransactions[culture],
      content.treasuryTransactions[culture],
    ],
    []
  );

  const { alert, sweetAlert } = useAlert();

  const [data, setData] = useState([]);
  const [entries, setEntries] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const editResponse = (item) => {
    item.date = item.date.split("T")[0];
    item.currencyText = env.currency[item.currency - 1][culture];
    item.entryStateText = env.entryState[item.entryState - 1][culture];
    item.value = item.entryDetails?.reduce(
      (prev, current) => prev + current.credit,
      0
    );
    return item;
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm();

  const {
    handleSubmit: edithandleSubmit,
    formState: { errors: editErrors, isDirty: editIsDirty },
    control: editControl,
    setValue: editSetValue,
  } = useForm();

  const handleCancel = () => {
    setAccountModal(false);
    setConfirmModal(false);
  };

  const getData = async () => {
    const res = await getAllApi.request();
    if (res.status === 200) {
      res.data.data.forEach(editResponse);
      setData(res.data.data);
    }
  };

  const handleSearch = async (params) => {
    setSpinner(true);
    const schema = {};
    Object.keys(params).forEach((key) => {
      if (params[key]) schema[key] = params[key];
      if (params.Id) schema.userId = params.Id;
    });

    const res = await notApprovedEntriesApi.request(schema);

    if (res.status === 200) {
      res.data.data.forEach(editResponse);
      setData(res.data.data);
    }
    setSpinner(false);
  };

  const handleSelect = (obj) => {
    setEntries((prevVal) => {
      const newEntries = [...prevVal];
      newEntries.push(obj);
      return newEntries;
    });
  };

  const handleUnselect = (obj) =>
    setEntries((prevVal) => prevVal.filter((item) => item.id !== obj.id));

  const renderSelect = (obj) => {
    const index = entries.findIndex((item) => item.id === obj.id);

    return index === -1;
  };
  const renderUnselect = (obj) => {
    const index = entries.findIndex((item) => item.id === obj.id);
    return index > -1;
  };

  const handleApproveEntries = async () => {
    setSpinner(true);
    setConfirmModal(false);
    const schema = entries.map((entry) => entry.id);
    const res = await approveEntriesApi.request(schema);
    if (res.status === 200) {
      const newData = data.filter((item) => !schema.includes(item.id));
      setData(newData);
      setEntries([]);
      sweetAlert(commonContent.done[culture]);
    }
    setSpinner(false);
  };

  const total = useMemo(
    () => data.reduce((total, next) => total + next.value, 0),
    [JSON.stringify(data)]
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setData([]);
  }, [tabIndex]);

  if (getAllApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {getAllApi.errorAlert}
      {approveEntriesApi.errorAlert}
      {notApprovedEntriesApi.errorAlert}
      {spinner && <Spinner gate="#11cdef" bg="#fff" opacity />}
      <SimpleHeader parents={[content.categoryName[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h3 className={`mb-0 text-md-left`}>
                {content.pageTitle[culture]}
              </h3>
            </div>
          </CardHeader>
        </Card>
        <div style={{ marginBottom: 20 }}>
          <Tabs tabs={tabs} tabIndex={tabIndex} setTabIndex={setTabIndex} />
        </div>
        <TabContent activeTab={tabIndex}>
          <TabPane tabId={0}>
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(handleSearch)}>
                  <Row className="align-items-center">
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="name">
                          {content.pageTitle[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="Account"
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="name"
                              type="text"
                              className={errors.mainTreasuryAccount && "error"}
                              value={value || ""}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                              }}
                              onClick={() => setAccountModal(true)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="currency"
                        >
                          {content.currency[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="currency"
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="name"
                              type="select"
                              value={value ?? "disabled"}
                              onChange={({ target: { value } }) => {
                                onChange(+value);
                              }}
                            >
                              <option disabled value="disabled">
                                -- {content.selectOption[culture]} --
                              </option>
                              {env.currency.map((item, key) => (
                                <option value={key + 1} key={key.toString()}>
                                  {item[culture]}
                                </option>
                              ))}{" "}
                            </Input>
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button className="mr-2" color="info" size="md" type="submit">
                    <span className="btn-inner--icon me-1">
                      <i className="fas fa-search" />
                    </span>
                    <span>{commonContent.search[culture]}</span>
                  </Button>
                </form>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
        <TabContent activeTab={tabIndex}>
          <TabPane tabId={1}>
            <CurrencyPurchaseForm setData={setData} />
          </TabPane>
        </TabContent>
        <TabContent activeTab={tabIndex}>
          <TabPane tabId={2}>
            <TreasuryTransactions setData={setData} />
          </TabPane>
        </TabContent>
        <Card>
          <TabContent activeTab={tabIndex}>
            <TabPane tabId={1}>
              <Table
                headers={headersSecondTab}
                data={data}
                columns={columnsSecondTab}
                handleSelect={handleSelect}
                handleUnselect={handleUnselect}
                renderSelect={renderSelect}
                renderUnselect={renderUnselect}
              />
            </TabPane>
          </TabContent>
          <TabContent activeTab={tabIndex}>
            <TabPane tabId={0}>
              <Table
                headers={headers}
                data={data}
                columns={columns}
                handleSelect={handleSelect}
                handleUnselect={handleUnselect}
                renderSelect={renderSelect}
                renderUnselect={renderUnselect}
              >
                <span fun="handleSelect" condition="renderSelect">
                  <div id="approve" className="table-action cursor-pointer">
                    <i className="fas fa-check fa-lg hover-info"></i>
                  </div>
                  <UncontrolledTooltip delay={0} target="approve">
                    {commonContent.approve[culture]}
                  </UncontrolledTooltip>
                </span>
                <span
                  fun="handleUnselect"
                  className="ms-1"
                  condition="renderUnselect"
                >
                  <div id="cancel" className="table-action cursor-pointer">
                    <i className="fas fa-times fa-lg hover-danger"></i>
                  </div>
                  <UncontrolledTooltip delay={0} target="cancel">
                    {commonContent.cancel[culture]}
                  </UncontrolledTooltip>
                </span>
                <span to={`/${culture}-admin/transaction/entryDetails`}>
                  <div id="details1" className="table-action cursor-pointer">
                    <i className="far fa-eye fa-lg hover-success"></i>
                  </div>
                  <UncontrolledTooltip delay={0} target="details1">
                    {commonContent.showDetails[culture]}
                  </UncontrolledTooltip>
                </span>
              </Table>
            </TabPane>
          </TabContent>
          {tabIndex === 0 && (
            <CardBody>
              <div className="d-flex justify-content-between">
                <Button
                  onClick={() => setConfirmModal(true)}
                  disabled={!!!entries.length}
                >
                  {content.approveEntries[culture]}
                </Button>
                <span>
                  {content.total[culture]} {total.toFixed(2)}
                </span>
              </div>
            </CardBody>
          )}
        </Card>
      </Container>
      <TextPopup
        modal={accountModal}
        noBtn
        handleCancel={handleCancel}
        fn={() => {}}
        name={content.pageTitle[culture]}
        color="success"
      >
        <FilterEntries
          watch={watch}
          setValue={setValue}
          setAccountModal={setAccountModal}
          accountClassification={3}
        />
      </TextPopup>
      <TextPopup
        modal={confirmModal}
        handleCancel={handleCancel}
        fn={handleApproveEntries}
        text={content.approve[culture]}
        name={content.approveEntries[culture]}
        color="success"
      >
        <CardBody>
          <h2>{content.confirmModal[culture]}</h2>
        </CardBody>
      </TextPopup>
    </>
  );
};

export default FinancialAuditors;
