export default {
  categoryName: {
    ar: 'المحاسبة',
    en: 'Accounting',
  },
  title: {
    ar: 'الحسابات',
    en: 'Accounts'
  },
  accountNo: {
    ar: 'رقم الحساب',
    en: 'Account Number'
  },
  accountName: {
    ar: 'اسم الحساب',
    en: 'Account name'
  },
  category: {
    ar: 'التصنيف',
    en: 'Category'
  },
  type: {
    ar: 'النوع',
    en: 'Type'
  },
  status: {
    ar: 'الحالة',
    en: 'Status'
  },
  closingAccount: {
    ar: 'الحساب الختامي ',
    en: 'closing account'
  },
  createdDate: {
    ar: 'تاريخ الانشاء',
    en : 'Created date'
  },
  editedDate: {
    ar: 'تاريخ التعديل',
    en: 'Edited date'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  accountList: {
    ar: 'قائمة الحسابات',
    en: 'Account list'
  },
  addAccount: {
    ar: 'اضافة حساب',
    en: 'Add account'
  },
  addAnEmployeeAccount: {
    ar: 'اضافة حساب موظف',
    en: 'Add an employee account'
  },
  balanceRecycle: {
    ar: 'تدوير الارصدة',
    en: 'Balance recycle'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  deletePopup: {
    ar: 'هل انت متأكد من انك تريد حذف هذا الحساب ؟',
    en: 'Are you sure you want to delete this account ?'
  },
  done: {
    ar: 'تمت العملية بنجاح',
    en: 'Done'
  },
  name: {
    ar: 'الاسم',
    en: 'Name'
  },
  accountingLevel: {
    ar: 'المستوى المحاسبي',
    en: 'Accounting level'
  }, 
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  createdBy: {
    ar: "انشأ من قبل",
    en: "Created by"
  },
  add: {
    ar: 'اضافة',
    en: 'Add'
  },
  arName: {
    ar: 'الإسم العربي',
    en: 'Arabic Name'
  },
  enName: {
    ar: 'الإسم اللاتيني',
    en: 'Latin Name'
  },
  affiliatedWith: {
    ar: "تابع لفرع",
    en: "affiliated with branch"
  },
  main: {
    ar: "رئيسي",
    en: "Main"
  },
  branch: {
    ar: "فرعي",
    en: "Branch"
  },
  account: {
    ar: "حساب",
    en: "Account"
  },
  credit: {
    ar: 'دائن',
    en: 'Credit'
  },
  debit: {
    ar: 'مدين',
    en: 'Debit'
  },
  activation: {
    ar: "تفعيل",
    en: "Activation"
  },
  country: {
    ar: "البلد",
    en: "Country"
  },
}