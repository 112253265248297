import shipping from "./shipping";
const endpoint = '/v1/Customer';

const customers = (page, docPerPage) => shipping.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const findByName = (name, page, docPerPage) => shipping.get(`${endpoint}/FindByName?filter=${name}&Page=${page}&PostsPerPage=${docPerPage}`);

const createCustomer = schema => shipping.post(`${endpoint}/`, schema);

const getAllCustomers = (page, docPerPage) => shipping.get(`${endpoint}?Page=${page}&PostsPerPage=${docPerPage}`);

const deleteCustomer = id => shipping.delete(`${endpoint}/${id}`);

const updateCustomer = (id, schema) => shipping.put(`${endpoint}/${id}`, schema);

const getByColumn = (col, val, page, docsPerPage) => (
  shipping.get(`${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
);

// This api for filter and has no permission
const getByPopupCoulum = (col, val, page, docsPerPage) => (
  shipping.put(`${endpoint}/GetByPopupCoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`)
);

export default {
  customers,
  createCustomer,
  findByName,
  getAllCustomers,
  deleteCustomer,
  updateCustomer,
  getByColumn,
  getByPopupCoulum
}