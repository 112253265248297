/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// router
import { Link, useRouteMatch, useHistory } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

// core components
import AuthHeader from "components/Headers/AuthHeader";

// content
import content from "./content";
import Spinner from "components/Spinner";
import { Controller, useForm } from "react-hook-form";
import useApi from "hooks/useApi";
import auth from "api/auth";
import TextPopup from "components/common/TextModal";
import BranchTable from "components/common/BranchTable";
import commonContent from "components/common/commonContent";
import useAlert from "hooks/useAlert";

function Register() {
  const [focusedName, setfocusedName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedCPassword, setfocusedCPassword] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [showConfermPassword, setConfermShowpassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [branchModal, setBranchModal] = useState(false);

  const history = useHistory();
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const { alert, sweetAlert } = useAlert();

  const registerApi = useApi(auth.signUp);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const signup = async (formData) => {
    setLoading(true);
    const res = await registerApi.request(formData);
    if (res.status === 200) {
      localStorage.setItem(btoa("token"), res.data.data.token);

      localStorage.setItem(
        btoa("userPermissions"),
        JSON.stringify(res.data.data.userPermissions)
      );
      localStorage.setItem("user", JSON.stringify(res.data.data.user));
      history.replace(`/${culture}-admin/dashboard`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem(btoa("token"))) {
      history.replace(`/${culture}-admin/`);
    }
    return () => setLoading(false);
  }, []);

  return (
    <>
      {alert}
      {registerApi.errorAlert}
      {loading ? <Spinner opacity /> : null}
      <div style={{ marginBottom: "-6.5%" }} />
      <AuthHeader title={content.title[culture]} lead={content.lead[culture]} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-secondary border-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <small style={{ fontSize: "20px" }}>
                    {content.signupTitle[culture]}
                  </small>
                </div>
                <div
                  style={{ marginTop: "20px" }}
                  className="btn-wrapper text-center"
                >
                  {/* <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/github.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button> */}
                  {culture == "ar" ? (
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span
                        style={{ color: "#000" }}
                        className="btn-inner--text"
                      >
                        Gateway Ly
                      </span>
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          style={{ width: 50 }}
                          src={require("assets/img/brand/gw.png").default}
                        />
                      </span>
                    </Button>
                  ) : (
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          style={{ width: 50 }}
                          src={require("assets/img/brand/gw.png").default}
                        />
                      </span>
                      <span
                        style={{ color: "#000" }}
                        className="btn-inner--text"
                      >
                        Gateway Ly
                      </span>
                    </Button>
                  )}
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>{content.plsFill[culture]}</small>
                </div>
                <Form
                  role="form"
                  onSubmit={handleSubmit(signup)}
                  autoComplete="off"
                >
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative rtl">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="firstName"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            type="text"
                            className={errors.firstName && "error"}
                            value={value || ""}
                            placeholder={content.firstName[culture]}
                            onChange={(e) => onChange(e.target.value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative rtl">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="lastName"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            type="text"
                            className={errors.lastName && "error"}
                            value={value || ""}
                            placeholder={content.lastName[culture]}
                            onChange={(e) => onChange(e.target.value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedName,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative rtl">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="username"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            type="text"
                            className={errors.username && "error"}
                            value={value || ""}
                            placeholder={content.userName[culture]}
                            onFocus={() => setfocusedName(true)}
                            onBlur={() => setfocusedName(false)}
                            onChange={(e) => onChange(e.target.value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="email"
                        rules={{
                          required: true,
                          pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            className={errors.email && "error"}
                            placeholder={content.email[culture]}
                            type="email"
                            minLength="5"
                            value={value || ""}
                            onFocus={() => setfocusedEmail(true)}
                            onBlur={() => setfocusedEmail(false)}
                            onChange={(e) => onChange(e.target.value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-phone" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="phoneNumber"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            className={errors.phoneNumber && "error"}
                            placeholder={content.phoneNumber[culture]}
                            type="number"
                            minLength="5"
                            value={value || ""}
                            onChange={(e) => onChange(e.target.value)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-hotel" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="branch"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            className={errors.branch && "error"}
                            placeholder={content.branch[culture]}
                            type="text"
                            value={value ?? ""}
                            onChange={(e) => onChange(e.target.value)}
                            onClick={() => setBranchModal(true)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="password"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            className={errors.password && "error"}
                            placeholder={content.password[culture]}
                            type={showPassword ? "text" : "password"}
                            onFocus={() => setfocusedPassword(true)}
                            onBlur={() => setfocusedPassword(false)}
                            value={value || ""}
                            onChange={(e) => onChange(e.target.value)}
                            minLength="8"
                          />
                        )}
                      />
                      <Button
                        color="secondary"
                        outline
                        type="button"
                        onClick={() => setShowpassword(!showPassword)}
                      >
                        <i
                          className={
                            showPassword ? "fas fa-eye" : "fas fa-eye-slash"
                          }
                        ></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedCPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="confirmPassword"
                        rules={{
                          required: true,
                          validate: (value) => value === watch("password"),
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            className={errors.confirmPassword && "error"}
                            placeholder={content.confermPassword[culture]}
                            type={showConfermPassword ? "text" : "password"}
                            value={value || ""}
                            onFocus={() => setfocusedCPassword(true)}
                            onBlur={() => setfocusedCPassword(false)}
                            onChange={(e) => onChange(e.target.value)}
                            minLength="8"
                          />
                        )}
                      />
                      <Button
                        color="secondary"
                        outline
                        type="button"
                        onClick={() =>
                          setConfermShowpassword(!showConfermPassword)
                        }
                      >
                        <i
                          className={
                            showConfermPassword
                              ? "fas fa-eye"
                              : "fas fa-eye-slash"
                          }
                        ></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                  {/* <div className="text-muted font-italic">
                    <small>
                      password strength:{" "}
                      <span className="text-success font-weight-700">
                        strong
                      </span>
                    </small>
                  </div> */}
                  {/* <Row className="my-4">
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">
                            I agree with the{" "}
                            <a
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Privacy Policy
                            </a>
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row> */}
                  <div className="text-center">
                    <Button className="mt-4" color="success" type="submit">
                      {content.create[culture]}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col className="text-center" xs="6">
                <Link
                  style={{ color: "#BFC8D0" }}
                  to={culture == "ar" ? "/ar-auth/login" : "/en-auth/login"}
                >
                  <small>{content.login[culture]}</small>{" "}
                </Link>
              </Col>

              <Col className="text-center" xs="6">
                {culture == "ar" ? (
                  <Link style={{ color: "#2DCE89" }} to="/en-auth/register">
                    English
                  </Link>
                ) : (
                  <Link style={{ color: "#2DCE89" }} to="/ar-auth/register">
                    العربية &nbsp; &nbsp;
                  </Link>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <TextPopup
        modal={branchModal}
        text={commonContent.next[culture]}
        handleCancel={() => setBranchModal(false)}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <BranchTable
            setValue={setValue}
            sweetAlert={sweetAlert}
            setModal={setBranchModal}
          />
        </Container>
      </TextPopup>
    </>
  );
}

export default Register;
