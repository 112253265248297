/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
  useParams,
} from "react-router-dom";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// get culture from utils
import getCulture from "utils/getCulture";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
// content
import content from "./content";
import axios from "axios";
// Components
import Spinner from "../../components/Spinner";
import catchAsync from "helper/catchAsync";
import env from "../../env";
import useApi from "hooks/useApi";
import auth from "api/auth";
import { Controller, useForm } from "react-hook-form";

// const ipcRenderer = window.require('electron').ipcRenderer

function Login() {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const history = useHistory();

  const loginApi = useApi(auth.signIn);

  // get the culture from the rout of the page
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // const invokeCoreScreen = () => {
  //   ipcRenderer.invoke('system-login-event')
  // }

  const login = async (formData) => {
    setLoading(true);
    const res = await loginApi.request(formData);

    if (res.status === 200) {
      localStorage.setItem(btoa("token"), res.data.data.token);
      localStorage.setItem(
        btoa("userPermissions"),
        JSON.stringify(res.data.data.userPermissions)
      );
      localStorage.setItem("user", JSON.stringify(res.data.data.user));
      history.replace(`/${culture}-admin/dashboard`);
    }
    setLoading(false);
  };

  useEffect(() => {
    // // if user is logged in
    if (localStorage.getItem(btoa("token"))) {
      // redirect him to the dashboard
      history.replace(`/${culture}-admin/`);
    }
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <div>
      {alert}
      {loginApi.errorAlert}
      {loading ? <Spinner opacity /> : null}
      <div style={{ marginBottom: "-6.5%" }} />
      <AuthHeader title={content.title[culture]} lead={content.lead[culture]} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <small style={{ fontSize: "20px" }}>
                    {content.signupTitle[culture]}
                  </small>
                </div>
                <div
                  style={{ marginTop: "20px" }}
                  className="btn-wrapper text-center"
                >
                  {/* <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/github.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button> */}
                  {culture == "ar" ? (
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span
                        style={{ color: "#000" }}
                        className="btn-inner--text"
                      >
                        Gateway Ly
                      </span>
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          style={{ width: 50 }}
                          src={require("assets/img/brand/ggwS23.png").default}
                        />
                      </span>
                    </Button>
                  ) : (
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          style={{ width: 50 }}
                          src={require("assets/img/brand/gw.png").default}
                        />
                      </span>
                      <span
                        style={{ color: "#000" }}
                        className="btn-inner--text"
                      >
                        Gateway Ly
                      </span>
                    </Button>
                  )}
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>{content.plsFill[culture]}</small>
                </div>
                <Form role="form" onSubmit={handleSubmit(login)}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative rtl">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="username"
                        rules={{
                          required: `${content.userName[culture]} field is required`,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            placeholder={content.userName[culture]}
                            type="text"
                            className={errors.username && "error"}
                            onChange={(e) => onChange(e.target.value)}
                            onFocus={() => setfocusedEmail(true)}
                            onBlur={() => setfocusedEmail(true)}
                          />
                        )}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Controller
                        control={control}
                        name="password"
                        rules={{
                          required: `${content.password[culture]} field is required`,
                        }}
                        render={({
                          field: { ref, onChange, value, ...field },
                        }) => (
                          <Input
                            {...field}
                            className={errors.password && "error"}
                            placeholder={content.password[culture]}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => onChange(e.target.value)}
                            onFocus={() => setfocusedPassword(true)}
                            onBlur={() => setfocusedPassword(true)}
                          />
                        )}
                      />
                      <Button
                        color="secondary"
                        outline
                        type="button"
                        onClick={() => setShowpassword(!showPassword)}
                      >
                        <i
                          className={
                            showPassword ? "fas fa-eye" : "fas fa-eye-slash"
                          }
                        ></i>
                      </Button>
                    </InputGroup>
                  </FormGroup>
                  {/* <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div> */}
                  <div className="text-center">
                    <Button className="my-4" color="success" type="submit">
                      {content.signin[culture]}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="4">
                <Link to="/" style={{ color: "#BFC8D0" }}>
                  <small>{content.forgot[culture]}</small>{" "}
                </Link>
              </Col>
              <Col className="text-center" xs="4">
                <Link
                  style={{ color: "#BFC8D0" }}
                  to={
                    culture == "ar" ? "/ar-auth/register" : "/en-auth/register"
                  }
                >
                  <small>{content.create[culture]}</small>{" "}
                </Link>
              </Col>

              <Col className="text-right" xs="4">
                {culture == "ar" ? (
                  <Link style={{ color: "#2DCE89" }} to="/en-auth/login">
                    English
                  </Link>
                ) : (
                  <Link style={{ color: "#2DCE89" }} to="/ar-auth/login">
                    العربية &nbsp; &nbsp;
                  </Link>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
