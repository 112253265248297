const Url = "https://gw.mail.oneify-store.com/api/v1";
const farhat = "http://192.168.0.107/GW.Mail.WebServerApi/api/v1";
const origin = "https://ggw-sh-accounting.netlify.app";
const heroku = "https://gw-archive.herokuapp.com";
const frontUrl = "http://localhost:3000";
const netlify = "https://unruffled-spence-60cdc5.netlify.app";
const swagger = "https://acc.gw.ly";
const pos = "https://pos.gw.ly";
const acc = "https://gw.accounting.shipping.gw.ly";

// exports.Url = () => Url;
// exports.farhat = () => farhat;
// exports.Origin = () => Origin;
exports.activeServer = () => `${acc}/api/v1`;
const currentUrl = window.location.href;
const urlWithoutProtocol = currentUrl.split('//')[1]; // Remove the protocol (http:// or https://)
const domainName = urlWithoutProtocol.split('/')[0]; // Extract the domain name without the slash and anything after it

exports.frontUrl = () => domainName;
exports.employee = () => "https://hr.gw.ly/api/v1/Employee";
//http://192.168.0.102:5000
exports.activeServerUrl = () => origin;
exports.identity = () => `https://identety.herokuapp.com/api/v1`;
exports.swagger = () => "https://hr.gw.ly/api/v1";
// http://192.168.0.106/GW.Mail.WebServerApi/api/v1

exports.Url = () => "https://aj.gw.ly/api/v1";

exports.chequeSizes = [25, 50, 100, 150];

exports.pages = [5, 10, 15, 25, 50];

exports.fiscalYearState = [
  { ar: "مفتوحة", en: "Opened" },
  { ar: "مغلقة", en: "Closed" },
];

exports.accountClassifications = [
  { ar: "طبيعي", en: "Normal" },
  { ar: "خزينة", en: "Treasury" },
  { ar: "موظف", en: "Employee" },
  { ar: "عميل", en: "Customer" },
  { ar: "مصروفات", en: "Expenses" },
  { ar: "ايراد", en: "Revenue" },
  { ar: "بروكر", en: "Broker" },
];

exports.entryType = [
  { ar: "حوالة فقط", en: "Money Transfer Only" },
  { ar: "صرف دين لعميل", en: "Customer Loan" },
  { ar: "سداد شحن", en: "Shipping Payment" },
  { ar: "حوالة داخلية", en: "Internal Transfer" },
  { ar: "شحنة", en: "Shipment" },
  { ar: "عهدة", en: "Custody" },
  { ar: "سلفة", en: "Loan" },
  { ar: "مصروفات", en: "Expenses" },
  { ar: "مرتبات", en: "Salary" },
  { ar: "نقل ارصدة", en: "Balance Transfer" },
  { ar: "ايداع زبون", en: "Customer Payment" },
  { ar: "قيد يدوي", en: "Manual" },
  { ar: "سداد دين عميل", en: "Customer Loan Payment" },
  { ar: "سداد بروكر", en: "Broker Payment" },
  { ar: "إعادة تحجيم", en: "Resizing Order" },
  { ar: "إعادة تسعير", en: "Repricing Order" },
  { ar: "تعويض تعديل طلبية", en: "Adjustment Recompense" },
  { ar: "مصروفات بلك", en: "Bulk Expenses" },
  { ar: "إستحقاق حوالة", en: "Transfers Claim" },
  { ar: "حركة تسوية ", en: "Settlement Transaction" },
];

exports.costCenterType = [
  { ar: "أساسي", en: "Main" },
  { ar: "فرعي", en: "Branch" },
];
// unconfirmed confiremed canceled

exports.entryState = [
  { ar: "غير مؤكد", en: "unconfirmed" },
  { ar: "مؤكد", en: "confiremed" },
  { ar: "ملغيه", en: "canceled" },
];

exports.currency = [
  { ar: "دولار امريكي", en: "USD" },
  { ar: "دينار ليبي", en: "LYD" },
  { ar: "يورو", en: "Euro" },
];

exports.accountNature = [
  {
    ar: "مدين",
    en: "debit",
  },
  {
    ar: "دائن",
    en: "credit",
  },
];

exports.shipmentType = [
  { ar: "غير شحنه", en: "None" },
  { ar: "جوي", en: "Air" },
  { ar: "بحري مشترك", en: "LCL" },
  { ar: "حاوية كاملة", en: "FCL" },
];
