export default {
  users: {
    ar: "المستخدمين",
    en: "Users",
  },
  name: {
    ar: "الاسم",
    en: "Name",
  },
  search: {
    ar: "بحث",
    en: "Search",
  },
  username: {
    ar: "اسم المستخدم",
    en: "user name",
  },
  firstName: {
    ar: "الاسم الأول",
    en: "First name",
  },
  lastName: {
    ar: "اللقب",
    en: "Last name",
  },
  email: {
    ar: "البريد الالكتروني",
    en: "E-mail",
  },
  phone: {
    ar: "رقم الهاتف",
    en: "Phone",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا المستخدم ؟",
    en: "Are you sure you want to delete this User ?",
  },
  permission: {
    ar: "صلاحيات المستخدم",
    en: "User permissions",
  },
  status: {
    ar: "الحالة",
    en: "Status",
  },
  permissionName: {
    ar: "اسم الصلاحية",
    en: "Permission Name",
  },
  activation: {
    ar: "تفعيل",
    en: "activation",
  },
  disable: {
    ar: "تعطيل",
    en: "Disable",
  },
  active: {
    ar: "مفعل",
    en: "Active",
  },
  notActive: {
    ar: "غير مفعل",
    en: "Not Active",
  },
  activationPopup: {
    ar: "هل انت متأكد من انك تريد تفعيل هذا الإذن لهذا المستخدم ؟",
    en: "Are you sure you want to activate this permission for this user?",
  },
  disablePopup: {
    ar: "هل انت متأكد من انك تريد تعطيل هذا الإذن لهذا المستخدم ؟",
    en: "Are you sure you want to disable this permission for this user?",
  },
  branch: {
    ar: "الفرع",
    en: "Branch",
  },
};
