import React, { useState, useEffect, useReducer } from "react";
import useCulture from "hooks/useCulture";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import content from "./content";
import SimpleHeader from "components/Headers/SimpleHeader";
import commonContent from "components/common/commonContent";
import useApi from "hooks/useApi";
import roles from "api/roles";
import { useHistory, useParams } from "react-router";
import Spinner from "components/Spinner";
import useAlert from "hooks/useAlert";
import PermissionsTable from "./PermissionsTable";
import permissionsReducer from "./permissionsReducer";

function RolePermissions() {

  const culture = useCulture();
  const history = useHistory();

  const { alert, sweetAlert } = useAlert();

  const [permissions, dispatcher] = useReducer(permissionsReducer, {});
  const [spinner, setSpinner] = useState(false);


  const getOneApi = useApi(roles.getOne);
  const updatePermissionsApi = useApi(roles.updatePermissions);

  const { id } = useParams();

  const getOne = async () => {
    const res = await getOneApi.request(id);
    console.log({ res });
    if (res.status === 200) dispatcher({ type: "FETCH", data: res.data.data });
  };

  const updatePermissions = async () => {
    setSpinner(true);
    const schema = [];

    permissions.appRolePermissions.forEach((role) => {
      role.appPermissions.forEach((per) => schema.push(per.id));
    });

    const res = await updatePermissionsApi.request(id, schema);
    if (res.status === 200) {
      sweetAlert(commonContent.done[culture]);
      setTimeout(() => {
        history.push(`/${culture}-admin/roles`)
      }, 1000)
    }

    setSpinner(false);

  };

  const allAppPermissions = permissions?.allAppPermissions || [];
  const appRolePermissions = permissions?.appRolePermissions || [];

  useEffect(() => {
    getOne();
  }, []);

  if (getOneApi.loading) return <Spinner gate="#11cdef" bg="#fff" />;

  return (
    <>
      {alert}
      {getOneApi.errorAlert}
      {updatePermissionsApi.errorAlert}
      {spinner ? <Spinner opacity bg="#fff" gate="#42ba96" /> : null}
      <SimpleHeader parents={[content.rolePermissions[culture]]} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h3>{content.rolePermissions[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className="align-items-center">
              <Col md="6" lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="username">
                    {content.username[culture]}
                  </label>
                  <Input
                    id="username"
                    placeholder={content.username[culture]}
                    type="text"
                    value={permissions?.name || ""}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col>
                <Button className="mt-2" color="success" size="md" onClick={updatePermissions}>
                  <span className="btn-inner--icon me-1">
                    <i className="far fa-save"></i>
                  </span>
                  <span>{commonContent.save[culture]}</span>
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <PermissionsTable
            dispatcher={dispatcher}
            allAppPermissions={allAppPermissions}
            appRolePermissions={appRolePermissions}
          />
        </Card>
      </Container>
    </>
  );
}

export default RolePermissions;
