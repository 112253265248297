import React, { useState } from 'react';
import {
  //BrowserRouter as Router,
  useRouteMatch,
  useHistory,
  Link
} from "react-router-dom";

// get culture from utils 
import getCulture from "utils/getCulture";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
//content 
import content from './content';

// core components
import SimpleHeader from 'components/Headers/SimpleHeader';

const AddTransaction = () => {
  let rout = useRouteMatch()
  let culture = getCulture(rout.url);

  return (
    <div>
      <SimpleHeader name={content.title[culture]} parentName="" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className={`mb-0 text-md`}>{content.title[culture]}</h3>
          </CardHeader>
          <CardBody>
            <Row className='align-items-center'>
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.serialNo[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="text"
                    placeholder={content.serialNo[culture]}
                  />
                </FormGroup>
              </Col>   
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.transactionType[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">سحب</option>
                    <option value="second">ايراد</option>
                  </Input>
                </FormGroup>
              </Col>   
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.transactionNo[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="number"
                  />
                </FormGroup>
              </Col>   
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.paymentMethod[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">سحب</option>
                    <option value="second">ايراد</option>
                  </Input>
                </FormGroup>
              </Col>   
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.transactionDate[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="date"
                  />
                </FormGroup>
              </Col> 
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.value[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="number"
                  />
                </FormGroup>
              </Col> 
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.creditorAccount[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">{content.test[culture]}</option>
                    <option value="second">{content.test[culture]}</option>
                  </Input>
                </FormGroup>
              </Col>   
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.debitAccount[culture]}
                  </label>
                  <Input
                    id="exampleFormControlSelect2"
                    type="select"
                    defaultValue={true}
                    onChange={e => {}}
                  >
                    <option disabled value> -- {content.selectOption[culture]} -- </option>
                    <option value="first">{content.test[culture]}</option>
                    <option value="second">{content.test[culture]}</option>
                  </Input>
                </FormGroup>
              </Col>   
              <Col md="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.beneficiary[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="text"
                  />
                </FormGroup>
              </Col> 
              <Col md="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols1Input"
                  >
                    {content.statement[culture]}
                  </label>
                  <Input
                    id="example3cols1Input"
                    type="textarea"
                  />
                </FormGroup>
              </Col> 
            </Row>
            <Row className='flex-row-reverse'>
              <Button className="mr-2" color="primary" size="md">
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-fat-add" />
                </span>
                <span>
                  {content.add[culture]}
                </span>
              </Button>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default AddTransaction
