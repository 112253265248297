export default {
  treasuryBalance: {
    ar: "رصيد الخزينة",
    en: "Treasury balance",
  },
  account: {
    ar: "الحساب",
    en: "Account",
  },
  branch: {
    ar: "الفرع",
    en: "Branch",
  },
  calculate: {
    ar: "احتساب",
    en: "Calculate",
  },
  balanceDetails: {
    ar: "بيانات الرصيد",
    en: "Balance Details",
  },
  bulkNumber: {
    ar: "رقم البلك",
    en: "Bulk number",
  },
  countryTeasuryRealBalance: {
    ar: "الرصيد الحقيقي لخزائن البلدان",
    en: "Country treasury real balance",
  },
  balanceUSD: {
    ar: "صافي القيمة بالدولار",
    en: "Balance USD",
  },
  balanceLYD: {
    ar: "صافي القيمة بالدينار",
    en: "Balance LYD",
  },
  balanceEuro: {
    ar: "صافي القيمة باليورو",
    en: "Balance euro",
  },
  totalDebitUSD: {
    ar: "مجموع المدين بالدولار",
    en: "Total Debit USD",
  },
  totalDebitLYD: {
    ar: "مجموع المدين بالدينار",
    en: "Total Debit LYD",
  },
  totalCreditUSD: {
    ar: "مجموع الدائن بالدولار",
    en: "Total Credit USD",
  },
  totalCreditLYD: {
    ar: "مجموع الدائن بالدينار",
    en: "Total Credit LYD",
  },
  totalDebitEURO: {
    ar: "مجموع المدين باليورو",
    en: "Total Debit EURO",
  },
  totalCreditEURO: {
    ar: "مجموع الدائن باليورو",
    en: "Total Credit EURO",
  },
  balanceEURO: {
    ar: "صافي القيمة باليورو",
    en: "Balance EURO",
  },
};
