import { useState } from "react";
import content from "../content";
import getCulture from "utils/getCulture";
import { CardBody, Col, FormGroup, Input, Row } from "reactstrap";
import { useRouteMatch } from "react-router-dom";
import useApi from "hooks/useApi";
import account from "api/account";
import Spinner from "components/Spinner";

function AccumulationBalance({ rowId }) {
  let rout = useRouteMatch();
  const culture = getCulture(rout.url);
  const totalBalanceUnderAccountDatedApi = useApi(
    account.totalBalanceUnderAccountDated
  );

  const [data, setData] = useState({});

  const handleChange = async (e) => {
    const { value } = e.target;
    const res = await totalBalanceUnderAccountDatedApi.request(rowId, value);

    if (res.status === 200) {
      setData(res.data.data);
    }
  };

  return (
    <CardBody>
      {totalBalanceUnderAccountDatedApi.errorAlert}
      {totalBalanceUnderAccountDatedApi.loading && (
        <Spinner gate="#11cdef" bg="#fff" opacity />
      )}

      <Row>
        <Col md="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="date">
              {content.date[culture]}
            </label>
            <Input id="date" type="date" onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="balance-ly">
              {content.balanceLYD[culture]}
            </label>
            <Input
              id="balance-ly"
              type="text"
              onChange={handleChange}
              readOnly
              value={data.balanceLYD}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="balance-usd">
              {content.balanceUSD[culture]}
            </label>
            <Input
              id="balance-usd"
              type="text"
              onChange={handleChange}
              readOnly
              value={data.balanceUSD}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="balance-euro">
              {content.balanceEuro[culture]}
            </label>
            <Input
              id="balance-euro"
              type="text"
              onChange={handleChange}
              readOnly
              value={data.balanceEURO}
            />
          </FormGroup>
        </Col>
      </Row>
    </CardBody>
  );
}

export default AccumulationBalance;
