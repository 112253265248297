import React, { useEffect, useState } from "react";
import { CardBody, FormGroup, Input, Row, Col, Container } from "reactstrap";
import { Controller } from "react-hook-form";
import content from "../content";
import env from "env";

const UpdateAccount = (props) => {
  const {
    watch,
    errors,
    control,
    culture,
    accountCategories,
    accountLevels,
    accountClassifications,
  } = props;

  const digits = watch("digits");
  const classification = watch("accountClassification");

  return (
    <>
      <CardBody>
        <Row>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                {content.name[culture]}
              </label>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: `${content.name[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="name"
                    type="text"
                    className={errors.name && "error"}
                    value={value || ""}
                    onChange={({ target: { value } }) => onChange(value)}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="accountSelected">
                {content.accountNo[culture]} ({digits})
              </label>
              <Row>
                <Col md="6">
                  <Controller
                    control={control}
                    name="number"
                    rules={{
                      required: `${content.name[culture]} field is required`,
                      pattern: {
                        value: /^[0-9]*$/,
                      },
                      minLength: digits,
                      maxLength: digits,
                    }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="accountSelected"
                        type="text"
                        value={value || ""}
                        className={errors.number && "error"}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </Col>
                <Col md="6">
                  <Controller
                    control={control}
                    name="parentNo"
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="accountSelected1"
                        type="text"
                        disabled
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="accountLevelId">
                {content.accountingLevel[culture]}
              </label>
              <Controller
                control={control}
                name="accountLevelId"
                rules={{
                  required: `${content.name[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="accountLevelId"
                    disabled
                    type="select"
                    className={errors.accountLevelId && "error"}
                    value={value || "disabled"}
                    onChange={({ target: { value } }) => onChange(value)}
                  >
                    <option disabled value="disabled">
                      -- {content.selectOption[culture]} --
                    </option>
                    {accountLevels.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="accountCategories">
                {content.category[culture]}
              </label>
              <Controller
                control={control}
                name="accountCategoryId"
                rules={{
                  required: `${content.name[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    id="accountCategories"
                    type="select"
                    className={errors.accountCategoryId && "error"}
                    value={value || "disabled"}
                    onChange={({ target: { value } }) => onChange(value)}
                  >
                    <option disabled value="disabled">
                      -- {content.selectOption[culture]} --
                    </option>
                    {accountCategories.map((category) => (
                      <option value={category?.id} key={category?.id}>
                        {category?.name}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="accountClassification"
              >
                {content.accountClass[culture]}
              </label>
              <Controller
                control={control}
                name="accountClassification"
                rules={{
                  required: `${content.name[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    disabled
                    id="accountClassification"
                    type="select"
                    className={errors.accountClassification && "error"}
                    value={value || "disabled"}
                  >
                    <option disabled value="disabled">
                      -- {content.selectOption[culture]} --
                    </option>
                    {env.accountClassifications.map((item, index) => (
                      <option value={index + 1} key={index}>
                        {item[culture]}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="accountClassification"
              >
                {content.accountNature[culture]}
              </label>
              <Controller
                control={control}
                name="accountNature"
                rules={{
                  required: `${content.accountNature[culture]} field is required`,
                }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <Input
                    {...field}
                    disabled
                    id="accountNature"
                    type="select"
                    className={errors.accountNature && "error"}
                    value={value || "disabled"}
                  >
                    <option disabled value="disabled">
                      -- {content.selectOption[culture]} --
                    </option>
                    {env.accountNature.map((item, index) => (
                      <option value={index + 1} key={index}>
                        {item[culture]}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormGroup>
          </Col>
          {classification === 4 && (
            <>
              <Col md="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="customer">
                    {content.customer[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="customer"
                    rules={{
                      required: `${content.customer[culture]} field is required`,
                    }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="customer"
                        type="text"
                        className={errors.customerAccountId && "error"}
                        value={value || ""}
                        readOnly
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="phoneNumber">
                    {content.phoneNumber[culture]}
                  </label>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    rules={{
                      required: `${content.phoneNumber[culture]} field is required`,
                    }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <Input
                        {...field}
                        id="phoneNumber"
                        type="number"
                        className={errors.phoneNumber && "error"}
                        value={value || ""}
                        onChange={({ target: { value } }) => onChange(value)}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </>
          )}
        </Row>
      </CardBody>
    </>
  );
};

export default UpdateAccount;
