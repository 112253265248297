import client from "./client";

const endpoint = "/v1/entry";

const preload = () => client.get(`${endpoint}/preload`);

const createEntry = (schema) => client.post(`${endpoint}`, schema);

const createAndConfirm = (schema) =>
  client.post(`${endpoint}/createAndConfirm`, schema);

const entries = (pageNo, docsPerPage) =>
  client.get(`${endpoint}?page=${pageNo}&PostsPerPage=${docsPerPage}`);

const getByColumn = (col, val, page, docsPerPage) =>
  client.get(
    `${endpoint}/getbycoulum?col=${col}&value=${val}&Page=${page}&PostsPerPage=${docsPerPage}`
  );

const getEntry = (id) => client.get(`${endpoint}/${id}`);

const findDetailsAsync = (id) => client.get(`${endpoint}/details/${id}`);

const confirmEntry = (id) => client.put(`${endpoint}/confirmEntry/${id}`);

const unconfirmEntry = (id) => client.put(`${endpoint}/unconfirmEntry/${id}`);

const cancelEntry = (id) => client.put(`${endpoint}/cancelentry/${id}`);

const updateEntry = (id, formData) => client.put(`${endpoint}/${id}`, formData);

const deleteEntry = (id) => client.delete(`${endpoint}/${id}`);

const filterEntry = (schema, pageNo, docsPerPage) =>
  client.put(
    `${endpoint}/filter?page=${pageNo}&PostsPerPage=${docsPerPage}`,
    schema
  );

const getNotApprovedEntries = (pageNo, docsPerPage) =>
  client.get(
    `${endpoint}/NotApprovedEntries?page=${pageNo}&PostsPerPage=${docsPerPage}`
  );
const getNoApprovedEntriesOfUser = (schema) =>
  client.get(`${endpoint}/NotApprovedEntriesOfUser`, schema);

const approveEntries = (schema) =>
  client.put(`${endpoint}/approveEntries`, schema);

const customerLoanIssue = (schema) =>
  client.post(`${endpoint}/customerLoanIssue`, schema);

const customerLoanPayment = (schema) =>
  client.post(`${endpoint}/customerLoanPayment`, schema);

const shipmentInformation = (bulkNumber, countryId, shipmentType) =>
  client.get(`${endpoint}/shipmentInformation`, {
    bulkNumber,
    countryId,
    shipmentType,
  });

const getTransfersRelatedEntries = (pageNo, docsPerPage) =>
  client.get(
    `${endpoint}/getTransfersRelatedEntries?page=${pageNo}&PostsPerPage=${docsPerPage}`
  );

const balanceOfMainTreasuryByBranch = (schema) =>
  client.get(`${endpoint}/balanceOfMainTreasuryByBranch`, schema);

const printEntry = (id) => client.put(`${endpoint}/print/${id}`);

const lydToConvet = (schema) => client.get(`${endpoint}/lydToConvert`, schema);

export default {
  entries,
  preload,
  createEntry,
  getByColumn,
  getEntry,
  findDetailsAsync,
  createAndConfirm,
  confirmEntry,
  unconfirmEntry,
  cancelEntry,
  updateEntry,
  deleteEntry,
  filterEntry,
  getNotApprovedEntries,
  getNoApprovedEntriesOfUser,
  approveEntries,
  customerLoanIssue,
  customerLoanPayment,
  shipmentInformation,
  getTransfersRelatedEntries,
  balanceOfMainTreasuryByBranch,
  printEntry,
  lydToConvet,
};
