export default {
  categoryName: {
    ar: "المحاسبة",
    en: "Accounting",
  },
  title: {
    ar: "الدليل المحاسبي",
    en: "Bank data",
  },
  add: {
    ar: "اضافة",
    en: "Add",
  },
  delete: {
    ar: "حذف",
    en: "Delete",
  },
  edit: {
    ar: "تعديل",
    en: "Edit",
  },
  accountNo: {
    ar: "رقم الحساب",
    en: "Account number",
  },
  name: {
    ar: "الاسم",
    en: "Name",
  },
  followTo: {
    ar: "يتبع الى",
    en: "Follow to",
  },
  test: {
    ar: "تجربة",
    en: "Test",
  },
  accountClass: {
    ar: "صنف الحساب",
    en: "Account class",
  },
  accountNature: {
    ar: "طبيعة الحساب",
    en: "Account nature",
  },
  accountingLevel: {
    ar: "المستوى المحاسبي",
    en: "Accounting level",
  },
  defaultCreditorAccount: {
    ar: "حساب الدائن الافتراضي",
    en: "Default creditor account",
  },
  category: {
    ar: "التصنيف",
    en: "Category",
  },
  bank: {
    ar: "المصرف",
    en: "Bank",
  },
  contract: {
    ar: "الغقد",
    en: "Contract",
  },
  custodyHolder: {
    ar: "أمين العهدة",
    en: "Custody holder",
  },
  selectOption: {
    ar: "حدد اختيارا",
    en: "select an option",
  },
  roads: {
    ar: "طرق",
    en: "Roads",
  },
  bridges: {
    ar: "الجسور",
    en: "Bridges",
  },
  employee: {
    ar: "الموظفين",
    en: "Employees",
  },
  branches: {
    ar: "الفروع",
    en: "Branches",
  },
  branch: {
    ar: "الفرع",
    en: "Branch",
  },
  bankAccounts: {
    ar: "حسابات مصرفية",
    en: "Bank accounts",
  },
  save: {
    ar: "حفظ",
    en: "Save",
  },
  selectOption: {
    ar: "حدد اختيارا",
    en: "select an option",
  },
  Credit: {
    ar: "دائن",
    en: "Credit",
  },
  Debit: {
    ar: "مدين",
    en: "Debit",
  },
  Unknown: {
    ar: "غير معروف",
    en: "Unknown",
  },
  phoneNumber: {
    ar: "رقم الهاتف",
    en: "Phone number",
  },
  customer: {
    ar: "العميل",
    en: "Customer",
  },
  done: {
    ar: "تمت العملية بنجاح",
    en: "Done",
  },
  account: {
    ar: "حساب",
    en: "Account",
  },
  accountsTree: {
    ar: "شجرة الحسابات",
    en: "Accounts tree",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا الحساب ؟",
    en: "Are you sure you want to delete this account ?",
  },
  title: {
    ar: "الحساب",
    en: "Account",
  },
  name: {
    ar: "اسم الحساب",
    en: "Name",
  },
  number: {
    ar: "الرمز",
    en: "Number",
  },
  accountClassification: {
    ar: "نوع الحساب",
    en: "account Classification",
  },
  accountLevelId: {
    ar: "مستوى الحساب",
    en: "account Level",
  },
  AccountCategory: {
    ar: "تصنيف الحساب",
    en: "Account Category",
  },
  bankName: {
    ar: "اسم البنك",
    en: "Bank Name",
  },
  BankBranchName: {
    ar: "اسم الفرع",
    en: "Bank Branch Name",
  },
  BankAccountNumber: {
    ar: "رقم حساب البنك",
    en: "Bank Account Number",
  },
  CoustomerAccount: {
    ar: "حساب الزبون",
    en: "Coustomer Account",
  },
  PhoneNumber: {
    ar: "رقم الهاتف",
    en: "PhoneNumber",
  },
  Normal: {
    ar: "طبيعي",
    en: "Normal",
  },
  Bank: {
    ar: "مصرف",
    en: "Bank",
  },
  Treasury: {
    ar: "خزينة",
    en: "Treasury",
  },
  Employee: {
    ar: "موظف",
    en: "Employee",
  },
  Person: {
    ar: "شخصي",
    en: "Person",
  },
  Customer: {
    ar: "العميل",
    en: "Customer",
  },
  Revenue: {
    ar: "ايراد",
    en: "Revenue",
  },
  Expenses: {
    ar: "مصروفات",
    en: "Expenses",
  },
  Broker: {
    ar: "بروكر",
    en: "Broker",
  },
  followedBy: {
    ar: "يتبع الى",
    en: "follow by",
  },
  accountDetails: {
    ar: "بيانات الحساب",
    en: "Account Details",
  },

  balanceUSD: {
    ar: "صافي القيمة بالدولار",
    en: "Balance USD",
  },
  balanceLYD: {
    ar: "صافي القيمة بالدينار",
    en: "Balance LYD",
  },
  balanceEuro: {
    ar: "صافي القيمة باليورو",
    en: "Balance euro",
  },
  totalDebitUSD: {
    ar: "مجموع المدين بالدولار",
    en: "Total Debit USD",
  },
  totalDebitLYD: {
    ar: "مجموع المدين بالدينار",
    en: "Total Debit LYD",
  },
  totalCreditUSD: {
    ar: "مجموع الدائن بالدولار",
    en: "Total Credit USD",
  },
  totalCreditLYD: {
    ar: "مجموع الدائن بالدينار",
    en: "Total Credit LYD",
  },
  balanceDetails: {
    ar: "بيانات الرصيد",
    en: "Balance Details",
  },
  custommerDetails: {
    ar: "بيانات الزبون",
    en: "Customer Details",
  },
  UnConfirmedDetailsLYD: {
    ar: " الحركات المالية الغير معتمدة دينار",
    en: "unapproved financial movements LYD",
  },
  confirmedDetailsLYD: {
    ar: "الحركات المالية المعتمدة دينار",
    en: "Approved financial movements LYD",
  },
  UnConfirmedDetailsUSD: {
    ar: " الحركات المالية الغير معتمدة دولار",
    en: "unapproved financial movements USD",
  },
  confirmedDetailsUSD: {
    ar: "الحركات المالية المعتمدة دولار",
    en: "Approved financial movements USD",
  },
  financialMovements: {
    ar: "عرض الحركات المالية",
    en: "Show Financial Movements",
  },
  arrayIsEmpty: {
    ar: "لا يوجد حركات مالية لهذا الحساب",
    en: "There is no Financial Movements for this user ",
  },
  accumulationBalance: {
    ar: "الرصيد التجميعي",
    en: "Accumulation balance",
  },
  date: {
    ar: "التاريخ",
    en: "Date",
  },
  financiallyRevised: {
    ar: "تمت مراجعته ماليا",
    en: "Financially revised",
  },
  continue: {
    ar: "الاستمرار",
    en: "Continue",
  },
  revisedModal: {
    ar: "هل انت متأكد من ان هذ الحساب تمت مراجعته ماليا ؟",
    en: 'Are you sure that this account has been financially revised?'
  }
};
