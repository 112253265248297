export default {
  title: {
    ar: "إيصالات القبض",
    en: "Receipt vouchers",
  },
  accountName: {
    ar: "إسم الحساب",
    en: "Account name",
  },
  receiptVouchers: {
    ar: "إيصالات الدفع",
    en: "Receipt vouchers",
  },
  voucherno: {
    ar: "رقم الإيصال",
    en: "Voucher No.",
  },
  entryNum: {
    ar: "رقم القيد",
    en: "Entry number",
  },
  account: {
    ar: "ألحساب",
    en: "Account",
  },
  voucherDate: {
    ar: "تاريخ الإيصال",
    en: "Voucher Day",
  },
  credit: {
    ar: "دائن",
    en: "Credit",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  costCenter: {
    ar: "مركز التكلفة",
    en: "Cost center",
  },
  details: {
    ar: "التفاصيل",
    en: "Details",
  },
  edit: {
    ar: "تعديل",
    en: "Edit",
  },
  delete: {
    ar: "حذف",
    en: "Delete",
  },
  currencyValue: {
    ar: "قيمة العملة	",
    en: "Currency value",
  },
  actions: {
    ar: "العمليات",
    en: "Actions",
  },
  none: {
    ar: "لا يوجد",
    en: "None",
  },
  new: {
    ar: "جديد",
    en: "New",
  },
  user: {
    ar: "المستخدم",
    en: "User",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا المستوى المحاسبي ؟",
    en: "Are you sure you want to delete this accounting level",
  },
  done: {
    ar: "تمت العملية بنجاح",
    en: "Done",
  },
  editPopup: {
    ar: "تعديل مستوى الحساب",
    en: "Edit account level",
  },
  entryNo: {
    ar: "رقم القيد",
    en: "Entry number",
  },
  entryDate: {
    ar: "تاريخ القيد",
    en: "Entry date",
  },
  surplus: {
    ar: "فائض في خزينة الصين",
    en: "The surplus in the treasury of China",
  },
  creditor: {
    ar: "دائن",
    en: "Creditor",
  },
  debtor: {
    ar: "مدين",
    en: "Debtor",
  },
  account: {
    ar: "الحساب",
    en: "Account",
  },
  statement: {
    ar: "البيان",
    en: "Statement",
  },
  costCenter: {
    ar: "مركز التكلفة",
    en: "Cost center",
  },
  currencyValue: {
    ar: "قيمة العملة",
    en: "Currency value",
  },
  print: {
    ar: "طباعة",
    en: "Print",
  },
  printPdf: {
    ar: "طباعة بي دي اف",
    en: "Print pdf",
  },
  attachments: {
    ar: "المرفقات",
    en: "Attachments",
  },
  manual: {
    ar: "يدوي",
    en: "Manual",
  },
  review: {
    ar: "مراجعة",
    en: "Review",
  },
  totalDebit: {
    ar: "اجمالي مدين",
    en: "Total debit",
  },
  totalCredit: {
    ar: "اجمالي دائن",
    en: "Total credit",
  },
  difference: {
    ar: "الفرق",
    en: "The difference",
  },
  entryType: {
    ar: "نوع القيد",
    en: "Entry type",
  },
  actions: {
    ar: "العمليات",
    en: "Actions",
  },
  entryState: {
    ar: "حالة القيد",
    en: "Entry state",
  },
  date: {
    ar: "التاريخ",
    en: "Date",
  },
  currency: {
    ar: "العملة",
    en: "Currency",
  },
  entry: {
    ar: "قيد",
    en: "Entry",
  },
  deletePopup: {
    ar: "هل انت متأكد من انك تريد حذف هذا القيد ؟",
    en: "Are you sure you want to delete this entry ?",
  },
  shipmentType: {
    ar: "نوع الشحن",
    en: "Shipment type",
  },
  from: {
    ar: "من",
    en: "From",
  },
  to: {
    ar: "الى",
    en: "To",
  },
  print: {
    ar: "طباعة",
    en: "Print",
  },
  enterReason: {
    ar: "ادخل السبب",
    en: "Enter the reason",
  },
  continue: {
    ar: "الاستمرار",
    en: "Continue",
  },
  source: {
    ar: "المصدر",
    en: "Source",
  },
  client: {
    ar: "الزبون",
    en: "Client",
  },
};
