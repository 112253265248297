export default {
  title: {
    ar: 'ربط حساب جديد',
    en: 'Link new account'
  },
  categoryName: {
    ar: 'اسم التصنيف',
    en: 'Category name'
  },
  save: {
    ar: 'حفظ',
    en: 'Save'
  },
  link: {
    ar: 'ربط',
    en: 'Link'
  },
  categoryName: {
    ar: 'اسم التصنيف',
    en: 'Category name'
  },
  linkedAccounts: {
    ar: 'الحسابات المرتبطة',
    en: 'Linked accounts'
  },
  linkNewAccount: {
    ar: 'ربط حساب جديد',
    en: 'Link new acoount'
  },
  creditor: {
    ar: 'دائن',
    en: 'Creditor'
  },
  debit: {
    ar: 'مدين',
    en: 'Debit'
  },
  origin: {
    ar: 'أصل',
    en: 'Origin'
  },
  accountCategory: {
    ar: 'صنف الحساب',
    en: 'Account category'
  },
  addAccount: {
    ar: 'حساب',
    en: 'Account'
  },
  group: {
    ar: 'المجموعة',
    en: 'Group'
  },
  analytical: {
    ar: 'تحليلي',
    en: 'Analytical'
  },
  selectOption: {
    ar: 'حدد اختيارا',
    en: 'select an option'
  },
  mainDoor: {
    ar: 'باب رئيسي',
    en: 'Main door'
  },
  account: {
    ar: 'بند',
    en: 'Account'
  },
  subEntry: {
    ar: 'قيد فرعي',
    en: 'Sub-entry'
  },
  NoOfDigits: {
    ar: 'عدد الخانات',
    en: 'Number of digits'
  },
  details: {
    ar: 'التفاصيل',
    en: 'Details'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  delete: {
    ar: 'حذف',
    en: 'Delete'
  },
  accountingLevel: {
    ar: 'مستوى محاسبي',
    en: 'Accounting level'
  },
  actions: {
    ar: 'العمليات',
    en: 'Actions'
  },
  none: {
    ar: 'لا يوجد',
    en: 'None'
  },
  new: {
    ar: 'جديد',
    en: 'New'
  },
  accountNumber: {
    ar: 'رقم الحساب',
    en: 'Account number'
  },
  name: {
    ar: 'الاسم',
    en: 'Name'
  },
  accountNature: {
    ar: 'طبيعة الحساب',
    en: 'Account nature'
  },
}